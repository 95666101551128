import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AddIcon from "@mui/icons-material/Add";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import { useHistory, useLocation } from "react-router-dom";

import logo from "../../../../img/logo oud-all-white.png";
import useAuth from "../../../Store/hooks/useAuth";


const useStyles = makeStyles(()=>({
    logo: {
        height: 68,
        maxWidth: 140
    }
}));

const AssociationsNavTabs = ({ association_memberships, url, onImgLoad }) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const { authenticated } = useAuth();

    const membershipTabs = useMemo(()=>(
        association_memberships.map((association_membership, id)=>{
            let association = association_membership.association;

            let subpath = url + "/associations/" + association.slug;
            let path = subpath + "/member";
            if (association.is_board) {
                if (location.pathname.includes(association.slug + "/boardmember")){
                    path = subpath + "/member";
                } else {
                    path = subpath + "/boardmember";
                }
            }
            let onClick = ()=>history.push(path);

            const logo = association_membership.association.logo;

            let label;
            if (association.short_name) {
                label = association.short_name;
            } else {
                if (association.name.length > 15){
                    const len = association.name.length;
                    label = association.name.slice(association.name.indexOf(" ", len - 15) + 1);
                } else {
                    label = association.name;
                }
            }
            let tabLogo;
            if (logo) {
                tabLogo = <img
                    onLoad={(image)=>onImgLoad(image, id)}
                    className={classes.logo}
                    src={logo}
                    alt={"logo"}
                />;
            } else {
                tabLogo = <AccountBalanceIcon fontSize={"large"} className={classes.logo}/>;
            }
            return (
                {
                    subpath: subpath,
                    label: label,
                    onClick: onClick,
                    logo: tabLogo,
                }
            );
        })
    ), [association_memberships, url, location]);

    let tabsValue = false;
    if (location.pathname.includes("home")) {
        tabsValue = "home";
    } else {
        if (location.pathname.includes("/associations/overview")) {
            tabsValue = "/associations/overview";
        } else {
            const subpaths = membershipTabs.map(membershipTab=>membershipTab.subpath);
            subpaths.forEach(subpath=>{
                if (location.pathname.includes(subpath)) {
                    tabsValue = subpath;
                }
            });
        }
    }

    return (
        <Tabs
            value={tabsValue}
            textColor={"inherit"}
        >
            { /*<Tab*/ }
            { /*    value={"home"}*/ }
            { /*    onClick={()=>authenticated && history.push("/protected/home")}*/ }
            { /*    icon={<img className={classes.logo} src={logo} alt={""}/>}*/ }
            { /*    // label={"Home"}*/ }
            { /*/>*/ }
            { membershipTabs.map(({subpath, label, onClick, logo}, id)=>(
                <Tab
                    key={id}
                    value={subpath}
                    onClick={onClick}
                    label={logo ? "" : label}
                    icon={logo || null}
                />
            )) }
            <Tab
                value={"/associations/overview"}
                onClick={()=>history.push(url+"/associations/overview")}
                icon={
                    <AddIcon fontSize={"large"} color={"inherit"} className={classes.logo}/>
                }
            />
        </Tabs>
    );
};

AssociationsNavTabs.propTypes = {
    association_memberships: PropTypes.array.isRequired,
    url: PropTypes.string.isRequired,
    onImgLoad: PropTypes.func.isRequired
};

export default AssociationsNavTabs;
