import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";

import Wrapper from "../../../../Components/FormComponents/Wrapper";
import Block from "../../../../Components/PageLayout/Content/Block";
import Divider from "../../../../Components/PageLayout/Content/Divider";
import ExtremeTable from "../../../../Components/Tables/ExtremeTable";
import {useGetSepaMandatesByAssociationAndProfileQuery} from "../../../../Store/services/MySU/sepaMandates";

const sepaMandateHeaders = [
    {name: "mandate_id", title: "Mandate id"},
    {name: "signature_date", title: "Signature date"},
    {name: "withdrawal_date", title: "Withdrawal date"},
];

const SepaMandatesBlock = ({ profileSlug, associationSlug }) => {
    const { data: sepaMandates } = useGetSepaMandatesByAssociationAndProfileQuery({ profileSlug: profileSlug, associationSlug: associationSlug});

    return (
        <Block>
            <ExtremeTable
                rows={sepaMandates || []}
                headers={sepaMandateHeaders}
                showSelect={false}
                showGrouping={false}
                showColumnChooser={false}
                dateColumns={["signature_date", "withdrawal_date"]}
                width={300}
                title={"Debt Collection Sepa Mandates"}
            />
        </Block>
    );
};

SepaMandatesBlock.propTypes = {
    profileSlug: PropTypes.string.isRequired,
    associationSlug: PropTypes.string.isRequired
};

export default SepaMandatesBlock;