import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
        const backend = getState().auth.authentication_backend;
        const token = getState().auth.authentication_token;

        if (backend === "OIDC") {
            headers.set("X-CSRFToken", Cookies.get("csrftoken"));
        }
        if (backend === "jwt") {
            headers.set("Authorization", token);
        }

        return headers;
    },
});

const pessimisticUpdateSingle = async ({dispatch, queryFulfilled, api, query, entityToQueryParam}) => {
    try {
        const { data: patchedEntity } = await queryFulfilled;
        dispatch(
            api.util.updateQueryData(query, entityToQueryParam(patchedEntity), (draftEntity) => {
                Object.assign(draftEntity, patchedEntity);
            })
        );
        // eslint-disable-next-line no-empty
    } catch {}
};
const pessimisticUpdateSingleInList = async ({dispatch, queryFulfilled, api, query, entityToQueryParam, entityFilter}) => {
    try {
        const { data: patchedEntity } = await queryFulfilled;
        dispatch(
            api.util.updateQueryData(query, entityToQueryParam(patchedEntity), (draftEntityList) => {
                const index = draftEntityList.findIndex(entityFilter);
                draftEntityList.splice(index, 1, {...draftEntityList[index], ...patchedEntity});
            })
        );
        // eslint-disable-next-line no-empty
    } catch {}
};

const pessimisticUpdateList = () => {

};

const pessimisticCreationMultiple = async ({dispatch, queryFulfilled, api, query, entityToQueryParam}) => {
    try {
        const { data: newEntities } = await queryFulfilled;
        dispatch(
            api.util.updateQueryData(query, entityToQueryParam(newEntities), (draftEntityList) => {
                draftEntityList.push(...newEntities);
            })
        );
        // eslint-disable-next-line no-empty
    } catch {}
};

const pessimisticCreation = async ({dispatch, queryFulfilled, api, query, entityToQueryParam}) => {
    try {
        const { data: newEntity } = await queryFulfilled;
        dispatch(
            api.util.updateQueryData(query, entityToQueryParam(newEntity), (draftEntityList) => {
                draftEntityList.push(newEntity);
            })
        );
        // eslint-disable-next-line no-empty
    } catch {}
};

const pessimisticRemoval = async ({dispatch, queryFulfilled, api, query, queryParam, entityFilter}) => {
    try {
        await queryFulfilled;
        dispatch(
            api.util.updateQueryData(query, queryParam, (draftEntityList) => {
                const index = draftEntityList.findIndex(entityFilter);
                draftEntityList.splice(index, 1);
            })
        );
        // eslint-disable-next-line no-empty
    } catch {}
};

const providesListTags = (resultsWithIds, tagType, listTag) => {
    return resultsWithIds
        ? [
            { type: tagType, id: listTag },
            ...resultsWithIds.map(({ slug }) => ({ type: tagType, id: slug })),
        ]
        : [{ type: tagType, id: listTag }];
};

// initialize an empty api service that we'll inject endpoints into later as needed
const emptySplitApi = createApi({
    reducerPath: "api",
    keepUnusedDataFor: 30 * 60,
    baseQuery: baseQuery,
    endpoints: () => ({}),
});

export {
    emptySplitApi,
    providesListTags,
    pessimisticCreationMultiple,
    pessimisticCreation,
    pessimisticUpdateSingle,
    pessimisticUpdateSingleInList,
    pessimisticUpdateList,
    pessimisticRemoval
};
export default emptySplitApi;