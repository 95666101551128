import PropTypes from "prop-types";
import React, {useMemo} from "react";

import Block from "../../../../Components/PageLayout/Content/Block";
import ExtremeTable from "../../../../Components/Tables/ExtremeTable";
import {useGetDebtEntriesByAssociationAndProfileQuery} from "../../../../Store/services/MySU/debtEntries";
import {RowDetail} from "../../../Financial/DebtCollections/DebtEntries/DebtEntries";
import DebtEntrySummary from "../../../Financial/DebtCollections/DebtEntrySummary";

const debt_collections_headers = [
    {name: "date", title: "Date"},
    {name: "amount", title: "Amount"},
    {name: "cancellation", title: "Cancellation"},
];
const booleanColumns = ["cancellation"];
const currencyColumns = ["amount"];
const dateTimeColumns = ["date"];

const DebtCollectionsBlock = ({ profileSlug, associationSlug }) => {
    const { data: debt_collections } = useGetDebtEntriesByAssociationAndProfileQuery({ profileSlug: profileSlug, associationSlug: associationSlug });

    const debt_collections_rows = useMemo(()=>debt_collections?.map(debt_collection=>({
        slug: debt_collection.slug,
        date: debt_collection.date,
        amount: debt_collection.total_price,
    })),[debt_collections]);

    return (
        <Block>
            <ExtremeTable
                booleanColumns={booleanColumns}
                currencyColumns={currencyColumns}
                dateTimeColumns={dateTimeColumns}
                rows={debt_collections_rows || []}
                headers={debt_collections_headers}
                showSelect={false}
                showGrouping={false}
                showColumnChooser={false}
                width={250}
                showDetail={true}
                RowDetail={DebtEntrySummary}
                title={"Debt Collections"}
            />
        </Block>
    );
};

DebtCollectionsBlock.propTypes = {
    profileSlug: PropTypes.string.isRequired,
    associationSlug: PropTypes.string.isRequired
};

export default DebtCollectionsBlock;