import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {toast} from "material-react-toastify";
import PropTypes from "prop-types";
import React, { useState } from "react";

import Button from "../../Components/Buttons/Button";
import ConfirmationModal from "../../Components/Modals/ConfirmationModal";
import Block from "../../Components/PageLayout/Content/Block";
import useIsBoardMemberPage from "../../Store/hooks/useIsBoardMemberPage";
import {useCurrentAssociation} from "../../Store/services/MySU/associations";


const MembershipEnd = () => {
    const { data: association } = useCurrentAssociation();
    const isBoardPage = useIsBoardMemberPage();

    const [modalOpen, setModalOpen] = useState(false);

    const onCancel = () => setModalOpen(false);
    const onConfirm = () => {setModalOpen(false); toast.info("Not implemented yet");};
    const onEndButtonClick = () => setModalOpen(true);

    let today = new Date().toLocaleDateString();
    return (
        <Container>
            <Block>
                <ConfirmationModal
                    title={"End Membership"}
                    description={""}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    open={modalOpen}
                />
                <Typography variant={"h5"}>End Membership</Typography>
                { isBoardPage
                    ?
                    <div>
                        <Typography>You cannot end your membership while being still in the board.</Typography>
                    </div>
                    :
                    <div>
                        <Typography>After pressing the button your membership with { association.name || association.short_name } will end
                            at { association.default_date_left || today }.
                        </Typography>
                        <hr className={"box-title-separator"}/>
                        <Button color={"primary"} variant={"contained"} onClick={onEndButtonClick}>End</Button>
                    </div>
                }
            </Block>
        </Container>
    );
};

MembershipEnd.propTypes = {
};

export default MembershipEnd;
