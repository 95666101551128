import {faFolderMinus, faFolderOpen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Folder} from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import Container from "@mui/material/Container";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";

import Wrapper from "../../Components/FormComponents/Wrapper";
import RoutingButton from "../../Components/PageLayout/Content/RoutingButton";
import Tabs from "../../Components/PageLayout/Content/Tabs";
import Cancellations from "./Cancellations/Cancellations";
import DebtCollections from "./DebtCollections/DebtCollections";
import Invoices from "./Invoices/Invoices";
import Settings from "./Settings/Settings";


const useStyles = makeStyles(() => ({
    rightTab: {
        width: "300px!important",
        marginLeft: "auto"
    },
    rightTabPanel: {
        backgroundColor: "transparent",
        padding: "0px",
    },
}));

const Financial = ({ association }) => {
    const classes = useStyles();

    const tabElements = [
        { Icon: <FontAwesomeIcon icon={faFolderOpen} size={"lg"}/>, label: "Debt collections", value: "Current", Panel: <DebtCollections association={association}/>},
        { Icon: <Folder/>, label: "Individual pay.", value: "Disputed", Panel: <Invoices association={association} old={false}/>},
        { Icon: <FontAwesomeIcon icon={faFolderMinus} size={"lg"}/>, label: "Cancellations", value: "Cancellations", Panel: <Cancellations association={association}/>},
        { Icon: <SettingsIcon/>, label: "Financial settings", value: "settings", Panel: <Settings association={association}/>, tabClass: classes.rightTab, tabPanelClass: classes.rightTabPanel},
    ];

    return (
        <Container maxWidth={"xl"} >
            <Wrapper>
                <RoutingButton
                    routeStringPieces={[
                        "Financial"
                    ]}
                />
            </Wrapper>
            <Tabs tabElements={tabElements}/>
        </Container>
    );
};

Financial.propTypes = {
    association: PropTypes.object.isRequired
};

export default Financial;