import {Getter, Plugin, Template, TemplateConnector, TemplatePlaceholder} from "@devexpress/dx-react-core";
import {
    EditingState,
    FilteringState,
    GroupingState,
    IntegratedFiltering,
    IntegratedGrouping,
    IntegratedPaging,
    IntegratedSelection,
    IntegratedSorting,
    PagingState,
    RowDetailState,
    SearchState,
    SelectionState,
    SortingState
} from "@devexpress/dx-react-grid";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import {
    ColumnChooser,
    DragDropProvider,
    ExportPanel,
    Grid,
    GroupingPanel,
    PagingPanel,
    SearchPanel,
    Table,
    TableBandHeader,
    TableColumnReordering,
    TableColumnResizing,
    TableColumnVisibility,
    TableEditColumn,
    TableEditRow,
    TableFilterRow,
    TableGroupRow,
    TableHeaderRow,
    TableRowDetail,
    TableSelection,
    Toolbar,
    VirtualTable
} from "@devexpress/dx-react-grid-material-ui";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import saveAs from "file-saver";
import PropTypes from "prop-types";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {JsonParam, useQueryParam} from "use-query-params";

import { Helper } from "../../Helper";
import withExtraProps from "../HOC/withExtraProps";
import BooleanTypeProvider from "./DataTypeProviders/BooleanTypeProvider";
import ChoiceTypeProvider from "./DataTypeProviders/ChoiceTypeProvider";
import CurrencyTypeProvider from "./DataTypeProviders/CurrencyTypeProvider";
import DateTimeTypeProvider from "./DataTypeProviders/DateTimeTypeProvider";
import DateTypeProvider from "./DataTypeProviders/DateTypeProvider";
import NumberTypeProvider from "./DataTypeProviders/NumberTypeProvider";
import ColumnChooserButton from "./Plugins/ColumnChooserButton";
import ExportToggleButton from "./Plugins/ExportToolbarButton";
import FilterToggleButton from "./Plugins/FilterToggleButton";
import PopupEditing from "./Plugins/PopupEditing";
import SearchPanelInput from "./Plugins/SearchPanelInput";


// ****** Styling ******

const tableCellStyles = theme => ({
    noBorder: {
        borderBottom: 0,
    },
});

const tableComponentStyles = theme => ({
    tableStriped: {
        borderColor: "grey",
        "& tbody tr:nth-of-type(even)": {
            backgroundColor: "#F8F8F8"
            // "& tbody tr:nth-of-type(odd)": {
            // backgroundColor: alpha(theme.palette.primary.main, 0.05),
        },
    },
    odd: {
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
    }
});

const toolbarStyles = theme => ({
    noBorder: {
        borderBottom: "0 !important",
    },
});

const ToolbarRootBase = ({ classes, title, children, ...restProps }) => (
    <Toolbar.Root
        {...restProps}
        className={classes.noBorder}
    >
        <Typography variant={"h5"}>
            { title }
        </Typography>
        { children }
    </Toolbar.Root>
);

const TableComponentBase = ({ classes, ...restProps }) => (
    <Table.Table
        {...restProps}
        className={classes.tableStriped}
    />
);


const TableCellBase = ({ classes, ...restProps }) => (
    <Table.Cell
        {...restProps}
        className={classes.noBorder}
    />
);

const TableFilterRowCellBase = ({ classes, ...restProps }) => (
    <TableFilterRow.Cell
        {...restProps}
        className={classes.noBorder}
    />
);

const TableEditColumnCellBase = ({ classes, ...restProps }) => (
    <TableEditColumn.Cell
        {...restProps}
        className={classes.noBorder}
    />
);

const TableEditColumnHeaderCellBase = ({ classes, ...restProps }) => (
    <TableEditColumn.HeaderCell
        {...restProps}
        className={classes.noBorder}
    />
);

const TableSelectionCellBase = ({ classes, ...restProps }) => (
    <TableSelection.Cell
        {...restProps}
        className={classes.noBorder}
    />
);

const TableSelectionHeaderCellBase = ({ classes, ...restProps }) => (
    <TableSelection.HeaderCell
        {...restProps}
        className={classes.noBorder}
    />
);

const TableStubCellBase = ({ classes, ...restProps }) => (
    <Table.StubCell
        {...restProps}
        className={classes.noBorder}
    />
);

// TODO: overwrite TableSelectCell with different icon
const TableStubCell = withStyles(tableCellStyles, { name: "TableStubCell" })(TableStubCellBase);
const ToolBarRoot = withStyles(toolbarStyles, { name: "ToolBar" })(ToolbarRootBase);
const TableFilterRowCell = withStyles(tableCellStyles, { name: "TableFilterRowCell" })(TableFilterRowCellBase);
const TableEditColumnCell = withStyles(tableCellStyles, { name: "TableEditColumnCell" })(TableEditColumnCellBase);
const TableEditColumnHeaderCell = withStyles(tableCellStyles, { name: "TableEditColumnHeaderCell" })(TableEditColumnHeaderCellBase);
const TableSelectionCell = withStyles(tableCellStyles, { name: "TableSelectionCell" })(TableSelectionCellBase);
const TableSelectionHeaderCell = withStyles(tableCellStyles, { name: "TableSelectionHeaderCell" })(TableSelectionHeaderCellBase);
const TableCell = withStyles(tableCellStyles, { name: "TableCell" })(TableCellBase);
const TableComponent = withStyles(tableComponentStyles, { name: "TableComponent" })(TableComponentBase);

const VirtualTableRow = withStyles(tableComponentStyles, { name: "Row" })(
    ({ classes, rowIndex, row, ...restProps }) => {
        const odd = !(rowIndex % 2);
        return (
            <Table.Row
                {...restProps}
                {...{ hover: true }}
                className={odd ? classes.odd : null}
            />
        );
    }
);

const TableHeaderRowTitle = (props) => (
    <Typography
        noWrap
    >
        { props.children }
    </Typography>
);

const ExtremeTable = ({ headers, rows, getRowId, defaultSorting, defaultFilters, defaultHiddenColumnNames,
    pageSizes, showDetail, RowDetail, showSelect, showSelectAll, showEditing, showExporter, showGrouping,
    showColumnBands, selectByRowClick, booleanColumns, choiceColumns, choiceSelectionOptions, currencyColumns, dateTimeColumns,
    dateColumns, numberColumns, rowSelectionEnabledFilter, editingStateColumnExtensions, virtual, messages,
    selection: {selection: selection, setSelection:setSelection}, allowAdd, allowDelete, allowEdit, onAdd, onDelete,
    onEdit, showColumnChooser, showSearch, columnBands, custom_formatting_plugins, custom_state_controlling_plugins,
    custom_integrated_type_plugins, custom_table_plugins, custom_miscelaneous_plugins, editThroughPopup, Popup, width,
    defaultPageSize, defaultCurrentPage, title
}) => {

    // ****** Functions ******

    // const getDateColumnFilteringExtensions = dateColumns =>
    //     dateColumns.map(columnName => ({columnName: columnName, predicate: dateFieldColumnExtenstionPredicate}));

    const getHiddenColumnsFilteringExtensions = hiddenColumnNames =>
        hiddenColumnNames.map(columnName => ({columnName: columnName, filteringEnabled: false}));

    const rowSelectionEnabled = row => rowSelectionEnabledFilter(row, selection);

    // ****** States ******

    const [sorting, setSorting] = useState(defaultSorting);
    const [grouping, setGrouping] = useState([]);
    const [hiddenColumnNames, setHiddenColumnNames] = useState(defaultHiddenColumnNames);
    const [filteringColumnExtensions, setFilteringColumnExtensions] = useState(getHiddenColumnsFilteringExtensions(defaultHiddenColumnNames));
    const [columnOrder, setColumnOrder] = useState(headers.map(header=>header.name));
    const [expandedRowIds, setExpandedRowIds] = useState([]);
    const [filters, setFilters] = useQueryParam("filter", JsonParam);
    // const [filters, setFilters] = useState(defaultFilters);
    const [searchValue, setSearchState] = useState("");
    const [defaultColumnWidths] = useState(headers.map(header=>({columnName: header.name, width: width })));

    useEffect(()=>setColumnOrder(headers.map(header=>header.name)), [headers]);
    useEffect(()=>setHiddenColumnNames(defaultHiddenColumnNames), [defaultHiddenColumnNames]);
    useEffect(()=>setFilteringColumnExtensions(getHiddenColumnsFilteringExtensions(hiddenColumnNames)), [hiddenColumnNames]);

    // ****** Export ******

    const exporterRef = useRef(null);

    const startExport = useCallback((options) => {exporterRef.current.exportGrid(options);}, [exporterRef]);

    const onSave = (workbook) => {
        workbook.csv.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.csv");
        });
    };

    // ****** Data handling function ******

    const onCommitChanges = ({ added, changed, deleted }) => {
        if (added) {
            onAdd(added[0]);
        }
        if (changed) {
            const original_row = rows.find(row=>changed[getRowId(row)]);
            // Do not execute `onEdit` to prevent errors caused by passing no changes.
            if (original_row !== undefined) {
                let edited_row_index = getRowId(original_row);
                let edited_row = {...original_row, ...changed[edited_row_index]};
                let differences = Helper.objectDifference(original_row, edited_row);
                onEdit(differences, original_row, edited_row, edited_row_index);
            }
        }
        if (deleted) {
            const original_row = rows.find(row=>deleted[0] === getRowId(row));
            onDelete(deleted[0], original_row);
        }
    };

    //  ****** Dynamic components  ******

    const ToolbarRootWithTitle = useMemo(()=>
        withExtraProps(ToolBarRoot, {title: title}),
    [title]
    );
    const filtersAreEnabled = filters !== undefined;
    const toggleFilters = useCallback(() => filtersAreEnabled
        ? setFilters(undefined)
        : setFilters([]),
    [filtersAreEnabled, setFilters]
    );
    const ConditionalTableFilterRow = useMemo(() => filtersAreEnabled
        ? TableFilterRow.Row
        : ()=> <React.Fragment/>
    , [filtersAreEnabled]);
    console.log(filters, filtersAreEnabled);

    //  ****** Plugin Configuration  ******

    const formatting_plugins = [
        <BooleanTypeProvider for={booleanColumns} />,
        <ChoiceTypeProvider for={choiceColumns} choiceSelectionOptions={choiceSelectionOptions}/>,
        <CurrencyTypeProvider for={currencyColumns} />,
        <DateTypeProvider for={dateColumns} />,
        <DateTimeTypeProvider for={dateTimeColumns}/>,
        <NumberTypeProvider for={numberColumns} />,
    ];
    const state_controlling_plugins = [
        <SortingState
            sorting={sorting}
            onSortingChange={setSorting}
        />,
        <FilteringState
            filters={filters}
            onFiltersChange={setFilters}
        />,
        <PagingState
            defaultCurrentPage={defaultCurrentPage}
            defaultPageSize={defaultPageSize}
        />,
        <SearchState
            value={searchValue}
            onValueChange={setSearchState}
        />
    ];
    const integrated_type_plugins = [
        <IntegratedSorting />,
        <IntegratedFiltering
            columnExtensions={filteringColumnExtensions}
        />,
        <IntegratedPaging/>
    ];

    const table_plugins = [
        <DragDropProvider />,
        virtual ?
            <VirtualTable rowComponent={VirtualTableRow}/>
            : <Table
                tableComponent={TableComponent}
                cellComponent={TableCell}
                stubCellComponent={TableStubCell}
                stubHeaderCellComponent={TableStubCell}
            />,
        <TableColumnReordering
            order={columnOrder}
            onOrderChange={setColumnOrder}
        />,
        <TableColumnResizing
            defaultColumnWidths={defaultColumnWidths}
        />,
        <TableHeaderRow
            showSortingControls
            cellComponent={TableCell}
            titleComponent={TableHeaderRowTitle}
        />,
        <TableFilterRow
            showFilterSelector
            cellComponent={TableFilterRowCell}
            rowComponent={ConditionalTableFilterRow}
        />,
        <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={setHiddenColumnNames}
        />,
    ];
    const miscelaneous_plugins = [
        <Toolbar rootComponent={ToolbarRootWithTitle}/>,
        <PagingPanel
            pageSizes={pageSizes}
        />,
    ];

    if (showDetail) {
        state_controlling_plugins.push(
            <RowDetailState
                expandedRowIds={expandedRowIds}
                onExpandedRowIdsChange={setExpandedRowIds}
            />);
        table_plugins.push(
            <TableRowDetail
                contentComponent={RowDetail}
            />);
    }
    if (showEditing) {
        state_controlling_plugins.push(
            <EditingState
                onCommitChanges={onCommitChanges}
                columnExtensions={editingStateColumnExtensions}
            />);
        if (editThroughPopup) {
            miscelaneous_plugins.push(
                <PopupEditing PopupComponent={Popup}/>);
        } else {
            table_plugins.push(
                <TableEditRow/>);
        }
        table_plugins.push(
            <TableEditColumn
                cellComponent={TableEditColumnCell}
                headerCellComponent={TableEditColumnHeaderCell}
                showAddCommand={allowAdd}
                showEditCommand={allowEdit}
                showDeleteCommand={allowDelete}
                messages={messages}
            />);
    }
    if (showSelect) {
        state_controlling_plugins.push(
            <SelectionState
                selection={selection}
                onSelectionChange={setSelection}
            />);
        integrated_type_plugins.splice(integrated_type_plugins.length-1, 0,
            <PatchedIntegratedSelection
                rowSelectionEnabled={rowSelectionEnabled}
            />);
        table_plugins.push(
            <PatchedTableSelection
                showSelectAll={showSelectAll}
                selectByRowClick={selectByRowClick}
                rowSelectionEnabled={rowSelectionEnabled}
                headerCellComponent={TableSelectionHeaderCell}
            />);
    }
    if (showGrouping) {
        integrated_type_plugins.push(
            <IntegratedGrouping/>);
        state_controlling_plugins.push(
            <GroupingState
                grouping={grouping}
                onGroupingChange={setGrouping}
            />);
        table_plugins.push(
            <TableGroupRow/>);
        miscelaneous_plugins.push(
            <GroupingPanel
                showGroupingControls
                showSortingControls
            />);
    }
    if (showSearch) {
        miscelaneous_plugins.push(
            <SearchPanel
                inputComponent={SearchPanelInput}
            />
        );
    }
    miscelaneous_plugins.push(<FilterToggleButton onClick={toggleFilters}/>);
    if (showColumnChooser) {
        miscelaneous_plugins.push(
            <ColumnChooser
                toggleButtonComponent={ColumnChooserButton}
            />
        );
    }
    if (showExporter) {
        miscelaneous_plugins.push(
            <ExportPanel
                startExport={startExport}
                toggleButtonComponent={ExportToggleButton}
            />);
    }
    if (showColumnBands) {
        table_plugins.push(
            <TableBandHeader
                columnBands={columnBands}
            />);
    }
    if (virtual) {
        table_plugins.push(
            <TableRowsIndexer/>
        );
    }

    const plugins = [
        formatting_plugins,
        custom_formatting_plugins,
        state_controlling_plugins,
        custom_state_controlling_plugins,
        integrated_type_plugins,
        custom_integrated_type_plugins,
        table_plugins,
        custom_table_plugins,
        miscelaneous_plugins,
        custom_miscelaneous_plugins,
    ];

    return (
        <>
            <Grid
                key={plugins.length}
                rows={rows}
                columns={headers}
                getRowId={getRowId}
            >
                { plugins.map((plugin, index) => (
                    <Plugin key={index}>{ plugin }</Plugin>
                )) }
            </Grid>
            { showExporter &&
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={headers}
                grouping={grouping}
                selection={selection}
                onSave={onSave}
                getRowId={getRowId}
            />
            }
        </>
    );
};

ExtremeTable.propTypes = {
    // ---- Required properties ----
    /* Headers above the table */
    headers: PropTypes.arrayOf(PropTypes.exact({
        name: PropTypes.string,
        title: PropTypes.string
    })).isRequired,
    /* Rows in the table */
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,

    // ---- Optional properties ----
    /* Boolean that enables selection */
    showSelect: PropTypes.bool,
    /* Boolean for showing the checkbox with allows selecting of all at once */
    showSelectAll: PropTypes.bool,
    /* Setting to true enables the option to edit rows */
    showEditing: PropTypes.bool,
    /* determine what can be edited */
    allowAdd: PropTypes.bool,
    allowDelete: PropTypes.bool,
    allowEdit: PropTypes.bool,
    /* determine what happens when command is executed */
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    /* Setting to true creates an option to export to .csv */
    showExporter: PropTypes.bool,
    /* Boolean for enabling the option to group options */
    showGrouping: PropTypes.bool,
    /* Boolean for enabling the option to show a more detailed row */
    showDetail: PropTypes.bool,
    /* Boolean that determines the search bar is present */
    showSearch: PropTypes.bool,
    /* Boolean that determines whether columns can be hidden or not; it is the eye menu next to search */
    showColumnChooser: PropTypes.bool,
    /* Boolean that determines whether columns can be grouped into bands */
    showColumnBands: PropTypes.bool,
    /* Determines whether modal is used for editing or in-row editing */
    editThroughPopup: PropTypes.bool,
    /* Setting to true allows selection of rows by clicking on the row instead of only the checkbox */
    selectByRowClick: PropTypes.bool,
    /* List of page sizes for the pagination */
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    defaultPageSize: PropTypes.number,
    defaultCurrentPage: PropTypes.number,
    defaultFilters: PropTypes.arrayOf(PropTypes.exact({
        columnName: PropTypes.string,
        operation: PropTypes.string,
        value: PropTypes.string
    })),
    defaultSorting: PropTypes.arrayOf(PropTypes.exact({
        columnName: PropTypes.string,
        direction: PropTypes.string,
    })),
    editingStateColumnExtensions: PropTypes.arrayOf(PropTypes.exact({
        columnName: PropTypes.string,
        editingEnabled: PropTypes.bool,
    })),
    defaultHiddenColumnNames: PropTypes.arrayOf(PropTypes.string),
    /* Function that is called on the row to give the indentifier of said row; setting to unidentified gives the default of returning the index */
    getRowId: PropTypes.func,
    /* Object determining how the columns should be banded */
    columnBands: PropTypes.array,
    /* List of column names that determines which columns need to rendered as currency */
    booleanColumns: PropTypes.arrayOf(PropTypes.string),
    /* List of column names that determines which columns need to rendered as selection field */
    choiceColumns: PropTypes.arrayOf(PropTypes.string),
    /* Object that maps the available choices for choiceColumns */
    choiceSelectionOptions: PropTypes.object,
    /* List of column names that determines which columns need to rendered as currency */
    currencyColumns: PropTypes.arrayOf(PropTypes.string),
    /* List of column names that determines which columns need to rendered as dates */
    dateColumns: PropTypes.arrayOf(PropTypes.string),
    dateTimeColumns: PropTypes.arrayOf(PropTypes.string),
    /* List of column names that determines which columns need to rendered as numbers */
    numberColumns: PropTypes.arrayOf(PropTypes.string),
    /* Function that decides whether rows should be selectable based on the current selection */
    rowSelectionEnabledFilter: PropTypes.func,
    /* Object that handles the selection state for the table. It consists of a function that is called once the selection is updated and the selection, usually constructed using `const [selection, setSelection] = useState([])`*/
    selection: PropTypes.exact({
        selection: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        setSelection: PropTypes.func
    }),
    /* Component to use for editing using a modal. */
    Popup: PropTypes.func,
    /* Component that is rendered when the detail of a row is requested; takes the row as input. */
    RowDetail: PropTypes.func,
    /* Lists of plugins to be included. */
    custom_formatting_plugins: PropTypes.array,
    custom_state_controlling_plugins: PropTypes.array,
    custom_integrated_type_plugins: PropTypes.array,
    custom_table_plugins: PropTypes.array,
    custom_miscelaneous_plugins: PropTypes.array,
    virtual: PropTypes.bool,
    width: PropTypes.number,
    messages: PropTypes.shape({
        addCommand: PropTypes.string,
        editCommand: PropTypes.string,
        deleteCommand: PropTypes.string
    }),
    title: PropTypes.string
};

ExtremeTable.defaultProps = {
    showSelect: true,
    showSelectAll: true,
    showDetail: false,
    showEditing: false,
    showExporter: false,
    showGrouping: false,
    editThroughPopup: false,
    selectByRowClick: false,
    showColumnBands: false,
    showColumnChooser: true,
    showSearch: true,
    pageSizes: [5,10,25,50,100,500],
    defaultPageSize: 10,
    defaultCurrentPage: 0,
    defaultFilters: [],
    defaultSorting: [],
    defaultHiddenColumnNames: [],
    getRowId: (row) => row.slug,
    editingStateColumnExtensions: [],
    columnBands: [],
    booleanColumns: [],
    choiceColumns: [],
    choiceSelectionOptions: {},
    currencyColumns: [],
    dateColumns: [],
    dateTimeColumns: [],
    numberColumns: [],
    rowSelectionEnabledFilter: (row, selection) => true,
    selection: {selection: [], setSelection: ()=>{}},
    Popup: null,
    RowDetail: ({ row }) => <div/>,
    allowDelete: false,
    allowEdit: true,
    allowAdd: false,
    onAdd: (added) => {},
    onEdit: (changed) => {},
    onDelete: (deleted) => {},
    custom_formatting_plugins: [],
    custom_state_controlling_plugins: [],
    custom_integrated_type_plugins: [],
    custom_table_plugins: [],
    custom_miscelaneous_plugins: [],
    virtual: false,
    width: 180,
    messages: {
        addCommand: "New",
        editCommand: "Edit",
        deleteCommand: "Delete"
    },
    title: ""
};

export default ExtremeTable;

// sakthi vel @ https://stackoverflow.com/questions/53800402/disable-selection-for-rows-with-particular-row-ids-in-devexpress-react-grid
// Removes checkbox if rowSelectionEnabled is true for the row
class PatchedTableSelection extends React.PureComponent {
    render() {
        const { rowSelectionEnabled, ...restProps } = this.props;
        return (
            <TableSelection
                cellComponent={(props) => rowSelectionEnabled(props.tableRow.row) ? (
                    <TableSelectionCell {...props} />
                ) : (
                    <TableStubCell {...props} />
                )}
                {...restProps}
            />
        );
    }
}

// sakthi vel @ https://stackoverflow.com/questions/53800402/disable-selection-for-rows-with-particular-row-ids-in-devexpress-react-grid
//
// Removes rows with rowSelectionEnabled true from the selectAll button
// Breaks sorting
//
// -------------------------------------------
//
class PatchedIntegratedSelection extends React.PureComponent {
    render() {
        const { rowSelectionEnabled, ...restProps } = this.props;
        const returnRows = () => this.rows;
        return (
            <Plugin>
                <Getter
                    name={"rows"}
                    computed={({ rows }) => {
                        this.rows = rows;
                        return rows.filter(rowSelectionEnabled);
                    }}
                />
                <IntegratedSelection {...restProps} />
                <Getter
                    name={"rows"}
                    computed={returnRows}
                    // computed={() => this.rows}
                />
            </Plugin>
        );
    }
}

// kvet @ https://supportcenter.devexpress.com/ticket/details/t956782/grid-virtual-table-alternate-row-color-changes-on-scrolling
//
// Computes index of row for use in virtual scrolling.
//
// -------------------------------------------
//
class TableRowsIndexer extends React.PureComponent {
    render() {
        return (
            <Plugin>
                <Template name={"tableRow"}>
                    { ({ tableRow, ...restParams }) => (
                        <TemplateConnector>
                            { ({ rows }) => {
                                const rowIndex = rows.indexOf(tableRow.row);
                                return (
                                    <TemplatePlaceholder
                                        params={{ rowIndex, tableRow, ...restParams }}
                                    />
                                );
                            } }
                        </TemplateConnector>
                    ) }
                </Template>
            </Plugin>
        );
    }
}


// class ConditionalTableFilterRow extends React.PureComponent {
//     render() {
//         const { filters, ...restProps } = this.props;
//         return (
//             <TableFilterRow
//                 rowComponent={(props) => filters !== undefined && <TableFilterRow.Row {...props} />}
//                 {...restProps}
//             />
//         );
//     }
// }