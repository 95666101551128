import CachedIcon from "@mui/icons-material/Cached";
import Create from "@mui/icons-material/Create";
import DescriptionIcon from "@mui/icons-material/Description";
import EuroIcon from "@mui/icons-material/Euro";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import GradeIcon from "@mui/icons-material/Grade";
import PropTypes from "prop-types";
import React from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import BooleanField from "../../../Components/FormComponents/Fields/BooleanField";
import NumberField from "../../../Components/FormComponents/Fields/NumberField";
import TextField from "../../../Components/FormComponents/Fields/TextField";
import YearlessDateField from "../../../Components/FormComponents/Fields/YearlessDateField";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";

const MemberTypesForm = ({ memberType, handleMemberTypeChange, onSubmit, onCancel, onDelete, enableDelete }) => {
    return (
        <ValidatorForm
            onSubmit={onSubmit}
            onError={errors => console.log(errors)}
        >
            <Wrapper>
                <IconHolder Icon={Create}/>
                <TextField
                    name={"name"}
                    value={memberType.type}
                    onChange={(event) => {
                        handleMemberTypeChange("type", event.target.value);
                    }}
                />
            </Wrapper>
            <Wrapper>
                <IconHolder Icon={EuroIcon}/>
                <NumberField
                    name={"membership fee"}
                    value={memberType.membership_fee}
                    onChange={(event) => {
                        handleMemberTypeChange("membership_fee", event.target.value);
                    }}
                    InputProps={{
                        inputProps: {
                            step: 0.1, min: 0
                        }
                    }}
                />
            </Wrapper>
            <Wrapper>
                <IconHolder Icon={GradeIcon}/>
                <BooleanField
                    name={"Only chooseable by board"}
                    validators={["required"]}
                    errorMessages={["A type has to be set"]}
                    value={memberType.only_chooseable_by_board}
                    onChange={(event) => {
                        handleMemberTypeChange("only_chooseable_by_board", event.target.value);
                    }}
                />
            </Wrapper>
            <Wrapper>
                <IconHolder Icon={CachedIcon}/>
                <BooleanField
                    name={"Full year Membership"}
                    value={memberType.full_year_membership}
                    onChange={(event) => {
                        handleMemberTypeChange("full_year_membership", event.target.value);
                    }}
                    required
                />
            </Wrapper>
            { memberType.full_year_membership ||
            <>
                <Wrapper>
                    <IconHolder Icon={EventAvailableIcon}/>
                    <YearlessDateField
                        name={"Membertype start date"}
                        onChange={
                            (value)=>handleMemberTypeChange("start_date", value)
                        }
                        value={memberType.start_date}
                    />
                </Wrapper>
                <Wrapper>
                    <IconHolder Icon={EventBusyIcon}/>
                    <YearlessDateField
                        name={"Membertype end date"}
                        onChange={
                            (value)=>handleMemberTypeChange("end_date", value)
                        }
                        value={memberType.end_date}
                    />
                </Wrapper>
            </>
            }
            <Wrapper>
                <IconHolder Icon={FormatListNumberedIcon}/>
                <NumberField
                    name={"Ordering"}
                    helperText={"Memberships will be ordered according to this field"}
                    value={memberType.order}
                    onChange={(event) => {
                        handleMemberTypeChange("order", event.target.value);
                    }}
                    required
                />
            </Wrapper>
            <Wrapper>
                <IconHolder Icon={DescriptionIcon}/>
                <TextField
                    name={"Description"}
                    helperText={""}
                    value={memberType.helper_text}
                    onChange={(event) => {
                        handleMemberTypeChange("helper_text", event.target.value);
                    }}
                    multiline
                />
            </Wrapper>
            <Wrapper>
                <div>
                    <Button variant={"outlined"} disabled={!enableDelete} onClick={onDelete} color={"error"}>
                        Delete
                    </Button>
                </div>
                <div>
                    <Button variant={"contained"} onClick={onCancel} color={"secondary"}>Cancel</Button>
                    &nbsp;
                    <Button type={"submit"} variant={"contained"} color={"primary"}>Save</Button>
                </div>
            </Wrapper>
        </ValidatorForm>
    );
};

MemberTypesForm.propTypes = {
    memberType: PropTypes.object.isRequired,
    handleMemberTypeChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    enableDelete: PropTypes.bool.isRequired
};

export default MemberTypesForm;