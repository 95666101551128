import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(theme=>({
    container: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: theme.spacing(2),
        width: "100%"
    },
    item: {
        flexGrow: ({ itemGrowth }) => itemGrowth
    }
}));

const chunkArrayIntoListOfArrays = (ar, num) => {
    return ar.reduce(function(r, v, i) {
        if (i % num === 0) r.push(ar.slice(i, i + num));
        return r;
    }, []);
};

const CardGridV2 = ({ componentProps, Component, chunkSize, spacings, itemGrowth, justifyContent, direction }) => {
    const classes = useStyles({ itemGrowth: itemGrowth });
    let componentPropsInGridFormat = chunkSize > 0 ? chunkArrayIntoListOfArrays(componentProps, chunkSize) : [ componentProps ];

    return (
        <Grid container className={classes.container} spacing={2}>
            { componentPropsInGridFormat.map((row, r)=>(
                <Grid container justifyContent={justifyContent} direction={direction} item xs={12} key={"card_row "+r} spacing={2}>
                    { row.map((card, c)=>(
                        <Grid item className={classes.item} key={"card "+r+" "+c} xs={4}>
                            <Component {...card}/>
                        </Grid>
                    )) }
                </Grid>
            )) }
        </Grid>
    );
};

const CardGrid = ({ componentProps, Component, chunkSize, spacings, itemGrowth, justifyContent, direction }) => {
    const classes = useStyles({ itemGrowth: itemGrowth });
    let componentPropsInGridFormat = chunkSize > 0 ? chunkArrayIntoListOfArrays(componentProps, chunkSize) : [ componentProps ];

    return (
        <Grid container className={classes.container} spacing={spacings[0]}>
            { componentPropsInGridFormat.map((row, r)=>(
                <Grid container justifyContent={justifyContent} direction={direction} item xs={12} key={"card_row "+r} spacing={spacings[1]}>
                    { row.map((card, c)=>(
                        <Grid item className={classes.item} key={"card "+r+" "+c}>
                            <Component {...card}/>
                        </Grid>
                    )) }
                </Grid>
            )) }
        </Grid>
    );
};




CardGrid.propTypes = {
    componentProps: PropTypes.array.isRequired,
    Component: PropTypes.elementType.isRequired,
    justifyContent: PropTypes.oneOf(["left", "center", "right"]),
    direction: PropTypes.oneOf(["row", "row-reverse", "column", "column-reverse"]),
    chunkSize: PropTypes.number,
    spacings: PropTypes.array,
    itemGrowth: PropTypes.number,
};

CardGrid.defaultProps = {
    justifyContent: "center",
    direction: "row",
    chunkSize: 0,
    spacings: [2,2,2],
    itemGrowth: 0
};
export { CardGridV2 };

export default CardGrid;
