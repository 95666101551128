import moment from "moment/moment";
import PropTypes from "prop-types";
import React, {useMemo} from "react";

import {useGetDataFieldsByAssociationQuery} from "../../../Store/services/MySU/dataFields";
import {useGetMemberTypesByAssociationQuery} from "../../../Store/services/MySU/memberTypes";
import Info from "../Info";

const MembershipInfo = ({ association, membership }) => {
    const { data: membertypes, loadingTypes } = useGetMemberTypesByAssociationQuery(association.slug);
    const { data: dataFields, loadingFields } = useGetDataFieldsByAssociationQuery(association.slug);

    const morphMembershipToData = (membership) => {
        let data = {
            "Date Joined:": moment(membership.date_joined).format("L")
        };
        if (membership.date_left) {
            const date_left_or_leaving_text = moment(membership.date_left) <= moment() ? "Date Left:" : "Date Leaving";
            data[date_left_or_leaving_text] = moment(membership.date_left).format("L");
        }
        data["Status"] = membership.status;
        data["Pay by"] = membership.pay_by;
        if (!loadingTypes) {
            if (membership.new_type) {
                data = {...data,
                    "Current Membertype:": membertypes?.find(membertype=>membertype.url === membership.type)?.type || "",
                    "Upcoming Membertype:": membertypes?.find(membertype=>membertype.url === membership.new_type)?.type || ""
                };
            } else {
                data = {...data,
                    "Membertype:": membertypes?.find(membertype=>membertype.url === membership.type)?.type || "",
                };
            }
        }
        if (!loadingFields) {
            const dataFieldToTypeDict = Object.fromEntries(dataFields?.map(dataField=>[dataField.name, dataField.type]) || []);
            const specificData = Object.fromEntries(membership.specific_data.map(sp=>[sp.name, dataFieldToTypeDict[sp.name] === "Boolean"
                ? (sp.value === "True" ? "Yes" : "No")
                : sp.value
            ]) || []);
            data = {...data, ...specificData};
        }
        return data;
    };

    const data = useMemo(() => morphMembershipToData(membership), [membership, membertypes, dataFields, loadingTypes, loadingFields]);
    return (
        <Info
            headerless={true}
            data={data}
        />
    );
};

MembershipInfo.propTypes = {
    association: PropTypes.object.isRequired,
    membership: PropTypes.object.isRequired
};

export default MembershipInfo;