import {Plugin, Template, TemplatePlaceholder} from "@devexpress/dx-react-core";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import React from "react";

import Button from "../../../Components/Buttons/Button";

const basePluginDependencies = [
    { name: "Toolbar" }
];

class ToolbarButton extends React.PureComponent {
    render() {
        const { Icon, label, extraPluginDependencies } = this.props;
        const dependencies = basePluginDependencies.concat(extraPluginDependencies);
        return (
            <Plugin
                dependencies={dependencies}
            >
                <Template name={"toolbarContent"}>
                    <TemplatePlaceholder />
                    <Tooltip title={this.props.tooltip}>
                        <Button
                            color={"primary"}
                            variant={"outlined"}
                            onClick={this.props.onClick}
                            sx={{ml: 1}}
                            style={{textTransform: "none"}}
                            startIcon={<Icon/>}
                            size={"small"}
                        >
                            { label }
                        </Button>
                    </Tooltip>
                </Template>
            </Plugin>
        );
    }
}

ToolbarButton.propTypes = {
    Icon: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    tooltip: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    extraPluginDependencies: PropTypes.array,
};

ToolbarButton.defaultProps = {
    extraPluginDependencies: []
};

export default ToolbarButton;