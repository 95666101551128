import {DataTypeProvider} from "@devexpress/dx-react-grid";
import Input from "@mui/material/Input";
import React from "react";

const NumberOperations = [
    "equal",
    "notEqual",
    "greaterThan",
    "greaterThanOrEqual",
    "lessThan",
    "lessThanOrEqual",
];

const NumberEditor = ({ value, onValueChange }) => {
    return (
        <Input
            value={value}
            onChange={event => onValueChange(event.target.value)}
            style={{ width: "100%" }}
            type={"number"}
            placeholder={"Select number"}
        />
    );
};

const NumberTypeProvider = props => (
    <DataTypeProvider
        editorComponent={NumberEditor}
        availableFilterOperations={NumberOperations}
        {...props}
    />
);

export default NumberTypeProvider;