import { capitalCase } from "change-case";


export const flattenMembers = (members, studies, memberTypes) => {
    // membership has profile, member type and association specific data attached
    // this flattens it to a list of objects that are no longer nested.
    if (!members || !studies || !memberTypes) {
        return undefined;
    }
    let studyUrlToNameDict = Object.fromEntries(studies.map(study=>([study.url, study.name])));
    let memberTypeUrlToNameDict = Object.fromEntries(memberTypes.map(memberType=>([memberType.url, memberType.type])));
    return members?.map(membership=>{
        // Get all values
        let member = {
            ...membership,
            ...membership.profile,
            type: memberTypeUrlToNameDict[membership.type],
            new_type: memberTypeUrlToNameDict[membership.new_type],
            date_joined: membership.date_joined,
            date_left: membership.date_left,
            study: studyUrlToNameDict[membership.profile.study] || "Unknown"
        };
        for (const data of membership.specific_data) {
            member[data.name] = data.value;
        }
        // add urls to find the relevant url for the PATCH/PUT when editing the profile
        member.urls = {
            "membership": membership.url,
            "profile": membership.profile.url,
            "type": membership.type,
            "new_type": membership.new_type,
            "data": {},
        };
        for (const data of membership.specific_data) {
            member.urls["data"][data.name] = data.url;
        }
        return member;
    });
    // for (let i = 0; i < 10; i++) {
    //     flattended = flattended.concat(flattended);
    // }
    // return flattended;
};

export const deriveMemberTableHeadersFromDataFields = (dataFields) => {
    const headers = [
        {name: "student_number", title: "Student Nr"},
        {name: "given_name", title: "Given Name"},
        {name: "initials", title: "Initials"},
        {name: "surname", title: "Last Name"},
        {name: "date_of_birth", title: "Date of Birth"},
        {name: "email", title: "Email"},
        {name: "phone_number", title: "Phone"},
        {name: "gender", title: "Gender"},
        {name: "address", title: "Address"},
        {name: "zip_code", title: "Zip Code"},
        {name: "city", title: "City"},
        {name: "country", title: "Country"},
        {name: "pay_by", title: "Payment by"},
        {name: "bank_account_name", title: "Bank account name"},
        {name: "iban", title: "IBAN"},
        {name: "bic", title: "BIC"},
        {name: "debt_collection_mandate_id", title: "Mandate id"},
        {name: "debt_collection_mandate_signature_date", title: "Signature date"},
        {name: "study", title: "Study"},
        {name: "phase", title: "Phase"},
        {name: "type", title: "Membertype"},
        {name: "new_type", title: "New Membertype"},
        {name: "date_joined", title: "Date Joined"},
        {name: "date_left", title: "Date Left"},
    ];
    const dataFieldHeaders = dataFields
        ? dataFields.map(dataField=>({name: dataField.name, title: capitalCase(dataField.name)}))
        : [];
    return headers.concat(dataFieldHeaders);
};

export const deriveColumnBoundsFromDataFields = (dataFields) => ([
    {
        title: "Profile",
        children: [
            { columnName: "student_number" },
            { columnName: "initials" },
            { columnName: "surname" },
            { columnName: "given_name" },
            { columnName: "date_of_birth" },
            { columnName: "country" },
            { columnName: "city" },
            { columnName: "email" },
            { columnName: "phone_number" },
            { columnName: "gender" },
            { columnName: "address" },
            { columnName: "zip_code" },
            { columnName: "iban" },
            { columnName: "is_master" },
        ],
    },
    {
        title: "Membership",
        children: [
            { columnName: "date_joined" },
            { columnName: "date_left" },
            { columnName: "type" },
            { columnName: "new_type" },
        ]
    },
    {
        title: "Association Specific Data",
        children: dataFields?.map(data_field=>({ columnName: data_field.name }))
    }
]);