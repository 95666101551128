import {emptySplitApi} from "./index";

const modulesApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["Modules"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getModulesByAssociation: build.query({
                query: (associationSlug) => ({ url: "/modules", params: {"association__slug": associationSlug} }),
                providesTags: (result) => [{type: "Modules", id: result.slug}],
                // providesTags: (result, error, associationSlug) => [{type: "Modules", id: associationSlug}],
                transformResponse: (response) => response.results[0]
            }),
            patchModules: build.mutation({
                query: ({ slug, ...patch}) => ({ url: `/modules/${slug}`, method: "PATCH", body: patch }),
                invalidatesTags: (result, error, arg) => [{ type: "Modules", id: arg.slug }],
            }),
        }),
        overrideExisting: false
    });

export const {
    useGetModulesByAssociationQuery,
    usePatchModulesMutation,
} = modulesApi;
export default modulesApi;