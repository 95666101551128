import "react-simple-tree-menu/dist/main.css";

import AddIcon from "@mui/icons-material/Add";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {orderBy} from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {useHistory, useParams} from "react-router-dom";

import Sushi from "../../../../img/Sushi-11-me.jpg";
import {BackButton} from "../../../Components/BackButton";
import Button from "../../../Components/Buttons/Button";
import Card, {CardV2} from "../../../Components/Card/Card";
import CardGrid from "../../../Components/Card/CardGrid";
import GroupCard from "../../../Components/Card/GroupCardV2";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import PageContent from "../../../Components/PageLayout/Content/Content";
import RoutingButton from "../../../Components/PageLayout/Content/RoutingButton";
import TopPageBlock from "../../../Components/PageLayout/Content/TopPageBlock";
import {useGetGroupsByAssociationQuery} from "../../../Store/services/MySU/groups";

const committeeToGroupCard = (committee) => ({
    title: committee.short_name,
    photo: committee.photo || Sushi,
    path: "committees/" + committee.slug
});

const GroupList = ({ boards }) => {
    // const filteredCommittees = groups.committees.filter(committee=> committee.parent_group === null);
    // const cards = filteredCommittees.map(committee=>committeeToGroupCard(committee));

    const { slug } = useParams();
    const history = useHistory();

    const { data: groups } = useGetGroupsByAssociationQuery({ associationSlug: slug, boardGroup: boards });

    const groupToCard = (group) => ({
        name: group.short_name || group.full_name,
        description: "",
        photo: group.photo || Sushi,
        onClick: () => history.push((group.board_group ? "board/" : "committees/") + group.slug)
    });
    const cards = orderBy(groups || [], ["full_name"], ["asc"]).map(board=>groupToCard(board));


    return (
        // <PageContent title={"Committees"}>
        //     <CardGrid
        //         // componentProps={cards}
        //         // Component={GroupCard}
        //         componentProps={cards}
        //         Component={Card}
        //         justifyContent={"left"}
        //         spacings={[0,2,2]}
        //     />
        // </PageContent>
        <Container maxWidth={"xl"}>
            <Wrapper>
                <RoutingButton
                    routeStringPieces={[
                        boards ? "Boards" : "Committees"
                    ]}
                />
            </Wrapper>
            <TopPageBlock>
                <Grid container justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
                    <Grid item>
                        <Typography variant={"h5"}>
                            { boards ? "Boards" : "Committees" }
                        </Typography>
                    </Grid>
                </Grid>
            </TopPageBlock>
            <CardGrid
                componentProps={cards}
                Component={CardV2}
                justifyContent={"left"}
                spacings={[0,2,2]}
            />
        </Container>
    );
};

GroupList.propTypes = {
    boards: PropTypes.bool.isRequired
};

export default GroupList;