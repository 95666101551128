import UploadRoundedIcon from "@mui/icons-material/UploadRounded";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import {orderBy} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import withExtraProps from "../../../Components/HOC/withExtraProps";
import useModalState from "../../../Components/Hooks/useModalState";
import ExtremeTable from "../../../Components/Tables/ExtremeTable";
import ToolbarButton from "../../../Components/Tables/Plugins/ToolbarButton";
import InvoicePopupForm from "../../../Forms/PopupForms/Invoice/InvoicePopupForm";
import {
    useAddInvoiceMutation, useDeleteInvoiceMutation,
    useGetInvoicesByAssociationQuery,
    usePatchInvoiceMutation
} from "../../../Store/services/MySU/invoices";
import {
    useGetCurrentMembersByAssociationQuery,
} from "../../../Store/services/MySU/members";
import InvoiceImporter from "./InvoiceImporter";


const columns = [
    { name: "member", title: "Member" },
    { name: "price", title: "Price" },
    { name: "reason", title: "Reason"},
    { name: "date_issued", title: "Date issued"},
    { name: "debt_collection_date", title: "Debt Collection"},
];
const currencyColumns = ["price"];
const dateTimeColumns = ["date_issued", "debt_collection_date"];

const Invoices = ({ old }) => {
    const { slug } = useParams();

    const { data: members } = useGetCurrentMembersByAssociationQuery(slug);
    const { data: invoices } = useGetInvoicesByAssociationQuery(slug);

    const [ addInvoice ] = useAddInvoiceMutation();
    const [ patchInvoice ] = usePatchInvoiceMutation();
    const [ removeInvoice ] = useDeleteInvoiceMutation();

    const Popup = withExtraProps(InvoicePopupForm, {associationSlug: slug});

    const [openImportModal, toggleImportModal] = useModalState(false);
    const [selection, setSelection] = useState([]);

    const onAdd = (newInvoice) => addInvoice({
        ...newInvoice,
        association: `/associations/${slug}`,
        date_issued: moment(newInvoice.date_issued).format("YYYY-MM-DD")
    });
    const onDelete = (removedInvoiceSlug) => removeInvoice({ slug: removedInvoiceSlug, association: `/associations/${slug}` });
    const onEdit = (differences, originalInvoice, newInvoice) => patchInvoice(newInvoice);

    const rows = useMemo(()=> {
        const invoiceWithEntries = invoices
            ? invoices.map(invoice => {
                let member = invoice.member;
                if (!member && members) {
                    const profile = members.find(member=>member.profile.url === invoice.profile).profile;
                    member = profile.given_name + " " + profile.surname;
                }
                return {
                    ...invoice,
                    member: member,
                };
            })
            : [];
        return orderBy(invoiceWithEntries, ["date_issued"], ["desc"]);
    }, [invoices, members]);

    const custom_miscelaneous_plugins = [
        <ToolbarButton key={1} tooltip={"Import data"} label={"Import"} Icon={UploadRoundedIcon} onClick={toggleImportModal}/>,
    ];

    if (old) {
        return (
            <Container>
                <InvoiceImporter
                    associationSlug={slug}
                    toggleImportModal={toggleImportModal}
                    openImportModal={openImportModal}
                />
                <Paper sx={{ mt: 2}}>
                    <ExtremeTable
                        rows={rows}
                        headers={columns}
                        currencyColumns={currencyColumns}
                        selection={{selection: selection, setSelection:setSelection}}
                        onAdd={onAdd}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        showEditing={true}
                        showExporter={true}
                        editThroughPopup={true}
                        Popup={Popup}
                        allowAdd={true}
                        allowDelete={true}
                        allowEdit={true}
                        showGrouping={false}
                        dateTimeColumns={dateTimeColumns}
                        custom_miscelaneous_plugins={custom_miscelaneous_plugins}
                        title={"Individual payments"}
                    />
                </Paper>
            </Container>
        );
    } else {
        return (
            <Paper elevation={0}>
                <InvoiceImporter
                    associationSlug={slug}
                    toggleImportModal={toggleImportModal}
                    openImportModal={openImportModal}
                />
                <ExtremeTable
                    rows={rows}
                    headers={columns}
                    currencyColumns={currencyColumns}
                    selection={{selection: selection, setSelection:setSelection}}
                    onAdd={onAdd}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    showEditing={true}
                    showExporter={true}
                    editThroughPopup={true}
                    Popup={Popup}
                    allowAdd={true}
                    allowDelete={true}
                    allowEdit={true}
                    showGrouping={false}
                    dateTimeColumns={dateTimeColumns}
                    custom_miscelaneous_plugins={custom_miscelaneous_plugins}
                    title={"Individual payments"}
                />
            </Paper>
        );
    }
};

Invoices.propTypes = {
    old: PropTypes.bool.isRequired
};

export default Invoices;