import { isFunction } from "lodash";
import {toast} from "material-react-toastify";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";

import {useAPI} from "../../Contexts/API";
import useCollection from "./useCollection";

const useCollectionAPI = (url, queryParams, lazy, showLoadStatus) => {
    const API = useAPI();

    const [loaded, setLoaded] = useState(false);
    const [collection, {set: setCollection, add: add, edit: edit, remove: remove}] = useCollection([]);

    const get = (value, key="slug") => collection.find(element=>element[key] === value);
    const list = () => collection;
    const refresh = (preRefresh) => {
        if (showLoadStatus) {
            toast.info("Loading data, please wait.");
        }
        API.callv4({
            url: url,
            queryParams: queryParams,
            method: "GET",
            on_succes: (data)=> {
                const results = (preRefresh && isFunction(preRefresh)) ? preRefresh(data.results) : data.results;
                setCollection(results);
                setLoaded(true);
                if (showLoadStatus) {
                    toast.success("Loading data complete");
                }
            },
            on_failure: () => toast.error("Loading data failed")
        });
    };

    // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(()=>{
        if (!lazy && !loaded) {
            refresh();
        }
    }, [lazy, loaded]);

    return (
        {
            add: add,
            edit: edit,
            remove: remove,
            list: list,
            collection: collection,
            set: setCollection,
            refresh: refresh,
            get: get,
            loaded: loaded
        }
    );
};

useCollectionAPI.propTypes = {
    url: PropTypes.string.isRequired,
    queryParams: PropTypes.object.isRequired,
    lazy: PropTypes.bool,
    showLoadStatus: PropTypes.bool
};

useCollectionAPI.defaultProps = {
    lazy: true,
    showLoadStatus: false
};

export default useCollectionAPI;