import {Paper} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {useHistory} from "react-router-dom";

import Keyboard from "./Keyboard";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100vh",
    },
}));

const KeyPad = ({ keyboardValue, setKeyboardValue }) => {
    const classes = useStyles();
    const history = useHistory();

    const redirect = () => history.push("terminal/products");

    return (
        <Paper className={classes.container}>
            <Keyboard keyboardValue={keyboardValue} setKeyboardValue={setKeyboardValue}/>
            { /*<SimpleNumpad/>*/ }
        </Paper>
    );
};

export default KeyPad;