import {Typography} from "@mui/material";
import Container from "@mui/material/Container";
import React, {useState} from "react";
import {useParams} from "react-router-dom";

import Button from "../../Components/Buttons/Button";
import Wrapper from "../../Components/FormComponents/Wrapper";
import Block from "../../Components/PageLayout/Content/Block";
import EventTypesDropDown from "../../Forms/DropdownForms/EventTypes/EventTypesDropDown";
import {useGetEventTypesByAssociationQuery} from "../../Store/services/MySU/eventTypes";


const EventTypes = () => {
    const { slug } = useParams();
    const { data: eventTypes } = useGetEventTypesByAssociationQuery(slug);

    const [creating, setCreating] = useState(false);

    return (
        <Container>
            <Block>
                <Wrapper>
                    <Typography variant={"h5"}>Event Types</Typography>
                    <Button onClick={()=>setCreating(true)} variant={"contained"} color={"primary"}>
                        Add
                    </Button>
                </Wrapper>
                <Typography>
                    Event Types are the categories events can belong too. For example a lunch lecture could be
                    associated with the type `education` and a poker tournament with `gezelligheid`.
                </Typography>
                <hr className={"box-title-separator"}/>
                <EventTypesDropDown
                    eventTypes={eventTypes || []}
                    associationUrl={`/associations/${slug}`}
                    creating={creating}
                    postSubmit={()=>setCreating(false)}
                    postCancel={()=>setCreating(false)}
                />
            </Block>
        </Container>
    );

};

EventTypes.propTypes = {
};

EventTypes.defaultProps = {
};

export default EventTypes;
