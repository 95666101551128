import "react-keyed-file-browser/dist/react-keyed-file-browser.css";

import {
    faFile,
    faFileImage,
    faFilePdf,
    faFolder,
    faFolderOpen,
    faTrashAlt
} from "@fortawesome/free-regular-svg-icons";
import {
    faCircleNotch,
    faDownload,
    faICursor
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Container from "@mui/material/Container";
import Moment from "moment";
import React, {useState} from "react";
import FileBrowser from "react-keyed-file-browser";

import Block from "../../Components/PageLayout/Content/Block";

const defaultFiles = [
    {
        key: "animals/cat.png",
        modified: +Moment().subtract(1, "hours"),
        size: 1.5 * 1024 * 1024,
    },
    {
        key: "kitten.png",
        modified: +Moment().subtract(3, "days"),
        size: 545 * 1024,
    },
    {
        key: "elephant.png",
        modified: +Moment().subtract(3, "days"),
        size: 52 * 1024,
    },
];

const FileLibrary = () => {
    const [files, setFiles] = useState(defaultFiles);

    const handleCreateFolder = (key) => {
        setFiles(prevFiles => prevFiles.concat({key: key, size: 1024, modified: +Moment() }));
    };
    const handleCreateFiles = (toBeAddedFiles, prefix) => {
        setFiles(prevFiles => {
            let newFilePrefix = prefix;
            if (prefix !== "" && prefix.substring(prefix.length - 1, prefix.length) !== "/") {
                newFilePrefix += "/";
            }
            const newFiles = toBeAddedFiles.map(file=>({ key: newFilePrefix + file.name, size: file.size, modified: +Moment() }));

            const fileKeys = files.map(file => file.key);
            let uniqueNewFiles = [];
            newFiles.forEach(file=>{
                if (!fileKeys.includes(file.key)){
                    uniqueNewFiles.push(file);
                }
            });

            return prevFiles.concat(uniqueNewFiles);
        });

    };
    const handleRenameFolder = (oldKey, newKey) => {
        setFiles(prevFiles => prevFiles.map(file=> file.key.substr(0, oldKey.length) === oldKey
            ? {...file, key: file.key.replace(oldKey, newKey), modified: +Moment() }
            : file
        ));
    };
    const handleRenameFile = (oldKey, newKey) => {
        setFiles(prevFiles => prevFiles.map(file=> file.key === oldKey ? {...file, key: newKey, modified: +Moment() } : file ));
    };
    const handleDeleteFolder = (folderKey) => {
        setFiles(prevFiles => prevFiles.filter(file => file.key.substr(0, folderKey.length) !== folderKey));
    };
    const handleDeleteFile = (fileKey) => {
        setFiles(prevFiles => prevFiles.filter(file => file.key !== fileKey));
    };
    const handleDownloadFile = (fileKey) => {
    };

    return (
        <Container>
            <Block>
                <FileBrowser
                    files={files}
                    icons={{
                        File: <FontAwesomeIcon icon={faFile}/>,
                        Image: <FontAwesomeIcon icon={faFileImage}/>,
                        PDF: <FontAwesomeIcon icon={faFilePdf}/>,
                        Rename: <FontAwesomeIcon icon={faICursor}/>,
                        Folder: <FontAwesomeIcon icon={faFolder}/>,
                        FolderOpen: <FontAwesomeIcon icon={faFolderOpen}/>,
                        Delete: <FontAwesomeIcon icon={faTrashAlt}/>,
                        Loading: <FontAwesomeIcon icon={faCircleNotch}/>,
                        Download: <FontAwesomeIcon icon={faDownload}/>,
                    }}
                    onCreateFolder={handleCreateFolder}
                    onCreateFiles={handleCreateFiles}
                    onMoveFolder={handleRenameFolder}
                    onMoveFile={handleRenameFile}
                    onRenameFolder={handleRenameFolder}
                    onRenameFile={handleRenameFile}
                    onDeleteFolder={handleDeleteFolder}
                    onDeleteFile={handleDeleteFile}
                    onDownloadFile={handleDownloadFile}
                />
            </Block>
        </Container>
    );
};

export default FileLibrary;