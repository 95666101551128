import {Grid} from "@mui/material";
import Paper from "@mui/material/Paper";
import {orderBy} from "lodash";
import React from "react";
import {useParams} from "react-router-dom";

import MembershipInfoFormV2 from "../../Forms/InfoForms/MembershipSettings/MembershipSettingsInfoForm";
import DataFieldsMulti from "../../Forms/MultiForms/DataFields/DataFieldsMultiForm";
import MemberTypesMulti from "../../Forms/MultiForms/MemberTypes/MemberTypesMultiForm";
import {useCurrentAssociation} from "../../Store/services/MySU/associations";
import {useGetDataFieldsByAssociationQuery} from "../../Store/services/MySU/dataFields";
import {useGetMemberTypesByAssociationQuery} from "../../Store/services/MySU/memberTypes";

const MembershipSettings = () => {
    const { slug } = useParams();

    const { data: association } = useCurrentAssociation();
    const { data: dataFields } = useGetDataFieldsByAssociationQuery(slug);
    const { data: memberTypes } = useGetMemberTypesByAssociationQuery(slug);

    const orderedDataFields = orderBy(dataFields || [], ["name"], ["asc"]);
    const orderedMemberTypes = orderBy(memberTypes || [], ["order"], ["asc"]);

    return (
        <Grid container direction={"column"} spacing={2}>
            <Grid item>
                <Paper elevation={0} sx={{ borderRadius: "0px 0px 16px 16px" }}>
                    <MembershipInfoFormV2
                        initialInfoOrFormState={"info"}
                        association={association || {full_year_membership_start_date: "09-01", new_member_title: "", new_member_text: ""}}
                        showInfoFormStateButton={true}
                    />
                </Paper>
            </Grid>
            <Grid item>
                <DataFieldsMulti
                    dataFields={orderedDataFields}
                    associationUrl={`/associations/${slug}`}
                />
            </Grid>
            <Grid item>
                <MemberTypesMulti
                    memberTypes={orderedMemberTypes}
                    associationUrl={`/associations/${slug}`}
                />
            </Grid>
        </Grid>
    );    
};

export default MembershipSettings;