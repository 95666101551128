import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, {useCallback, useState} from "react";

import Button from "../../Components/Buttons/Button";
import useInfoFormState from "../../Components/Hooks/useInfoFormState";
import Block from "../../Components/PageLayout/Content/Block";
import Divider from "../../Components/PageLayout/Content/Divider";

const dummyFunction = () => {};
const defaultFieldAndValueToStateChanges = (field, value) => ({ [field]: value });


const TitleComponent = ({ title, formType, toggleFormType, infoFormObject, showInfoFormStateButton, MoreActionButtonGroup }) => {
    return (
        <>
            <Grid container spacing={2} alignItems={"center"}>
                <Grid item>
                    <Typography variant={"h5"}>{ title }</Typography>
                </Grid>
                <Grid item>
                    <MoreActionButtonGroup formType={formType} infoFormObject={infoFormObject}/>
                    { showInfoFormStateButton && formType === "info" &&
                        <Button
                            onClick={toggleFormType}
                            version={"edit"}
                        >
                            { formType === "info" ? "Edit" : "View" }
                        </Button>
                    }
                </Grid>
            </Grid>
            <Divider/>
        </>
    );
};


const InfoForm = ({infoFormObject: propInfoFormObject, InfoComponent, FormComponent, TitleComponent, fieldAndValueToStateChanges, title, onDelete: propOnDelete, onSubmit: propOnSubmit, initialInfoOrFormState, showInfoFormStateButton, MoreActionButtonGroup, enableDelete, onCancel: propOnCancel}) => {
    const [infoFormObjectChanges, setInfoFormObjectChanges] = useState({});
    const infoFormObject = {...propInfoFormObject, ...infoFormObjectChanges};

    const [formType, toggleFormType] = useInfoFormState(initialInfoOrFormState);

    const handleInfoFormObjectChange = (field, value) => {
        setInfoFormObjectChanges(prevState => ({...prevState, ...fieldAndValueToStateChanges(field, value, infoFormObject)}));
    };

    const baseCallback = useCallback(()=>{
        toggleFormType();
        setInfoFormObjectChanges({});
    }, [toggleFormType, setInfoFormObjectChanges]);
    const onSubmit = ()=>{
        propOnSubmit(infoFormObject);
        baseCallback();
    };
    const onDelete = ()=>{
        propOnDelete(infoFormObject);
        baseCallback();
    };
    const onCancel = ()=>{
        propOnCancel(infoFormObject);
        baseCallback();
    };

    return (
        <Block>
            <TitleComponent
                title={title}
                formType={formType}
                toggleFormType={toggleFormType}
                infoFormObject={infoFormObject}
                showInfoFormStateButton={showInfoFormStateButton}
                MoreActionButtonGroup={MoreActionButtonGroup}
            />
            { formType === "info"
            ? (
                <InfoComponent
                    info={infoFormObject}
                />
            ) : (
                <FormComponent
                    formObject={infoFormObject}
                    handleFormObjectChange={handleInfoFormObjectChange}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    onCancel={onCancel}
                    enableDelete={enableDelete}
                />
            ) }
        </Block>
    );
};

InfoForm.propTypes = {
    initialInfoOrFormState: PropTypes.string,
    showInfoFormStateButton: PropTypes.bool,
    enableDelete: PropTypes.bool,
    infoFormObject: PropTypes.object,
    InfoComponent: PropTypes.elementType.isRequired,
    FormComponent: PropTypes.elementType.isRequired,
    TitleComponent: PropTypes.elementType,
    fieldAndValueToStateChanges: PropTypes.func,
    title: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    MoreActionButtonGroup: PropTypes.elementType
};

InfoForm.defaultProps = {
    onDelete: dummyFunction,
    onCancel: dummyFunction,
    infoFormObject: {},
    initialInfoOrFormState: "info",
    showInfoFormStateButton: true,
    enableDelete: false,
    fieldAndValueToStateChanges: defaultFieldAndValueToStateChanges,
    MoreActionButtonGroup: () => null,
    TitleComponent: TitleComponent
};

export default InfoForm;
