import React from "react";
import { Redirect,Route, Switch} from "react-router-dom";

import Help from "../../Pages/Public/Help";
import Login from "../../Pages/Public/Login";
import OIDC from "../../Pages/Public/OIDC";
import useAuth from "../../Store/hooks/useAuth";

const NotAuthorized = () => {
    const { authenticated } = useAuth();

    return (
        <Switch>
            <Route exact path={"/"} render={
                ()=><Redirect exact from={"/"} to={authenticated ? "/protected/associations/overview" : "/account/login"}/>}
            />
            <Route exact path={"/account/login"}>
                { authenticated ? <Redirect to={"/protected/associations/overview"}/> : <Login/> }
            </Route>
            <Route path={"/help"}>
                <Help/>
            </Route>
            <Route path={"/oidc"}>
                <OIDC/>
            </Route>
        </Switch>
    );
};

export default NotAuthorized;