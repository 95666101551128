import PropTypes from "prop-types";
import React, {useMemo} from "react";

const generateRandomColour = () => "#" + Math.floor(Math.random()*16777215).toString(16);

const ColouredBlock = ({ colour }) => {
    const backgroundColor = useMemo(()=>colour || generateRandomColour(), [colour]);
    return (
        <div style={{ backgroundColor: backgroundColor, height: "100%" }}>

        </div>
    );
};

ColouredBlock.propTypes = {
    colour: PropTypes.string
};

ColouredBlock.defaultProps = {
    colour: ""
};

export { generateRandomColour };
export default ColouredBlock;