import React from "react";
import {Route, Switch, useParams, useRouteMatch} from "react-router-dom";

import {useGetGroupsByAssociationQuery} from "../../../Store/services/MySU/groups";
import GroupDetail from "./GroupDetail";
import GroupList from "./GroupList";


export const GroupsRouter = ({boards}) => {
    const { slug } = useParams();
    const { path } = useRouteMatch();



    const { data: committees } = useGetGroupsByAssociationQuery({ associationSlug: slug, boardGroup: false });

    return (
        <Switch>
            <Route path={path+"/:slug"}>
                <GroupDetail boards={boards}/>
            </Route>
            <Route path={path}>
                <GroupList boards={boards}/>
            </Route>
        </Switch>
    );
};

GroupsRouter.propTypes = {
};
