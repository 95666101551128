import InfoIcon from "@mui/icons-material/Info";
import {CardHeader} from "@mui/material";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import LinesEllipsis from "react-lines-ellipsis";

import useModalState from "../../../../Components/Hooks/useModalState";

const NewsCard = ({ title, text, date, photo, onClick }) => {
    return (
        <Card sx={{ maxWidth: 312, borderRadius: 4 }}>
            <CardActionArea onClick={onClick}>
                <CardHeader
                    sx={{ pt: 3, pr: 3, pl: 3, pb: 1 }}
                    action={
                        <IconButton color={"primary"} onClick={onClick} edge={"start"} size={"large"}>
                            <InfoIcon fontSize={"large"}/>
                        </IconButton>
                    }
                    title={title}
                    subheader={moment(date).format("h:mm")}
                />
                <CardContent sx={{ pt: 0 }}>
                    <Divider/>
                    <LinesEllipsis
                        className={"MuiTypography-root MuiTypography-body1"}
                        component={"p"}
                        maxLine={2}
                        text={text || ""}
                    />
                </CardContent>
            </CardActionArea>
        </Card>

    );
};

NewsCard.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    date: PropTypes.any,
    image: PropTypes.any,
};

NewsCard.defaultProps = {
};

export default NewsCard;
