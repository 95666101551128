import { Skeleton } from "@mui/material";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";

import Block from "../../Components/PageLayout/Content/Block";
import MemberTable from "../../Components/Tables/MemberTable";
import useMembersByStatus from "../../Store/hooks/useMembersByStatus";
import {useCurrentAssociation} from "../../Store/services/MySU/associations";

const initialHeaders = ["given_name", "surname", "email", "reason"];

const MembersDisputed = () => {
    const { data: association, isLoading } = useCurrentAssociation();
    const { members } = useMembersByStatus("Disputed");

    if (isLoading) {
        return (
            <Container>
                <Skeleton variant={"rectangular"} width={"100%"} height={250}/>
            </Container>
        );
    }

    {/*When { association.name } joined the platform, its members were imported.*/}
    {/*The imported members are asked to confirm that there are a member of the association.*/}
    {/*The people below indicated that they are not a member of the association.*/}
    {/*Please contact them to find out why they said they weren&apos;t.*/}
    {/*Afterwards, contact the help desk to resolve the matter.*/}
    return (
        <Paper elevation={0}>
            <MemberTable
                initial_headers={initialHeaders}
                allowEdit={false}
                showSelect={false}
                showGrouping={false}
                rows={members || []}
                title={"Disputed members"}
            />
        </Paper>
    );
};

export default MembersDisputed;