import { isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "material-react-toastify";

import {clearCredentials} from "./slices/authentication";

/**
 * Log a warning and show a toast!
 */
const rtkQueryErrorLogger = (api) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
        console.log(api, next, action);
        if (action.payload.status === 401) {
            toast.error("Credentials expired.");
            api.dispatch(clearCredentials());
        } else {
            console.error("Current action failed because:", action.payload.data);
            toast.error("Something went wrong (status code: " + action.payload.status + ").");
        }
    }

    return next(action);
};

export default rtkQueryErrorLogger;