import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import React from "react";

const MenuItemButton = React.forwardRef((props, ref) => {
    const { onClick, primary, icon } = props;
    return (
        <MenuItem
            ref={ref}
            onClick={onClick}
            primary={primary}
        >
            <ListItemIcon>{ icon }</ListItemIcon>
            <ListItemText primary={primary} />
        </MenuItem>
    );
});

MenuItemButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    primary: PropTypes.string.isRequired,
    icon: PropTypes.element,
};

export default MenuItemButton;