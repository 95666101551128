import {Skeleton} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import MuiCardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {loremIpsum} from "react-lorem-ipsum";
import {useHistory, useParams} from "react-router-dom";

import Image from "../../../../img/default_photo.jpg";
import {BackButton} from "../../../Components/BackButton";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import Block from "../../../Components/PageLayout/Content/Block";
import PageContent from "../../../Components/PageLayout/Content/Content";
import RoutingButton from "../../../Components/PageLayout/Content/RoutingButton";
import TopPageBlock from "../../../Components/PageLayout/Content/TopPageBlock";
import ExtremeTable from "../../../Components/Tables/ExtremeTable";
import AssociationInfo from "../../../Forms/InfoForms/Association/AssociationInfo";
import GroupAboutInfo from "../../../Forms/InfoForms/Group/GroupAboutInfo";
import GroupInfo from "../../../Forms/InfoForms/Group/GroupInfo";
import GroupInfoForm from "../../../Forms/InfoForms/Group/GroupInfoForm";
import Info from "../../../Forms/InfoForms/Info";
import PopupBoard from "../../../Forms/PopupForms/GroupMembership/GroupMembershipPopupBoard";
import PopupCommittee from "../../../Forms/PopupForms/GroupMembership/GroupMembershipPopupCommittee";
import {useGetGroupMembershipByGroupQuery} from "../../../Store/services/MySU/groupMemberships";
import {useGetGroupQuery, usePatchGroupMutation} from "../../../Store/services/MySU/groups";

const getRowId = (row) => row.slug;
const editingStateColumnExtensions = [
    { columnName: "full_name", editingEnabled: false },
];
const defaultFilters = [
    {columnName: "date_joined", operation: "startsWith", value: ""}
];
const defaultSorting = [
    {columnName: "order", direction: "asc" },
    {columnName: "date_joined", direction: "asc" }
];

const dateColumns = ["date_joined", "date_left"];
const numberColumns = ["order"];


const useStyles = makeStyles(theme => ({
    avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    }
}));

const GroupDetail = ({ boards }) => {

    const { slug } = useParams();
    const history = useHistory();

    const { data: group } = useGetGroupQuery(slug);
    // const [ patchGroup ] = usePatchGroupMutation();
    //
    const { data: groupMemberships } = useGetGroupMembershipByGroupQuery(slug);

    let columns = useMemo(()=>{
        let cols = [
            { name: "full_name", title: "Name" },
            { name: "duty", title: "Function"},
            { name: "date_joined", title: "Joined"},
            { name: "date_left", title: "Left"}
        ];
        if (group?.board_group === true) {
            cols.push({ name: "email", title: "Email"});
            cols.push({ name: "order", title: "Order"});
            cols.push({ name: "description", title: "Board text"});
        }
        return cols;
    },[group?.board_group]);

    const Popup = useMemo(()=>group?.board_group ? PopupBoard : PopupCommittee, [group?.board_group]);

    return (

        <Container maxWidth={"xl"}>
            <Wrapper>
                <RoutingButton routeStringPieces={[
                    boards ? "Boards" : "Committees", group?.short_name
                ]}
                />
            </Wrapper>
            { /*<GroupInfo group={group}/>*/ }
            <TopPageBlock>
                <Typography variant={"h5"}>
                    { group?.full_name }
                </Typography>
            </TopPageBlock>
            <Grid container rowSpacing={4} spacing={6} sx={{ mt: 0 }}>
                <Grid item xs={8}>
                    <Block>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant={"h6"}>
                                    About us
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <GroupAboutInfo group={group}/>
                            </Grid>
                            { /*<Grid item xs={12}>*/ }
                            { /*    <MuiCardMedia image={group.photo || Image}*/ }
                            { /*        sx={{*/ }
                            { /*            width: "100%",*/ }
                            { /*            height: 0,*/ }
                            { /*            paddingBottom: "48%",*/ }
                            { /*            borderRadius: 4,*/ }
                            { /*            backgroundColor: "background.paper",*/ }
                            { /*            position: "relative",*/ }
                            { /*            transform: "translateY(8px)",*/ }
                            { /*            // paddingLeft: 1,*/ }
                            { /*            // paddingRight: 1,*/ }
                            { /*            "&:after": {*/ }
                            { /*                content: "\" \"",*/ }
                            { /*                position: "absolute",*/ }
                            { /*                top: 0,*/ }
                            { /*                left: 0,*/ }
                            { /*                width: "100%",*/ }
                            { /*                height: "100%",*/ }
                            { /*                borderRadius: "shape.borderRadius",*/ }
                            { /*                opacity: 0.5,*/ }
                            { /*            },*/ }
                            { /*        }}*/ }
                            { /*    />*/ }
                            { /*</Grid>*/ }
                            { /*<Grid item xs={12}>*/ }
                            { /*    <Typography variant={"body1"}>*/ }
                            { /*        { group?.description }*/ }
                            { /*    </Typography>*/ }
                            { /*</Grid>*/ }
                        </Grid>
                    </Block>
                </Grid>
                <Grid item xs={4}>
                    <Block>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Typography variant={"h5"}>Details</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <GroupInfo group={group}/>
                            </Grid>
                        </Grid>
                    </Block>
                </Grid>
                <Grid item xs={12}>
                    <Block>
                        <ExtremeTable
                            headers={columns}
                            rows={groupMemberships || []}
                            getRowId={getRowId}
                            showSelect={false}
                            showGrouping={false}
                            showEditing={false}
                            allowAdd={false}
                            allowDelete={false}
                            numberColumns={numberColumns}
                            dateColumns={dateColumns}
                            defaultFilters={defaultFilters}
                            defaultSorting={defaultSorting}
                            editingStateColumnExtensions={editingStateColumnExtensions}
                            // onAdd={onAdd}
                            // onEdit={onChange}
                            // onDelete={onDelete}
                            editThroughPopup={false}
                            Popup={Popup}
                            title={"Group members"}
                        />
                    </Block>
                </Grid>
            </Grid>
        </Container>
    );
};

GroupDetail.propTypes = {
    boards: PropTypes.bool.isRequired
};

GroupDetail.defaultProps = {
};

export default GroupDetail;