import BallotIcon from "@mui/icons-material/Ballot";
import CalendarToday from "@mui/icons-material/CalendarToday";
import Create from "@mui/icons-material/Create";
import LocationOn from "@mui/icons-material/LocationOn";
import Notes from "@mui/icons-material/Notes";
import RemoveIcon from "@mui/icons-material/Remove";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import {Dialog, DialogActions, DialogContent} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import makeStyles from "@mui/styles/makeStyles";
import * as moment from "moment/moment";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import DateTimeField from "../../../Components/FormComponents/Fields/DateTimeField";
import FileField from "../../../Components/FormComponents/Fields/FileField";
import NumberField from "../../../Components/FormComponents/Fields/NumberField";
import SelectField from "../../../Components/FormComponents/Fields/SelectField";
import TextField from "../../../Components/FormComponents/Fields/TextField";
import FontAwesomeIconHolder from "../../../Components/FormComponents/FontAwesomeIconHolder";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import {useAddEventMutation, useDeleteEventMutation, usePatchEventMutation} from "../../../Store/services/MySU/events";

const useStyles = makeStyles(() => ({
    actionWrapper: {
        width: "100%"
    }
}));

const fromSchedulertoAPIFormat = (calendarEvent) => {
    // API wants UTC ISOstrings, but scheduler gives local strings.
    let apiCalendarEvent = {
        ...calendarEvent,
        start_date:  new Date(calendarEvent.startDate).toISOString(),
        end_date:  new Date(calendarEvent.endDate).toISOString(),
        name: calendarEvent.title
    };
    if (calendarEvent.enrollable === true) {
        apiCalendarEvent["enrollable_until"] = new Date(apiCalendarEvent["enrollable_until"]).toISOString();
        apiCalendarEvent["enrollable_from"] = new Date(apiCalendarEvent["enrollable_from"]).toISOString();
    }
    if (apiCalendarEvent.unenrollable === true) {
        apiCalendarEvent["unenrollable_until"] = new Date(apiCalendarEvent["unenrollable_until"]).toISOString();
    }
    return apiCalendarEvent;
};

const baseEvent = {
    enrollable: false,
    unenrollable: false,
    type: ""
};

const EventModalForm = ({ visible, postCancel, postSubmit, postDelete, selectedEvent, eventTypes }) => {
    const classes = useStyles();

    const [calendarEvent, setCalendarEvent] = useState({...baseEvent, ...selectedEvent});

    const [ addEvent] = useAddEventMutation();
    const [ updateEvent ] = usePatchEventMutation();
    const [ removeEvent ] = useDeleteEventMutation();

    const handleSubmit = () => {
        if (calendarEvent.slug) {
            updateEvent(fromSchedulertoAPIFormat(calendarEvent)).then(()=>postSubmit());
        } else {
            addEvent(fromSchedulertoAPIFormat(calendarEvent)).then(()=>postSubmit());
        }
    };
    const onDelete = () => {
        removeEvent(fromSchedulertoAPIFormat(calendarEvent)).then(()=>postDelete());
    };


    const handleEventChange = (field, value) => {
        if (field === "public" && value) {
            setCalendarEvent(prevState => ({...prevState, public: value, enrollable: false, unenrollable: false}));
        } else if (field === "startDate") {
            setCalendarEvent(prevState => ({...prevState, startDate: value, endDate: moment(prevState.endDate).add(moment(value).diff(moment(prevState.startDate))).format("YYYY-MM-DD HH:mm")}));
            // } else if (field === "enrollable_from") {
            //     setCalendarEvent(prevState => ({...prevState, enrollable_from: value, enrollable_until: moment(prevState.enrollable_until).add(moment(value).diff(moment(prevState.enrollable_from)))}));
        } else if (field === "enrollable") {
            if (value) {
                setCalendarEvent(prevState => ({...prevState, enrollable: true, enrollable_from: moment().format("YYYY-MM-DD HH:mm"), enrollable_until: prevState.startDate}));
            } else {
                setCalendarEvent(prevState => ({...prevState, enrollable: false, enrollable_until: null, enrollable_from: null}));
            }
        } else if (field === "unenrollable") {
            if (value) {
                setCalendarEvent(prevState => ({...prevState, unenrollable: true, unenrollable_until: prevState.startDate}));
            } else {
                setCalendarEvent(prevState => ({...prevState, unenrollable: false, unenrollable_until: null}));
            }
        } else {
            setCalendarEvent(prevState => ({...prevState, [field]: value}));
        }
    };

    return (
        <Dialog
            open={visible}
            maxWidth={"lg"}
        >
            <ValidatorForm
                onSubmit={handleSubmit}
                onError={errors => console.log(errors)}
            >
                <DialogTitle>
                    Create event
                </DialogTitle>
                <DialogContent>
                    <Wrapper>
                        <IconHolder Icon={Create}/>
                        <TextField
                            name={"name"}
                            value={calendarEvent.title}
                            onChange={(event) => handleEventChange("title", event.target.value)}
                            validators={["minStringLength:4"]}
                            errorMessages={["Name must have at least 4 characters"]}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <IconHolder Icon={CalendarToday}/>
                        <DateTimeField
                            name={"Start date"}
                            value={calendarEvent.startDate}
                            onChange={date => handleEventChange("startDate", date)}
                        />
                        <IconHolder Icon={RemoveIcon}/>
                        <DateTimeField
                            name={"End date"}
                            value={calendarEvent.endDate}
                            onChange={date => handleEventChange("endDate", date)}
                        />
                    </Wrapper>
                    <Wrapper>
                        <IconHolder Icon={Notes}/>
                        <TextField
                            name={"description"}
                            value={calendarEvent.description}
                            onChange={(event) => handleEventChange("description", event.target.value)}
                            multiline
                            rows={"5"}
                        />
                    </Wrapper>
                    <Wrapper>
                        <IconHolder Icon={BallotIcon}/>
                        <SelectField
                            name={"type"}
                            value={calendarEvent.type}
                            validators={["required"]}
                            errorMessages={["A type has to be set"]}
                            onChange={(event) => handleEventChange("type", event.target.value)}
                            required
                        >
                            { eventTypes && eventTypes.map(event_type => (
                                <MenuItem key={event_type.slug} value={event_type.url}>
                                    { event_type.type }
                                </MenuItem>
                            )) }
                        </SelectField>
                    </Wrapper>
                    <Wrapper>
                        <IconHolder Icon={LocationOn}/>
                        <TextField
                            name={"location"}
                            value={calendarEvent.location}
                            onChange={(event) => handleEventChange("location", event.target.value)}
                        />
                        <IconHolder Icon={WallpaperIcon}/>
                        <FileField
                            name={"cover_photo"}
                            label={"Cover photo"}
                            onChange={(event)=>handleEventChange("cover_photo",event.target.files[0])}
                        />
                    </Wrapper>
                    { /*<FormControlLabel*/ }
                    { /*    control={*/ }
                    { /*        <Switch*/ }
                    { /*            color={"primary"}*/ }
                    { /*            checked={calendarEvent.public}*/ }
                    { /*            onChange={(event) => handleEventChange("public", event.target.checked)}*/ }
                    { /*        />*/ }
                    { /*    }*/ }
                    { /*    label={"Public"}*/ }
                    { /*    labelPlacement={"start"}*/ }
                    { /*/>*/ }
                    <FormControlLabel
                        control={
                            <Switch
                                color={"primary"}
                                value={calendarEvent.enrollable}
                                checked={calendarEvent.enrollable}
                                onChange={(event) => handleEventChange("enrollable", event.target.checked)}
                            />
                        }
                        label={"Enrollable"}
                        labelPlacement={"start"}
                        disabled={calendarEvent.public}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                color={"primary"}
                                value={calendarEvent.unenrollable}
                                checked={calendarEvent.unenrollable}
                                onChange={(event) => handleEventChange("unenrollable", event.target.checked)}
                            />
                        }
                        label={"Unenrollable"}
                        labelPlacement={"start"}
                        disabled={!calendarEvent.enrollable}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                color={"primary"}
                                value={calendarEvent.hidden_for_members}
                                checked={calendarEvent.hidden_for_members}
                                onChange={(event) => handleEventChange("hidden_for_members", event.target.checked)}
                            />
                        }
                        label={"Hidden"}
                        labelPlacement={"start"}
                    />
                    { calendarEvent.enrollable &&
                    <Wrapper>
                        <IconHolder Icon={CalendarToday}/>
                        <DateTimeField
                            name={"Enrollable from"}
                            value={calendarEvent.enrollable_from}
                            onChange={(date) => handleEventChange("enrollable_from", date)}
                        />
                        <IconHolder Icon={RemoveIcon}/>
                        <DateTimeField
                            name={"Enrollable until"}
                            value={calendarEvent.enrollable_until}
                            onChange={(date) => handleEventChange("enrollable_until", date)}
                        />
                    </Wrapper>
                    }
                    { calendarEvent.unenrollable &&
                    <Wrapper>
                        <IconHolder Icon={CalendarToday}/>
                        <DateTimeField
                            name={"Unenrollable until"}
                            value={calendarEvent.unenrollable_until}
                            onChange={(date) => handleEventChange("unenrollable_until", date)}
                        />
                    </Wrapper>
                    }
                    { calendarEvent.enrollable &&
                    <Wrapper>
                        <FontAwesomeIconHolder icon={"hashtag"}/>
                        <NumberField
                            name={"Maximum number of enrollments"}
                            value={calendarEvent.max_number_of_enrollments}
                            onChange={(event) => handleEventChange("max_number_of_enrollments", event.target.value)}
                            InputProps={{
                                inputProps: {
                                    step: 1, min: 0
                                }
                            }}
                            helperText={"A maximum of `0` means no limit to the number of enrollments"}
                            required
                        />
                    </Wrapper>
                    }
                </DialogContent>
                <DialogActions>
                    <Wrapper className={classes.actionWrapper}>
                        <div>
                            <Button variant={"outlined"} disabled={!selectedEvent.slug} color={"secondary"} onClick={onDelete}>
                                Delete
                            </Button>
                        </div>
                        <div>
                            <Button type={"submit"} variant={"contained"} color={"primary"}>Save</Button>
                            &nbsp;
                            <Button variant={"contained"} onClick={postCancel}>Cancel</Button>
                        </div>
                    </Wrapper>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
};

EventModalForm.propTypes = {
    visible: PropTypes.bool.isRequired,
    postCancel: PropTypes.func.isRequired,
    postSubmit: PropTypes.func.isRequired,
    postDelete: PropTypes.func.isRequired,
    selectedEvent: PropTypes.object.isRequired,
    eventTypes: PropTypes.array.isRequired
};

export default EventModalForm;