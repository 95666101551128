import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {useParams} from "react-router-dom";

import {useGetDataFieldsByAssociationQuery} from "../../Store/services/MySU/dataFields";
import {useGetMemberTypesByAssociationQuery} from "../../Store/services/MySU/memberTypes";
import {
    deriveColumnBoundsFromDataFields,
    deriveMemberTableHeadersFromDataFields,
} from "../../Transformers/Members";
import ExtremeTable from "./ExtremeTable";

const getRowId = (row) => row.slug;

const MemberTable = ({ initial_headers, rows, ...extremeTableProps }) => {
    const { slug } = useParams();
    const { data: dataFields, isLoading: isLoadingDataFields } = useGetDataFieldsByAssociationQuery(slug);
    const { data: memberTypes, isLoading: isLoadingMemberTypes } = useGetMemberTypesByAssociationQuery(slug);

    const headers = useMemo(()=>deriveMemberTableHeadersFromDataFields(dataFields), [dataFields]);

    const defaultHiddenColumnNames = useMemo(()=>{
        if (initial_headers.length > 0) {
            return headers
                .map(header=>header.name)
                .filter(header_name => !initial_headers.includes(header_name));
        } else {
            return [];
        }
    }, [headers, initial_headers]);
    const columnBands = useMemo(()=>deriveColumnBoundsFromDataFields(dataFields), [dataFields]);

    const booleanColumns = useMemo(()=>dataFields
        ?.filter(field=>field.type === "Boolean")
        .map(field=>field.name)
    , [dataFields]);
    const numberColumns = useMemo(()=>dataFields
        ?.filter(field=>field.type === "Number")
        .map(field=>field.name)
    , [dataFields]);
    const choiceColumns = useMemo(()=>dataFields
        ?.filter(field=>field.type === "Choice")
        .map(field=>field.name)
        .concat(["phase", "type", "new_type"])
    , [dataFields]);
    const choiceSelectionOptions = useMemo(()=>({
        "phase": ["PhD", "Master", "Premaster", "Bachelor", "Other"],
        "type": memberTypes?.map(memberType=>memberType.type) || [],
        "new_type": memberTypes?.map(memberType=>memberType.type) || [],
        ...Object.fromEntries(dataFields
            ?.filter(field=>field.type === "Choice")
            .map(field=>[field.name, field.choices.split(",")]
            ) || [])
    }), [memberTypes, dataFields]);
    const currencyColumns = ["membership_fee"];
    const dateColumns = ["date_of_birth", "date_joined", "date_left"];
    const messages = {
        // addCommand: "New",
        // editCommand: "Edit",
        deleteCommand: "Details"
    };

    if (isLoadingDataFields || isLoadingMemberTypes) {
        return <Skeleton variant={"rectangular"} width={"100%"} height={627}/>;
    }
    return (
        <ExtremeTable
            rows={rows}
            headers={headers}
            showExporter={true}
            showEditing={true}
            showColumnBands={false}
            columnBands={columnBands}
            defaultHiddenColumnNames={defaultHiddenColumnNames}
            booleanColumns={booleanColumns}
            choiceColumns={choiceColumns}
            choiceSelectionOptions={choiceSelectionOptions}
            currencyColumns={currencyColumns}
            numberColumns={numberColumns}
            dateColumns={dateColumns}
            getRowId={getRowId}
            messages={messages}
            {...extremeTableProps}
        />
    );
};

MemberTable.propTypes = {
    initial_headers: PropTypes.array,
    rows: PropTypes.array.isRequired,
};

MemberTable.defaultProps = {
    initial_headers: [],
};

export default MemberTable;