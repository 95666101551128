import {useMemo} from "react";
import {useParams} from "react-router-dom";

import {flattenMembers} from "../../Transformers/Members";
import {useGetMembersByAssociationAndStatusQuery} from "../services/MySU/members";
import {useGetMemberTypesByAssociationQuery} from "../services/MySU/memberTypes";
import {useGetStudiesQuery} from "../services/MySU/studies";

const useMembersByStatus = (status) => {
    const { slug } = useParams();
    const { data: studies } = useGetStudiesQuery();
    const { data: memberTypes } = useGetMemberTypesByAssociationQuery(slug);
    const { data: currentMembers, ...props } = useGetMembersByAssociationAndStatusQuery({ associationSlug: slug, status: status });

    const members = useMemo(()=>flattenMembers(currentMembers, studies, memberTypes),[studies, currentMembers, memberTypes]);
    return { members, ...props};
};

export default useMembersByStatus;