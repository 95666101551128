import Container from "@mui/material/Container";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { capitalCase } from "change-case";
import {toast} from "material-react-toastify";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

import EmailTemplateForm from "../../Components/Forms/EmailTemplateForm";
import CollapsableListItem from "../../Components/Lists/CollapsableListItem";
import Block from "../../Components/PageLayout/Content/Block";
import {useAPI} from "../../Contexts/API";


const EmailTemplates = ({ association }) =>{
    const API = useAPI();

    const templateList = [
        "membership_accepted_email",
        "membership_rejected_email",
        "membership_end_email",
        "group_membership_added_email",
        "group_membership_removed_email",
        "group_membership_ended_email",
        "event_enrolled_email",
        "event_unenrolled_email",
        "match_created_email",
    ];
    const templateObjects = templateList.map(template=>({
        "what_it_is_for": template,
        "subject": null,
        "message": null,
    }));
    const [templates, setTemplates] = useState(templateObjects);

    const fields = templateList.flatMap(template=>[
        template+"_subject", template+"_message"
    ]);
    const fieldsObjectToTemplateObjects = (fieldsObject) => {
        // change
        //  ["membership_accepted_email_subject", "membership_accepted_email_message", ...]
        // to
        // [
        //  {"what_it_is_for": "membership_accepted_email", "subject": value1},
        //  {"what_it_is_for": "membership_accepted_email", "message": value2}
        // ]
        const rawTemplateObjects = Object.entries(fieldsObject).reduce((fieldsObjectConvertedToRawTemplateObjects, [field, value])=>{
            const splitField = field.split("_");
            const subjectOrMessage = splitField.pop();
            const what_it_is_for = splitField.join("_");
            fieldsObjectConvertedToRawTemplateObjects.push({
                "what_it_is_for": what_it_is_for,
                [subjectOrMessage]: value
            });
            return fieldsObjectConvertedToRawTemplateObjects;
        }, []);
        // Change
        // [
        //  {"what_it_is_for": "membership_accepted_email", "subject": value1},
        //  {"what_it_is_for": "membership_accepted_email", "message": value2},...
        // ]
        // to
        // ["what_it_is_for": {"what_it_is_for": "membership_accepted_email", "subject": value1, "message": value2},...]
        const templateObjectsConstruct = rawTemplateObjects.reduce((templateObjects, rawTemplateObject)=>(
            {...templateObjects, [rawTemplateObject.what_it_is_for]: {...templateObjects[rawTemplateObject.what_it_is_for],...rawTemplateObject}}
        ), []);
        // Change
        // ["what_it_is_for": {"what_it_is_for": "membership_accepted_email", "subject": value1, "message": value2},...]
        // To
        // [{"what_it_is_for": "membership_accepted_email", "subject": value1, "message": value2},...]
        // and returns
        return Object.values(templateObjectsConstruct);
    };

    const getEmailTemplates = () => {
        API.callv4({
            url: association.url,
            queryParams: {fields: fields.join(",")},
            method: "GET",
            on_succes: (templates_from_association)=>setTemplates(fieldsObjectToTemplateObjects(templates_from_association)),
            on_failure: ()=>toast.error("Something went wrong fetching the templates"),
        });
    };

    const onTemplateChange = (edited_template) => {setTemplates(prevState =>
        prevState.map(template=> (template.what_it_is_for === edited_template.what_it_is_for) ? edited_template : template));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(()=>getEmailTemplates(), [association]);

    return (
        <Container>
            <Block>
                <Typography variant={"h5"}>Templates</Typography>
                <hr className={"box-title-separator"}/>
                <Typography>
                    On this page you will find the templates of the various emails that will be sent by the system.
                    You can change them to create a more personal message to the user.

                    If you want to use the name of the user or the association, you can ...
                </Typography>
                <hr className={"box-title-separator"}/>
                <List>
                    { templates.map((template, id)=>(
                        <CollapsableListItem
                            key={id}
                            component={EmailTemplateForm}
                            listItemText={capitalCase(template.what_it_is_for)}
                            association={association}
                            emailTemplate={template}
                            onTemplateChange={onTemplateChange}
                        />
                    )) }
                </List>
            </Block>
        </Container>
    );
};

EmailTemplates.propTypes = {
    association: PropTypes.object.isRequired
};

export default EmailTemplates;