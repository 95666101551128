import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";

const MenuItemLink = React.forwardRef((props, ref) => {
    const { to, primary, icon } = props;
    return (
        <MenuItem
            ref={ref}
            component={Link}
            to={to}
            primary={primary}
        >
            <ListItemIcon>{ icon }</ListItemIcon>
            <ListItemText primary={primary} />
        </MenuItem>
    );
});

MenuItemLink.propTypes = {
    to: PropTypes.string.isRequired,
    primary: PropTypes.string.isRequired,
    icon: PropTypes.element,
};

export default MenuItemLink;