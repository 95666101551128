import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import LinesEllipsis from "react-lines-ellipsis";

import useAuth from "../../../Store/hooks/useAuth";
import {useGetNotificationsQuery} from "../../../Store/services/MySU/notifications";


const useStyles = makeStyles(theme => ({
    content: {
        width: 300,
        flexGrow: 1,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    ellipsis: {
        whiteSpace: "break-spaces"
    }
}));


const Notification = ({ date, message }) => {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            <Typography variant={"caption"}>{ date }</Typography>
            <LinesEllipsis
                className={`${classes.ellipsis} MuiTypography-root MuiTypography-body1`}
                component={"p"}
                width={300}
                maxLine={5}
                text={message}
            />
        </div>
    );
};

Notification.propTypes = {
    date: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};


const NotificationMenu = () => {
    const { authenticated } = useAuth();

    const { data: notifications } = useGetNotificationsQuery();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {setAnchorEl(event.currentTarget);};
    const handleClose = () => {setAnchorEl(null);};

    if (!authenticated) {
        return null;
    }

    return (
        <div>
            <IconButton
                aria-label={"account of current user"}
                aria-controls={"menu-appbar"}
                aria-haspopup={"true"}
                onClick={handleClick}
                color={"inherit"}
                size={"large"}
            >
                <Badge badgeContent={0} color={"secondary"}>
                    <NotificationsIcon/>
                </Badge>
            </IconButton>
            <Menu
                id={"menu-appbar"}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
            >
                { notifications && notifications.map((notification, id)=>{return (
                    <MenuItem
                        key={"list_link_menu_item"+id}
                    >
                        <Notification {...notification}/>
                    </MenuItem>
                );}) }
            </Menu>
        </div>
    );
};

NotificationMenu.propTypes = {
};

export default NotificationMenu;