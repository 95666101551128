/* eslint-disable no-shadow */
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, {useMemo} from "react";
import {ValidatorForm} from "react-material-ui-form-validator";
import {useParams} from "react-router-dom";

import Button from "../../../Components/Buttons/Button";
import {DateFieldV3} from "../../../Components/FormComponents/Fields/DateField";
import MemberField, {useMemberFieldOptions} from "../../../Components/FormComponents/Fields/MemberField";
import NumberField from "../../../Components/FormComponents/Fields/NumberField";
import TextField from "../../../Components/FormComponents/Fields/TextField";
import Wrapper from "../../../Components/FormComponents/Wrapper";


const PopupBoard = ({ row, onChange, onApplyChanges, onCancelChanges, open }) => {
    const { slug } = useParams();
    const memberFieldOptions = useMemberFieldOptions(slug);
    const profile = useMemo(()=>memberFieldOptions.find(option=> option.url === row.profile), [memberFieldOptions, row.profile]);

    return (
        <Dialog open={open} onClose={onCancelChanges} aria-labelledby={"form-dialog-title"} fullWidth={true}>
            <ValidatorForm
                onSubmit={onApplyChanges}
                onError={errors => console.log(errors)}
                debounceTime={1000}
            >
                <DialogTitle id={"form-dialog-title"}>Board membership details</DialogTitle>
                <DialogContent>
                    <Wrapper>
                        <MemberField
                            associationSlug={slug}
                            name={"profile"}
                            label={"Member"}
                            value={profile || {url: ""}}
                            onChange={(event, value)=>onChange({target: {name: "profile", value: value ? value.url : ""}})}
                            disabled={Boolean(row.slug)}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <TextField
                            name={"duty"}
                            label={"Duty"}
                            value={row.duty || ""}
                            onChange={onChange}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <DateFieldV3
                            name={"date_joined"}
                            label={"Date joined"}
                            value={row.date_joined}
                            onChange={(date)=>onChange({ target: {name: "date_joined", value: date}})}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <DateFieldV3
                            name={"date_left"}
                            label={"Date left"}
                            value={row.date_left}
                            onChange={(date)=>onChange({ target: {name: "date_left", value: date}})}
                        />
                    </Wrapper>
                    <Wrapper>
                        <TextField
                            name={"email"}
                            label={"Email"}
                            value={row.email || ""}
                            onChange={onChange}
                            validators={["isEmail"]}
                            errorMessages={["This is not a valid email"]}
                        />
                    </Wrapper>
                    <Wrapper>
                        <NumberField
                            name={"order"}
                            value={row.order || ""}
                            onChange={onChange}
                            InputProps={{
                                inputProps: {
                                    step: 1, min: 1
                                }
                            }}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <TextField
                            name={"description"}
                            label={"Board text"}
                            value={row.description || ""}
                            onChange={onChange}
                            multiline
                        />
                    </Wrapper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelChanges} variant={"contained"}>
                        Cancel
                    </Button>
                    <Button type={"submit"} color={"primary"} variant={"contained"}>
                        Save
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
};

export default PopupBoard;