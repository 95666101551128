import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Container from "@mui/material/Container";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import Button from "./Buttons/Button";

export const BackButton = ({ container }) => {
    const history = useHistory();

    if (container) {
        return (
            <Container>
                <Button onClick={history.goBack} color={"primary"} className={""}>
                    <FontAwesomeIcon className={"fa-xs"} icon={"chevron-left"}/>
                    &nbsp;Back
                </Button>
            </Container>
        );
    } else {
        return (
            <Button onClick={history.goBack} color={"primary"} className={""}>
                <FontAwesomeIcon className={"fa-xs"} icon={"chevron-left"}/>
                &nbsp;Back
            </Button>
        );
    }
};

BackButton.propTypes = {
    container: PropTypes.bool
};

BackButton.defaultProps = {
    container: true
};