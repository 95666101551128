import PropTypes from "prop-types";
import React, {useState} from "react";

import withPropMap from "../../../Components/HOC/withPropMap";
import useModalState from "../../../Components/Hooks/useModalState";
import {
    useAddMemberTypeMutation,
    useDeleteMemberTypeMutation,
    usePatchMemberTypeMutation
} from "../../../Store/services/MySU/memberTypes";
import MultiFormContainer from "../MultiForm";
import MemberTypeRemoveModal from "./MemberTypeRemoveModal";
import MemberTypesForm from "./MemberTypesForm";
import MemberTypesInfo from "./MemberTypesInfo";

const multiObjectToMemberTypesFormPropsMap = ({ multiObject, handleMultiObjectChange, ...rest_props}) =>
    ({ memberType: multiObject, handleMemberTypeChange: handleMultiObjectChange, update: Boolean(multiObject.slug), ...rest_props});

const multiObjectToMemberTypesInfoPropsMap = ({ multiObject, ...rest_props }) =>
    ({ memberType: multiObject, ...rest_props});

const memberTypeToTitle = (memberType) => memberType.type;
const fieldAndValueToStateChanges = (field, value) => ({ [field]: value} );

const MemberTypesMulti = ({ memberTypes, associationUrl, postSubmit, postDelete, postCancel }) => {
    const [modalOpen, toggleModalOpen] = useModalState(false);
    const [toBeRemovedMemberType, setToBeRemovedMemberType] = useState({});
    const initialMemberType = {
        association: associationUrl,
        full_year_membership: true,
        only_chooseable_by_board: false,
        start_date: "09-01",
        end_date: "08-31",
        ordering: 1,
        membership_fee: 0,
        type: "",
        description: ""
    };
    const MemberTypeFormComponent = withPropMap(MemberTypesForm, multiObjectToMemberTypesFormPropsMap);
    const MemberTypeInfoComponent = withPropMap(MemberTypesInfo, multiObjectToMemberTypesInfoPropsMap);

    const [ addMemberType ] = useAddMemberTypeMutation();
    const [ patchMemberType ] = usePatchMemberTypeMutation();
    const [ deleteMemberType ] = useDeleteMemberTypeMutation();

    const onSubmit = (memberType) => memberType.slug
        ? patchMemberType(memberType).then(()=>postSubmit(memberType))
        : addMemberType(memberType).then(()=>postSubmit(memberType));
    const onDelete = (event) => {
        deleteMemberType({ ...toBeRemovedMemberType, change_membertype_to: event.target.alternativeMemberType.value}).then(()=>{
            postDelete(toBeRemovedMemberType, memberTypes);
            setToBeRemovedMemberType({});
            toggleModalOpen();
        });
    };
    const preDelete = (memberType) => {
        setToBeRemovedMemberType(memberType);
        toggleModalOpen();
    };

    const alternativeMemberTypes = memberTypes.filter(memberType=>memberType.slug !== toBeRemovedMemberType.slug);

    return (
        <>
            <MemberTypeRemoveModal
                onDelete={onDelete}
                modalOpen={modalOpen}
                toggleModalOpen={toggleModalOpen}
                toBeRemovedMemberType={toBeRemovedMemberType}
                alternativeMemberTypes={alternativeMemberTypes}
            />
            <MultiFormContainer
                multiObjects={memberTypes}
                multiObjectToTitle={memberTypeToTitle}
                allowCreation={true}
                initialMultiObject={initialMemberType}
                FormComponent={MemberTypeFormComponent}
                InfoComponent={MemberTypeInfoComponent}
                fieldAndValueToStateChanges={fieldAndValueToStateChanges}
                enableDelete={true}
                onSubmit={onSubmit}
                onCancel={postCancel}
                onDelete={preDelete}
                title={"Member types"}
            />
        </>
    );
};

MemberTypesMulti.propTypes = {
    memberTypes: PropTypes.array.isRequired,
    associationUrl: PropTypes.string.isRequired,
    postSubmit: PropTypes.func,
    postDelete: PropTypes.func,
    postCancel: PropTypes.func
};

const dummyFunction = () => {};
MemberTypesMulti.defaultProps = {
    postSubmit: dummyFunction,
    postDelete: dummyFunction,
    postCancel: dummyFunction
};

export default MemberTypesMulti;