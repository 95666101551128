import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import React from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import {SelectFieldV3} from "../../../Components/FormComponents/Fields/SelectField";
import SpecificDataField from "../../../Components/FormComponents/Fields/SpecificDataField";
import {TextFieldV3} from "../../../Components/FormComponents/Fields/TextField";

const DataFieldsForm = ({ dataField, handleDataFieldChange, onSubmit, update }) => {
    const smallScreen = useMediaQuery((theme)=>theme.breakpoints.up("sm"), { noSsr: true });
    const mediumScreen = useMediaQuery((theme)=>theme.breakpoints.up("md"), { noSsr: true });
    const variant = smallScreen ? "separated" : "outlined";
    const direction = mediumScreen ? "row" : "column";
    
    return (
        <ValidatorForm
            onSubmit={onSubmit}
            onError={errors => console.log(errors)}
        >
            <Grid container spacing={2} direction={direction}>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"name"}
                                label={"Name"}
                                value={dataField.name}
                                onChange={(event) => handleDataFieldChange("name", event.target.value)}
                                labelXs={3}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectFieldV3
                                variant={variant}
                                name={"type"}
                                label={"Type"}
                                validators={["required"]}
                                errorMessages={["A type has to be set"]}
                                value={dataField.type}
                                onChange={(event) => handleDataFieldChange("type", event.target.value)}
                                disabled={update}
                                labelXs={3}
                            >
                                <MenuItem value={"String"}>Text</MenuItem>
                                <MenuItem value={"Number"}>Number</MenuItem>
                                <MenuItem value={"Choice"}>Choice</MenuItem>
                                <MenuItem value={"Boolean"}>Yes/No</MenuItem>
                            </SelectFieldV3>
                        </Grid>
                        { dataField.type === "Choice" &&
                            <Grid item xs={12}>
                                <TextFieldV3
                                    variant={variant}
                                    name={"choices"}
                                    label={"Choices"}
                                    value={dataField.choices}
                                    onChange={(event) => handleDataFieldChange("choices", event.target.value)}
                                    helperText={"Enter a comma-separated list, e.g. 'apple pie,quark pie,peach,lemonade'"}
                                    labelXs={3}
                                />
                            </Grid>
                        }
                        { dataField.type === "Boolean" &&
                            <Grid item xs={12}>
                                <SelectFieldV3
                                    variant={variant}
                                    name={"mandatory"}
                                    label={"Mandatory"}
                                    validators={["required"]}
                                    errorMessages={["A type has to be set"]}
                                    helperText={"Put this field to yes, if you want that people have to check this box in order to be able to apply for a membership."}
                                    value={dataField.mandatory}
                                    onChange={(event) => handleDataFieldChange("mandatory", event.target.value)}
                                    labelXs={3}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </SelectFieldV3>
                            </Grid>
                        }
                        { dataField.mandatory ||
                            <Grid item xs={12}>
                                <SelectFieldV3
                                    variant={variant}
                                    name={"board_only"}
                                    label={"Board only"}
                                    value={dataField.board_only}
                                    onChange={(event) => handleDataFieldChange("board_only", event.target.value)}
                                    helperText={"Setting this to `Yes` means that the content of this field can only be set by the board. NB: The member can still read it."}
                                    required
                                    labelXs={3}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </SelectFieldV3>
                            </Grid>
                        }
                        { dataField.mandatory ||
                            <Grid item xs={12}>
                                <SpecificDataField
                                    variant={variant}
                                    name={"default"}
                                    label={"Default"}
                                    field={{...dataField, url: "default"}}
                                    value={dataField.default}
                                    onChange={handleDataFieldChange}
                                    required={dataField.type === "Boolean" || dataField.type === "Choice"}
                                    labelXs={3}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"helper_text"}
                                label={"Help message"}
                                value={dataField.helper_text}
                                onChange={(event) => handleDataFieldChange("helper_text", event.target.value)}
                                helperText={"The text here will be shown be shown just like this text is."}
                                labelXs={3}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}/>
            </Grid>
        </ValidatorForm>
    );
};

DataFieldsForm.propTypes = {
    dataField: PropTypes.object.isRequired,
    handleDataFieldChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    update: PropTypes.bool.isRequired
};

export default DataFieldsForm;