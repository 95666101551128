import {emptySplitApi, pessimisticCreation} from "./index";

const memberTypesApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["MemberType"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getMemberTypesByAssociation: build.query({
                query: (association_slug) => ({ url: "/membertypes", params: {"association__slug": association_slug, limit: 100, disabled: false } }),
                providesTags: (result, error, association_slug) => [{ type: "MemberType", id: association_slug }],
                transformResponse: (response) => response.results
            }),
            addMemberType: build.mutation({
                query: (body) => ({ url: "/membertypes/", method: "POST", body: body }),
                async onQueryStarted(memberType, { dispatch, queryFulfilled }) {
                    await pessimisticCreation({
                        dispatch,
                        queryFulfilled,
                        api: memberTypesApi,
                        query: "getMemberTypesByAssociation",
                        entityToQueryParam: () => memberType.association.split("/associations/")[1]
                    });
                }
            }),
            patchMemberType: build.mutation({
                query: ({ slug, ...patch }) => ({ url: `/membertypes/${slug}`, method: "PATCH", body: patch }),
                invalidatesTags: (result, error, { association }) => [
                    { type: "MemberType", id: association.split("/associations/")[1] },
                    { type: "Member", id: association.split("/associations/")[1] }
                ]
            }),
            deleteMemberType: build.mutation({
                query: ({ slug, change_membertype_to }) => ({ url: `/membertypes/${slug}`, method: "DELETE", body: { change_membertype_to: change_membertype_to } }),
                invalidatesTags: (result, error, { association }) => [
                    { type: "MemberType", id: association.split("/associations/")[1] },
                    { type: "Member", id: association.split("/associations/")[1] }
                ]
            })
        }),
        overrideExisting: false
    });

export const {
    useGetMemberTypesByAssociationQuery,
    useAddMemberTypeMutation,
    usePatchMemberTypeMutation,
    useDeleteMemberTypeMutation
} = memberTypesApi;
// export default memberTypesApi;