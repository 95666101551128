import AddIcon from "@mui/icons-material/Add";
import {grey} from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";

import logo from "../../../../img/logo oud.png";
import Menu from "../../Menu/Menu";


const useStyles = makeStyles(theme=>({
    association_logo: {
        maxHeight: 42,
        height: 42,
        maxWidth: null
    },
    dropdown: {
        color: grey[100]
    }
}));

const AssociationsNavDropdown = ({ url, association_memberships }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    let items = association_memberships.map(membership=>({
        onClick: ()=>{},
        primary: membership.association.name,
        icon: <img src={membership.association.logo} className={classes.association_logo} alt={""}/>
    }));
    // items.unshift({onClick: ()=>{}, primary: "Home", icon: <img src={logo} className={classes.association_logo}/>});
    items.push({onClick: ()=>{}, primary: "Overview", icon: <AddIcon/>});

    return (
        <Menu items={items}>
            <Typography variant={"h4"} className={classes.dropdown}>Associations</Typography>
        </Menu>
    );
};

AssociationsNavDropdown.propTypes = {
    url: PropTypes.string.isRequired,
    association_memberships: PropTypes.array.isRequired
};

export default AssociationsNavDropdown;