import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import {Switch} from "react-router-dom";

import Button from "../../../Components/Buttons/Button";
import DateField, {DateFieldV2, DateFieldV3} from "../../../Components/FormComponents/Fields/DateField";
import FileField from "../../../Components/FormComponents/Fields/FileField";
import NumberField from "../../../Components/FormComponents/Fields/NumberField";
import SelectField from "../../../Components/FormComponents/Fields/SelectField";
import TextField, {TextFieldV3} from "../../../Components/FormComponents/Fields/TextField";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";


const GroupForm = ({ onSubmit, onDelete, onCancel, enableDelete, group, parentable_groups, handleGroupChange }) => {
    let bodyText = "body1";
    
    return (
        <ValidatorForm
            onSubmit={onSubmit}
            onError={errors => console.log(errors)}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={bodyText} sx={{ textTransform: "uppercase" }} color={"#B4B8BB"} >Contact</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={"separated"}
                                label={"Name"}
                                value={group.full_name}
                                onChange={(group)=>handleGroupChange("full_name",group.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={"separated"}
                                label={"Short name"}
                                value={group.short_name}
                                onChange={(group)=>handleGroupChange("short_name",group.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={"separated"}
                                label={"Email"}
                                validators={["isEmail"]}
                                errorMessages={["This is not a valid email"]}
                                value={group.email}
                                onChange={(group)=>handleGroupChange("email",group.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={bodyText} sx={{ textTransform: "uppercase" }} color={"#B4B8BB"}>Personal</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            { /*<DateField*/ }
                            { /*    label={"Founding date"}*/ }
                            { /*    // variant={"separated"}*/ }
                            { /*    size={"small"}*/ }
                            { /*    value={group.founding_date}*/ }
                            { /*    onChange={(date)=>handleGroupChange("founding_date", date)}*/ }
                            { /*    disableFuture*/ }
                            { /*    required*/ }
                            { /*/>*/ }
                        </Grid>
                        <Grid item xs={12}>
                            { /*<DateField*/ }
                            { /*    size={"small"}*/ }
                            { /*    disabled*/ }
                            { /*    name={"dissolution_date"}*/ }
                            { /*    label={"Dissolution date"}*/ }
                            { /*    // variant={"separated"}*/ }
                            { /*    value={group.dissolution_date}*/ }
                            { /*    onChange={(date)=>handleGroupChange("dissolution_date", date)}*/ }
                            { /*/>*/ }
                        </Grid>
                        <Grid item xs={12}>
                            <NumberField
                                label={"Year - start"}
                                variant={"separated"}
                                value={group.start_year}
                                onChange={(group)=>handleGroupChange("start_year",group.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <NumberField
                                label={"Year - end"}
                                variant={"separated"}
                                value={group.end_year}
                                onChange={(group)=>handleGroupChange("end_year",group.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={"separated"}
                                label={"Edition"}
                                value={group.edition}
                                onChange={(group)=>handleGroupChange("edition",group.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                label={"Theme"}
                                variant={"separated"}
                                value={group.theme}
                                onChange={(group)=>handleGroupChange("theme",group.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <NumberField
                                label={"Number"}
                                variant={"separated"}
                                value={group.number}
                                onChange={(group)=>handleGroupChange("number",group.target.value)}
                            />
                        </Grid>
                        {/*{ group.board_group &&*/}
                        {/*<Grid item xs={12}>*/}
                        {/*    <Grid container spacing={2}>*/}
                        {/*        <Grid item xs={4}>*/}
                        {/*            <Typography>Board permissions?</Typography>*/}
                        {/*        </Grid>*/}
                        {/*        <Grid item xs={8}>*/}

                        {/*        </Grid>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                        {/*}*/}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction={"row"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                    >
                        <Grid item>
                            { /*<div>*/ }
                            <Button onClick={onCancel} style={{textTransform: "none"}}>Cancel</Button>
                                &nbsp;
                            <Button type={"submit"} variant={"contained"} size={"small"} color={"primary"} style={{textTransform: "none"}}>Save</Button>
                            { /*</div>*/ }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            { /*<Wrapper>*/ }
            { /*    <div>*/ }
            { /*        <Button variant={"outlined"} color={"error"} disabled={!enableDelete} onClick={onDelete}>*/ }
            { /*            Delete*/ }
            { /*        </Button>*/ }
            { /*    </div>*/ }
            { /*    <div>*/ }
            { /*        <Button variant={"contained"} onClick={onCancel} color={"secondary"}>Cancel</Button>*/ }
            { /*        &nbsp;*/ }
            { /*        <Button type={"submit"} variant={"contained"} color={"primary"}>Save</Button>*/ }
            { /*    </div>*/ }
            { /*</Wrapper>*/ }
        </ValidatorForm>
    );
};

GroupForm.propTypes = {
    group: PropTypes.object.isRequired,
    handleGroupChange: PropTypes.func.isRequired,
    // parentable_groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    enableDelete: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

GroupForm.defaultProps = {
};

export default GroupForm;