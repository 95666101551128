import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {clearUser, setUser} from "../../../Store/slices/terminal";
import KeyPad from "./KeyPad/KeyPad";
import TerminalBlocks from "./TerminalBlocks/TerminalBlocks";
import PassCodeModal from "./UserSelectionModal/PassCodeModal";
import UserSelectionModal from "./UserSelectionModal/UserSelectionModal";

const useStyles = makeStyles(() => ({
    container: {
        width: "100%"
    },
}));

const Home = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const selectedProfile = useSelector((state => state.terminal.user));
    const setSelectedProfile = (profile) => dispatch(setUser(profile));
    const clearSelectedProfile = () => dispatch(clearUser());

    const [keyboardValue, setKeyboardValue] = useState("");
    const [numpadValue, setNumpadValue] = useState("");

    return (
        <Grid container className={classes.container}>
            <UserSelectionModal
                keyboardValue={keyboardValue} setKeyboardValue={setKeyboardValue}
                numpadValue={numpadValue} setNumpadValue={setNumpadValue}
                selectedProfile={selectedProfile} setSelectedProfile={setSelectedProfile}
            />
            <PassCodeModal
                selectedProfile={selectedProfile}
                clearSelectedProfile={clearSelectedProfile}
            />
            <Grid item xs={false} sm={7} md={9} lg={10}>
                <TerminalBlocks/>
            </Grid>
            <Grid item xs={12} sm={5} md={3} lg={2}>
                <KeyPad
                    keyboardValue={keyboardValue} setKeyboardValue={setKeyboardValue}
                    numpadValue={numpadValue} setNumpadValue={setNumpadValue}
                />
            </Grid>
        </Grid>
    );
};

export default Home;