import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import PropTypes from "prop-types";
import React from "react";

import ToolbarButton from "./ToolbarButton";


const FilterToggleButton = ({ onClick }) => (
    <ToolbarButton
        onClick={onClick}
        tooltip={"Click to toggle filtering"}
        Icon={FilterAltRoundedIcon}
        label={"Filter"}
        extraPluginDependencies={[{ name: "FilteringState" }]}
    />
);

FilterToggleButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default FilterToggleButton;