import ContactsIcon from "@mui/icons-material/Contacts";
import LockIcon from "@mui/icons-material/Lock";
import {Typography} from "@mui/material";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import Button from "../../Components/Buttons/Button";
import CollapsableList from "../../Components/Lists/CollapsableList";
import ConfirmationModal from "../../Components/Modals/ConfirmationModal";
import Block from "../../Components/PageLayout/Content/Block";


const EmailUsers = ({ association }) => {
    const [users, setUsers] = useState([
        {id: "1", email: "2", password: "3"},
    ]);

    return (
        <Container>
            <Block>
                <h4>Email Accounts</h4>
                <Typography>
                    These are the email accounts belonging to the association. These are meant for functional accounts, e.g. treasurer@[domain of association] as email for the treasurer. Using these provided continuity between board members by making sure that emails don&apos;t get lost in personal inboxes.
                </Typography>
                { association.domain !== undefined
                    ? (<>
                        <hr className={"box-title-separator"}/>
                        { users && users.map((user, index) => { return (
                            <CollapsableList
                                component={EmailUser}
                                listItemText={user.email}
                                key={index}
                                association={association}
                                user={user}
                            />
                        );}) }
                        <Divider/>
                        <CollapsableList
                            component={EmailUser}
                            listItemText={"New"}
                            association={association}
                            postAdd={()=>{}}
                        />
                    </>)
                    : (<>
                        <br/>
                        <Typography>This feature requires access to an email domain. </Typography>
                    </>)
                }
            </Block>
        </Container>
    );
};

EmailUsers.propTypes = {
    association: PropTypes.object.isRequired
};

EmailUsers.defaultProps = {

};

export default EmailUsers;

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(1, 0),
    },
    icon: {
        margin: theme.spacing(2, 0),
        marginRight: theme.spacing(2),
    },
    textField: {
        width: "100%",
    }
}));

const EmailUser = (props) => {
    const classes = useStyles();
    const {user, postAdd} = props;
    const [emailUser, setEmailUser] = useState(user);
    const defaultModalState = {open: false, title: "", description: "", onConfirm: ()=>{}};
    const [modalState, setModalState] = useState(defaultModalState);

    const textEditorProps = field => ({
        variant: "outlined",
        label: field[0].toUpperCase() + field.slice(1),
        className: classes.textField,
        containerProps: {className: classes.textField}
    });

    const handleUserChange = (field, value) => {
        setEmailUser(prevState => ({...prevState, [field]: value}));
    };

    const handleValidSubmit = (event, values) => {
        if (postAdd !== undefined) {
            addUser(values);
        } else {
            patchUser(values);
        }
    };

    const deleteUser = () => {
        setModalState({
            open: true,
            title: "Confirm Delete",
            description: `
                Are you sure you wish to delete this user?
                
                This can NOT be reversed!
            `,
            onConfirm: ()=>{}
        });
    };

    const patchUser = (values) => {
        setModalState({
            open: true,
            title: "Confirm Update",
            description: `
                Are you sure you wish to update this user?
            `,
            onConfirm: ()=>{}
        });
    };

    const addUser = (values) => {
        setModalState({
            open: true,
            title: "Confirm Delete",
            description: `
                Are you sure you wish to add this user?
            `,
            onConfirm: ()=>{}
        });
    };

    const resetUser = () => {
        props.handleToggle();
    };

    const closeModal = () => {
        setModalState(defaultModalState);
    };

    return (
        <>
            { modalState.open && <ConfirmationModal
                title={modalState.title}
                description={modalState.description}
                onConfirm={modalState.title.onConfirm}
                onCancel={closeModal}
            /> }
            <ValidatorForm
                onSubmit={handleValidSubmit}
                onError={errors => console.log(errors)}
            >
                <div className={classes.wrapper}>
                    <ContactsIcon className={classes.icon} color={"action"}/>
                    <TextValidator
                        {...textEditorProps("email")}
                        value={emailUser.email}
                        onChange={(event)=>handleUserChange("email",event.target.value)}
                    />
                </div>
                <div className={classes.wrapper}>
                    <LockIcon className={classes.icon} color={"action"}/>
                    <TextValidator
                        {...textEditorProps("password")}
                        type={"password"}
                        value={emailUser.password}
                        onChange={(event)=>handleUserChange("password",event.target.value)}
                    />
                </div>
                <div className={classes.wrapper} style={{"justifyContent": "space-between"}}>
                    <div>
                        <Button variant={"outlined"} onClick={deleteUser} color={"secondary"}>
                            Delete
                        </Button>
                    </div>
                    <div>
                        <Button type={"submit"} variant={"contained"} color={"primary"}>Save</Button>
                        &nbsp;
                        <Button variant={"contained"} onClick={resetUser}>Cancel</Button>
                    </div>
                </div>
            </ValidatorForm>
        </>
    );
};

EmailUser.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        password: PropTypes.string
    }),
    handleToggle: PropTypes.func.isRequired,
    postAdd: PropTypes.func,
};

EmailUser.defaultProps = {
    user: {id: "", email: "", password: ""}
};
