import DescriptionIcon from "@mui/icons-material/Description";
import EuroIcon from "@mui/icons-material/Euro";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import { DateFieldV3 } from "../../../Components/FormComponents/Fields/DateField";
import MemberField, {useMemberFieldOptions} from "../../../Components/FormComponents/Fields/MemberField";
import NumberField from "../../../Components/FormComponents/Fields/NumberField";
import TextField, {TextFieldV3} from "../../../Components/FormComponents/Fields/TextField";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";

const InvoicePopupForm = ({ associationSlug, row, onChange, onApplyChanges, onCancelChanges, open }) => {
    const smallScreen = useMediaQuery((theme)=>theme.breakpoints.up("sm"), { noSsr: true });
    const variant = smallScreen ? "separated" : "outlined";

    const memberFieldOptions = useMemberFieldOptions(associationSlug);
    const profile = useMemo(()=>memberFieldOptions.find(option=> option.url === row.profile), [memberFieldOptions, row.profile]);

    return (
        <Dialog open={open} onClose={onCancelChanges} aria-labelledby={"form-dialog-title"} fullWidth={true}>
            <ValidatorForm
                onSubmit={onApplyChanges}
                onError={errors => console.log(errors)}
            >
                <DialogTitle id={"form-dialog-title"}>Invoice Details</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MemberField
                                variant={variant}
                                associationSlug={associationSlug}
                                name={"profile"}
                                label={"Member"}
                                value={profile || ""}
                                onChange={(event, value)=>onChange({target: {name: "profile", value: value ? value.url : ""}})}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DateFieldV3
                                variant={variant}
                                name={"date_issued"}
                                label={"Date issued"}
                                value={row.date_issued || ""}
                                onChange={(date)=>onChange({target: {name: "date_issued", value: date}})}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <NumberField
                                variant={variant}
                                name={"price"}
                                label={"Price"}
                                value={row.price || ""}
                                onChange={onChange}
                                InputProps={{
                                    inputProps: {
                                        step: 0.1, min: 0
                                    }
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"reason"}
                                label={"Reason"}
                                value={row.reason || ""}
                                onChange={onChange}
                                required
                            />
                        </Grid>
                        <Grid container item spacing={1} justifyContent={"flex-end"}>
                            <Grid>
                                <div/>
                                <div>
                                    <Button onClick={onCancelChanges} variant={"contained"} color={"secondary"} disableElevation>
                                        Cancel
                                    </Button>
                                    &nbsp;
                                    <Button type={"submit"} color={"primary"} variant={"contained"} disableElevation>
                                        Save
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </ValidatorForm>
        </Dialog>
    );
};

InvoicePopupForm.propTypes = {
    associationSlug: PropTypes.string.isRequired,
    row: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onApplyChanges: PropTypes.func.isRequired,
    onCancelChanges: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default InvoicePopupForm;