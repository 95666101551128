import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {orderBy} from "lodash";
import React, { useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import Wrapper from "../../../Components/FormComponents/Wrapper";
import withExtraProps from "../../../Components/HOC/withExtraProps";
import Block from "../../../Components/PageLayout/Content/Block";
import ExtremeTable from "../../../Components/Tables/ExtremeTable";
import ProductPopupForm from "../../../Forms/PopupForms/Products/ProductPopupForm";
import {
    useAddInvoiceMutation, useDeleteInvoiceMutation,
    useGetInvoicesByAssociationQuery,
    usePatchInvoiceMutation
} from "../../../Store/services/MySU/invoices";
import {
    useGetCurrentMembersByAssociationQuery,
} from "../../../Store/services/MySU/members";

const columns = [
    { name: "name", title: "Name" },
    { name: "price", title: "Price" },
    { name: "product_category", title: "Category"},
];
const currencyColumns = ["price"];
const choiceColumns = ["product_category"];

const Products = () => {
    const { slug } = useParams();

    const { data: members } = useGetCurrentMembersByAssociationQuery(slug);
    const { data: invoices } = useGetInvoicesByAssociationQuery(slug);

    const [ addInvoice ] = useAddInvoiceMutation();
    const [ patchInvoice ] = usePatchInvoiceMutation();
    const [ removeInvoice ] = useDeleteInvoiceMutation();

    const Popup = withExtraProps(ProductPopupForm, {associationSlug: slug});

    const [selection, setSelection] = useState([]);

    const onAdd = (newInvoice) => addInvoice({
        ...newInvoice,
        association: `/associations/${slug}`,
    });
    const onDelete = (removedInvoiceSlug) => removeInvoice({ slug: removedInvoiceSlug, association: `/associations/${slug}` });
    const onEdit = (differences, originalInvoice, newInvoice) => patchInvoice(newInvoice);

    const rows = useMemo(()=> {
        const invoiceWithEntries = invoices
            ? invoices.map(invoice => {
                let member = invoice.member;
                if (!member && members) {
                    const profile = members.find(member=>member.profile.url === invoice.profile).profile;
                    member = profile.given_name + " " + profile.surname;
                }
                return {
                    ...invoice,
                    member: member,
                };
            })
            : [];
        return orderBy(invoiceWithEntries, ["date_issued"], ["desc"]);
    }, [invoices, members]);

    return (
        <Container>
            <Block>
                <Wrapper>
                    <Typography variant={"h5"}>Products</Typography>
                </Wrapper>
                <hr className={"box-title-separator"}/>
                <Paper>
                    <ExtremeTable
                        rows={rows}
                        headers={columns}
                        currencyColumns={currencyColumns}
                        choiceColumns={choiceColumns}
                        choiceSelectionOptions={{"product_category": []}}
                        selection={{selection: selection, setSelection:setSelection}}
                        onAdd={onAdd}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        showEditing={true}
                        showExporter={true}
                        editThroughPopup={true}
                        Popup={Popup}
                        allowAdd={true}
                        allowDelete={true}
                        allowEdit={true}
                        showGrouping={false}
                    />
                </Paper>
            </Block>
        </Container>
    );
};

Products.propTypes = {
};

export default Products;