import PropTypes from "prop-types";
import React from "react";

import withPropMap from "../../../Components/HOC/withPropMap";
import {
    useAddDataFieldMutation,
    useDeleteDataFieldMutation,
    usePatchDataFieldMutation
} from "../../../Store/services/MySU/dataFields";
import {useAddGroupMutation, useDeleteGroupMutation, usePatchGroupMutation} from "../../../Store/services/MySU/groups";
import DropDownFormContainer from "../dropDownForm";
import DataFieldsForm from "./DataFieldsForm";

const dropDownObjectToDataFieldsFormPropsMap = ({ dropDownObject, handleDropDownObjectChange, ...rest_props}) =>
    ({ dataField: dropDownObject, handleDataFieldChange: handleDropDownObjectChange, update: Boolean(dropDownObject.slug), ...rest_props});

const DataFieldToTitle = (dataField) => dataField.name;
const fieldAndValueToStateChanges = (field, value) => {
    if ( field === "mandatory" && value === true) {
        return { mandatory: true, default: "False" };
    }
    return { [field]: value };
};

const DataFieldsDropDown = ({ dataFields, associationUrl, postSubmit, postDelete, postCancel, creating }) => {
    const initialDataField = {
        name: "",
        association: associationUrl,
        type: "String",
        board_only: false,
        mandatory: false,
        choices: "",
        default: "",
        helper_text: "",
    };
    const DataFieldFormComponent = withPropMap(DataFieldsForm, dropDownObjectToDataFieldsFormPropsMap);

    const [ addDataField ] = useAddDataFieldMutation();
    const [ patchDataField ] = usePatchDataFieldMutation();
    const [ deleteDataField ] = useDeleteDataFieldMutation();

    const onSubmit = (dataField) => dataField.slug
        ? patchDataField(dataField).then(()=>postSubmit(dataField))
        : addDataField(dataField).then(()=>postSubmit(dataField));
    const onDelete = (dataField) => deleteDataField(dataField).then(()=>postDelete(dataField));

    return (
        <DropDownFormContainer
            creating={creating}
            dropDownObjects={dataFields}
            dropDownObjectToTitle={DataFieldToTitle}
            allowCreation={true}
            initialDropDownObject={initialDataField}
            FormComponent={DataFieldFormComponent}
            fieldAndValueToStateChanges={fieldAndValueToStateChanges}
            enableDelete={true}
            onSubmit={onSubmit}
            onCancel={postCancel}
            onDelete={onDelete}
        />
    );
};

DataFieldsDropDown.propTypes = {
    dataFields: PropTypes.array.isRequired,
    associationUrl: PropTypes.string.isRequired,
    creating: PropTypes.bool.isRequired,
    postSubmit: PropTypes.func,
    postDelete: PropTypes.func,
    postCancel: PropTypes.func
};

const dummyFunction = () => {};
DataFieldsDropDown.defaultProps = {
    postSubmit: dummyFunction,
    postDelete: dummyFunction,
    postCancel: dummyFunction
};

export default DataFieldsDropDown;