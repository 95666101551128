import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import {useHistory} from "react-router-dom";

import Button from "../../../Components/Buttons/Button";
import TextField from "../../../Components/FormComponents/Fields/TextField";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import Block from "../../../Components/PageLayout/Content/Block";


const Login = () => {
    const history = useHistory();

    const handleSubmit = () => {
        console.log("triggered");
        history.push("/terminal");
    };

    return (
        <Container>
            <Block>
                <Typography variant={"h5"}>API User</Typography>
                <hr className={"box-title-separator"}/>
                <ValidatorForm
                    onSubmit={handleSubmit}
                    onError={errors => console.log(errors)}
                >
                    <Wrapper>
                        <TextField name={"Username"}/>
                    </Wrapper>
                    <Wrapper>
                        <TextField name={"Password"} type={"password"}/>
                    </Wrapper>
                    <Wrapper>
                        <div/>
                        <Button variant={"contained"} color={"primary"} type={"submit"}>Submit</Button>
                    </Wrapper>
                </ValidatorForm>
            </Block>
        </Container>
    );
};

export default Login;