import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {orderBy} from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {useHistory, useParams} from "react-router-dom";

import Sushi from "../../../../img/Sushi-11-me.jpg";
import Button from "../../../Components/Buttons/Button";
import Card from "../../../Components/Card/Card";
import CardGrid from "../../../Components/Card/CardGrid";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import RoutingButton from "../../../Components/PageLayout/Content/RoutingButton";
import TopPageBlock from "../../../Components/PageLayout/Content/TopPageBlock";
import {useGetGroupsByAssociationQuery} from "../../../Store/services/MySU/groups";

const GroupList = ({ boards }) => {
    const { slug } = useParams();
    const history = useHistory();
    const { data: groups } = useGetGroupsByAssociationQuery({ associationSlug: slug, boardGroup: boards });

    const groupToCard = (group) => ({
        name: group.short_name || group.full_name,
        description: "",
        photo: group.photo || Sushi,
        onClick: () => history.push((group.board_group ? "boards/" : "committees/") + group.slug)
    });
    const cards = orderBy(groups || [], ["full_name"], ["asc"]).map(board=>groupToCard(board));

    return (
        <Container maxWidth={"xl"}>
            <Wrapper>
                <RoutingButton
                    routeStringPieces={[
                        boards ? "Boards" : "Committees"
                    ]}
                />
            </Wrapper>
            <TopPageBlock>
                <Grid container justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
                    <Grid item>
                        <Typography variant={"h5"}>
                            { boards ? "Boards" : "Committees" }
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant={"contained"} size={"small"} style={{textTransform: "none"}} startIcon={<AddIcon fontSize={"large"} />} onClick={()=>history.push((boards ? "boards/" : "committees/") + "add")}>
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </TopPageBlock>
            <CardGrid
                componentProps={cards}
                Component={Card}
                justifyContent={"left"}
                spacings={[0,2,2]}
            />
        </Container>
    );
};

GroupList.propTypes = {
    boards: PropTypes.bool.isRequired
};

export default GroupList;