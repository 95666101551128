import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import MuiButton from "@mui/material/Button";
import PropTypes from "prop-types";
import React from "react";

const versionToPropsMap = {
    "add": {
        color: "primary",
        variant: "contained",
        startIcon: <AddIcon />
    },
    "edit": {
        color: "primary",
        variant: "contained",
        startIcon: <EditIcon />
    },
    "remove": {
        color: "primary",
        variant: "outlined",
        startIcon: <RemoveIcon />
    },
    "cancel": {
    },
    "save": {
        color: "primary",
        variant: "contained",
    },
};

const Button = ({ version, ...rest_props }) => {
    if (!Object.keys(versionToPropsMap).includes(version)) {
        return <MuiButton {...rest_props}/>;
    }
    return (
        <MuiButton
            style={{textTransform: "none"}}
            size={"small"}
            disableElevation
            {...versionToPropsMap[version]}
            {...rest_props}
        />
    );
};

Button.propTypes = {
    version: PropTypes.string
};

Button.defaultProps = {
    version: ""
};

export default Button;