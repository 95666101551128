import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, {useEffect, useMemo, useState} from "react";

import ConfirmationModal from "../../Components/Modals/ConfirmationModal";
import Block from "../../Components/PageLayout/Content/Block";
import MemberTable from "../../Components/Tables/MemberTable";
import useMembersByStatus from "../../Store/hooks/useMembersByStatus";
import {
    useSetFinancialObligationSatisfiedMutation
} from "../../Store/services/MySU/members";

const initialHeaders = ["student_number", "given_name", "surname", "email", "iban", "date_joined", "date_left"];
const messages = {
    editCommand: "Decide",
};

const title = "Process Member";
const confirmButtonText = "Yes";
const secondaryButtonText = "Not yet";
const cancelButtonText = "I don't know";

const Popup = ({ row, onChange, onApplyChanges, onCancelChanges, open }) => {
    const description = useMemo(()=>"Has " + row.given_name + " " + row.surname +" paid all the things that the member needs to be pay?", [row.given_name, row.surname]);
    useEffect(()=>{
        if (row.status) {
            onApplyChanges();
        }
    }, [row.status]);

    const acceptMember = () => onChange({ target: { name: "financial_obligations_satisfied", value: true } });
    const rejectMember = () => onCancelChanges();

    return (
        <ConfirmationModal
            title={title}
            onCancel={onCancelChanges}
            open={open}
            onConfirm={acceptMember}
            description={description}
            size={"lg"}
            cancelButtonText={cancelButtonText}
            confirmButtonText={confirmButtonText}
            secondaryButtonText={secondaryButtonText}
            secondaryAction={rejectMember}
        />
    );
};

const MembersLeaving = () => {
    const { members } = useMembersByStatus("Ended");
    const [ updateMembership ] = useSetFinancialObligationSatisfiedMutation();


    const onEdit = (differences, original_row, edited_row, edited_row_index) => {
        updateMembership({
            slug: original_row.urls.membership.split("/memberships/")[1],
            financial_obligations_satisfied: edited_row.financial_obligations_satisfied
        });
    };

    return (
        <Paper elevation={0}>
            <MemberTable
                initial_headers={initialHeaders}
                messages={messages}
                allowEdit={true}
                onEdit={onEdit}
                showSelect={false}
                showGrouping={false}
                editThroughPopup={true}
                Popup={Popup}
                rows={members || []}
                title={"Leaving members"}
            />
        </Paper>
    );
};

export default MembersLeaving;