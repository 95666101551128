import {Input} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import {TextValidator} from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import useFieldStyles from "../fieldStyles";
import {TextFieldV2} from "./TextField";


const FileField = (props) => {
    const classes = useFieldStyles();
    return (
        <TextField
            variant={"outlined"}
            className={classes.field}
            InputLabelProps={{ shrink: true }}
            type={"file"}
            {...props}
        />
    );
};

const FileFieldV2 = ({label, ...props}) => {
    const classes = useFieldStyles();
    return (
        <Button
            variant={"outlined"}
            InputLabelProps={{ shrink: true }}
            type={"file"}
            size={"small"}
            component={"label"}
            style={{textTransform: "none"}}
        >
            { " " }
            Upload { label }
            <input type={"file"} {...props} hidden />
        </Button>
    );
};


FileField.propTypes = {
};

FileFieldV2.propTypes = {
};

export { FileFieldV2 };
export default FileField;