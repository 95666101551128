import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import React from "react";
import { ValidatorForm } from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import BooleanField from "../../../Components/FormComponents/Fields/BooleanField";
import NumberField from "../../../Components/FormComponents/Fields/NumberField";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";


const FinancialSettingsForm = ({ association, handleAssociationChange, onSubmit, onCancel }) => {
    const smallScreen = useMediaQuery((theme)=>theme.breakpoints.up("sm"), { noSsr: true });
    const variant = smallScreen ? "separated" : "outlined";

    return (
        <ValidatorForm
            onSubmit={onSubmit}
            onError={errors => console.log(errors)}
        >
            { /*<Wrapper>*/ }
            { /*    <IconHolder Icon={AccountBalanceIcon}/>*/ }
            { /*    <BooleanField*/ }
            { /*        name={"Members need Iban"}*/ }
            { /*        value={association.member_needs_iban}*/ }
            { /*        onChange={(event) => handleAssociationChange("member_needs_iban", event.target.value)}*/ }
            { /*        helperText={"This will only be enforced on new members."}*/ }
            { /*    />*/ }
            { /*</Wrapper>*/ }
            <Wrapper>
                <IconHolder Icon={LowPriorityIcon}/>
                <NumberField
                    variant={variant}
                    name={"debt_collection_threshold"}
                    label={"Debt Collection Threshold"}
                    value={association.debt_collection_threshold}
                    onChange={(event) => handleAssociationChange("debt_collection_threshold", event.target.value)}
                    InputProps={{
                        inputProps: {
                            step: 0.1, min: 0
                        }
                    }}
                    helperText={
                        "Debt Collection Entries below this amount will not be included in the debt collection, " +
                        "unless its the final debt collection of a membership"
                    }
                />
            </Wrapper>

            <Wrapper>
                <div/>
                <div>
                    <Button onClick={onCancel} disableElevation>Cancel</Button>
                    &nbsp;
                    <Button type={"submit"} variant={"contained"} color={"primary"} disableElevation>Save</Button>
                </div>
            </Wrapper>
        </ValidatorForm>
    );
};

FinancialSettingsForm.propTypes = {
    association: PropTypes.object.isRequired,
    handleAssociationChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default FinancialSettingsForm;