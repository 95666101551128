import "moment/locale/nl";

import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";
import PropTypes from "prop-types";
import React, {createContext, useContext, useState} from "react";

const LocaleContext = createContext({
    locale: "en",
    setLocale: ()=>{}
});

export function useLocale() {
    return useContext(LocaleContext);
}

const LocaleProvider = ({ children }) => {
    // TODO: look in navigator.languages and see if an imported language is available;
    //  if not set the locale to a default like "en" or "nl"
    const [locale, setLocale] = useState(navigator.language);
    moment.locale(locale);

    const handleSetLocale = (locale) => {
        moment.locale(locale);
        setLocale(locale);
    };

    const contextValue = {
        locale: locale,
        setLocale: handleSetLocale
    };

    return (
        <LocaleContext.Provider value={contextValue}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                { children }
            </LocalizationProvider>
        </LocaleContext.Provider>
    );
};

LocaleProvider.propTypes = {
    children: PropTypes.node
};

export default LocaleProvider;