import PropTypes from "prop-types";
import {useState} from "react";

/**
 * A custom hooks that provides function to manage a state that is a list of objects.
 *
 *
 */
const useCollection = (initial_collection) => {
    const [collection, setCollection] = useState(initial_collection);

    const add = (item) => setCollection(prevState => (
        prevState.concat(item)
    ));
    const edit = (edited_item, key="slug", partial=false) => {
        if (partial) {
            setCollection(prevState => (
                prevState.map(item=> item[key] === edited_item[key] ? {...item, ...edited_item} : item)
            ));
        } else {
            setCollection(prevState => (
                prevState.map(item=> item[key] === edited_item[key] ? edited_item : item)
            ));
        }
    };
    const remove = (removed_item, key="slug") => setCollection(prevState => (
        prevState.filter(item=> item[key] !== removed_item[key])
    ));

    return [collection, {set: setCollection, add: add, edit: edit, remove: remove}];
};

useCollection.propTypes = {
    initial_collection: PropTypes.array.isRequired
};

export default useCollection;