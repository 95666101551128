import PropTypes from "prop-types";
import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./Home";
import Login from "./Login";
import ProductList from "./ProductList/ProductList";


const TerminalRouting = ({ path }) => {
    return (
        <Switch>
            <Route exact path={path+"/login"}>
                <Login/>
            </Route>
            <Route exact path={path+"/products"}>
                <ProductList/>
            </Route>
            <Route exact path={path}>
                <Home/>
            </Route>
        </Switch>
    );
};

TerminalRouting.propTypes = {
    path: PropTypes.string.isRequired,
};

export default TerminalRouting;