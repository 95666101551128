import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import ContactsIcon from "@mui/icons-material/Contacts";
import Create from "@mui/icons-material/Create";
import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import FacebookIcon from "@mui/icons-material/Facebook";
import GavelIcon from "@mui/icons-material/Gavel";
import InstagramIcon from "@mui/icons-material/Instagram";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import PaymentIcon from "@mui/icons-material/Payment";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import PhotoIcon from "@mui/icons-material/Photo";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import ReceiptIcon from "@mui/icons-material/Receipt";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Grid from "@mui/material/Grid";
import MuiTextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {Item} from "@mui-treasury/components/flex";
import PropTypes from "prop-types";
import React from "react";
import { ValidatorForm } from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import DateField, {DateFieldV3} from "../../../Components/FormComponents/Fields/DateField";
import FileField, {FileFieldV2} from "../../../Components/FormComponents/Fields/FileField";
import TextField, {TextFieldV2, TextFieldV3} from "../../../Components/FormComponents/Fields/TextField";
import FontAwesomeIconHolder from "../../../Components/FormComponents/FontAwesomeIconHolder";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import useAddValidationRule from "../../../Components/Hooks/useAddValidationRule";
import isIban from "../../../Components/ValidatorRules/isIban";


const AssociationForm = ({ association, handleAssociationChange, onSubmit, onCancel }) => {
    useAddValidationRule(ValidatorForm, isIban.name, isIban.validate);
    let bodyText = "body1";

    return (
        <ValidatorForm
            onSubmit={onSubmit}
            onError={errors => console.log(errors)}
        >
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={bodyText} sx={{ textTransform: "uppercase" }} color={"#B4B8BB"} >Contact</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={"separated"}
                                label={"Name"}
                                value={association.name}
                                onChange={(event)=>handleAssociationChange("name",event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={"separated"}
                                label={"Short name"}
                                value={association.short_name}
                                onChange={(event)=>handleAssociationChange("short_name",event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={"separated"}
                                label={"Email"}
                                value={association.email}
                                onChange={(event)=>handleAssociationChange("email",event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={"separated"}
                                label={"Phone number"}
                                value={association.phone_number}
                                onChange={(event)=>handleAssociationChange("phone_number",event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid item xs={4}>
                                    <Typography variant={bodyText}>Photo</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <FileFieldV2
                                        onChange={(event)=>handleAssociationChange("photo",event.target.files[0])}
                                        name={"photo"}
                                        size={"small"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid item xs={4}>
                                    <Typography variant={bodyText}>Logo</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <FileFieldV2
                                        name={"logo"}
                                        onChange={(event)=>handleAssociationChange("logo",event.target.files[0])}
                                        size={"small"}
                                        // helperText={"The logo is the icon used in the blue navigation bar on top of your screen. Please, upload a white version."}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={bodyText} sx={{ textTransform: "uppercase" }} color={"#B4B8BB"}>Visiting Address</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={"separated"}
                                label={"Zip code"}
                                value={association.zip_code}
                                onChange={(event)=>handleAssociationChange("zip_code",event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={"separated"}
                                label={"City"}
                                value={association.city}
                                onChange={(event)=>handleAssociationChange("city",event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={"separated"}
                                label={"Address"}
                                value={association.address}
                                onChange={(event)=>handleAssociationChange("address",event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={"separated"}
                                label={"Room"}
                                value={association.room}
                                onChange={(event)=>handleAssociationChange("room",event.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={bodyText} sx={{ textTransform: "uppercase" }} color={"#B4B8BB"}>Postal address</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={"separated"}
                                label={"Zip code"}
                                value={association.postal_zipcode}
                                onChange={(event)=>handleAssociationChange("postal_zipcode",event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={"separated"}
                                label={"City"}
                                value={association.postal_city}
                                onChange={(event)=>handleAssociationChange("postal_city",event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={"separated"}
                                label={"Address"}
                                value={association.postal_address}
                                onChange={(event)=>handleAssociationChange("address",event.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={bodyText} sx={{ textTransform: "uppercase" }} color={"#B4B8BB"}>Constitutional</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid item xs={4}>
                                    <Typography variant={bodyText}>Articles of association</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <FileFieldV2
                                        onChange={(event)=>handleAssociationChange("articles_of_association_en",event.target.files[0])}
                                        name={"articles_of_association_en"}
                                        label={"English"}
                                        size={"small"}
                                        // helperText={"Dutch: Statuten, English version."}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FileFieldV2
                                        onChange={(event)=>handleAssociationChange("articles_of_association_nl",event.target.files[0])}
                                        name={"articles_of_association_nl"}
                                        label={"Dutch"}
                                        size={"small"}
                                        // helperText={"Dutch: Statuten, Dutch version."}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid item xs={4}>
                                    <Typography variant={bodyText}>Bylaws</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <FileFieldV2
                                        onChange={(event)=>handleAssociationChange("bylaws_en",event.target.files[0])}
                                        name={"bylaws_en"}
                                        label={"English"}
                                        size={"small"}
                                        // helperText={"Dutch: Huishoudelijk Regelement, English version."}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FileFieldV2
                                        onChange={(event)=>handleAssociationChange("bylaws_nl",event.target.files[0])}
                                        name={"bylaws_nl"}
                                        label={"Dutch"}
                                        size={"small"}
                                        // helperText={"Dutch: Huishoudelijk Regelement, Dutch version."}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid item xs={4}>
                                    <Typography variant={bodyText}>Founding date</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <DateFieldV3
                                        name={"founding_date"}
                                        value={association.founding_date}
                                        onChange={date => handleAssociationChange("founding_date", date)}
                                        size={"small"}
                                        disableFuture
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid item xs={4}>
                                    <Typography variant={bodyText}>Dissolution date</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <DateFieldV3
                                        name={"dissolution_date"}
                                        value={association.dissolution_date}
                                        onChange={date => handleAssociationChange("dissolution_date", date)}
                                        size={"small"}
                                        disabled
                                        // helperText={"Connect the SU if you want to dissolve your association."}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={bodyText} sx={{ textTransform: "uppercase" }} color={"#B4B8BB"}>Legal</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid item xs={4}>
                                    <Typography variant={bodyText}>Privacy statement</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <FileFieldV2
                                        onChange={(event)=>handleAssociationChange("privacy_statement", event.target.files[0])}
                                        name={"privacy_statement"}
                                        size={"small"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid item xs={4}>
                                    <Typography variant={bodyText}>Terms of service</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <FileFieldV2
                                        name={"terms_of_service"}
                                        onChange={(event)=>handleAssociationChange("terms_of_service", event.target.files[0])}
                                        size={"small"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid
                        container
                        direction={"row"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                    >
                        <Grid item xs={5}>
                            <div>
                                <Button onClick={onCancel} style={{textTransform: "none"}}>Cancel</Button>
                                &nbsp;
                                <Button type={"submit"} variant={"contained"} color={"primary"} style={{textTransform: "none"}}>Save</Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </ValidatorForm>
    );
};

AssociationForm.propTypes = {
    association: PropTypes.object.isRequired,
    handleAssociationChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default AssociationForm;