import {emptySplitApi, pessimisticCreation, pessimisticUpdateSingle} from "./index";

const groupApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["Group"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getCurrentBoardOfAssociation: build.query({
                query: (associationSlug) => ({ url: "/groups", params: { "association__slug": associationSlug, board_group: true, current_board: true } }),
                providesTags: (result, error, associationSlug) => [{ type: "Group", id: `${associationSlug}__board` }],
                transformResponse: (response) => response.results[0]
            }),
            getGroupsByAssociation: build.query({
                query: ({ associationSlug, boardGroup }) => ({ url: "/groups", params: { "association__slug": associationSlug, board_group: boardGroup } }),
                providesTags: (result, error, { associationSlug, boardGroup }) => [{ type: "Group", id: `${associationSlug}__${boardGroup}` }],
                transformResponse: (response) => response.results
            }),
            getGroup: build.query({
                query: (group_slug) => (group_slug && { url: `/groups/${group_slug}`  }),
                providesTags: (result, error, group_slug) => [{ type: "Group", id: group_slug }],
            }),
            addGroup: build.mutation({
                query: (body) => ({ url: "/groups/", method: "POST", body: body }),
                async onQueryStarted(group, { dispatch, queryFulfilled }) {
                    await pessimisticCreation({
                        dispatch,
                        queryFulfilled,
                        api: groupApi,
                        query: "getGroupsByAssociation",
                        entityToQueryParam: (addedGroup) => `${addedGroup.association.split("/associations/")[1]}__${addedGroup.board_group}`
                    });
                }
            }),
            patchGroup: build.mutation({
                query: ({ slug, ...patch}) => ({ url: `/groups/${slug}`, method: "PATCH", body: patch }),
                invalidatesTags: (result, error, arg) => [{ type: "Group", id: arg.association }],
                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    await pessimisticUpdateSingle({
                        dispatch,
                        queryFulfilled,
                        api: groupApi,
                        query: "getGroup",
                        entityToQueryParam: group => group.slug,
                    });
                }
            }),
            patchGroupFormData: build.mutation({
                query: (formData) => ({ url: `/groups/${formData.get("slug")}`, method: "PATCH", body: formData }),
                invalidatesTags: (result, error, arg) => [{ type: "Group", id: arg.association }],
                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    await pessimisticUpdateSingle({
                        dispatch,
                        queryFulfilled,
                        api: groupApi,
                        query: "getGroup",
                        entityToQueryParam: group => group.slug,
                    });
                }
            }),
            deleteGroup: build.mutation({
                query: ({ slug }) => ({ url: `/groups/${slug}`, method: "DELETE" }),
                invalidatesTags: (result, error, { association }) => [{ type: "Group", id: association.split("/associations/")[1] }],
                async onQueryStarted(group, { dispatch, queryFulfilled }) {
                    try {
                        await queryFulfilled;
                        dispatch(
                            emptySplitApi.util.updateQueryData("getGroupsByAssociation", {associationSlug: group.association.split("/associations/")[1], boardGroup: group.board_group }, (draftGroups) => {
                                const index = draftGroups.findIndex(draftGroup => draftGroup.slug === group.slug);
                                draftGroups.splice(index, 1);
                            })
                        );
                        // eslint-disable-next-line no-empty
                    } catch {}
                }
            })
        }),
        overrideExisting: false
    });

export const {
    useGetGroupsByAssociationQuery,
    useGetCurrentBoardOfAssociationQuery,
    useGetGroupQuery,
    useAddGroupMutation,
    usePatchGroupMutation,
    usePatchGroupFormDataMutation,
    useDeleteGroupMutation
} = groupApi;
export default groupApi;