import {
    emptySplitApi,
    pessimisticCreation,
    pessimisticCreationMultiple,
    pessimisticRemoval,
    pessimisticUpdateSingleInList
} from "./index";

const invoicesApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["Invoice"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getInvoicesByAssociationAndProfile: build.query({
                query: ({ profileSlug, associationSlug }) => ({ url: "/invoices", params: { profile__slug: profileSlug, association__slug: associationSlug } }),
                providesTags: (result, error, { profileSlug, associationSlug }) => [{ type: "Invoice", id: `${profileSlug}__${associationSlug}` }],
                transformResponse: (response) => response.results
            }),
            getInvoicesByAssociation: build.query({
                query: (associationSlug) => ({ url: "/invoices", params: {"association__slug": associationSlug, limit: 1000} }),
                providesTags: (result, error, associationSlug) => [{ type: "Invoice", id: associationSlug }],
                transformResponse: (response) => response.results
            }),
            addInvoice: build.mutation({
                query: (body) => ({ url: "/invoices/", method: "POST", body: body }),
                async onQueryStarted(invoice, { dispatch, queryFulfilled }) {
                    await pessimisticCreation({
                        dispatch,
                        queryFulfilled,
                        api: invoicesApi,
                        query: "getInvoicesByAssociation",
                        entityToQueryParam: () => invoice.association.split("/associations/")[1]
                    });
                }
            }),
            addInvoices: build.mutation({
                query: (body) => ({ url: "/invoices/", method: "POST", body: body }),
                async onQueryStarted(invoices, { dispatch, queryFulfilled }) {
                    await pessimisticCreationMultiple({
                        dispatch,
                        queryFulfilled,
                        api: invoicesApi,
                        query: "getInvoicesByAssociation",
                        entityToQueryParam: () => invoices[0].association.split("/associations/")[1]
                    });
                }
            }),
            patchInvoice: build.mutation({
                query: ({ slug, ...patch }) => ({ url: `/invoices/${slug}`, method: "PATCH", body: patch }),
                async onQueryStarted({ slug, association }, { dispatch, queryFulfilled }) {
                    await pessimisticUpdateSingleInList({
                        dispatch,
                        queryFulfilled,
                        api: invoicesApi,
                        query: "getInvoicesByAssociation",
                        entityToQueryParam: () => association.split("/associations/")[1],
                        entityFilter: draftInvoice => draftInvoice.slug === slug
                    });
                }
            }),
            deleteInvoice: build.mutation({
                query: ({ slug }) => ({ url: `/invoices/${slug}`, method: "DELETE" }),
                async onQueryStarted({ slug, association }, { dispatch, queryFulfilled }) {
                    await pessimisticRemoval({
                        dispatch,
                        queryFulfilled,
                        api: invoicesApi,
                        query: "getInvoicesByAssociation",
                        queryParam: association.split("/associations/")[1],
                        entityFilter: draftInvoice => draftInvoice.slug === slug
                    });
                }
            })
        }),
        overrideExisting: false
    });

export const {
    useGetInvoicesByAssociationAndProfileQuery,
    useGetInvoicesByAssociationQuery,
    useAddInvoiceMutation,
    useAddInvoicesMutation,
    usePatchInvoiceMutation,
    useDeleteInvoiceMutation
} = invoicesApi;
export default invoicesApi;