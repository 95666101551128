import PropTypes from "prop-types";
import {useMemo} from "react";
import {useParams} from "react-router-dom";

import {flattenMembers} from "../../Transformers/Members";
import {useGetCurrentMembersByAssociationQuery} from "../services/MySU/members";
import {useGetMemberTypesByAssociationQuery} from "../services/MySU/memberTypes";
import {useGetStudiesQuery} from "../services/MySU/studies";

const useCurrentMembers = () => {
    const { slug } = useParams();
    const { data: studies } = useGetStudiesQuery();
    const { data: memberTypes } = useGetMemberTypesByAssociationQuery(slug);
    const { data: currentMembers } = useGetCurrentMembersByAssociationQuery(slug);

    const members = useMemo(()=>flattenMembers(currentMembers, studies, memberTypes),[studies, currentMembers, memberTypes]);

    return { members };
};

useCurrentMembers.propTypes = {
    association: PropTypes.string.isRequired
};

export default useCurrentMembers;