import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import {useHistory} from "react-router-dom";

import Card from "../../Components/Card/Card";
import Wrapper from "../../Components/FormComponents/Wrapper";
import RoutingButton from "../../Components/PageLayout/Content/RoutingButton";
import TopPageBlock from "../../Components/PageLayout/Content/TopPageBlock";
import {useGetAssociationsQuery} from "../../Store/services/MySU/associations";

const Associations = () => {
    const history = useHistory();
    const { data: associations } = useGetAssociationsQuery();

    const associationToCard = (association) => ({
        name: association.name,
        description: association.description,
        slug: association.slug,
        photo: association.photo,
        onClick: ()=>history.push("/protected/associations/" + association.slug + "/info"),
    });

    return (
        <Container maxWidth={"xl"}>
            <Wrapper>
                <RoutingButton
                    routeStringPieces={[
                        "Associations"
                    ]}
                />
            </Wrapper>
            <TopPageBlock>
                <Typography variant={"h6"}>
                    Assocations
                </Typography>
                <Typography>
                    You can find the associations you are affiliated with in the top bar.
                </Typography>
            </TopPageBlock>
            <Box sx={{ mt: 2 }}>
                <Grid container spacing={4} justifyContent={"space-between"}>
                    { associations?.map(association=>(
                        <Grid item key={association.slug} sx={{ flexGrow: 1 }}>
                            <Card {...associationToCard(association)}/>
                        </Grid>
                    )) }
                </Grid>
            </Box>
        </Container>
    );
    //
    // return (
    //     <FigmaContent
    //         name={"Associations"}
    //         image={null}
    //     >
    //         <CardGrid
    //             componentProps={associations?.map(association=>associationToCard(association)) || []}
    //             Component={AssociationCard}
    //         />
    //     </FigmaContent>
    // );
};

Associations.propTypes = {
};

export default Associations;
