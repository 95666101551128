import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

import Image from "../../img/background_opacity_15.png";

const lightTheme = createTheme({
    palette: {
        primary: {
            main: "#5078B4",
            light: "#89C2E6",
            dark: "#1A2E49",
        },
        background: {
            dark: "#1A2E49"
        },
    },
    typography: {
        fontSize: 16,
        h1: {
            fontSize: "6rem",
            color: "#1A2E49"
        },
        h2: {
            fontSize: "3.75rem",
            color: "#1A2E49"
        },
        h3: {
            fontSize: "3rem",
            color: "#1A2E49"
        },
        h4: {
            fontSize: "2.125rem",
            color: "#1A2E49"
        },
        h5: {
            fontSize: "1.25rem",
            color: "#5078B4",
        },
        h6: {
            fontSize: "1.125rem",
            color: "#5078B4",
        },
        body1: {
            fontSize: "1rem"
        },
        body2: {
            fontSize: "0.75rem"
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "html": {
                    body: {
                        backgroundImage: `url(${Image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "absolute",
                        backgroundSize: "cover",
                        backgroundPosition: "center 120px",
                        backgroundColor: grey[100],
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    "&.Mui-selected": {
                        backgroundColor: "#1A2E49",
                    }
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 16
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                },
            }
        },
    }
});

export default lightTheme;