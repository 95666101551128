import React from "react";
import {Route, Switch, useRouteMatch} from "react-router-dom";

import {useCurrentAssociation} from "../../Store/services/MySU/associations";
import Financial from "./Financial";

const FinancialRouter = () => {
    const { path } = useRouteMatch();
    const { data: association, isLoading } = useCurrentAssociation();

    if (isLoading) {
        return null;
    }

    return (
        <Switch>
            <Route path={path+"/finances"}>
                <Financial
                    association={association}
                />
            </Route>
        </Switch>
    );
};

FinancialRouter.propTypes = {
};

export default FinancialRouter;