import {DataTypeProvider} from "@devexpress/dx-react-grid";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";

const ChoiceOperations = [
    "equal",
    "notEqual",
];

const ChoiceEditor = ({ value, onValueChange, column, choiceSelectionOptions }) => {
    let options = choiceSelectionOptions[column.name];
    return (
        <Select
            input={<Input placeholder={"Select item"}/>}
            value={value || ""}
            onChange={event => onValueChange(event.target.value)}
            style={{ width: "100%" }}
            placeholder={"Select item"}
        >
            <MenuItem value={""}>
                -
            </MenuItem>
            { options.map((option,o)=>
                <MenuItem key={o} value={option}>
                    { option }
                </MenuItem>
            ) }
        </Select>
    );
};

const ChoiceTypeProvider = ({choiceSelectionOptions, ...dataTypeProps}) => {
    const ChoiceEditorWithOptions = (props) => React.createElement(ChoiceEditor, {choiceSelectionOptions, ...props});
    return (
        <DataTypeProvider
            editorComponent={ChoiceEditorWithOptions}
            availableFilterOperations={ChoiceOperations}
            {...dataTypeProps}
        />
    );
};

export default ChoiceTypeProvider;