import PropTypes from "prop-types";
import {useState} from "react";

const useInfoFormState = ( initial_state ) => {
    const [formType, setFormType] = useState(initial_state);

    const toggleFormType = () => {
        if (formType === "form") {
            setFormType("info");
        } else {
            setFormType("form");
        }
    };
    return [formType, toggleFormType, setFormType];
};

useInfoFormState.propTypes = {
    initial_state: PropTypes.string.isRequired
};

export default useInfoFormState;