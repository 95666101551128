import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import Block from "../../Components/PageLayout/Content/Block";

const useStyles = makeStyles(theme=>({

}));

const EmailLists = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const { association } = props;
    return (
        <Container>
            <Block>
                <Typography variant={"h5"} >Email Lists</Typography>
                <hr className={"box-title-separator"}/>
                <div>
                    <Typography>This feature has yet to be implemented.</Typography>
                    <br/><br/>
                    { association.domain !== undefined && <Typography>This feature requires access to an email domain.</Typography> }
                    <br/><br/>
                    <Typography>The following steps will give you roughly the same functionality:</Typography>
                    <ol>
                        <li>
                            go to <Link>/members/current</Link>,
                        </li>
                        <li>
                            filter the members you want to email,
                        </li>
                        <li>
                            download the csv by pressing the `export csv` button,
                        </li>
                        <li>
                            sent email to the members using thunderbird with the mail merge plugin.
                        </li>
                    </ol>
                </div>
            </Block>
        </Container>
    );
};

EmailLists.propTypes = {
    association: PropTypes.object.isRequired
};

EmailLists.defaultProps = {

};

export default EmailLists;