/* eslint-disable no-shadow */
import EuroIcon from "@mui/icons-material/Euro";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import React from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import BooleanField from "../../../Components/FormComponents/Fields/BooleanField";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";

const helperText = "Put this to 'Yes', if for some reason the debt collection to this person got cancelled.";

const DebtEntryPopupForm = ({ row, onChange, onApplyChanges, onCancelChanges, open }) => {
    return (
        <Dialog open={open} onClose={onCancelChanges} aria-labelledby={"form-dialog-title"} fullWidth={true}>
            <ValidatorForm
                onSubmit={onApplyChanges}
                onError={errors => console.log(errors)}
            >
                <DialogTitle id={"form-dialog-title"}>Debt entry details</DialogTitle>
                <DialogContent>
                    <Wrapper>
                        <IconHolder Icon={EuroIcon}/>
                        <BooleanField
                            label={"Cancelled"}
                            name={"cancelled"}
                            value={row.cancelled}
                            helperText={helperText}
                            onChange={onChange}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <div/>
                        <div>
                            <Button onClick={onCancelChanges} variant={"contained"} disableElevation>
                                Cancel
                            </Button>
                            &nbsp;
                            <Button type={"submit"} color={"primary"} variant={"contained"} disableElevation>
                                Save
                            </Button>
                        </div>
                    </Wrapper>
                </DialogContent>
            </ValidatorForm>
        </Dialog>
    );
};

DebtEntryPopupForm.propTypes = {
    row: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onApplyChanges: PropTypes.func.isRequired,
    onCancelChanges: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default DebtEntryPopupForm;