import PropTypes from "prop-types";
import React from "react";
import {Route, Switch, useRouteMatch} from "react-router-dom";

import GroupAdd from "./GroupAdd";
import GroupDetail from "./GroupDetail";
import GroupList from "./GroupList";
import GroupMemberManagement from "./GroupMemberManagement";


export const GroupsRouter = ({ boards }) => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path+"/add"}>
                <GroupAdd boards={boards}/>
            </Route>
            <Route path={path+"/:groupSlug/membermanagement"}>
                <GroupMemberManagement/>
            </Route>
            <Route path={path+"/:groupSlug"}>
                <GroupDetail boards={boards}/>
            </Route>
            <Route path={path}>
                <GroupList boards={boards}/>
            </Route>
        </Switch>
    );
};

GroupsRouter.propTypes = {
    boards: PropTypes.bool.isRequired
};
