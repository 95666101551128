import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(theme=>({
    icon: {
        margin: theme.spacing(2, 0),
        marginRight: theme.spacing(2),
    },
}));

const IconHolder = ({ Icon, ...rest_props }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <Icon className={classes.icon} color={"action"} {...rest_props} />
    );
};

IconHolder.propTypes = {
    Icon: PropTypes.object.isRequired
};

export default IconHolder;
