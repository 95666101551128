import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";

import LocaleSwitch from "../../Components/Forms/LocaleSwitch";
import ThemeSwitch from "../../Components/Forms/ThemeSwitch";
import Block from "../../Components/PageLayout/Content/Block";


const Settings = () => {
    return (
        <Container>
            <Block>
                <Typography variant={"h5"}>Settings</Typography>
                <hr className={"box-title-separator"}/>
                <ThemeSwitch/>
                <LocaleSwitch/>
            </Block>
        </Container>
    );
};

Settings.protoTypes = {

};

export default Settings;