import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import {matchSorter} from "match-sorter";
import PropTypes from "prop-types";
import React, {useCallback} from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {
        zIndex: "0 !important"
    },
}));

const profiles = Array.from(Array(100), ()=>({
    name: (Math.random() + 1).toString(36).substring(2),
    hasPasscode: true,
}));
const maxSuggestedProfiles = 5;

const UserSelectionModal = ({ keyboardValue, setKeyboardValue, numpadValue, setNumpadValue, selectedProfile, setSelectedProfile }) => {
    const classes = useStyles();

    let filteredProfiles = matchSorter(profiles, keyboardValue, {keys: ["name"]});
    filteredProfiles.length = Math.min(filteredProfiles.length, maxSuggestedProfiles);

    const selectProfile = useCallback((profile)=>()=>{
        setSelectedProfile(profile);
        setKeyboardValue("");
        setNumpadValue("");
    }, [setSelectedProfile]);

    return (
        <Dialog
            open={keyboardValue !== ""}
            maxWidth={"md"}
            fullWidth={true}
            className={classes.root}
            aria-labelledby={"contained-modal-title-vcenter"}
            disablePortal
            disableEnforceFocus
            disableAutoFocus
        >
            <DialogTitle variant={"h5"}>Select your name</DialogTitle>
            <DialogContent>
                <Typography>{ keyboardValue || "" }</Typography>
                <DialogContentText>
                    <Divider/>
                    <List>
                        { filteredProfiles.map((filteredProfile, index) => (
                            <ListItem
                                button
                                // onClick={()=>setSelectedProfile(filteredProfile)}
                                onClick={selectProfile(filteredProfile)}
                                alignItems={"flex-start"}
                                key={index}
                            >
                                <ListItemText
                                    primary={filteredProfile.name}
                                />
                            </ListItem>
                        )) }
                    </List>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

UserSelectionModal.propTypes = {
    keyboardValue: PropTypes.string.isRequired,
    setKeyboardValue: PropTypes.func.isRequired,
    numpadValue: PropTypes.string.isRequired,
    setNumpadValue: PropTypes.func.isRequired,
    selectedProfile: PropTypes.object.isRequired,
    setSelectedProfile: PropTypes.func.isRequired
};


export default UserSelectionModal;