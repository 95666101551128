import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import { useParams } from "react-router-dom";

import { BackButton } from "../../../Components/BackButton";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import Block from "../../../Components/PageLayout/Content/Block";
import ToggleGroup from "../../../Components/Toggle/ToggleGroup";
import {useGetGroupQuery} from "../../../Store/services/MySU/groups";
import GroupMemberManagementAssociationMemberList from "./GroupMemberManagementAssociationMemberList";
import GroupMemberManagementGroupMemberList from "./GroupMemberManagementGroupMemberList";

const archiveButtons = [
    { value: true, ariaLabel: "Archived", Icon: ArchiveIcon },
    { value: false, ariaLabel: "Not Archived", Icon: UnarchiveIcon }
];

const GroupMemberManagement = ({ association }) => {
    const { groupSlug } = useParams();

    const [archive, setArchive] = useState(true);
    const { data: group } = useGetGroupQuery(groupSlug);

    return (
        <div className={"CommitteeMemberManagement"}>
            <BackButton container={true}/>

            <Container style={{maxWidth: "100%"}}>
                <Block>
                    <Wrapper>
                        <Typography variant={"h5"}>In { group?.board_group ? "Board" : "Committee" }</Typography>
                        <ToggleGroup
                            toggle={archive}
                            setToggle={setArchive}
                            toggleButtons={archiveButtons}
                        />
                    </Wrapper>
                    <hr className={"box-title-separator"}/>
                    <GroupMemberManagementGroupMemberList
                        groupSlug={groupSlug}
                        board_group={group?.board_group}
                    />
                </Block>
                <GroupMemberManagementAssociationMemberList
                    association={association}
                />
            </Container>
        </div>
    );
};

export default GroupMemberManagement;
