import BallotIcon from "@mui/icons-material/Ballot";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, {useState} from "react";

import SelectField from "../../../Components/FormComponents/Fields/SelectField";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import withPropMap from "../../../Components/HOC/withPropMap";
import useModalState from "../../../Components/Hooks/useModalState";
import FormModal from "../../../Components/Modals/FormModal";
import {
    useAddMemberTypeMutation,
    useDeleteMemberTypeMutation,
    usePatchMemberTypeMutation
} from "../../../Store/services/MySU/memberTypes";
import DropDownFormContainer from "../dropDownForm";
import MemberTypesForm from "./MemberTypesForm";

const dropDownObjectToMemberTypesFormPropsMap = ({ dropDownObject, handleDropDownObjectChange, ...rest_props}) =>
    ({ memberType: dropDownObject, handleMemberTypeChange: handleDropDownObjectChange, ...rest_props});

const memberTypeToTitle = (memberType) => memberType.type;
const fieldAndValueToStateChanges = (field, value) => ({ [field]: value} );

const MemberTypesDropDown = ({ memberTypes, associationUrl, postSubmit, postDelete, postCancel, creating }) => {
    const [modalOpen, toggleModalOpen] = useModalState(false);
    const [toBeRemovedMemberType, setToBeRemovedMemberType] = useState({});
    const initialMemberType = {
        association: associationUrl,
        full_year_membership: true,
        only_chooseable_by_board: false,
        start_date: "09-01",
        end_date: "08-31",
        ordering: 1,
        membership_fee: 0,
        name: "",
        description: ""
    };
    const MemberTypeFormComponent = withPropMap(MemberTypesForm, dropDownObjectToMemberTypesFormPropsMap);

    const [ addMemberType ] = useAddMemberTypeMutation();
    const [ patchMemberType ] = usePatchMemberTypeMutation();
    const [ removeMemberType ] = useDeleteMemberTypeMutation();

    const onSubmit = (memberType) => memberType.slug
        ? patchMemberType(memberType).then(()=>postSubmit(memberType, memberTypes))
        : addMemberType(memberType).then(()=>postSubmit(memberType, memberTypes));
    const onDelete = (event) => {
        removeMemberType({ ...toBeRemovedMemberType, change_membertype_to: event.target.alternativeMemberType.value}).then(()=>{
            postDelete(toBeRemovedMemberType, memberTypes);
            setToBeRemovedMemberType({});
            toggleModalOpen();
        });
    };
    const preDelete = (memberType) => {
        setToBeRemovedMemberType(memberType);
        toggleModalOpen();
    };

    const alternativeMemberTypes = memberTypes.filter(memberType=>memberType.slug !== toBeRemovedMemberType.slug);

    return (
        <>
            <FormModal
                title={"Select alternative member type"}
                size={"lg"}
                open={modalOpen}
                onCancel={toggleModalOpen}
                onSubmit={onDelete}
                cancelButtonText={"Cancel"}
                submitButtonText={"Confirm"}
            >
                <Typography>
                    Each member needs to have a member type.
                    Some members have the member type, { toBeRemovedMemberType.type }, that you are trying to delete.
                    Select a new member type for these members.
                </Typography>
                <Wrapper>
                    <IconHolder Icon={BallotIcon}/>
                    <SelectField
                        name={"alternativeMemberType"}
                        label={"Alternative member type"}
                        required
                    >
                        { alternativeMemberTypes && alternativeMemberTypes.map(memberType => (
                            <MenuItem key={memberType.slug} value={memberType.slug}>
                                { memberType.type }
                            </MenuItem>
                        )) }
                    </SelectField>
                </Wrapper>
            </FormModal>
            <DropDownFormContainer
                creating={creating}
                dropDownObjects={memberTypes}
                dropDownObjectToTitle={memberTypeToTitle}
                allowCreation={true}
                initialDropDownObject={initialMemberType}
                FormComponent={MemberTypeFormComponent}
                fieldAndValueToStateChanges={fieldAndValueToStateChanges}
                enableDelete={true}
                onSubmit={onSubmit}
                onCancel={postCancel}
                onDelete={preDelete}
            />
        </>
    );
};

MemberTypesDropDown.propTypes = {
    memberTypes: PropTypes.array.isRequired,
    associationUrl: PropTypes.string.isRequired,
    postSubmit: PropTypes.func,
    postCancel: PropTypes.func,
    postDelete: PropTypes.func
};

const dummyFunction = () => {};
MemberTypesDropDown.defaultProps = {
    postSubmit: dummyFunction,
    postCancel: dummyFunction,
    postDelete: dummyFunction
};

export default MemberTypesDropDown;