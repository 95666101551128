import MenuItem from "@mui/material/MenuItem";
import React from "react";

import {SelectFieldV3} from "./SelectField";

const BooleanField = (props) => {
    return (
        <SelectFieldV3
            {...props}
        >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
        </SelectFieldV3>
    );
};

export default BooleanField;