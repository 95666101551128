import React from "react";

import {useGetEventsNotInDebtCollectionByAssociationQuery} from "../../../Store/services/MySU/events";
import DebtCollectionPopupAddFormForm from "./DebtCollectionPopupAddForm";
import DebtCollectionPopupEditAndRemoveForm from "./DebtCollectionPopupEditAndRemoveForm";

const DebtCollectionPopupForm = ({ association, row, ...props }) => {
    const { data: events } = useGetEventsNotInDebtCollectionByAssociationQuery(association.slug);

    if (row.slug) {
        return <DebtCollectionPopupEditAndRemoveForm row={row} {...props}/>;
    } else {
        const rowWithDefaults = {
            debt_collection_date: null,
            include_events: [],
            include_membership_fee: "",
            include_invoices: "",
            ignore_threshold: "",
            ...row
        };
        return <DebtCollectionPopupAddFormForm events={events || []} row={rowWithDefaults} {...props}/>;
    }
};

export default DebtCollectionPopupForm;