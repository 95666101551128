import {DataTypeProvider} from "@devexpress/dx-react-grid";
import Input from "@mui/material/Input";
import React from "react";

const NumberOperations = [
    "equal",
    "notEqual",
    "greaterThan",
    "greaterThanOrEqual",
    "lessThan",
    "lessThanOrEqual",
];

const CurrencyFormatter = ({ value }) => value ? "€ " + value.toLocaleString("en-GB", { style: "currency", currency: "EUR" }) :  "";

const CurrencyEditor = ({ value, onValueChange }) => {
    return (
        <Input
            value={value}
            onChange={event => onValueChange(event.target.value)}
            style={{ width: "100%" }}
            type={"number"}
            inputProps={{
                step: 0.05, min: 0
            }}
        />
    );
};

const CurrencyTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={CurrencyFormatter}
        editorComponent={CurrencyEditor}
        availableFilterOperations={NumberOperations}
        {...props}
    />
);

export default CurrencyTypeProvider;