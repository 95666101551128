import {emptySplitApi} from "./index";

const sepaMandatesApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["SepaMandate"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getSepaMandatesByAssociationAndProfile: build.query({
                query: ({ profileSlug, associationSlug }) => ({ url: "/sepa_mandates", params: { profile__slug: profileSlug, association__slug: associationSlug } }),
                providesTags: (result, error, { profileSlug, associationSlug }) => [{ type: "SepaMandate", id: `${profileSlug}__${associationSlug}` }],
                transformResponse: (response) => response.results
            }),
        }),
        overrideExisting: false
    });

export const {
    useGetSepaMandatesByAssociationAndProfileQuery,
} = sepaMandatesApi;
export default sepaMandatesApi;