import Container from "@mui/material/Container";
import PropTypes from "prop-types";
import React from "react";

import MembershipInfoFormV2 from "../../Forms/InfoForms/MembershipSettings/MembershipSettingsInfoForm";
import {useCurrentAssociation, useGetAssociationBySlugQuery} from "../../Store/services/MySU/associations";

const Settings = () => {
    const { data: association } = useCurrentAssociation();
    // const { data: association } = useGetAssociationBySlugQuery(propAssociation.slug);
    return (
        <Container>
            <MembershipInfoFormV2
                initialInfoOrFormState={"info"}
                association={association || {full_year_membership_start_date: "09-01", new_member_title: "", new_member_text: ""}}
                showInfoFormStateButton={true}
            />
        </Container>
    );
};

Settings.propTypes = {
};

export default Settings;