import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { ValidatorForm } from "react-material-ui-form-validator";

import {useLocale} from "../../Contexts/Locale";
import SelectField from "../FormComponents/Fields/SelectField";


const LocaleSwitch = (props) => {
    const localer = useLocale();

    return (
        <ValidatorForm
            onSubmit={()=>{}}
            onError={errors => console.log(errors)}
        >
            <SelectField
                name={"I18n"}
                value={localer.locale}
                onChange={event=>localer.setLocale(event.target.value)}
            >
                <MenuItem value={"en"}>English</MenuItem>
                <MenuItem value={"fr"}>French</MenuItem>
            </SelectField>
        </ValidatorForm>
    );
};

LocaleSwitch.propTypes = {

};

export default LocaleSwitch;