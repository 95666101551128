import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import HelpIcon from "@mui/icons-material/Help";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useOverShadowStyles } from "@mui-treasury/styles/shadow/over";
import cx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";

import Image from "../../../img/default_photo.jpg";

const useStyles = makeStyles(theme => ({
    root: {
        margin: "auto",
        borderRadius: theme.spacing(2), // 16px
        transition: "0.3s",
        boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
        position: "relative",
        minWidth: 304,
        maxWidth: 500,
        marginLeft: "auto",
        overflow: "initial",
        background: theme.palette.background.paper,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: 0,
        backgroundColor: theme.palette.primary.main
    },
    media: {
        width: "100%",
        paddingBottom: "48%",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        position: "relative",
        paddingLeft: 10,
        paddingRight: 10,
        "&:after": {
            content: "\" \"",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: theme.spacing(2), // 16
            opacity: 0.5,
        },
    },
    content: {
        padding: 0,
        "&:last-child": {
            padding: 0
        }
    },
    title: {
        padding: theme.spacing(1),
        color: theme.palette.common.white,
        textAlign: "center"
    },
    icon: {
        color: theme.palette.background.paper
    },
    iconWrapper: {
        width: "90%",
        display: "flex",
        justifyContent: "space-between"
    },
    link: {
        width: "100%"
    }
}));

const GroupCard = (props) => {
    const theme = useTheme();
    const styles = useStyles(theme);
    const shadowStyles = useOverShadowStyles();

    const { title, photo, enrolled, self_enrollable, seeks_members, path} = props;

    return (
        <Card className={cx(styles.root, shadowStyles.root)}>
            <Link to={path} className={styles.link}>
                <div className={styles.iconWrapper}>
                    <div>{ enrolled && <EmojiEmotionsIcon className={styles.icon}/> }</div>
                    <div>
                        { seeks_members && <HelpIcon className={styles.icon}/> }
                        { self_enrollable && <EmojiPeopleIcon className={styles.icon}/> }
                    </div>
                </div>
                <CardMedia
                    className={styles.media}
                    image={photo}
                />
                <Typography variant={"h4"} className={styles.title}>{ title }</Typography>
            </Link>
        </Card>
    );
};

GroupCard.propTypes = {
    title: PropTypes.string.isRequired,
    photo: PropTypes.string,
    path: PropTypes.string,
    enrolled: PropTypes.bool,
    self_enrollable: PropTypes.bool,
    seeks_members: PropTypes.bool
};

GroupCard.defaultProps = {
    photo: `url(${Image})`,
    path: undefined,
    enrolled: false,
    self_enrollable: false,
    seeks_members: false
};

export default GroupCard;
