import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, {useEffect} from "react";

import Button from "../../../Components/Buttons/Button";
import Wrapper from "../../../Components/FormComponents/Wrapper";

const unlockedText = <>
    Have you made a mistake, like forgetting to add a specfic event? Then remove the debt collection and create a new one.
    <br/><br/>
    If the debt collection is correct, then please lock it. After locking the debt collection can no longer be removed.
    Once you have done that, please go the individual records and download the debt collection entries as a .xslx or .csv.
    These can be added to the SEPA template for your bank, which you can send to the bank to order them to do the debt collection.
    <br/><br/>
    NB: Once locked the debt collection cannot be unlocked, changed or deleted. This is permanent.
</>;
const lockedText = <>This Debt Collection is locked. It cannot be unlocked, changed or deleted. This is permanent.</>;

const DebtCollectionPopupEditAndRemoveForm = ({ row, onChange, onApplyChanges, onCancelChanges, open }) => {

    const onDelete = () => {
        onChange({target: {name: "remove", value: true }});
        onChange({target: {name: "changed", value: true }});
    };
    const onLockedToggle = () => {
        onChange({target: {name: "locked", value: !row.locked }});
        onChange({target: {name: "changed", value: true }});
    };
    useEffect(()=>{
        if (row.changed) {
            onApplyChanges();
        }
    }, [row.changed, onApplyChanges]);

    return (
        <Dialog open={open} onClose={onCancelChanges} aria-labelledby={"form-dialog-title"} fullWidth={true}>
            <DialogTitle id={"form-dialog-title"}>Debt collection</DialogTitle>
            <DialogContent>
                <Typography>
                    { row.locked ? lockedText : unlockedText }
                </Typography>
                <Wrapper>
                    <div>
                        <Button onClick={onDelete} variant={"contained"} color={"secondary"} disabled={row.locked}>
                            Delete
                        </Button>
                    </div>
                    <div>
                        <Button onClick={onCancelChanges} variant={"contained"}>
                            Cancel
                        </Button>
                        &nbsp;
                        <Button onClick={onLockedToggle} color={"primary"} variant={"contained"} disabled={row.locked}>
                            { row.locked ? "Locked" : "Lock" }
                        </Button>
                    </div>
                </Wrapper>
            </DialogContent>
        </Dialog>
    );
};

DebtCollectionPopupEditAndRemoveForm.propTypes = {
    row: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onApplyChanges: PropTypes.func.isRequired,
    onCancelChanges: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default DebtCollectionPopupEditAndRemoveForm;