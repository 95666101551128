import {emptySplitApi, pessimisticCreation} from "./index";

const productsApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["Product"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getProductsByAssociation: build.query({
                query: (association_slug) => ({ url: "/event_types", params: {"association__slug": association_slug, limit: 100} }),
                providesTags: (result, error, association_slug) => [{ type: "Product", id: association_slug }],
                transformResponse: (response) => response.results
            }),
            addProduct: build.mutation({
                query: (body) => ({ url: "/event_types/", method: "POST", body: body }),
                async onQueryStarted(eventType, { dispatch, queryFulfilled }) {
                    await pessimisticCreation({
                        dispatch,
                        queryFulfilled,
                        api: productsApi,
                        query: "getProductsByAssociation",
                        entityToQueryParam: () => eventType.association.split("/associations/")[1]
                    });
                }
            }),
            patchProduct: build.mutation({
                query: ({ slug, ...patch }) => ({ url: `/event_types/${slug}`, method: "PATCH", body: patch }),
                invalidatesTags: ({ association }) => [{ type: "Product", id: association.split("/associations/")[1] }]
            }),
            deleteProduct: build.mutation({
                query: ({ slug }) => ({ url: `/event_types/${slug}`, method: "DELETE" }),
                invalidatesTags: ({ association }) => [{ type: "Product", id: association.split("/associations/")[1] }]
            })
        }),
        overrideExisting: false
    });

export const {
    useGetProductsByAssociationQuery,
    useAddProductMutation,
    usePatchProductMutation,
    useDeleteProductMutation,
} = productsApi;
export default productsApi;