import InfoIcon from "@mui/icons-material/Info";
import Checkbox from "@mui/material/Checkbox";
import { red } from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React from "react";
import {TextValidator, ValidatorComponent} from "react-material-ui-form-validator";

const style = {
    fontSize: "12px",
    color: red[500],
    marginTop: "-15px",
};

class CheckboxField extends ValidatorComponent {

    renderValidatorComponent() {
        const { variant, errorMessages, validators, validatorListener, requiredError, label, labelPlacement, helperText, ...rest } = this.props;

        if (variant === "separated") {
            return (
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={4}>
                        <Typography variant={"body1"}>
                            { label }
                            { helperText &&
                                <Tooltip title={helperText} arrow>
                                    <InfoIcon fontSize={"small"} color={"primary"}/>
                                </Tooltip>
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Checkbox {...rest}/>
                        { this.errorText() }
                    </Grid>
                </Grid>
            );
        }

        return (
            <>
                <FormControlLabel
                    control={<Checkbox {...rest} ref={(r) => { this.input = r; }}/>}
                    label={label}
                    labelPlacement={labelPlacement}
                />
                <FormHelperText>{ helperText }</FormHelperText>
                { this.errorText() }
            </>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={style}>
                { this.getErrorMessage() }
            </div>
        );
    }
}

export default CheckboxField;