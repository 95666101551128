import * as moment from "moment";
import PropTypes from "prop-types";
import React, {useCallback, useMemo} from "react";

import withExtraProps from "../../../Components/HOC/withExtraProps";
import withPropMap from "../../../Components/HOC/withPropMap";
import {useGetDataFieldsByAssociationQuery} from "../../../Store/services/MySU/dataFields";
import {usePatchMembershipMutation} from "../../../Store/services/MySU/members";
import {usePatchMyMembershipMutation} from "../../../Store/services/MySU/user";
import InfoForm from "../InfoForm";
import MembershipForm from "./MembershipForm";
import MembershipInfo from "./MembershipInfo";


const formObjectToMembershipFormPropsMap = ({ formObject, handleFormObjectChange, ...rest_props}) =>
    ({ membership: formObject, handleMembershipChange: handleFormObjectChange, ...rest_props});
const formObjectToMembershipInfoPropsMap = ({ info, ...rest_props}) =>
    ({ membership: info, ...rest_props});
const membershipWithDatesToApi = (membership) => ({
    ...membership,
    profile: membership.profile.url,
    association: membership.association.url,
    date_joined: membership.date_joined && moment(membership.date_joined).format("YYYY-MM-DD"),
    date_left: membership.date_left && moment(membership.date_left).format("YYYY-MM-DD"),
});

const MembershipInfoForm = ({ membership, showInfoFormStateButton, initialInfoOrFormState, MoreActionButtonGroup, postSubmit, postCancel, isYou, association }) => {
    const [ patchMembership ] = usePatchMembershipMutation();
    const [ patchMyMembership ] = usePatchMyMembershipMutation();

    const { data: dataFields } = useGetDataFieldsByAssociationQuery(association.slug);
    const fieldAndValueToStateChanges = useCallback((field, value, prevState) => {
        const dataFieldUrls = dataFields ? dataFields.map(dataField=>dataField.url) : [];
        if (dataFieldUrls.includes(field)) {
            return { specific_data: prevState.specific_data.map(
                data => data.data_field === field ? {...data, value: value} : data
            )};
        }
        return { [field]: value };
    }, [dataFields]);

    const onSubmit = (membership) => isYou
        ? patchMyMembership(membershipWithDatesToApi(membership)).then(()=>postSubmit(membership))
        : patchMembership(membershipWithDatesToApi(membership)).then(()=>postSubmit(membership));

    const MembershipInfoComponent = withExtraProps(withPropMap(MembershipInfo, formObjectToMembershipInfoPropsMap), {
        association: association
    });
    const MembershipFormComponent = withExtraProps(withPropMap(MembershipForm, formObjectToMembershipFormPropsMap), {
        isYou: isYou,
        association: association
    });

    return (
        <InfoForm
            onSubmit={onSubmit}
            onCancel={postCancel}
            title={"Membership"}
            infoFormObject={membership}
            InfoComponent={MembershipInfoComponent}
            FormComponent={MembershipFormComponent}
            fieldAndValueToStateChanges={fieldAndValueToStateChanges}
            showInfoFormStateButton={showInfoFormStateButton}
            initialInfoOrFormState={initialInfoOrFormState}
            MoreActionButtonGroup={MoreActionButtonGroup}
        />
    );
};

const dummyFunction = () => {};

MembershipInfoForm.propTypes = {
    membership: PropTypes.object.isRequired,
    showInfoFormStateButton: PropTypes.bool.isRequired,
    initialInfoOrFormState: PropTypes.string.isRequired,
    isYou: PropTypes.bool.isRequired,
    association: PropTypes.object.isRequired,
    MoreActionButtonGroup: PropTypes.elementType,
    enableDelete: PropTypes.bool,
    postSubmit: PropTypes.func,
    postDelete: PropTypes.func,
    postCancel: PropTypes.func
};

MembershipInfoForm.defaultProps = {
    enableDelete: false,
    MoreActionButtonGroup: dummyFunction(),
    postSubmit: dummyFunction,
    postDelete: dummyFunction,
    postCancel: dummyFunction,
};

export default MembershipInfoForm;