import {emptySplitApi, pessimisticUpdateSingleInList} from "./index";

const eventsApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["Event"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getEvent: build.query({
                query: (slug) => ({ url: `/events/${slug}` }),
                providesTags: (result, error, slug) => [{ type: "Event", id: slug }],
            }),
            getEventsByAssociation: build.query({
                query: ({associationSlug: associationSlug, hidden}) => ({ url: "/events", params: {association__slug: associationSlug, limit: 1000, hidden_for_members: hidden} }),
                providesTags: (result, error, associationSlug) => [{ type: "Event", id: associationSlug }],
                transformResponse: (response) => response.results
            }),
            getEventsNotInDebtCollectionByAssociation: build.query({
                query: (associationSlug) => ({ url: "/events", params: {"association__slug": associationSlug, limit: 1000, "debt_collection__isnull": true} }),
                providesTags: (result, error, associationSlug) => [{ type: "Event", id: associationSlug }],
                transformResponse: (response) => response.results
            }),
            addEvent: build.mutation({
                query: (body) => ({ url: "/events/", method: "POST", body: body }),
                invalidatesTags: ["Event"],
            }),
            patchEvent: build.mutation({
                query: ({ slug, associationSlug, ...patch }) => ({ url: `/events/${slug}`, method: "PATCH", body: patch }),
                invalidatesTags: (result, error, arg) => [{ type: "Event", id: arg.associationSlug }],
                async onQueryStarted({ slug, associationSlug }, { dispatch, queryFulfilled }) {
                    await pessimisticUpdateSingleInList({
                        dispatch,
                        queryFulfilled,
                        api: eventsApi,
                        query: "getEventsByAssociation",
                        entityToQueryParam: () => associationSlug,
                        entityFilter: patchedDebtEntry => patchedDebtEntry.slug === slug
                    });
                }
            }),
            deleteEvent: build.mutation({
                query: (body) => ({ url: `/events/${body.slug}`, method: "DELETE", body: body }),
                invalidatesTags: ["Event"],
            }),
        }),
        overrideExisting: false
    });

export const {
    useGetEventQuery,
    useGetEventsByAssociationQuery,
    useGetEventsNotInDebtCollectionByAssociationQuery,
    useAddEventMutation,
    usePatchEventMutation,
    useDeleteEventMutation,
} = eventsApi;
export default eventsApi;