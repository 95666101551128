import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {orderBy} from "lodash";
import React, {useState} from "react";
import {useParams} from "react-router-dom";

import Button from "../../Components/Buttons/Button";
import Wrapper from "../../Components/FormComponents/Wrapper";
import Block from "../../Components/PageLayout/Content/Block";
import DataFieldsDropDown from "../../Forms/DropdownForms/DataFields/DataFieldsDropDown";
import {useGetDataFieldsByAssociationQuery} from "../../Store/services/MySU/dataFields";


const AssociationDataFields = () => {
    const { slug } = useParams();
    const { data: dataFields } = useGetDataFieldsByAssociationQuery(slug);
    const orderedDataFields = orderBy(dataFields || [], ["name"], ["asc"]);

    const [creating, setCreating] = useState(false);

    return (
        <Container>
            <Block>
                <Wrapper>
                    <Typography variant={"h5"}>Data fields</Typography>
                    <Button onClick={()=>setCreating(true)} variant={"contained"} color={"primary"}>
                        Add
                    </Button>
                </Wrapper>
                <hr className={"box-title-separator"}/>
                <DataFieldsDropDown
                    dataFields={orderedDataFields}
                    associationUrl={`/associations/${slug}`}
                    creating={creating}
                    postSubmit={()=>setCreating(false)}
                    postCancel={()=>setCreating(false)}
                />
            </Block>
        </Container>
    );
};

AssociationDataFields.propTypes = {
};

export default AssociationDataFields;