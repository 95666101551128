import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {Container, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { loremIpsum } from "react-lorem-ipsum";
import {useHistory, useParams} from "react-router-dom";

import Button from "../../../Components/Buttons/Button";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import useModalState from "../../../Components/Hooks/useModalState";
import ConfirmationModal from "../../../Components/Modals/ConfirmationModal";
import Block from "../../../Components/PageLayout/Content/Block";
import FigmaContent from "../../../Components/PageLayout/Content/FigmaContent";
import RoutingButton from "../../../Components/PageLayout/Content/RoutingButton";
import {useGetNewsByAssociationQuery} from "../../../Store/services/MySU/news";
import {useDeleteMembershipMutation} from "../../../Store/services/MySU/user";

const useStyles = makeStyles(theme => ({
    eventsBlock: {
        background: theme.palette.background.paper,
        width: "60%",
        margin: "auto",
        marginTop: "32px"
    },
    eventsList: {
        padding: theme.spacing(2)
    },
    moreEventsButton: {
        height: "40px",
        width: "100%",
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.dark
    },
    introText: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(6),
    },
    container: {

    }
}));

const EventBlock = ({ event }) => {
    return (
        <Grid container spacing={2} component={Button} onClick={()=>console.log("clicked!")}>
            <Grid item xs={2}>
                <Typography variant={"h5"}>15</Typography>
                <Typography>Feb</Typography>
            </Grid>
            <Grid item xs={9}>
                <Typography variant={"h5"}>Ice Skating</Typography>
            </Grid>
            <Grid item xs={1}>
                <KeyboardArrowRightIcon fontSize={"large"}/>
            </Grid>
        </Grid>
    );
};

const NewsBlock = ({ news }) => {
    return (
        <Grid container spacing={2} direction={"column"}>
            <Grid item xs={2}>
                <Wrapper>
                    <Typography variant={"h5"}>{ news?.title || "Lorem Ipsum" }</Typography>
                    <Typography>{ moment(news?.date).format("LLLL") || "Feb 15th 2022" }</Typography>
                </Wrapper>
            </Grid>
            <Grid item xs={9}>
                <Typography>{ news?.text || loremIpsum() }</Typography>
            </Grid>
        </Grid>
    );
};

NewsBlock.defaultProps = {
};

const NotCurrentMember = ({ association, membership }) => {
    const history = useHistory();
    const { slug } = useParams();

    const [modalOpen, toggleModalOpen] = useModalState(false);
    const [cancelMembership] = useDeleteMembershipMutation();

    const onCancelButtonClick = () => toggleModalOpen();
    const onCancel = () => toggleModalOpen();
    const onConfirm = () => {
        toggleModalOpen();
        cancelMembership(membership.slug).then(()=>history.push("/protected/associations/overview"));
    };

    if (membership.status === "Pending") {
        return (
            <Container>
                <ConfirmationModal
                    title={"Cancel Membership Request"}
                    description={"Are you sure you want to cancel your membership request for LEAP?"}
                    open={modalOpen}
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                />
                <RoutingButton/>
                <Block>
                    <Wrapper>
                        <Typography variant={"h5"}>Membership notice</Typography>
                        <Button color={"primary"} variant={"contained"} onClick={onCancelButtonClick}>Cancel Request</Button>
                    </Wrapper>
                    <Divider/>
                    <br/>
                    <Typography>
                        You have send a request to join { association.short_name }. The board will evaluate your request.
                        When your membership request is accepted, you will receive an email and be granted access to the rest of the page.
                    </Typography>
                </Block>
            </Container>
        );
    }

    if (membership.status === "Ended") {
        return (
            <Container>
                <RoutingButton/>
                <Block>
                    <Wrapper>
                        <Typography variant={"h5"}>Membership notice</Typography>
                    </Wrapper>
                    <Divider/>
                    <br/>
                    <Typography>
                        You are no longer a member of { association.short_name }. When the board indicates that you no longer owe { association.short_name } any money, your data will be removed.
                    </Typography>
                </Block>
            </Container>
        );
    }
};

NotCurrentMember.propTypes = {
    association: PropTypes.object.isRequired,
    membership: PropTypes.object.isRequired
};

export default NotCurrentMember;