import CategoryIcon from "@mui/icons-material/Category";
import EuroIcon from "@mui/icons-material/Euro";
import TitleIcon from "@mui/icons-material/Title";
import {MenuItem} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import React from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import NumberField from "../../../Components/FormComponents/Fields/NumberField";
import SelectField from "../../../Components/FormComponents/Fields/SelectField";
import TextField from "../../../Components/FormComponents/Fields/TextField";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import {useGetProductCategoriesByAssociationQuery} from "../../../Store/services/MySU/productCategories";

const ProductPopupForm = ({ associationSlug, row, onChange, onApplyChanges, onCancelChanges, open }) => {
    const { data: productCategories } = useGetProductCategoriesByAssociationQuery(associationSlug);
    return (
        <Dialog open={open} onClose={onCancelChanges} aria-labelledby={"form-dialog-title"} fullWidth={true}>
            <ValidatorForm
                onSubmit={onApplyChanges}
                onError={errors => console.log(errors)}
            >
                <DialogTitle id={"form-dialog-title"}>Product Details</DialogTitle>
                <DialogContent>
                    <Wrapper>
                        <IconHolder Icon={TitleIcon}/>
                        <TextField
                            associationSlug={associationSlug}
                            name={"name"}
                            label={"Name"}
                            value={row.name || ""}
                            onChange={onChange}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <IconHolder Icon={EuroIcon}/>
                        <NumberField
                            name={"price"}
                            value={row.price || ""}
                            onChange={onChange}
                            InputProps={{
                                inputProps: {
                                    step: 0.1, min: 0
                                }
                            }}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <IconHolder Icon={CategoryIcon}/>
                        <SelectField
                            name={"product_category"}
                            label={"Product Category"}
                            value={row.product_category || ""}
                            onChange={onChange}
                        >
                            { productCategories?.map((productCategory)=>(
                                <MenuItem key={productCategory.slug} value={productCategory.url}>
                                    { productCategory.name }
                                </MenuItem>
                            )) }
                        </SelectField>
                    </Wrapper>
                    <Wrapper>
                        <div/>
                        <div>
                            <Button onClick={onCancelChanges} variant={"contained"}>
                                Cancel
                            </Button>
                            &nbsp;
                            <Button type={"submit"} color={"primary"} variant={"contained"}>
                                Save
                            </Button>
                        </div>
                    </Wrapper>
                </DialogContent>
            </ValidatorForm>
        </Dialog>
    );
};

ProductPopupForm.propTypes = {
    associationSlug: PropTypes.string.isRequired,
    row: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onApplyChanges: PropTypes.func.isRequired,
    onCancelChanges: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default ProductPopupForm;