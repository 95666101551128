import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import React from "react";

import Button from "../../../Components/Buttons/Button";

const ColumnChooserButton = ({ onToggle, getMessage, buttonRef }) => (
    <Tooltip
        title={"Click to toggle columns"}
        placement={"bottom"}
        enterDelay={300}
    >
        <Button
            color={"primary"}
            variant={"outlined"}
            onClick={onToggle}
            ref={buttonRef}
            sx={{ml: 1}}
            style={{textTransform: "none"}}
            startIcon={<VisibilityRoundedIcon />}
            size={"small"}
        >
            Columns
        </Button>
    </Tooltip>
);

ColumnChooserButton.propTypes = {
    onToggle: PropTypes.func.isRequired,
    getMessage: PropTypes.func.isRequired,
    buttonRef: PropTypes.any.isRequired
};

export default ColumnChooserButton;