import PropTypes from "prop-types";
import React, {useEffect, useMemo} from "react";

import ConfirmationModal from "../../../Components/Modals/ConfirmationModal";
import DebtEntryPopupForm from "./DebtEntryPopupForm";

const description = <>
    This person had one of his/her debt collection cancelled. Has the member paid the money to the association?
    <br/><br/>
    After clicking `&apos;`Yes`&apos;`, this cancellation will disappear from the list.
</>;
const cancelButtonText = "I don't know yet.";
const confirmButtonText = "Yes";
const secondaryButtonText = "No";

const CancellationPopupForm = ({ row, onChange, onApplyChanges, onCancelChanges, open }) => {
    const title = useMemo(()=>"Debt collection of "+ row.name, [row.name]);
    useEffect(()=>{
        if (row.paid_after_cancellation) {
            onApplyChanges();
        }
    }, [row.paid_after_cancellation]);

    const duesPaid = () => onChange({ target: { name: "paid_after_cancellation", value: true } });
    const duesNotPaid = () => onCancelChanges();

    return (
        <ConfirmationModal
            title={title}
            onCancel={onCancelChanges}
            open={open}
            onConfirm={duesPaid}
            description={description}
            size={"md"}
            cancelButtonText={cancelButtonText}
            confirmButtonText={confirmButtonText}
            secondaryButtonText={secondaryButtonText}
            secondaryAction={duesNotPaid}
        />
    );
};

CancellationPopupForm.propTypes = {
    row: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onApplyChanges: PropTypes.func.isRequired,
    onCancelChanges: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default CancellationPopupForm;