import {Checkbox, MenuItem} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import React from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import CheckboxField from "../../../Components/FormComponents/Fields/CheckboxField";
import {DateTimeFieldV4} from "../../../Components/FormComponents/Fields/DateTimeField";
import {SelectFieldV3} from "../../../Components/FormComponents/Fields/SelectField";
import useAddValidationRule from "../../../Components/Hooks/useAddValidationRule";
import isAfterDate from "../../../Components/ValidatorRules/isAfterDate";


const DebtCollectionPopupAddForm = ({ events, row, onChange, onApplyChanges, onCancelChanges, open }) => {
    const smallScreen = useMediaQuery((theme)=>theme.breakpoints.up("sm"), { noSsr: true });
    const variant = smallScreen ? "separated" : "outlined";

    useAddValidationRule(ValidatorForm, isAfterDate.name, isAfterDate.validate(moment().add(1, "days")));

    // const handleAddAllEvents = () => {
    //     onChange({target: { name: "include_events", value: events.map(event=>event.url) }});
    // };
    //
    // const handleClearEvents = () => {
    //     onChange({target: { name: "include_events", value: [] }});
    // };
    //
    // const renderMultipleSelect = useCallback((selected)=> {
    //     return events.filter(event=>selected.includes(event.url)).map(event=>event.name).join(", ");
    // }, [events]);

    const renderMultipleSelect = (selected) => selected?.length > 0
        ? (selected.length > 1 ? selected.length + " events included" : "1 event included")
        : "no events included";

    return (
        <Dialog
            open={open}
            onClose={onCancelChanges}
            aria-labelledby={"form-dialog-title"}
            fullWidth={true}
            maxWidth={"md"}
            PaperProps={{
                sx: { borderRadius: 8 }
            }}
        >
            <ValidatorForm
                onSubmit={onApplyChanges}
            >
                <DialogTitle id={"form-dialog-title"}>New debt collection</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DateTimeFieldV4
                                variant={variant}
                                name={"debt_collection_date"}
                                label={"Debt collection date"}
                                value={row.debt_collection_date}
                                onChange={date => onChange({target: {name:"debt_collection_date", value: date }})}
                                helperText={"This is the date you are planning on collection the debt collection."}
                                validators={["isAfterDate", "required"]}
                                errorMessages={["Must be at least 1 day from now.", "This field is required."]}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectFieldV3
                                variant={variant}
                                name={"include_events"}
                                label={"Events included"}
                                value={row.include_events}
                                onChange={event=>onChange({target: {name:"include_events", value: event.target.value}})}
                                labelPlacement={"end"}
                                SelectProps={{
                                    multiple: true,
                                    renderValue: renderMultipleSelect
                                }}
                            >
                                { events && events.map((event,e)=>(
                                    <MenuItem key={e} value={event.url}>
                                        <Checkbox checked={row.include_events.indexOf(event.url) > -1} />
                                        <ListItemText primary={event.name} secondary={event.start_date}/>
                                    </MenuItem>
                                )) }
                            </SelectFieldV3>
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxField
                                variant={variant}
                                label={"Include Membership fees"}
                                value={row.include_membership_fee}
                                checked={row.include_membership_fee}
                                onChange={(event, value)=>onChange({target: {name:"include_membership_fee", value: event.target.checked}})}
                                labelPlacement={"start"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxField
                                variant={variant}
                                label={"Include invoices"}
                                value={row.include_invoices}
                                checked={row.include_invoices}
                                onChange={(event, value)=>onChange({target: {name:"include_invoices", value: event.target.checked}})}
                                labelPlacement={"start"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxField
                                variant={variant}
                                label={"Final debt collection of the year"}
                                value={row.ignore_threshold}
                                checked={row.ignore_threshold}
                                onChange={(event, value)=>onChange({target: {name:"ignore_threshold", value: event.target.checked}})}
                                labelPlacement={"start"}
                                helperText={"Ticking this will mean that the debt collection threshold will be ignored."}
                            />
                        </Grid>
                        <Grid container item spacing={1} justifyContent={"flex-end"}>
                            <Grid>
                                <div>
                                    <Button onClick={onCancelChanges} disableElevation>
                                        Cancel
                                    </Button>
                                    &nbsp;
                                    <Button type={"submit"} color={"primary"} variant={"contained"} disableElevation>
                                        Create
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </ValidatorForm>
        </Dialog>
    );
};

export default DebtCollectionPopupAddForm;