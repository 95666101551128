import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React, {useState} from "react";
import { Link } from "react-router-dom";

import logoCreateTomorrow from "../../../img/create-tomorrow-logo-wit.png";
import logoEcoChallenge from "../../../img/final-logo-white.png";
import logoKamerSite from "../../../img/logo-kamersite-white.png";
import logoKickInBuddy from "../../../img/logo-kickin-buddy-wit.png";
import logoKickInGo from "../../../img/logo-kickin-giveitagonew-white.png";
import logoUnionCard from "../../../img/logo-unioncard-white-new.png";
import logoUnionDeals from "../../../img/logo-uniondeals-white.png";
import logoUnionShop from "../../../img/logo-unionshop-white.png";
import logoSU from "../../../img/studentunion.png";
import logoTedX from "../../../img/tedex.png";
import Button from "../../Components/Buttons/Button";


const useStyles = makeStyles((theme => ({
    channelBar: {
        width: "100%",
        backgroundColor: "#000",
        zIndex: theme.zIndex.drawer + 1,
        // position: "sticky",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    collapse: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center"
    },
    logo: {
        width: 200,
        opacity: 0.5,
        "&:hover": {
            opacity: 1
        },
    },
    channelButton: {
        "&:focus": {
            outline: 0,
        }
    },
    channelColoring: {
        color: theme.palette.primary.main
    }
})));

const channels = [
    {logo: logoSU, ariaLabel: "", alt: "", link: "https://su.utwente.nl/"},
    {logo: logoKamerSite, ariaLabel: "", alt: "", link: "https://su.utwente.nl/redirect/?redirectto=kamersite"},
    {logo: logoUnionCard, ariaLabel: "", alt: "", link: "https://su.utwente.nl/redirect/?redirectto=unioncard"},
    {logo: logoUnionDeals, ariaLabel: "", alt: "", link: "https://su.utwente.nl/en/union-services/student/uniondeals/"},
    {logo: logoUnionShop, ariaLabel: "", alt: "", link: "https://su.utwente.nl/redirect/?redirectto=unionshop"},
    {logo: logoCreateTomorrow, ariaLabel: "", alt: "", link: "https://su.utwente.nl/redirect/?redirectto=createtomorrow"},
    {logo: logoTedX, ariaLabel: "", alt: "", link: "https://tedxtwenteu.com/"},
    {logo: logoEcoChallenge, ariaLabel: "", alt: "", link: "https://su.utwente.nl/redirect/?redirectto=eco-challenge"},
    {logo: logoKickInGo, ariaLabel: "", alt: "", link: "https://su.utwente.nl/redirect/?redirectto=give-it-a-go"},
    {logo: logoKickInBuddy, ariaLabel: "", alt: "", link: "https://su.utwente.nl/redirect/?redirectto=buddy"}
];

const StudentUnionChannels = () => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [open, setOpen] = useState(false);
    const toggleChannels = () => setOpen(prevState => !prevState);


    return (
        <div className={classes.channelBar}>
            <Collapse
                classes={{wrapperInner: classes.collapse}}
                orientation={"vertical"}
                in={open}
                collapsedSize={0}
            >
                { channels.map((channel, id)=>(
                    <IconButton
                        key={id}
                        aria-label={channel.ariaLabel}
                        color={"primary"}
                        component={Link}
                        to={{ pathname: channel.link }}
                        target={"_blank"}
                        rel={"noopener"}
                        size={"large"}
                    >
                        <img className={classes.logo} src={channel.logo} alt={channel.alt}/>
                    </IconButton>
                )) }
            </Collapse>
            <Button onClick={toggleChannels} className={classes.channelButton}>
                <Typography style={{ color: "#fff" }}>
                    { open
                        ? <>Hide Student Union <span className={classes.channelColoring}>channels</span></>
                        : <>See all Student Union <span className={classes.channelColoring}>channels</span></>
                    }
                </Typography>
                { open ? <ExpandLess /> : <ExpandMore /> }
            </Button>
        </div>
    );
};

export default StudentUnionChannels;