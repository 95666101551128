import {useParams} from "react-router-dom";

import {emptySplitApi, pessimisticUpdateSingle} from "./index";

const associationsApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["Association"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getAssociations: build.query({
                query: () => ({url: "/associations"}),
                providesTags: () => [{type: "Association", id: "LIST"}],
                transformResponse: (response) => response.results
            }),
            getAssociationBySlug: build.query({
                query: (associationSlug) => ({url: `/associations/${associationSlug}`}),
                providesTags: (result, error, associationSlug) => [{type: "Association", id: associationSlug}]
            }),
            patchAssociationFormData: build.mutation({
                query: (formData) => ({url: `/associations/${formData.get("slug")}`, method: "PATCH", body: formData}),
                async onQueryStarted(_, {dispatch, queryFulfilled}) {
                    await pessimisticUpdateSingle({
                        dispatch,
                        queryFulfilled,
                        api: associationsApi,
                        query: "getAssociationBySlug",
                        entityToQueryParam: patchedAssociation => patchedAssociation.slug,
                    });
                }
            }),
            patchAssociation: build.mutation({
                query: ({slug, ...patch}) => ({url: `/associations/${slug}`, method: "PATCH", body: patch}),
                async onQueryStarted(_, {dispatch, queryFulfilled}) {
                    await pessimisticUpdateSingle({
                        dispatch,
                        queryFulfilled,
                        api: associationsApi,
                        query: "getAssociationBySlug",
                        entityToQueryParam: patchedAssociation => patchedAssociation.slug,
                    });
                }
            })
        }),
        overrideExisting: false
    });

const useCurrentAssociation = () => {
    const { slug } = useParams();
    return useGetAssociationBySlugQuery(slug);
};

export { useCurrentAssociation };
export const {
    useGetAssociationsQuery,
    useGetAssociationBySlugQuery,
    usePatchAssociationFormDataMutation,
    usePatchAssociationMutation,
} = associationsApi;
export default associationsApi;