import {CardActionArea, CardActions} from "@mui/material";
import Badge from "@mui/material/Badge";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useOverShadowStyles } from "@mui-treasury/styles/shadow/over";
import cx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import Image from "../../../img/default_photo.jpg";
import Sushi from "../../../img/Sushi-11-me.jpg";
import Button from "../../Components/Buttons/Button";

const useStyles = makeStyles(theme => ({
    root: {
        margin: "auto",
        borderRadius: theme.spacing(2), // 16px
        transition: "0.3s",
        boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
        position: "relative",
        minWidth: 304,
        maxWidth: 500,
        marginLeft: "auto",
        overflow: "initial",
        background: theme.palette.background.paper,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: 0,
        backgroundColor: theme.palette.primary.main
    },
    media: {
        width: "100%",
        paddingBottom: "48%",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        position: "relative",
        paddingLeft: 10,
        paddingRight: 10,
        "&:after": {
            content: "\" \"",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: theme.spacing(2), // 16
            opacity: 0.5,
        },
    },
    content: {
        padding: 0,
        "&:last-child": {
            padding: 0
        }
    },
    title: {
        color: theme.palette.common.white,
        textAlign: "center"
    },
    icon: {
        color: theme.palette.background.paper
    },
    iconWrapper: {
        width: "90%",
        display: "flex",
        justifyContent: "space-between"
    },
    link: {
        width: "100%"
    },
    tag: {
        position: "relative",
        top: 35,
        right: -215,
        backgroundColor: "beige",
        color: "black",
        textAlign: "center",
        width: 75,
        borderRadius: theme.shape.borderRadius,
        zIndex: 1
    }
}));

const ProductCard = ({ title, photo, path}) => {
    const styles = useStyles();
    const shadowStyles = useOverShadowStyles();

    return (
        <Card className={cx(styles.root, shadowStyles.root)}>
            <Button onClick={()=>{}} className={styles.link}>
                <CardMedia
                    className={styles.media}
                    image={photo}
                />
                <Typography variant={"h4"} className={styles.title}>{ title }</Typography>
            </Button>
        </Card>
    );
};

const ProductCardV2 = ({ title, photo, cost, onClick }) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={onClick}>
                <Typography className={classes.tag}>{ "€ " + cost }</Typography>
                <CardMedia
                    // title={"Contemplative Reptile"}
                    component={"img"}
                    height={"140"}
                    alt={"Contemplative Reptile"}
                    // image={photo || Image}
                    image={photo || Sushi}
                />
                <CardContent className={classes.content}>
                    <Typography variant={"h5"} className={classes.title}>
                        { title }
                    </Typography>
                </CardContent>
            </CardActionArea>
            { /*<CardActions>*/ }
            { /*    <Button size={"small"} onClick={subtractOne}>*/ }
            { /*        -1*/ }
            { /*    </Button>*/ }
            { /*    <Button size={"small"} onClick={addOne}>*/ }
            { /*        +1*/ }
            { /*    </Button>*/ }
            { /*</CardActions>*/ }
        </Card>
    );
};

ProductCard.propTypes = {
    title: PropTypes.string.isRequired,
    photo: PropTypes.string,
    path: PropTypes.string,
};

ProductCard.defaultProps = {
    photo: Image,
    path: undefined,
    cost: "free"
};

export {ProductCardV2};
export default ProductCard;
