import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/query/react";

const xkcdApi = createApi({
    reducerPath: "xkcd",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["XKCD"],
    baseQuery: fetchBaseQuery({
        baseUrl: "https://xkcd.com/",
    }),
    endpoints: (build) => ({
        getXkcd: build.query({
            query: (id) => ({ url: `/${id}/info.0.json` }),
            providesTags: (result, error, id) => [{ type: "XKCD", id: id }],
        }),
    }),
});

export const {
    useGetXkcdQuery,
} = xkcdApi;
export default xkcdApi;