import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useHistory } from "react-router-dom";

import {BackButton} from "../../Components/BackButton";
import Button from "../../Components/Buttons/Button";
import CheckboxField from "../../Components/FormComponents/Fields/CheckboxField";
import SelectField from "../../Components/FormComponents/Fields/SelectField";
import SpecificDataField from "../../Components/FormComponents/Fields/SpecificDataField";
import Wrapper from "../../Components/FormComponents/Wrapper";
import useAddValidationRule from "../../Components/Hooks/useAddValidationRule";
import useAddValidationRuleConditionally from "../../Components/Hooks/useAddValidationRuleConditionally";
import Block from "../../Components/PageLayout/Content/Block";
import isNotEqualTo from "../../Components/ValidatorRules/isNotEqualTo";
import isTruthy from "../../Components/ValidatorRules/isTruthy";
import { useGetDataFieldsByAssociationQuery } from "../../Store/services/MySU/dataFields";
import { useGetMemberTypesByAssociationQuery } from "../../Store/services/MySU/memberTypes";
import { usePreferredProfile,useRequestMembershipMutation } from "../../Store/services/MySU/user";

const useStyles = makeStyles(theme => ({
    block: {
        marginBottom: theme.spacing(4)
    }
}));

const debtCollectionText = (association) => <span> By signing this form, you authorize { association.name } to send recurrent collection orders to your bank
to debit your account for ??? and your bank to debit a recurrent amount from your account in accordance with
the order of { association.name }. If you do not agree with this debit, you can arrange for it to be refunded. Please
contact your bank within eight weeks of the date of the debit. Ask your bank for the conditions.*
</span>;
const breakedTextNode = (text) => <>{ text }<br/></>;

const MembershipRequest = ({ onCancel, association }) => {
    const classes = useStyles();
    const history = useHistory();

    const [ formState, setFormState ] = useState({
        privacy: false,
        terms_of_service: false,
        memberType: "",
        pay_by: "",
        debt_collection_mandate_given: false
    });

    const { neededDataFields, isLoading } = useGetDataFieldsByAssociationQuery(association.slug, {
        selectFromResult: ({ data }) => ({
            neededDataFields: data?.filter(dataField=>!dataField.board_only),
        }),
    });
    const { chooseableMemberTypes } = useGetMemberTypesByAssociationQuery(association.slug, {
        selectFromResult: ({ data }) => ({
            chooseableMemberTypes: data?.filter(memberType=>!memberType.only_chooseable_by_board) || [],
        }),
    });
    const [ requestMembership ] = useRequestMembershipMutation();
    const profile = usePreferredProfile();

    useAddValidationRule(ValidatorForm, isTruthy.name, isTruthy.validate);
    useAddValidationRuleConditionally(ValidatorForm, isNotEqualTo.name, isNotEqualTo.validate("Debt collection"), !profile.iban);

    useEffect(()=>{
        if ( neededDataFields && neededDataFields[0] && formState[neededDataFields[0].url] === undefined ) {
            setFormState(prevState => {
                neededDataFields.forEach(dataField => {
                    // console.log(dataField, dataField.type === "Boolean");
                    if (dataField.type === "Boolean") {
                        prevState[dataField.url] = "False";
                    } else {
                        prevState[dataField.url] = "";
                    }
                });
                return {...prevState};
            });
        }
    }, [neededDataFields, isLoading]);

    const handleChange = (field, value) => setFormState(prevState => {
        if (field === "pay_by" && value === "Invoice") {
            return {...prevState, "pay_by": "Invoice", debt_collection_mandate_given: false};
        }
        return {...prevState, [field]: value};
    });
    const handleSubmit = () => {
        // eslint-disable-next-line no-unused-vars
        let { memberType, terms_of_service, privacy, pay_by, debt_collection_mandate_given, ...data_fields_values} = formState;
        let association_data = [];
        for (let [url, value] of Object.entries(data_fields_values)) {
            if (Array.isArray(value)) {
                value = value[0];
            }
            association_data.push({
                data_field: url,
                value: value
            });
        }

        requestMembership({
            profile: profile.url,
            association: association.url,
            pay_by: pay_by,
            debt_collection_mandate_given: debt_collection_mandate_given,
            type: memberType,
            specific_data: association_data,
        }).then(()=>history.push("/protected/associations/" + association.slug + "/member"));
    };

    const memberTypeHelperText = useMemo(()=> {
        const memberType_helper_data = chooseableMemberTypes
            .filter(memberType=>memberType.helper_text)
            .map(memberType=>memberType.type + ": " + memberType.helper_text);
        return (
            <>
                { memberType_helper_data.map(text=>breakedTextNode(text)) }
            </>
        );
    }, [chooseableMemberTypes]);

    const raiseIbanMessage = useMemo(()=>association.member_needs_iban && !profile.iban, [association, profile]);

    return (
        <Container className={classes.block}>
            <BackButton/>
            <Block >
                <Typography variant={"h4"}>{ association.new_member_text || "Membership request for " + association.name }</Typography>
                <Typography>
                    { association.new_member_text || association.name + " requires additional information. To become a member you need to provide them with the following:" }
                </Typography>
                <hr className={"box-title-separator"}/>
                { raiseIbanMessage
                ? (
                    <>
                        <Typography>This association requires that you have an iban set in your profile. Please go to your profile and add it.</Typography>
                        <Wrapper>
                            <div/>
                            <Button variant={"contained"} onClick={onCancel}>Back</Button>
                        </Wrapper>
                    </>
                )
                : (
                    <ValidatorForm
                        onSubmit={handleSubmit}
                        onError={errors => console.error(errors)}
                    >
                        <Wrapper>
                            <SelectField
                                label={"Member type"}
                                name={"memberType"}
                                value={formState.memberType}
                                onChange={(event)=>handleChange("memberType", event.target.value)}
                                helperText={memberTypeHelperText}
                                required
                            >
                                { chooseableMemberTypes.map((memberType, m) => (
                                    <MenuItem key={m} value={memberType.url}>
                                        { memberType.type + ", € " + memberType.membership_fee }
                                    </MenuItem>
                                )) }
                            </SelectField>
                        </Wrapper>
                        <Wrapper>
                            <SelectField
                                label={"Payment by"}
                                name={"pay_by"}
                                value={formState.pay_by}
                                onChange={(event)=>handleChange("pay_by", event.target.value)}
                                validators={["isNotEqualTo"]}
                                errorMessages={["You need to have an iban set before you can pay by debt collection."]}
                                required
                            >
                                <MenuItem value={"Debt collection"}>Debt collection</MenuItem>
                                <MenuItem value={"Invoice"}>Invoice</MenuItem>
                            </SelectField>
                        </Wrapper>
                        { formState.pay_by === "Debt collection" &&
                            <Wrapper>
                                <CheckboxField
                                    checked={formState.debt_collection_mandate_given}
                                    value={formState.debt_collection_mandate_given}
                                    name={"allow_debt_collection"}
                                    onChange={(event)=>handleChange("debt_collection_mandate_given", event.target.checked)}
                                    label={debtCollectionText(association)}
                                    labelPlacement={"end"}
                                    validators={["isTruthy"]}
                                    errorMessages={["This field is required"]}
                                />
                            </Wrapper>
                        }
                        { neededDataFields?.map((dataField, d)=>(
                            <Wrapper key={d}>
                                <SpecificDataField
                                    field={dataField}
                                    value={formState[dataField.url] === undefined ? "" : formState[dataField.url]}
                                    onChange={handleChange}
                                    helperText={dataField.helper_text}
                                    required
                                />
                            </Wrapper>
                        )) }
                        <Wrapper>
                            <CheckboxField
                                checked={formState.privacy}
                                value={formState.privacy}
                                name={"privacy"}
                                onChange={(event)=>handleChange("privacy", event.target.checked)}
                                label={<span>I have read the <a href={association.privacy_statement}>Privacy Statement</a> *</span>}
                                labelPlacement={"end"}
                                validators={["isTruthy"]}
                                errorMessages={["This field is required"]}
                            />
                        </Wrapper>
                        <CheckboxField
                            checked={formState.terms_of_service}
                            value={formState.terms_of_service}
                            name={"terms_of_service"}
                            onChange={(event)=>handleChange("terms_of_service", event.target.checked)}
                            label={<span>I have read and accept the <a href={association.terms_of_service}>Terms of Service</a> *</span>}
                            labelPlacement={"end"}
                            validators={["isTruthy"]}
                            errorMessages={["This field is required"]}
                        />
                        <Wrapper>
                            <div/>
                            <div>
                                <Button variant={"contained"} color={"primary"} type={"submit"}>Submit</Button>
                                &nbsp;
                                <Button variant={"contained"} onClick={onCancel}>Cancel</Button>
                            </div>
                        </Wrapper>
                    </ValidatorForm>
                ) }
            </Block>
        </Container>
    );
};

MembershipRequest.propTypes = {
    association: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default MembershipRequest;