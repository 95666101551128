import {emptySplitApi} from "./index";

const notificationsApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["Notification"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getNotifications: build.query({
                query: () => ({ url: "/notifications", params: {limit: 1000} }),
                providesTags: () => [{ type: "Notification" }],
                transformResponse: (response) => response.results
            }),
        }),
        overrideExisting: false
    });

export const {
    useGetNotificationsQuery,
} = notificationsApi;
export default notificationsApi;