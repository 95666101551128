import * as moment from "moment/moment";
import PropTypes from "prop-types";
import React, {useMemo} from "react";

import Button from "../../../../Components/Buttons/Button";
import useModalState from "../../../../Components/Hooks/useModalState";
import ConfirmationModal from "../../../../Components/Modals/ConfirmationModal";
import MembershipInfoForm from "../../../../Forms/InfoForms/Membership/MembershipInfoForm";
import {useCurrentAssociation} from "../../../../Store/services/MySU/associations";
import {usePatchMembershipMutation} from "../../../../Store/services/MySU/members";
import {usePatchMyMembershipMutation} from "../../../../Store/services/MySU/user";

const MembershipsBlock = ({ membership, isYou }) => {
    const [modalOpen, toggleModal] = useModalState(false);

    const { data: association } = useCurrentAssociation();
    const [ updateMyMembership ] = usePatchMyMembershipMutation();
    const [ updateMembership ] = usePatchMembershipMutation();

    const title = useMemo(()=>membership.date_left
        ? (isYou ? "Stay Member?" : "Reinstate Member?")
        : "End Membership?",
    [membership.date_left, isYou]);
    const description = useMemo(()=>membership.date_left
        ? (isYou ? "Are you sure you want to stay a member?" : "Are you sure you want to let the member stay a member?")
        : (isYou ? "Are you sure you want to end your membership?" : "Are you sure you want to end this membership?"),
    [membership.date_left, isYou]);
    const buttonText = useMemo(()=>membership.date_left
        ? (isYou ? "Stay Member" : "Reinstate Member")
        : "End Membership",
    [membership]);

    if (!association) {
        return null;
    }

    const onCancel = () => toggleModal();
    const onConfirm = () => {
        let new_date_left = null;
        if (!membership.date_left) {
            const current_full_year_membership_start_date = parseInt(new Date().getFullYear()) + "-" + association.full_year_membership_start_date;
            const next_full_year_membership_start_date = parseInt(new Date().getFullYear() + 1) + "-" + association.full_year_membership_start_date;
            if (moment(current_full_year_membership_start_date) >= moment()) {
                new_date_left = current_full_year_membership_start_date;
            } else {
                new_date_left = next_full_year_membership_start_date;
            }
        }
        if (isYou) {
            updateMyMembership({slug: membership.slug, date_left: new_date_left}).then(
                ()=>toggleModal()
            );
        } else {
            updateMembership({slug: membership.slug, date_left: new_date_left}).then(
                ()=>toggleModal()
            );
        }
    };

    const MoreActionButtonGroup = () => <Button variant={"contained"} onClick={toggleModal}>{ buttonText }</Button>;

    return (
        <React.Fragment>
            <MembershipInfoForm
                initialInfoOrFormState={"info"}
                showInfoFormStateButton={true}
                isYou={isYou}
                membership={{...membership, debt_collection_mandate_given: membership.pay_by === "Debt collection"}}
                association={association}
                MoreActionButtonGroup={MoreActionButtonGroup}
            />
            <ConfirmationModal
                title={title}
                description={description}
                open={modalOpen}
                onCancel={onCancel}
                onConfirm={onConfirm}
            />
        </React.Fragment>
    );
};

MembershipsBlock.propTypes = {
    membership: PropTypes.object.isRequired,
    isYou: PropTypes.bool.isRequired
};

export default MembershipsBlock;