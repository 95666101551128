import ContactsIcon from "@mui/icons-material/Contacts";
import PropTypes from "prop-types";
import React from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import {TextFieldV2} from "../../../Components/FormComponents/Fields/TextField";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";

const EventTypesForm = ({ eventType, handleEventTypeChange, onSubmit, onCancel, onDelete, enableDelete }) => {
    return (
        <ValidatorForm
            onSubmit={onSubmit}
            onError={errors => console.log(errors)}
        >
            <Wrapper>
                <IconHolder Icon={ContactsIcon}/>
                <TextFieldV2
                    name={"type"}
                    label={"name"}
                    value={eventType.type}
                    onChange={(event)=>handleEventTypeChange("type", event.target.value)}
                />
            </Wrapper>
            <Wrapper>
                <div>
                    <Button variant={"outlined"} disabled={!enableDelete} onClick={onDelete} color={"error"}>
                        Delete
                    </Button>
                </div>
                <div>
                    <Button variant={"contained"} onClick={onCancel} color={"secondary"}>Cancel</Button>
                    &nbsp;
                    <Button type={"submit"} variant={"contained"} color={"primary"}>Save</Button>
                </div>
            </Wrapper>
        </ValidatorForm>
    );
};

EventTypesForm.propTypes = {
    eventType: PropTypes.object.isRequired,
    handleEventTypeChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    enableDelete: PropTypes.bool.isRequired
};

export default EventTypesForm;