import {CircularProgress, Skeleton} from "@mui/material";
import MuiCardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {isEmpty} from "lodash";
import React from "react";

import Image from "../../../../img/default_photo.jpg";
import Wrapper from "../../../Components/FormComponents/Wrapper";


const GroupAboutInfo = ({ group }) => {
    if (isEmpty(group)) {
        return <Wrapper><CircularProgress /></Wrapper>;
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <MuiCardMedia image={group.photo || Image}
                    sx={{
                        width: "100%",
                        height: 0,
                        paddingBottom: "48%",
                        borderRadius: 4,
                        backgroundColor: "background.paper",
                        position: "relative",
                        transform: "translateY(8px)",
                        // paddingLeft: 1,
                        // paddingRight: 1,
                        "&:after": {
                            content: "\" \"",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            borderRadius: "shape.borderRadius",
                            opacity: 0.5,
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant={"body1"}>
                    { group?.description }
                </Typography>
            </Grid>
        </Grid>
    );
};

export default GroupAboutInfo;