import {emptySplitApi} from "./index";

const memberTypesApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["Mathing"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getMatchingsByAssociation: build.query({
                query: (association_slug) => ({ url: "/matchings", params: {"association__slug": association_slug, limit: 100} }),
                providesTags: (result, error, association_slug) => [{ type: "Mathing", id: association_slug }],
                transformResponse: (response) => response.results
            }),
            //    TODO: addMatching
            //    TODO: deleteMatching
            //    TODO: emailMatchingsByAssociation
        }),
        overrideExisting: false
    });

export const {
    useGetMatchingsByAssociationQuery,
} = memberTypesApi;
export default memberTypesApi;