import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {ButtonGroup, CardActionArea as MuiCardActionArea} from "@mui/material";
import MuiCard from "@mui/material/Card";
import MuiCardContent from "@mui/material/CardContent";
import MuiCardHeader from "@mui/material/CardHeader";
import MuiCardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import React from "react";
import LinesEllipsis from "react-lines-ellipsis";

import Image from "../../../img/default_photo.jpg";

const Card = ({ name, description, photo, onClick }) => {
    return (
        <MuiCard
            sx={{
                minWidth: 416,
                maxWidth: 577,
                borderRadius: 4
            }}
        >
            <MuiCardActionArea onClick={onClick}>
                <MuiCardHeader
                    title={name}
                    action={
                        <Container spacing={2}>
                            <ButtonGroup >
                                <IconButton size={"small"} sx={{border: 1, borderColor: "primary.main", borderRadius: 8, marginRight: 1}}>
                                    <EditIcon
                                        color={"primary"}
                                        fontSize={"small"}
                                    />
                                </IconButton>
                                <IconButton size={"small"} sx={{border: 1, borderColor: "primary.main", borderRadius: 8}}>
                                    <DeleteIcon
                                        color={"primary"}
                                        fontSize={"small"}
                                    />
                                </IconButton>
                            </ButtonGroup>
                        </Container>
                    }
                    sx={{
                        marginLeft: 4,
                        marginRight: 0,
                        paddingLeft: 0,
                        justifyContent: "flex-start",
                        alignItems: "flex-start"
                    }}
                />
                <Divider
                    sx={{
                        marginLeft: 4,
                        marginRight: 4,
                    }}
                />
                <MuiCardMedia image={photo || Image}
                    sx={{
                        width: "85%",
                        marginLeft: 4,
                        marginRight: 4,
                        height: 0,
                        paddingBottom: "48%",
                        borderRadius: 4,
                        backgroundColor: "background.paper",
                        position: "relative",
                        transform: "translateY(8px)",
                        paddingLeft: 1,
                        paddingRight: 1,
                        "&:after": {
                            content: "\" \"",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            borderRadius: "shape.borderRadius",
                            opacity: 0.5,
                        },
                    }}
                />
                <MuiCardContent
                    sx={{
                        marginLeft: 4,
                        marginRight: 4,
                        paddingLeft: 0,
                    }}
                >
                    <LinesEllipsis
                        className={"MuiTypography-root MuiTypography-body1"}
                        component={"p"}
                        maxLine={5}
                        text={description}
                    />
                </MuiCardContent>
            </MuiCardActionArea>
        </MuiCard>
    );
};

const CardV2 = ({ name, description, photo, onClick }) => {
    return (
        <MuiCard
            sx={{
                minWidth: 416,
                maxWidth: 577,
                borderRadius: 4
            }}
        >
            <MuiCardActionArea onClick={onClick}>
                <MuiCardHeader
                    title={name}
                    sx={{
                        marginLeft: 4,
                        marginRight: 0,
                        paddingLeft: 0,
                        justifyContent: "flex-start",
                        alignItems: "flex-start"
                    }}
                />
                <Divider
                    sx={{
                        marginLeft: 4,
                        marginRight: 4,
                    }}
                />
                <MuiCardMedia image={photo || Image}
                    sx={{
                        width: "85%",
                        marginLeft: 4,
                        marginRight: 4,
                        height: 0,
                        paddingBottom: "48%",
                        borderRadius: 4,
                        backgroundColor: "background.paper",
                        position: "relative",
                        transform: "translateY(8px)",
                        paddingLeft: 1,
                        paddingRight: 1,
                        "&:after": {
                            content: "\" \"",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            borderRadius: "shape.borderRadius",
                            opacity: 0.5,
                        },
                    }}
                />
                <MuiCardContent
                    sx={{
                        marginLeft: 4,
                        marginRight: 4,
                        paddingLeft: 0,
                    }}
                >
                    <LinesEllipsis
                        className={"MuiTypography-root MuiTypography-body1"}
                        component={"p"}
                        maxLine={5}
                        text={description}
                    />
                </MuiCardContent>
            </MuiCardActionArea>
        </MuiCard>
    );
};

Card.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    photo: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

export {CardV2};
export default Card;