import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React from "react";
import {useHistory, useParams} from "react-router-dom";

import {BackButton} from "../../Components/BackButton";
import Button from "../../Components/Buttons/Button";
import Wrapper from "../../Components/FormComponents/Wrapper";
import RoutingButton from "../../Components/PageLayout/Content/RoutingButton";
import AssociationInfo from "../../Forms/InfoForms/Association/AssociationInfo";
import {useGetAssociationBySlugQuery} from "../../Store/services/MySU/associations";
import {useMemberships} from "../../Store/services/MySU/user";


const AssociationDetail = () => {
    const history = useHistory();
    const { slug } = useParams();
    const { data: association } = useGetAssociationBySlugQuery(slug);

    const associationMemberships = useMemberships();
    const associationMembershipAssociations = associationMemberships?.map(association_membership => association_membership.association) || [];
    const memberOfAssociation = associationMembershipAssociations.find(association => association.slug === slug) !== undefined;

    return (
        <Container maxWidth={"xl"}>
            <Wrapper>
                <RoutingButton
                    routeStringPieces={[
                        "Associations", "Detail of " + association?.name
                    ]}
                />
                <BackButton container={false}/>
            </Wrapper>
            <Box
                sx={{
                    borderRadius: 4,
                    backgroundColor: "background.paper",
                    padding: 5
                }}
            >
                <Wrapper>
                    <Typography variant={"h5"}>
                        { association?.name }
                    </Typography>
                    { memberOfAssociation ||
                        <Button variant={"contained"} onClick={()=>history.push("./request")}>
                            Become member
                        </Button>
                    }
                </Wrapper>
                <Divider sx={{ mt: 1, mb: 1}}/>
                <AssociationInfo
                    association={association || {}}
                />
            </Box>
        </Container>
    );
};

AssociationDetail.propTypes = {
};

export default AssociationDetail;