import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import {SelectValidator} from "react-material-ui-form-validator";

import useFieldStyles, {useFieldStylesV2} from "../fieldStyles";


const SelectField = ({name, children, value, ...remaining_props}) => {
    const classes = useFieldStyles();

    return (
        <SelectValidator
            name={name}
            variant={"outlined"}
            className={classes.field}
            containerProps={{className: classes.field}}
            label={name[0].toUpperCase() + name.slice(1).replaceAll("_", " ")}
            value={value === null ? "" : value}
            {...remaining_props}
        >
            { children || <MenuItem/> }
        </SelectValidator>
    );
};

const SelectFieldV2 = (props) => {
    const classes = useFieldStyles();

    return (
        <SelectValidator
            variant={"outlined"}
            className={classes.field}
            containerProps={{className: classes.field}}
            {...props}
        />
    );
};

const SelectFieldV3 = ({ variant, label, labelXs, helperText, ...rest_props }) => {
    const classes = useFieldStylesV2();
    if (variant === "separated") {
        return (
            <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={labelXs}>
                    <Tooltip title={helperText} arrow>
                        <Typography variant={"body1"}>{ label }</Typography>
                    </Tooltip>
                </Grid>
                <Grid item xs={12 - labelXs}>
                    <SelectValidator
                        variant={"outlined"}
                        className={classes.field}
                        containerProps={{className: classes.field}}
                        size={"small"}
                        {...rest_props}
                    />
                </Grid>
            </Grid>
        );
    } else {
        return (
            <SelectValidator
                variant={"outlined"}
                label={label}
                className={classes.field}
                containerProps={{className: classes.field}}
                helperText={helperText}
                {...rest_props}
            />
        );
    }
};

SelectFieldV3.propTypes = {
    variant: PropTypes.string,
    label: PropTypes.string,
    labelXs: PropTypes.number,
    helperText: PropTypes.string
};

SelectFieldV3.defaultProps = {
    helperText: "",
    variant: "separated",
    label: "",
    labelXs: 4,
};


SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    children: PropTypes.node
};

export { SelectFieldV2, SelectFieldV3 };
export default SelectField;