import {DialogContentText} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment/moment";
import PropTypes from "prop-types";
import React from "react";

import Image from "../../../../../img/default_photo.jpg";
import {useGetNewsQuery} from "../../../../Store/services/MySU/news";

const useStyles = makeStyles(()=>({
    image: {
        borderRadius: 16,
        maxHeight: 200,
        width: "100%",
        objectFit: "cover"
    }
}));

const NewsModal = ({ slug, onClose }) => {
    const classes = useStyles();

    const { data: newsItem, isLoading } = useGetNewsQuery(slug, {
        skip: slug === "",
    });

    if (isLoading) {
        return null;
    }

    return (
        <Dialog
            open={slug !== "" && newsItem !== undefined && !isLoading}
            onClose={onClose}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby={"contained-modal-title-vcenter"}
        >
            <DialogTitle>
                <Typography variant={"h5"}>{ newsItem?.title }</Typography>
                <Typography variant={"subtitle1"}>Posted on { moment(newsItem?.date).format("LLL") }</Typography>
                { newsItem?.date !== newsItem?.date &&
                    <Typography variant={"subtitle1"}>
                        Posted on { moment(newsItem?.last_modified).format("LLL") }
                    </Typography>
                }
            </DialogTitle>
            <DialogContent>
                <img src={newsItem?.photo || Image} alt={"bad description"} className={classes.image}/>
                <DialogContentText>
                    { newsItem?.text }
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

NewsModal.propTypes = {
    slug: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default NewsModal;