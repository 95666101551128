import {
    IntegratedSummary,
    SummaryState,
} from "@devexpress/dx-react-grid";
import {
    Grid,
    Table,
    TableHeaderRow,
    TableSummaryRow
} from "@devexpress/dx-react-grid-material-ui";
import withStyles from "@mui/styles/withStyles";
import {isEmpty} from "lodash";
import PropTypes from "prop-types";
import React from "react";

const getRowId = row => row.slug;

const tableHeaderCellStyles = theme => ({
    coloured: {
        borderTop: "1px solid #e0e0e0",
        borderBottom: "1px solid #e0e0e0",
        backgroundColor:  "#f3f3f3",
    }
});

const tableCellStyles = theme => ({
    noBorder: {
        borderBottom: 0,
        borderTop: 0,
    },
});

const TableHeaderCellBase = ({ classes, children, ...restProps }) => (
    <TableHeaderRow.Cell
        {...restProps}
        className={classes.coloured}
    >
        { children }
    </TableHeaderRow.Cell>
);

const TableSummaryTotalCellBase = ({ classes, ...restProps }) => (
    <TableSummaryRow.TotalCell
        {...restProps}
        className={classes.noBorder}
    />
);

const TableHeaderCell = withStyles(tableHeaderCellStyles, { name: "TableHeaderCell" })(TableHeaderCellBase);
const TableSummaryTotalCell = withStyles(tableCellStyles, { name: "TableSummaryCell" })(TableSummaryTotalCellBase);


const Detail = ({ rows, headers, totalItems  }) => {
    if (!rows || isEmpty(rows)) {
        return null;
    }

    return (
        <Grid
            rows={rows}
            columns={headers}
            getRowId={getRowId}
        >
            <SummaryState
                totalItems={totalItems}
            />
            <IntegratedSummary/>
            <Table />
            <TableHeaderRow
                cellComponent={TableHeaderCell}
                headerCellComponent={TableHeaderCell}
            />
            <TableSummaryRow
                totalCellComponent={TableSummaryTotalCell}
            />
        </Grid>
    );
};

Detail.propTypes = {
    rows: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    totalItems: PropTypes.array.isRequired,
};

export default Detail;