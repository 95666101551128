import {CircularProgress} from "@mui/material";
import PropTypes from "prop-types";
import React, {useMemo} from "react";

import {useGetXkcdQuery} from "../../../../Store/services/XKCD/xkcd";

const minXkcdId = 1;
const maxXkcdId = 2556;
const generateRandomInteger = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
const generateRandomXkcdId = () => generateRandomInteger(minXkcdId, maxXkcdId);

const XkcdBlock = ({ id }) => {
    const xkcdId = useMemo(()=>id || generateRandomXkcdId(), [id]);
    const { data: comicData, isLoading } = useGetXkcdQuery(xkcdId);

    if (isLoading || !comicData ) {
        return <CircularProgress/>;
    }

    return (
        <article className={"comic"}>
            <h2>{ comicData.safe_title }</h2>
            <h3>Comic #{ comicData.num }</h3>
            <img src={comicData.img} alt={`XKCD #${comicData.num}`}/>
            <p>{ comicData.alt }</p>
        </article>
    );
};

XkcdBlock.propTypes = {
    id: PropTypes.number
};

XkcdBlock.defaultProps = {
    id: 0
};

export { generateRandomXkcdId };
export default XkcdBlock;