import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {ValidatorForm} from "react-material-ui-form-validator";
import {useHistory, useLocation} from "react-router-dom";

import Button from "../../Components/Buttons/Button";
import TextField from "../../Components/FormComponents/Fields/TextField";
import Wrapper from "../../Components/FormComponents/Wrapper";
import {useLoginJWTMutation} from "../../Store/services/MySU/auth";

const useStyles = makeStyles(theme => ({
    login: {
        width: "100%"
    },
    rightSide: {
        backgroundColor: theme.palette.background.dark
    },
    gridRoot: {
        height: "calc(100vh - 102px)"
        // height: "853px"
    }
}));

const title = "Welcome to My SU";
const body =
    <>
    Welcome to the MySU member administration system. Log in to join a wide range of +
    student associations, or, if you’re a board, to see information about your members.
        <br/><br/>
    Currently, the system is in a pilot phase. This means that are still bugs. If you find a bug
    please mail it to help@su.utwente.nl or make an issue at git.snt.utwente.nl/sun.
    </>;

const Login = () => {
    const location = useLocation();
    const theme = useTheme();
    const classes = useStyles(theme);

    const history = useHistory();

    const [ login ] = useLoginJWTMutation();

    const referer = location.state && location.state.referer;

    const handleSubmit = async (event) => {
        try {
            await login({
                username: event.target.username.value,
                password: event.target.password.value,
            }).unwrap();
            history.push(referer ? referer.pathname : "/protected/associations/overview");
        } catch (error) {
            console.error("Failed to login", error);
        }
        // API.login("jwt", {
        //     username: event.target.username.value,
        //     password: event.target.password.value,
        //     referer: referer
        // });
    };

    return (
        <Grid container direction={"row"} spacing={3} justifyContent={"space-between"} className={classes.gridRoot}>
            <Grid item xs={6}>
                <Typography align={"center"} variant={"h2"} sx={{ marginTop: 19}}>
                    Welcome to MySU
                </Typography>
                <Typography align={"center"} variant={"h4"} sx={{ marginTop: 2}}>
                    The platform to manage your associations
                </Typography>
                <Typography align={"center"} variant={"body1"} sx={{ margin: "auto", marginTop: 8, maxWidth: "420px"}}>
                    The system is still in a pilot phase. If you find a bug, please mail it to support@su.utwente.nl
                    or make an issue at https://gitlab.utwente.nl/my-su/frontend/website.
                </Typography>
            </Grid>
            <Grid item xs={6} className={classes.rightSide}>
                <Typography align={"center"} variant={"h4"} sx={{ marginTop: 19, color: "primary.light" }}>
                    Login
                </Typography>
                <Box sx={{ maxWidth: "400px", margin: "auto", marginTop: 8 }}>
                    <Button variant={"outlined"} color={"secondary"} className={classes.login}
                        href={process.env.REACT_APP_API_URL + "/oidc/authenticate/?next=" + process.env.REACT_APP_SERVER_URL + "/oidc"}
                    >
                        Login with University Account
                    </Button>
                    { process.env.REACT_APP_PRODUCTION_ENV === "true" ||
                        <>
                            <Divider variant={"middle"} light={true}
                                sx={{
                                    marginTop: 4,
                                    "&::before, &::after": {
                                        borderColor: "grey.400",
                                    },
                                }}
                            >
                                <Typography sx={{ color: theme.palette.common.white }}> or </Typography>
                            </Divider>
                            <ValidatorForm
                                onSubmit={handleSubmit}
                                onError={errors => console.log(errors)}
                            >
                                <Wrapper>
                                    <TextField
                                        sx={{ color: "#fff" }}
                                        name={"username"}
                                        variant={"outlined"}
                                        placeholder={"Username or Email Address"}
                                        required
                                    />
                                </Wrapper>
                                <Wrapper>
                                    <TextField
                                        type={"password"}
                                        name={"password"}
                                        placeholder={"Password"}
                                        required
                                    />
                                </Wrapper>
                                <Button color={"primary"} variant={"outlined"} type={"submit"} className={classes.login}>Login</Button>
                            </ValidatorForm>
                        </>
                    }
                </Box>
            </Grid>
        </Grid>
    );
};

Login.propTypes = {
};

export default Login;

