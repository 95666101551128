import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import React from "react";


const Burger = ({ open, onClick }) => {
    return (
        <IconButton color={"inherit"} onClick={onClick} edge={"start"} size={"large"}>
            { open ? <MenuOpenIcon fontSize={"large"}/> : <MenuIcon fontSize={"large"}/> }
        </IconButton>
    );
};

Burger.propTypes = {
    open: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};

export default Burger;