import { emptySplitApi } from "./index";

const authApi = emptySplitApi
    .injectEndpoints({
        endpoints: (build) => ({
            loginJWT: build.mutation({
                query: (credentials) => ({ url: "/auth/token/obtain/", method: "POST", body: credentials }),
                invalidatesTags: [{ type: "User", id: "Me" }],
            })
        }),
        overrideExisting: false
    });

export const {
    useLoginJWTMutation
} = authApi;
export default authApi;