import PropTypes from "prop-types";
import React, {useState} from "react";
import { useLocation } from "react-router-dom";

import {useMemberships} from "../../../Store/services/MySU/user";
import AssociationsNavDropdown from "./Dropdown";
import AssociationsNavTabs from "./NavTabs";

const Header = ({ size }) => {
    const [imageWidths, setImageWidths] = useState([]);
    const { pathname } = useLocation();
    const association_memberships = useMemberships();
    const url = "/protected";

    // const calculateImageWidths = () => {
    //     const membership_count = association_memberships.length;
    //     const image_count = imageWidths.length;
    //     const associations_without_logo_count = membership_count - image_count;
    //     return sum(imageWidths)+membership_count*30+associations_without_logo_count*100;
    // };

    const calculateTotalNavBar = () => {
        //the total sum of all the margins and icons
        // return calculateImageWidths()+65;
        return ((association_memberships?.length || 0) + 2)*160+100;
    };

    const onImgLoad = ({target:img}, id) => {
        if (imageWidths[id] === undefined) {
            setImageWidths(prevState => {
                prevState[id] = img.offsetWidth;
                return prevState;
            });
        }
    };

    return (
        <div className={"navigation"}>
            { size.width > calculateTotalNavBar()
                ? (
                    <AssociationsNavTabs
                        association_memberships={association_memberships || []}
                        url={url}
                        path={pathname}
                        onImgLoad={onImgLoad}
                    />)
                : (
                    <AssociationsNavDropdown
                        association_memberships={association_memberships || []}
                        path={pathname}
                        url={url}
                    />)
            }
        </div>
    );
};

Header.propTypes = {
    size: PropTypes.shape({width: PropTypes.number, height: PropTypes.number}).isRequired
};

export default Header;
