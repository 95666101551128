import {CircularProgress} from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import {useParams} from "react-router-dom";
import {Cell, Pie, PieChart, ResponsiveContainer} from "recharts";

import Block from "../../Components/PageLayout/Content/Block";
import {useGetCurrentMembersByAssociationQuery} from "../../Store/services/MySU/members";
import {useGetStudiesQuery} from "../../Store/services/MySU/studies";


const random_hex_color_code = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return "#" + n.slice(0, 6);
};

const COLOUR_LIST = Array.from({length: 200}, () => random_hex_color_code());

const test = [
    { name: "network 1", value: 2, colour: random_hex_color_code() },
    { name: "network 3", value: 4, colour: random_hex_color_code() },
    { name: "Bachelor", value: 23, colour: random_hex_color_code() }
];

const MemoChart = React.memo(({ data, label })=> {
    return (
        <ResponsiveContainer width={"100%"} height={300}>
            <PieChart height={300}>
                <Pie
                    data={data}
                    cx={"50%"}
                    cy={"50%"}
                    outerRadius={100}
                    isAnimationActive={false}
                    fill={"#8884d8"}
                    dataKey={"value"}
                    label={({
                        cx,
                        cy,
                        midAngle,
                        innerRadius,
                        outerRadius,
                        value,
                        index
                    }) => {
                        const RADIAN = Math.PI / 180;
                        // eslint-disable-next-line
                        const radius = 25 + innerRadius + (outerRadius - innerRadius);
                        // eslint-disable-next-line
                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                        // eslint-disable-next-line
                        const y = cy + radius * Math.sin(-midAngle * RADIAN);

                        return (
                            <text
                                x={x}
                                y={y}
                                fill={"#8884d8"}
                                textAnchor={x > cx ? "start" : "end"}
                                dominantBaseline={"central"}
                            >
                                { data[index] ? data[index].name : "" } ({ value })
                            </text>
                        );
                    }}
                >
                    {
                        data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLOUR_LIST[index]}/>
                        ))
                    }
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
});

const StudyPieChart = () => {
    const { slug } = useParams();
    const { data: members, isLoading: isLoadingMembers } = useGetCurrentMembersByAssociationQuery(slug);
    const { data: studies, isLoading: isLoadingStudies } = useGetStudiesQuery();

    if (isLoadingMembers || isLoadingStudies) {
        return <CircularProgress/>;
    }

    const studyUrlToStudyNameDict = Object.fromEntries(studies?.map(study=>[study.url, study.name]) || []);
    const numberOfMembersPerStudy = members?.reduce((perStudyObject, member) => {
        const study = member.profile.study;
        perStudyObject[study] = perStudyObject[study] + 1;
        return perStudyObject;
    }, Object.fromEntries(studies?.map(study=>[study.url, 0])) || {});

    const pieChartData = Object
        .entries(numberOfMembersPerStudy)
        .map(([studyUrl, amount])=>({ name: studyUrlToStudyNameDict[studyUrl], value: amount, colour: random_hex_color_code() }))
        .filter(entry => entry.value > 0);

    return (
        <MemoChart data={pieChartData}/>
    );
};

const PHASES = ["Bachelor", "Premaster", "Master", "PhD", "Other"];

const PhasePieChart = () => {
    const { slug } = useParams();
    const { data: members, isLoading } = useGetCurrentMembersByAssociationQuery(slug);

    if (isLoading) {
        return <CircularProgress/>;
    }

    const numberOfMembersPerPhase = members?.reduce((perPhaseObject, member) => {
        const study = member.profile.phase;
        perPhaseObject[study] = perPhaseObject[study] + 1;
        return perPhaseObject;
    }, Object.fromEntries(PHASES.map(phase=>[phase, 0])));

    const pieChartData = Object
        .entries(numberOfMembersPerPhase)
        .map(([phase, amount])=>({ name: phase, value: amount, colour: random_hex_color_code() }))
        .filter(entry => entry.value > 0);

    return (
        <MemoChart data={pieChartData}/>
    );
};



const MemberStatistics = () => {
    return (
        <Container>
            <Block>
                <Typography variant={"h5"}>Studies</Typography>
                <StudyPieChart/>
            </Block>
            <Block>
                <Typography variant={"h5"}>Phases</Typography>
                <PhasePieChart/>
            </Block>
        </Container>
    );
};

export default MemberStatistics;