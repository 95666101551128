import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import {orderBy} from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {ValidatorForm} from "react-material-ui-form-validator";
import {useParams} from "react-router-dom";

import Sushi from "../../../img/Sushi-11-me.jpg";
import Button from "../../Components/Buttons/Button";
import Card from "../../Components/Card/Card";
import CardGrid from "../../Components/Card/CardGrid";
import TextField from "../../Components/FormComponents/Fields/TextField";
import Wrapper from "../../Components/FormComponents/Wrapper";
import Block from "../../Components/PageLayout/Content/Block";
import RoutingButton from "../../Components/PageLayout/Content/RoutingButton";
import TopPageBlock from "../../Components/PageLayout/Content/TopPageBlock";
import {useAddNewsMutation, useDeleteNewsMutation, useGetNewsByAssociationQuery} from "../../Store/services/MySU/news";

const useStyles = makeStyles((theme) => ({
    progress: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));

const News = () => {
    const classes = useStyles();

    const { slug } = useParams();
    const { data: news, isLoading } = useGetNewsByAssociationQuery(slug);

    const [ addNews ] = useAddNewsMutation();
    const [ deleteNews ] = useDeleteNewsMutation();

    const handleSubmit = (event) => {
        const data = new FormData(event.target);
        addNews({
            title: data.get("title"),
            text: data.get("text"),
            association: `/associations/${slug}`
        });
    };

    if (isLoading) {
        return (
            <LinearProgress className={classes.progress}/>
        );
    }

    const newsToCard = (newsItem) => ({
        name: newsItem.short_name || newsItem.full_name,
        description: "",
        photo: newsItem.photo || Sushi,
        onClick: () => history.push("news/" + newsItem.slug)
    });

    const cards = orderBy(news || [], ["full_name"], ["asc"]).map(newsItem=>newsToCard(newsItem));

    return (
        <Container maxWidth={"xl"}>
            <RoutingButton/>
            <TopPageBlock>
                <Grid container justifyContent={"space-between"}>
                    <Grid item>
                        <Typography variant={"h6"}>
                            News
                        </Typography>
                    </Grid>
                    <Grid>
                        <Button variant={"contained"} onClick={()=>history.push("news/add")}>
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </TopPageBlock>
            <CardGrid
                componentProps={cards}
                Component={Card}
            />
        </Container>
    );
};

export default News;