import PropTypes from "prop-types";
import React from "react";
import { Redirect } from "react-router-dom";

const RequireBoard = ({ children, isBoard, redirectTo }) => {
    return isBoard ? children : <Redirect to={redirectTo}/>;
};

RequireBoard.propTypes = {
    children: PropTypes.node.isRequired,
    redirectTo: PropTypes.string.isRequired,
    isBoard: PropTypes.bool.isRequired,
};

export default RequireBoard;