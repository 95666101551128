import "@icon/open-iconic/open-iconic.css";
import "material-react-toastify/dist/ReactToastify.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faDiscord, faSnapchat } from "@fortawesome/free-brands-svg-icons";
import {
    faAt,
    faBars,
    faCheck,
    faChevronLeft, faCookieBite, faCubes, faExchangeAlt, faHashtag,
    faHome,
    faIgloo, faImage, faNewspaper,
    faPlus, faShoppingCart,
    faSignOutAlt, faSocks,
    faTimes,
    faUser,
    faWarehouse
} from "@fortawesome/free-solid-svg-icons";
import CssBaseline from "@mui/material/CssBaseline";
import {ToastContainer} from "material-react-toastify";
import { parse, stringify } from "query-string";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

import Api from "./Contexts/API";
import LocaleProvider from "./Contexts/Locale";
import MySUThemeProvider from "./Contexts/Theme";
import Routing from "./Routing/Routing";
import store from "./Store/store";

library.add(faSignOutAlt, faUser, faIgloo, faBars, faCheck, faTimes, faHome,
    faPlus, faChevronLeft, faWarehouse, faShoppingCart, faSocks, faExchangeAlt,
    faNewspaper, faCubes, faImage, faCookieBite, faAt, faSnapchat, faDiscord, faHashtag);


const App = () => {
    return (
        <React.Fragment>
            <ToastContainer
                enableMultiContainer
                containerId={"bottom"}
                position={"bottom-center"}
                autoClose={4000}
                limit={3}
                hideProgressBar={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                pauseOnHover={false}
                draggable
                newestOnTop
            />
            <Provider store={store}>
                <Router>
                    <QueryParamProvider
                        adapter={ReactRouter5Adapter}
                        options={{
                            searchStringToObject: parse,
                            objectToSearchString: stringify,
                        }}
                    >
                        <Api>
                            <MySUThemeProvider>
                                <CssBaseline/>
                                <LocaleProvider>
                                    <Routing/>
                                </LocaleProvider>
                            </MySUThemeProvider>
                        </Api>
                    </QueryParamProvider>
                </Router>
            </Provider>
        </React.Fragment>
    );
};

export default App;