import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import {ValidatorForm} from "react-material-ui-form-validator";
import {useParams} from "react-router-dom";

import Button from "../../Components/Buttons/Button";
import Wrapper from "../../Components/FormComponents/Wrapper";
import Block from "../../Components/PageLayout/Content/Block";
import {useGetModulesByAssociationQuery, usePatchModulesMutation} from "../../Store/services/MySU/modules";

const moduleFields = [
    { name: "disputed_members", label: "Disputed members"},
    { name: "events", label: "Calendar and events"},
    { name: "matchings", label: "Matchings"},
    { name: "committees", label: "Committees"},
    { name: "news", label: "News"},
    { name: "partners", label: "Partners"},
    { name: "emails", label: "Emails"},
    { name: "inventory", label: "Inventory"},
    { name: "statistics", label: "Statistics"},
    { name: "documents", label: "Documents"},
];

const Modules = () => {
    const { slug } = useParams();

    const { data: modules } = useGetModulesByAssociationQuery(slug);
    const [ patchModules ] = usePatchModulesMutation();
    const [ moduleChanges, setModuleChanges ] = useState({});
    const modulesWithChanges = {...modules, ...moduleChanges};

    console.log(modulesWithChanges);

    const handleSubmit = () => {
        patchModules(modulesWithChanges);
    };

    const handleChange = (event) => {
        setModuleChanges(prevState => ( { ...prevState, [event.target.name]: event.target.checked }));
        // if (event.target.checked) {
        //     setModuleChanges(prevState => [...prevState, event.target.name]);
        // } else {
        //     setModuleChanges(prevState => prevState.filter(module => module !== event.target.name));
        // }
    };
    return (
        <Container>
            <Block>
                <Typography variant={"h5"}>Modules</Typography>
                <hr className={"box-title-separator"}/>
                <ValidatorForm
                    onSubmit={handleSubmit}
                    onError={errors => console.log(errors)}
                    debounceTime={1000}
                >
                    <Box>
                        <FormControl sx={{ m: 3 }} component={"fieldset"} variant={"standard"}>
                            <FormLabel component={"legend"}>Pick which modules to enable</FormLabel>
                            { moduleFields.map((moduleField, id)=> (
                                <FormGroup key={id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                label={moduleField.label}
                                                name={moduleField.name}
                                                checked={modulesWithChanges[moduleField.name]}
                                                onChange={handleChange}
                                            />
                                        }
                                        label={moduleField.label}
                                    />
                                </FormGroup>
                            )) }
                        </FormControl>
                    </Box>
                    <Wrapper>
                        <div/>
                        <Button type={"submit"} variant={"contained"} color={"primary"}>
                            Save
                        </Button>
                    </Wrapper>
                </ValidatorForm>
            </Block>
        </Container>
    );
};

export default Modules;