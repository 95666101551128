import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import EuroIcon from "@mui/icons-material/Euro";
import EventIcon from "@mui/icons-material/Event";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SettingsIcon from "@mui/icons-material/Settings";
import ShareIcon from "@mui/icons-material/Share";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import React, {useMemo} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {useGetModulesByAssociationQuery} from "../../../Store/services/MySU/modules";
import Drawer from "../../Lists/Drawer/Drawer";


const AssociationBoardSideBar = () => {
    const history = useHistory();
    const location = useLocation();
    let url = location.pathname;
    const slug = url.split("/")[3];
    url = url.substring(0, url.indexOf("boardmember") + 11);

    const { data: modules } = useGetModulesByAssociationQuery(slug);

    const data = useMemo(()=>{
        let data = [
            {
                // icon: <FontAwesomeIcon className={"fa-lg"} icon={["fas", "newspaper"]}/>,
                icon: <PlayArrowIcon />,
                primary: "Start",
                to: url
            },
            {
                icon: <GroupsIcon />,
                primary: "Association",
                items: [
                    {
                        // icon: <InfoIcon />,
                        primary: "About",
                        to: url + "/association/info"
                    },
                    {
                        // icon: <SupervisedUserCircleIcon />,
                        primary: "Committees",
                        to: url + "/committees"
                    },
                    {
                        // icon: <GradeIcon />,
                        primary: "Boards",
                        to: url + "/boards"
                    },
                ]
            },
            {
                icon: <PersonIcon />,
                primary: "Members",
                to: url + "/members/all",
            },
            {
                icon: <EuroIcon />,
                primary: "Financial",
                to: url + "/financial/finances",
            },
        ];
        if (modules) {
            if (modules["events"]) {
                data.push(
                    {
                        icon: <EventIcon />,
                        primary: "Events",
                        items: [
                            {
                                // icon: <DateRangeIcon />,
                                primary: "Calendar",
                                to: url + "/events/calendar"
                            },
                            {
                                // icon: <DateRangeIcon />,
                                primary: "Events",
                                to: url + "/events"
                            },
                            {
                                // icon: <SubjectIcon />,
                                primary: "Event Types",
                                to: url + "/events/types"
                            },
                        ]
                    },
                );
            }
            if (modules["inventory"]) {
                data.push(
                    {
                        icon: <FontAwesomeIcon className={"fa-lg"} icon={["fas", "warehouse"]}/>,
                        primary: "Inventory",
                        items: [
                            {
                                // icon: <FontAwesomeIcon className={"fa-lg"} icon={["fas", "socks"]}/>,
                                primary: "Products",
                                to: url + "/inventory/products"
                            },
                            {
                                // icon: <CategoryIcon/>,
                                primary: "Product Categories",
                                to: url + "/inventory/categories"
                            },
                            {
                                // icon: <FontAwesomeIcon className={"fa-lg"} icon={["fas", "shopping-cart"]}/>,
                                primary: "Purchases",
                                to: url + "/inventory/purchases"
                            },
                        ]
                    },
                );
            }
            if (modules["emails"]) {
                data.push(
                    {
                        icon: <EmailIcon />,
                        primary: "Emails",
                        items: [
                            {
                                // icon: <SettingsIcon />,
                                primary: "Settings",
                                to: url + "/emails/settings"
                            },
                            {
                                // icon: <FeaturedVideoIcon />,
                                primary: "Templates",
                                to: url + "/emails/templates"
                            },
                        //     icon: <PersonIcon />,
                        //     primary: "Users",
                        //     to: url + "/emails/users"
                        // },
                        ]
                    },
                );
            }
            if (modules["statistics"]) {
                data.push(
                    {
                        icon: <EqualizerIcon />,
                        primary: "Statistics",
                        items: [
                            {
                                // icon: <PeopleRoundedIcon />,
                                primary: "Members",
                                to: url + "/statistics/members"
                            },
                        ]
                    },
                );
            }
            if (modules["matchings"]) {
                data.push(
                    {
                        icon: <ShareIcon />,
                        primary: "Matchings",
                        to: url + "/matchings"
                    },
                );
            }
            if (modules["documents"]) {
                data.push(
                    {
                        icon: <DescriptionIcon />,
                        primary: "Documents",
                        to: url + "/documents"
                    },
                );
            }
            if (modules["news"]) {
                data[1].items.push(
                    {
                        // icon: <FontAwesomeIcon className={"fa-lg"} icon={["fas", "newspaper"]}/>,
                        primary: "News",
                        to: url + "/association/news"
                    },
                );
            }
            if (modules["partners"]) {
                data[1].items.push(
                    {
                        // icon: <BusinessIcon />,
                        primary: "Partners",
                        to: url + "/association/partners"
                    },
                );
            }
        }
        // data.push(
        //     {
        //         icon: <StorageIcon />,
        //         primary: "Lab",
        //         to: url + "/lab"
        //     }
        // );
        return data;
    }, [url, modules]);
    return (
        <React.Fragment>
            <Box sx={{ maxHeight: "853px"}}>
                <Drawer items={data}/>
            </Box>
            <Box sx={{ position: "absolute", bottom: "5px", right: "5px" }}>
                { process.env.REACT_APP_PRODUCTION_ENV === "false" &&
                    <IconButton onClick={() => history.push(url + "/settings/modules")}>
                        <SettingsIcon sx={{color: "#fff"}}/>
                    </IconButton>
                }
            </Box>
        </React.Fragment>
    );
};

export default AssociationBoardSideBar;