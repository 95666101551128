import {TabContext, TabPanel} from "@mui/lab";
import Tab from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import cx from "clsx";
import PropTypes from "prop-types";
import React, {useState} from "react";

const useStyles = makeStyles(theme => ({
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3)
    },
    profileBlock: {
        width: "100%"
    },
    growingGridItem: {
        flexGrow: 1
    },
    tab: {
        backgroundColor: "white",
        borderRadius: "16px 16px 0px 0px",
        padding: "0px",
        width: "200px",
        height: "48px",
        marginBottom: theme.spacing(0.5)
    },
    rightTab: {
        width: "280px",
        marginLeft: "auto"
    },
    tabPanel: {
        backgroundColor: "white",
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    },
    rightTabPanel: {
        padding: "0px",
    },
    selected: {
        backgroundColor: "white!important",
        color: theme.palette.main
    },
    flexContainer: {
        gap: theme.spacing(1),
    },
    indicator: {
        backgroundColor: "white",
        height: "8px",
    },
    tabDivider: {
        display: "flex",
        justifyContent: "space-between"
    },
    routing: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    }
}));

const Tabs = ({ tabElements }) => {
    const classes = useStyles();

    const [tab, setTab] = useState(tabElements[0].value);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <TabContext value={tab}>
            <MuiTabs value={tab} onChange={handleTabChange} indicatorColor={"secondary"}
                classes={{
                    flexContainer: classes.flexContainer,
                    indicator: classes.indicator
                }}
            >
                { tabElements.map((tabElement, id) => (
                    <Tab key={id}
                        icon={tabElement.Icon}
                        iconPosition={"start"}
                        className={tabElement.tabClass ? cx(classes.tab, tabElement.tabClass) : classes.tab}
                        classes={{ selected: classes.selected }}
                        label={<Typography variant={"h5"} sx={{ ...(tab !== tabElement.value && { color:  "gray" })}}>{ tabElement.label }</Typography>}
                        value={tabElement.value}
                    />
                )) }
            </MuiTabs>
            { tabElements.map((tabElement, id) => (
                <TabPanel key={id}
                    value={tabElement.value}
                    sx={{ borderBottomRightRadius: 16, borderBottomLeftRadius: 16}}
                    className={tabElement.tabPanelClass ? cx(classes.tabPanel, tabElement.tabPanelClass) : classes.tabPanel}
                >
                    { tabElement.Panel }
                </TabPanel>
            )) }
        </TabContext>
    );
};

Tabs.propTypes = {
    tabElements: PropTypes.arrayOf(PropTypes.object).isRequired
};
export default Tabs;
