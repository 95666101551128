import {emptySplitApi, pessimisticCreation, pessimisticRemoval, pessimisticUpdateSingleInList} from "./index";

const debtCollectionsApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["DebtCollection"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getDebtCollectionsByAssociation: build.query({
                query: (associationSlug) => ({ url: "/debt_collections", params: { association__slug: associationSlug } }),
                providesTags: (result, error, associationSlug) => [{ type: "DebtCollection", id: associationSlug }],
                transformResponse: (response) => response.results
            }),
            getDebtCollectionDetail: build.query({
                query: (debtCollectionSlug) => ({ url: `/debt_collections/${debtCollectionSlug}/detail` }),
                providesTags: (result, error, debtCollectionSlug) => [{ type: "DebtCollection", id: debtCollectionSlug }],
            }),
            addDebtCollection: build.mutation({
                query: (debtCollection) => ({ url: "/debt_collections", method: "POST", body: debtCollection }),
                invalidatesTags: (result, error, arg) => [{ type: "Invoice"}, { type: "Member"}, { type: "Event", id: arg.association.split("/associations/")[1] }],
                async onQueryStarted(debtCollection, {dispatch, queryFulfilled}) {
                    await pessimisticCreation({
                        dispatch,
                        queryFulfilled,
                        api: debtCollectionsApi,
                        query: "getDebtCollectionsByAssociation",
                        entityToQueryParam: (patchedDebtCollection) => patchedDebtCollection.association.split("/associations/")[1],
                    });
                }
            }),
            patchDebtCollection: build.mutation({
                query: ({ slug, ...patch}) => ({ url: `/debt_collections/${slug}`, method: "PATCH", body: patch }),
                invalidatesTags: () => [{ type: "Member"}],
                async onQueryStarted(debtCollection, {dispatch, queryFulfilled}) {
                    await pessimisticUpdateSingleInList({
                        dispatch,
                        queryFulfilled,
                        api: debtCollectionsApi,
                        query: "getDebtCollectionsByAssociation",
                        entityToQueryParam: (patchedDebtCollection) => patchedDebtCollection.association.split("/associations/")[1],
                        entityFilter: patchedDebtCollection => patchedDebtCollection.slug === debtCollection.slug
                    });
                }
            }),
            deleteDebtCollection: build.mutation({
                query: ({ slug}) => ({ url: `/debt_collections/${slug}`, method: "DELETE" }),
                invalidatesTags: (result, error, arg) => [{ type: "Member"}, { type: "Event", id: arg.association.split("/associations/")[1] }],
                async onQueryStarted({ slug, association }, {dispatch, queryFulfilled}) {
                    await pessimisticRemoval({
                        dispatch,
                        queryFulfilled,
                        api: debtCollectionsApi,
                        query: "getDebtCollectionsByAssociation",
                        queryParam: association,
                        entityFilter: patchedDebtCollection => patchedDebtCollection.slug === slug
                    });
                }
            }),
        }),
        overrideExisting: false
    });

export const {
    useGetDebtCollectionsByAssociationQuery,
    useGetDebtCollectionDetailQuery,
    useAddDebtCollectionMutation,
    usePatchDebtCollectionMutation,
    useDeleteDebtCollectionMutation,
} = debtCollectionsApi;
export default debtCollectionsApi;