import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(theme => ({
    tableStriped: {
        backgroundColor: alpha(theme.palette.primary.main, 0.35),
    },
}));

/**
 * A list with an unfold option. When unfolded the component passed is shown.
 *
 * @param Component
 * @param listItemText
 * @param passThroughProps
 * @returns {JSX.Element}
 * @constructor
 */
const CollapsableListItem = ({ component: Component, listItemText, ...passThroughProps }) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleToggle = () => setOpen(!open);

    return (
        <React.Fragment>
            <ListItem button onClick={handleToggle} className={classes.tableStriped}>
                <ListItemText primary={listItemText} />
                { open ? <ExpandLess /> : <ExpandMore /> }
            </ListItem>
            <Collapse in={open} timeout={"auto"} unmountOnExit>
                <Component {...passThroughProps} handleToggle={handleToggle}/>
            </Collapse>
        </React.Fragment>
    );
};

CollapsableListItem.propTypes = {
    listItemText: PropTypes.string.isRequired,
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default CollapsableListItem;