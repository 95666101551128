import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";
import { useHistory } from "react-router-dom";

import ProfileInfoForm from "../../Forms/InfoForms/Profile/ProfileInfoForm";


const initialProfile = {
    given_name: "",
    initials: "",
    surname: "",
    gender: "",
    date_of_birth: null,
    phone_number: "",
    email: "",
    address: "",
    zip_code: "",
    city: "",
    country: "",
    phase: "",
    study: "",
    bank_account_name: "",
    iban: "",
    bic: "",
};


const NoProfileYet = () => {
    const history = useHistory();

    return (
        <Container maxWidth={"lg"}>
            <Box 
                sx={{
                    backgroundColor: "background.paper",
                    borderRadius: 4,
                    padding: 4,
                    mb: 2,
                    mt: 2
                }}
            >
                <Typography variant={"h5"}>Welcome to MySU</Typography>
                <hr className={"box-title-separator"}/>
                <Typography>
                    Whenever you become a member of an association, you will
                    share your profile. In order to do so you first need to
                    create a profile.
                    <br/><br/>
                    You can change your profile at any point.
                </Typography>
            </Box>
            <ProfileInfoForm
                initialInfoOrFormState={"form"}
                profile={initialProfile}
                showInfoFormStateButton={false}
                is_you={true}
                update={false}
                postSubmit={()=>history.push("/protected/associations/overview")}
            />
        </Container>
    );
};

export default NoProfileYet;