import {emptySplitApi, pessimisticCreation, pessimisticRemoval, pessimisticUpdateSingleInList} from "./index";

const dataFieldsApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["DataField"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getDataFieldsByAssociation: build.query({
                query: (association_slug) => ({ url: "/association_data_fields", params: {"association__slug": association_slug, limit: 100} }),
                providesTags: (result, error, association_slug) => [{ type: "DataField", id: association_slug }],
                transformResponse: (response) => response.results
            }),
            addDataField: build.mutation({
                query: (body) => ({ url: "/association_data_fields", method: "POST", body: body }),
                invalidatesTags: () => [{ type: "Member"}],
                async onQueryStarted(dataField, {dispatch, queryFulfilled}) {
                    await pessimisticCreation({
                        dispatch,
                        queryFulfilled,
                        api: dataFieldsApi,
                        query: "getDataFieldsByAssociation",
                        entityToQueryParam: (addedDataField) => addedDataField.association.split("/associations/")[1]
                    });
                }
            }),
            patchDataField: build.mutation({
                query: ({ slug, ...patch}) => ({ url: `/association_data_fields/${slug}`, method: "PATCH", body: patch }),
                invalidatesTags: () => [{ type: "Member"}],
                async onQueryStarted(dataField, {dispatch, queryFulfilled}) {
                    await pessimisticUpdateSingleInList({
                        dispatch,
                        queryFulfilled,
                        api: dataFieldsApi,
                        query: "getDataFieldsByAssociation",
                        entityToQueryParam: (patchedDataField) => patchedDataField.association.split("/associations/")[1],
                        entityFilter: draftDataField => draftDataField.slug === dataField.slug
                    });
                }
            }),
            deleteDataField: build.mutation({
                query: ({ slug }) => ({ url: `/association_data_fields/${slug}`, method: "DELETE" }),
                invalidatesTags: () => [{ type: "Member"}],
                async onQueryStarted(dataField, {dispatch, queryFulfilled}) {
                    await pessimisticRemoval({
                        dispatch,
                        queryFulfilled,
                        api: dataFieldsApi,
                        query: "getDataFieldsByAssociation",
                        queryParam: dataField.association.split("/associations/")[1],
                        entityFilter: draftDataField => draftDataField.slug === dataField.slug
                    });
                }
            })
        }),
        overrideExisting: false
    });

export const {
    useGetDataFieldsByAssociationQuery,
    useAddDataFieldMutation,
    usePatchDataFieldMutation,
    useDeleteDataFieldMutation
} = dataFieldsApi;
// export default dataFieldsApi;