import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import {TabContext, TabPanel} from "@mui/lab";
import {DialogActions} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {orderBy} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useState} from "react";

import Button from "../../../Components/Buttons/Button";
import withExtraProps from "../../../Components/HOC/withExtraProps";
import ExtremeTable from "../../../Components/Tables/ExtremeTable";
import DebtCollectionPopupForm from "../../../Forms/PopupForms/DebtCollection/DebtCollectionPopupForm";
import {
    useAddDebtCollectionMutation,
    useDeleteDebtCollectionMutation,
    useGetDebtCollectionsByAssociationQuery,
    usePatchDebtCollectionMutation
} from "../../../Store/services/MySU/debtCollections";
import DebtCollectionSummary from "./DebtCollectionSummary";
import DebtEntries from "./DebtEntries";

const headers = [
    {name: "amount", title: "Amount"},
    {name: "debt_collection_date", title: "Date"},
    {name: "locked", title: "Locked"},
];

const booleanColumns = ["locked"];
const currencyColumns = ["amount"];
const dateTimeColumns = ["debt_collection_date"];

const StyledTabs = styled((props) => <Tabs {...props}/>)(({ theme }) => ({
    "& .MuiTabs-indicator": {
        display: "none"
    }
}));

const StyledMuiTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    "&.Mui-selected": {
        backgroundColor: "unset"
    },
}));

const DebtCollectionsDetailModal = ({ open, row, onClose }) => {
    const [tab, setTab] = useState("General");

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth={"xl"}
            PaperProps={{
                sx: { borderRadius: 8 }
            }}
        >
            <DialogTitle onClose={onClose}>
                Details on the debt collection of { moment(row.date).format("LLLL") }
            </DialogTitle>
            <DialogContent>
                <TabContext value={tab}>
                    <StyledTabs value={tab} onChange={handleTabChange}>
                        <StyledMuiTab
                            icon={<GroupsIcon/>}
                            iconPosition={"start"}
                            label={"General details"}
                            value={"General"}
                        />
                        <StyledMuiTab
                            icon={<PersonIcon/>}
                            iconPosition={"start"}
                            label={"Individual details"}
                            value={"Individual"}
                        />
                    </StyledTabs>
                    <TabPanel
                        value={"General"}
                    >
                        { row.slug && <DebtCollectionSummary debtCollection={row}/> }
                    </TabPanel>
                    <TabPanel
                        value={"Individual"}
                    >
                        { row.slug && <DebtEntries debtCollectionSlug={row.slug}/> }
                    </TabPanel>
                </TabContext>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus variant={"contained"} disableElevation>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};


const DebtCollectionsList = ({ association }) => {
    const { data: debtCollections } = useGetDebtCollectionsByAssociationQuery(association.slug);

    const [ addDebtCollection ] = useAddDebtCollectionMutation();
    const [ editDebtCollection ] = usePatchDebtCollectionMutation();
    const [ deleteDebtCollection ] = useDeleteDebtCollectionMutation();

    const [selectedDebtCollection, setSelectedDebtCollection] = useState({});

    const onAdd = (newInvoice) => addDebtCollection({
        ...newInvoice,
        association: association.url,
    });
    const onEditOrDelete = (differences, originalDebtCollection, editedOrRemovedDebtCollection) => {
        if (editedOrRemovedDebtCollection.remove) {
            deleteDebtCollection({ slug: editedOrRemovedDebtCollection.slug, association: association.slug });
        } else {
            editDebtCollection(editedOrRemovedDebtCollection);
        }
    };
    const onDetail = (slug, row) => setSelectedDebtCollection(row);

    return (
        <Paper elevation={0}>
            <DebtCollectionsDetailModal
                open={Boolean(selectedDebtCollection.slug)}
                row={selectedDebtCollection}
                onClose={()=>setSelectedDebtCollection({})}
            />
            <ExtremeTable
                rows={orderBy(debtCollections || [], ["debt_collection_date"], ["desc"])}
                headers={headers}
                booleanColumns={booleanColumns}
                currencyColumns={currencyColumns}
                dateTimeColumns={dateTimeColumns}
                showSelect={false}
                showSelectAll={false}
                showEditing={true}
                showGrouping={false}
                showSearch={false}
                showColumnChooser={false}
                allowAdd={true}
                allowEdit={true}
                allowDelete={true}
                messages={{
                    addCommand: "New",
                    editCommand: "Edit",
                    deleteCommand: "Detail",
                }}
                onAdd={onAdd}
                onEdit={onEditOrDelete}
                onDelete={onDetail}
                editThroughPopup={true}
                Popup={withExtraProps(DebtCollectionPopupForm, { association: association })}
                width={250}
                title={"Debt collections"}
            />
        </Paper>
    );
};

DebtCollectionsList.propTypes = {
    association: PropTypes.object.isRequired,
};

export default DebtCollectionsList;

