import {IntegratedSummary, SummaryState} from "@devexpress/dx-react-grid";
import {Grid, Table, TableHeaderRow, TableSummaryRow} from "@devexpress/dx-react-grid-material-ui";
import AppleIcon from "@mui/icons-material/Apple";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import RefreshIcon from "@mui/icons-material/Refresh";
import {AppBar} from "@mui/material";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import {groupBy} from "lodash";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import {useTimer} from "react-timer-hook";

import Button from "../../../../Components/Buttons/Button";
import CardGrid from "../../../../Components/Card/CardGrid";
import { ProductCardV2 } from "../../../../Components/Card/ProductCard";
import useModalState from "../../../../Components/Hooks/useModalState";
import ConfirmationModal from "../../../../Components/Modals/ConfirmationModal";
import {addProductToCart, clearUser,emptyCart} from "../../../../Store/slices/terminal";
import TabPanel from "./TabPanel";

const useStyles = makeStyles(theme=>({
    tabPanelHolder: {
        paddingTop: theme.spacing(10)
    },
    tabPanel: {
        minHeight: "90vh"
    },
    tabs: {
        width: "63%"
    },
    appBar:  {
        flexDirection: "row"
    },
    backSpan: {
        backgroundColor: "brown",
        height: "100%",
        width: "100%",
        position: "absolute",
        float: "left",
        opacity: 0.3
    },
    nameTab: {
        maxWidth: 100
    },
    backButtonDiv: {
        margin: "auto",
        marginRight: theme.spacing(2)
    },
    nameSpan: {
        width: 200,
        height: 50,
        textAlign: "center",
        marginTop: "auto"
    },
    backButton: {
        width: 200,
        height: 50,
        textAlign: "center",
    }
}));

const headers = [
    {name: "title", title: "Product"},
    {name: "cost", title: "Unit Cost"},
    {name: "number", title: "Number"},
    {name: "amount", title: "Amount"},
];
const totalItems = [
    { columnName: "amount", type: "max" },
    { columnName: "amount", type: "sum" },
    { columnName: "number", type: "sum" },
];
const getRowId = row => row.slug;

const products = [
    {
        "title": "banana",
        "cost": "0.05",
        productCategory: "Fruit"
    },
    {
        "title": "bueno",
        "cost": "0.70",
        productCategory: "chocolates"
    },
    {
        "title": "ice tea",
        "cost": "0.07",
        productCategory: "Drinks"
    },
    {
        "title": "chocolate",
        "cost": "0.79",
        productCategory: "chocolates"
    },
    {
        "title": "flower",
        "cost": "0.26",
        productCategory: "Fruit"
    },
    {
        "title": "mars",
        "cost": "0.30",
        productCategory: "chocolates"
    },
    {
        "title": "grape",
        "cost": "0.80",
        productCategory: "Fruit"

    },
    {
        "title": "Sprite",
        "cost": "0.38",
        productCategory: "Drinks"
    },
    {
        "title": "apple",
        "cost": "0.44",
        productCategory: "Fruit"
    },
    {
        "title": "pear",
        "cost": "0.91",
        productCategory: "Fruit"
    },
    {
        "title": "mango",
        "cost": "0.19",
        productCategory: "Fruit"
    },
    {
        "title": "kitkat",
        "cost": "0.51",
        productCategory: "Chocolates"
    },
    {
        "title": "chocolate milk",
        "cost": "0.06",
        productCategory: "Drinks"
    },
    {
        "title": "Coke",
        "cost": "4.80",
        productCategory: "Drinks"
    },
];

const timeDelta = 300;

const getTimePlusTimeDelta = () => {
    const newTime = new Date();
    newTime.setSeconds(newTime.getSeconds() + timeDelta);
    return newTime;
};

const timeToWidth = (seconds) => 100 / timeDelta * seconds + "%";

const ProductList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector((state)=>state.terminal.user);
    const shoppingCart = useSelector((state)=>state.terminal.cart);

    const emptyShoppingCart = () => dispatch(emptyCart());
    const addProductToShoppingCart = (product) => dispatch(addProductToCart(product));

    // const { data: productCategories } = useGetProductCategoriesByAssociation();

    const productCategories = [
        { slug: "Fruit", icon: AppleIcon, label: "Fruit" },
        { slug: "Chocolates", icon: FavoriteIcon, label: "Chocolates" },
        { slug: "Drinks", icon: PersonPinIcon, label: "Drinks" },
    ];

    const goBack = () => {
        dispatch(clearUser());
        emptyShoppingCart();
        history.push("../terminal");
    };

    const [tab, setTab] = useState(1);
    const [openModal, toggleOpenModal] = useModalState(false);

    const { seconds, isRunning, restart, pause } = useTimer({ expiryTimestamp: getTimePlusTimeDelta(), onExpire: goBack });

    const handleReview = () => {
        toggleOpenModal();
        pause();
    };
    const continueAfterReview = () => {
        toggleOpenModal();
        restart(getTimePlusTimeDelta());
    };
    const clearAfterReview = () => {
        toggleOpenModal();
        emptyShoppingCart();
        restart(getTimePlusTimeDelta());
    };
    const buyAfterReview = () => {
        toggleOpenModal();
        // Todo: actually sent the API call for purchase
        goBack();
    };
    const buy = () => {
        // Todo: actually sent the API call for purchase
        goBack();
    };
    const handleRefreshTimer = () => restart(getTimePlusTimeDelta());

    const cardProducts = products.map(product=>{
        return {
            ...product,
            onClick: () => {
                addProductToShoppingCart(product);
                handleRefreshTimer();
            }
        };
    });
    const cardProductByProductCategories = groupBy(cardProducts, "productCategory");

    const badgeContent = shoppingCart
        .reduce((cumSum, product) => cumSum + parseFloat(product["cost"])*parseFloat(product["number"]), 0)
        .toLocaleString("en-GB", { style: "currency", currency: "EUR" });

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    // console.log(products, cardProducts, cardProductByProductCategories);
    return <>
        <AppBar
            position={"fixed"}
            color={"primary"}
            className={classes.appBar}
        >
            <Typography className={classes.nameSpan}>
                { user.name }
            </Typography>
            <Tabs
                value={tab}
                className={classes.tabs}
                onChange={handleTabChange}
                variant={"scrollable"}
                scrollButtons={"auto"}
                aria-label={"icon tabs example"}
            >
                { productCategories?.map(productCategory=> (
                    <Tab
                        icon={<productCategory.icon/>}
                        aria-label={productCategory.label}
                        label={productCategory.label}
                    />
                )) }
            </Tabs>
            <div
                className={classes.backButtonDiv}
            >
                <IconButton
                    color={"inherit"}
                    onClick={handleRefreshTimer}
                    edge={"start"}
                    size={"large"}
                >
                    <RefreshIcon fontSize={"large"}/>
                </IconButton>
                &nbsp;
                <Badge
                    badgeContent={badgeContent}
                    color={"secondary"}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <Button
                        variant={"contained"}
                        onClick={handleReview}
                        className={classes.backButton}
                        disabled={shoppingCart.length === 0}
                    >
                        Review
                    </Button>
                </Badge>
                &nbsp;
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={shoppingCart.length > 0 ? goBack : buy}
                    className={classes.backButton}
                >
                    <span className={classes.backSpan} style={{ width: timeToWidth(seconds) }}>
                        { isRunning && seconds }
                    </span>
                    <span>{ shoppingCart.length > 0 ? "Buy" : "Back" }</span>
                </Button>
            </div>
        </AppBar>
        <ConfirmationModal
            title={"Shopping cart"}
            open={openModal}
            secondaryAction={clearAfterReview}
            onCancel={continueAfterReview}
            onConfirm={buyAfterReview}
            confirmButtonText={"Buy"}
            cancelButtonText={"Continue shopping"}
            secondaryButtonText={"Clear cart"}
            description={"You have selected the following items:"}
        >
            <Grid
                rows={shoppingCart.map(product=>({...product, amount: product.number * product.cost }))}
                columns={headers}
                getRowId={getRowId}
            >
                <SummaryState
                    totalItems={totalItems}
                />
                <IntegratedSummary />
                <Table />
                <TableHeaderRow />
                <TableSummaryRow />
            </Grid>
        </ConfirmationModal>
        <div className={classes.tabPanelHolder}>
            <SwipeableViews
                index={tab}
                onChangeIndex={setTab}
                enableMouseEvents
                animateHeight
            >
                { productCategories?.map(productCategory=> (
                    <TabPanel
                        value={tab}
                        index={0}
                        className={classes.tabPanel}
                    >
                        <CardGrid
                            Component={ProductCardV2}
                            componentProps={cardProductByProductCategories[productCategory.slug]}
                            chunkSize={0}
                        />
                    </TabPanel>
                )) }
            </SwipeableViews>
        </div>
    </>;
};


export default ProductList;