import React from "react";

import AssociationType from "../../../Components/Types/Association";
import Info from "../Info";


const FinancialSettingsInfo = ({ association }) => {
    let data = {
        "Threshold below which debt collection will not be collected": "€ "+ association.debt_collection_threshold,
        // "People need to have an iban to become member": association.member_needs_iban ? "Yes" : "No",
    };
    return (
        <Info
            headerless={true}
            data={data}
        />
    );
};

FinancialSettingsInfo.propTypes = {
    association: AssociationType
};

export default FinancialSettingsInfo;