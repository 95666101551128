import {pick} from "@mui-treasury/styling/utils";
import PropTypes from "prop-types";
import React from "react";

import withPropMap from "../../../Components/HOC/withPropMap";
import {usePatchAssociationMutation} from "../../../Store/services/MySU/associations";
import InfoForm from "../InfoForm";
import FinancialSettingsForm from "./FinancialSettingsForm";
import FinancialSettingsInfo from "./FinancialSettingsInfo";


const formObjectToAssociationFormPropsMap = ({ formObject, handleFormObjectChange, ...rest_props}) =>
    ({ association: formObject, handleAssociationChange: handleFormObjectChange, ...rest_props});
const formObjectToAssociationInfoPropsMap = ({ info, ...rest_props}) =>
    ({ association: info, ...rest_props});

const fields = ["slug", "debt_collection_threshold", "member_needs_iban"];

const FinancialSettingsInfoForm = ({ association, showInfoFormStateButton, initialInfoOrFormState, MoreActionButtonGroup, postSubmit, postCancel }) => {
    const [ patchAssociation ] = usePatchAssociationMutation();

    const fieldAndValueToStateChanges = (field, value) => ({ [field]: value });
    const onSubmit = (association) => patchAssociation(pick(association, fields)).then(()=>postSubmit(association));

    const AssociationInfoComponent = withPropMap(FinancialSettingsInfo, formObjectToAssociationInfoPropsMap);
    const AssociationFormComponent = withPropMap(FinancialSettingsForm, formObjectToAssociationFormPropsMap);

    return (
        <InfoForm
            onSubmit={onSubmit}
            onCancel={postCancel}
            title={"Financial settings"}
            infoFormObject={association}
            InfoComponent={AssociationInfoComponent}
            FormComponent={AssociationFormComponent}
            fieldAndValueToStateChanges={fieldAndValueToStateChanges}
            showInfoFormStateButton={showInfoFormStateButton}
            initialInfoOrFormState={initialInfoOrFormState}
            MoreActionButtonGroup={MoreActionButtonGroup}
        />
    );
};

const dummyFunction = () => {};

FinancialSettingsInfoForm.propTypes = {
    association: PropTypes.object.isRequired,
    showInfoFormStateButton: PropTypes.bool.isRequired,
    initialInfoOrFormState: PropTypes.string.isRequired,
    MoreActionButtonGroup: PropTypes.elementType,
    postSubmit: PropTypes.func,
    postCancel: PropTypes.func
};

FinancialSettingsInfoForm.defaultProps = {
    MoreActionButtonGroup: dummyFunction(),
    postSubmit: dummyFunction,
    postCancel: dummyFunction,
};

export default FinancialSettingsInfoForm;