import React from "react";
import {Route, Switch} from "react-router-dom";

import PageLayout from "../Components/PageLayout/PageLayout";
import RequireAuth from "../Components/Routes/RequireAuth";
import TerminalRouting from "../Pages/Inventory/Terminal/Routing";
import { AuthorizedRoutes } from "./Authenticated/Authorized";
import NotAuthorized from "./NotAuthenticated/NotAuthorized";

const Routing = () => {
    return (
        <Switch>
            <Route path={"/terminal"} render={() => <TerminalRouting path={"/terminal"}/>}/>
            <Route path={"/"}>
                <PageLayout>
                    <Route path={"/protected"} render={() => (
                        <RequireAuth redirectTo={"/"}>
                            <AuthorizedRoutes/>
                        </RequireAuth>
                    )}
                    />
                    <Route path={"/"} render={() => <NotAuthorized/>}/>
                </PageLayout>
            </Route>
        </Switch>
    );
};

export default Routing;