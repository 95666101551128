import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";

const MemberTypesInfo = ({ memberType }) => {
    return (
        <div>
            <Grid container spacing={2}>
                <Grid container item lg={4}>
                    <Grid container item spacing={2}>
                        <Grid item xs={6}>
                            <Typography>Name</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{ memberType.type }</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item xs={6}>
                            <Typography>Membership fee</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{ memberType.membership_fee }</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item xs={6}>
                            <Typography>Only chooseable by board</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{ memberType.board_only }</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item xs={6}>
                            <Typography>Full year membership</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{ memberType.full_year_membership }</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item xs={6}>
                            <Typography>Description</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{ memberType.helper_text }</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

MemberTypesInfo.propTypes = {
    memberType: PropTypes.object.isRequired,
};

export default MemberTypesInfo;