import React from "react";

import AssociationType from "../../../Components/Types/Association";
import Info from "../Info";


const MembershipSettingsInfo = ({ association }) => {
    let data = {
        "Full year membership start at": association.full_year_membership_start_date,
        "Title for the membership request": association.new_member_title || "Membership request for " + association.name,
        "Text shown on the membership request": association.new_member_text || association.name + " requires additional information. To become a member you need to provide them with the following:",
    };
    return (
        <Info
            headerless={true}
            data={data}
        />
    );
};

MembershipSettingsInfo.propTypes = {
    association: AssociationType
};

export default MembershipSettingsInfo;