import BallotIcon from "@mui/icons-material/Ballot";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import Create from "@mui/icons-material/Create";
import GradeIcon from "@mui/icons-material/Grade";
import InputIcon from "@mui/icons-material/Input";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import React from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import SelectField from "../../../Components/FormComponents/Fields/SelectField";
import SpecificDataField from "../../../Components/FormComponents/Fields/SpecificDataField";
import TextField from "../../../Components/FormComponents/Fields/TextField";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";

const DataFieldsForm = ({ dataField, handleDataFieldChange, onSubmit, onCancel, onDelete, enableDelete, update }) => {
    return (
        <ValidatorForm
            onSubmit={onSubmit}
            onError={errors => console.log(errors)}
        >
            <Wrapper>
                <IconHolder Icon={Create}/>
                <TextField
                    name={"name"}
                    value={dataField.name}
                    onChange={(event) => handleDataFieldChange("name", event.target.value)}
                />
            </Wrapper>
            <Wrapper>
                <IconHolder Icon={BallotIcon}/>
                <SelectField
                    name={"type"}
                    validators={["required"]}
                    errorMessages={["A type has to be set"]}
                    value={dataField.type}
                    onChange={(event) => handleDataFieldChange("type", event.target.value)}
                    disabled={update}
                >
                    <MenuItem value={"String"}>Text</MenuItem>
                    <MenuItem value={"Number"}>Number</MenuItem>
                    <MenuItem value={"Choice"}>Choice</MenuItem>
                    <MenuItem value={"Boolean"}>Yes/No</MenuItem>
                </SelectField>
            </Wrapper>
            { dataField.type === "Choice" &&
            <Wrapper>
                <IconHolder Icon={Create}/>
                <TextField
                    name={"choices"}
                    value={dataField.choices}
                    onChange={(event) => handleDataFieldChange("choices", event.target.value)}
                    helperText={"Enter a comma-separated list, e.g. 'apple pie,quark pie,peach,lemonade'"}
                />
            </Wrapper>
            }
            { dataField.type === "Boolean" &&
            <Wrapper>
                <IconHolder Icon={PriorityHighIcon}/>
                <SelectField
                    name={"Mandatory"}
                    validators={["required"]}
                    errorMessages={["A type has to be set"]}
                    helperText={"Put this field to yes, if you want that people have to check this box in order to be able to apply for a membership."}
                    value={dataField.mandatory}
                    onChange={(event) => handleDataFieldChange("mandatory", event.target.value)}
                >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </SelectField>
            </Wrapper>
            }
            { dataField.mandatory ||
            <Wrapper>
                <IconHolder Icon={GradeIcon}/>
                <SelectField
                    name={"board only"}
                    value={dataField.board_only}
                    onChange={(event) => handleDataFieldChange("board_only", event.target.value)}
                    helperText={"Setting this to `Yes` means that the content of this field can only be set by the board. NB: The member can still read it."}
                    required
                >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </SelectField>
            </Wrapper>
            }
            { dataField.mandatory ||
            <Wrapper>
                <IconHolder Icon={InputIcon}/>
                <SpecificDataField
                    field={{...dataField, url: "default"}}
                    name={"Default"}
                    value={dataField.default}
                    onChange={handleDataFieldChange}
                    required={dataField.type === "Boolean" || dataField.type === "Choice"}
                />
            </Wrapper>
            }
            <Wrapper>
                <IconHolder Icon={ContactSupportIcon}/>
                <TextField
                    name={"help message"}
                    value={dataField.helper_text}
                    onChange={(event) => handleDataFieldChange("helper_text", event.target.value)}
                    helperText={"The value will be shown be shown below the field, just like this text is."}
                />
            </Wrapper>

            <Wrapper>
                <div>
                    { dataField.slug &&
                    <Button variant={"outlined"} disabled={!enableDelete} onClick={onDelete} color={"error"}>
                        Delete
                    </Button>
                    }
                </div>
                <div>
                    <Button variant={"contained"} onClick={onCancel} color={"secondary"}>Cancel</Button>
                    &nbsp;
                    <Button type={"submit"} variant={"contained"} color={"primary"}>Save</Button>
                </div>
            </Wrapper>
        </ValidatorForm>
    );
};

DataFieldsForm.propTypes = {
    dataField: PropTypes.object.isRequired,
    handleDataFieldChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    enableDelete: PropTypes.bool.isRequired,
    update: PropTypes.bool.isRequired
};

export default DataFieldsForm;