import {Skeleton} from "@mui/material";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import React from "react";

import FinancialSettingsInfoForm from "../../../Forms/InfoForms/FinancialSettings/FinancialSettingsInfoForm";
import {useGetAssociationBySlugQuery} from "../../../Store/services/MySU/associations";

const Settings = ({ association: propAssociation }) => {
    const { data: association } = useGetAssociationBySlugQuery(propAssociation.slug);

    if (!association) {
        return (
            <Paper elevation={0} sx={{ borderRadius: "0px 0px 16px 16px" }}>
                <Skeleton variant={"rectangular"} width={"100%"} height={250}/>
            </Paper>
        );
    }

    return (
        <Paper elevation={0} sx={{ borderRadius: "0px 0px 16px 16px" }}>
            <FinancialSettingsInfoForm
                initialInfoOrFormState={"info"}
                association={association || {}}
                showInfoFormStateButton={true}
            />
        </Paper>
    );
};

Settings.propTypes = {
    association: PropTypes.object.isRequired
};

export default Settings;