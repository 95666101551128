import {Skeleton} from "@mui/material";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";

import Wrapper from "../../Components/FormComponents/Wrapper";
import Block from "../../Components/PageLayout/Content/Block";
import RoutingButton from "../../Components/PageLayout/Content/RoutingButton";
import TopPageBlock from "../../Components/PageLayout/Content/TopPageBlock";
import AssociationAboutInfoForm from "../../Forms/InfoForms/Association/AssociationAboutInfoForm";
import AssociationInfo from "../../Forms/InfoForms/Association/AssociationInfo";
import AssociationInfoForm from "../../Forms/InfoForms/Association/AssociationInfoForm";
import useIsBoardMemberPage from "../../Store/hooks/useIsBoardMemberPage";
import {useCurrentAssociation} from "../../Store/services/MySU/associations";

const AssociationInfoScreen = () => {
    const { data: association } = useCurrentAssociation();
    const isBoardPage = useIsBoardMemberPage();

    return (
        <Container maxWidth={"xl"}>
            <Wrapper>
                <RoutingButton
                    routeStringPieces={[
                        "Association", "About"
                    ]}
                />
            </Wrapper>
            <TopPageBlock>
                <Typography variant={"h5"}>
                    { association ? association.name : <Skeleton /> }
                </Typography>
            </TopPageBlock>
            <Grid container rowSpacing={4} spacing={6} sx={{ mt: 0 }}>
                <Grid item xs={7}>
                    <Block>
                        <AssociationAboutInfoForm
                            association={association || {}}
                            showInfoFormStateButton={isBoardPage}
                            initialInfoOrFormState={"info"}
                        />
                        { /*<Typography variant={"h5"}>*/ }
                        { /*    About*/ }
                        { /*</Typography>*/ }
                        { /*<Divider/>*/ }
                        { /*<Typography>*/ }
                        { /*    { association ? association.description : <Skeleton /> }*/ }
                        { /*</Typography>*/ }
                        { /*{ /*<Typography color={"#1A2E49"}>*/ }
                        { /*{ /*    Welcome on the official MySU website of Foton Arts. We are a visual arts association of the University of Twente.*/ }
                        { /*{ /*</Typography>*/ }
                    </Block>
                </Grid>
                <Grid item xs={5}>
                    <Block>
                        <AssociationInfoForm
                            association={association || {}}
                            showInfoFormStateButton={isBoardPage}
                            initialInfoOrFormState={"info"}
                        />
                    </Block>
                </Grid>
            </Grid>
        </Container>
    );
};

AssociationInfoScreen.propTypes = {
};

export default AssociationInfoScreen;