import DatePicker from "@mui/lab/DatePicker";
import Grid from "@mui/material/Grid";
import MuiTextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import {ValidatorComponent} from "react-material-ui-form-validator";

import useFieldStyles, {useFieldStylesV2} from "../fieldStyles";


const useStyles = makeStyles(theme => ({
    picker: {
        marginRight: theme.spacing(2),
        "&:last-child": {
            marginRight: 0,
        },
        width: "100%",
    },
}));

const DateField = ({name, value, onChange, apiFormat, size, ...remaining_props}) => {
    const classes = useStyles();

    const handleChange = (datetime_util_object) => {
        onChange(datetime_util_object === null ? null : datetime_util_object.format(apiFormat));
    };

    return (
        <DatePicker
            className={classes.picker}
            ampm={false}
            inputVariant={"outlined"}
            renderInput={(props) => (<MuiTextField
                sx={{ width: "100%" }}
                label={name[0].toUpperCase() + name.slice(1).replaceAll("_", " ")}
                size={size}
                {...props}
            />)}
            {...remaining_props}
            onChange={handleChange}
            value={value && moment(value)}
        />
    );
};

DateField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    size: PropTypes.string,
    apiFormat: PropTypes.string,
    inputFormat: PropTypes.string,
};

DateField.defaultProps = {
    apiFormat: "YYYY-MM-DD",
    inputFormat: "YYYY-MM-DD"
};

const DateFieldV2 = (props) => {
    const classes = useStyles();

    return (
        <DatePicker
            inputVariant={"outlined"}
            className={classes.picker}
            ampm={false}
            inputFormat={"L"}
            {...props}
        />
    );
};

class DateValidator extends ValidatorComponent {
    renderValidatorComponent() {
        const {
            errorMessages,
            validators,
            requiredError,
            helperText,
            validatorListener,
            containerProps,
            ...rest
        } = this.props;
        const { isValid } = this.state;

        return (
            <DateField
                {...rest}
                error={!isValid}
                helperText={(!isValid && this.getErrorMessage()) || helperText}
            />
        );
    }
}

const DateFieldV3 = ({ variant, label, ...rest_props }) => {
    const fieldClasses = useFieldStylesV2();
    const classes = useStyles();

    if (variant === "separated") {
        return (
            <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={4}>
                    <Typography variant={"body1"}>{ label }</Typography>
                </Grid>
                <Grid item xs={8}>
                    <DateValidator
                        inputVariant={"outlined"}
                        size={"small"}
                        className={classNames(fieldClasses.field, classes.picker, fieldClasses.field)}
                        ampm={false}
                        format={"L"}
                        containerProps={{className: fieldClasses.field}}
                        {...rest_props}
                    />
                </Grid>
            </Grid>
        );
    } else {
        return (
            <DateValidator
                inputVariant={variant}
                label={label}
                className={classNames(classes.picker, fieldClasses.field)}
                ampm={false}
                format={"L"}
                containerProps={{className: fieldClasses.field}}
                {...rest_props}
            />
        );
    }
};

export { DateFieldV2, DateFieldV3 };
export default DateField;


