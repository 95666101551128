import {DataTypeProvider} from "@devexpress/dx-react-grid";
import Input from "@mui/material/Input";
import moment from "moment/moment";
import React from "react";

const DateOperations = [
    "equal",
    "notEqual",
    "greaterThan",
    "greaterThanOrEqual",
    "lessThan",
    "lessThanOrEqual",
];

const DateFormatter = ({ value }) => value ? moment(value).format("L") : "";

const DateEditor = ({ value, onValueChange }) => {
    return (
        <Input
            value={value}
            onChange={event => onValueChange(event.target.value)}
            style={{ width: "100%" }}
            type={"date"}
        />
    );
};

const DateTypeProvider = props => (
    <DataTypeProvider
        editorComponent={DateEditor}
        formatterComponent={DateFormatter}
        availableFilterOperations={DateOperations}
        {...props}
    />
);

export default DateTypeProvider;