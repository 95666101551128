import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React, {useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import Button from "../../Components/Buttons/Button";
import Wrapper from "../../Components/FormComponents/Wrapper";
import useModalState from "../../Components/Hooks/useModalState";
import Block from "../../Components/PageLayout/Content/Block";
import MemberTable from "../../Components/Tables/MemberTable";
import useCurrentMembers from "../../Store/hooks/useCurrentMembers";
import {useGetEnrollmentOptionsByEventQuery} from "../../Store/services/MySU/enrollmentOptions";
import {useAddEnrollmentsMutation, useGetEnrollmentsByEventQuery} from "../../Store/services/MySU/enrollments";
import EnrollModal from "./EnrollModal";


const EventEnrollmentsManagementAssociationMemberList = () => {
    const { slug, eventSlug } = useParams();
    const { members } = useCurrentMembers();

    const { data: enrollmentOptions } = useGetEnrollmentOptionsByEventQuery(eventSlug);
    const { data: enrollments } = useGetEnrollmentsByEventQuery(eventSlug);
    const [ addEnrollments ] = useAddEnrollmentsMutation();

    const [openModal, toggleModal] = useModalState(false);

    const [selection, setSelection] = useState([]);

    const defaultEnrollment = useMemo(()=>({enrollment_option: "", association: `/associations/${slug}`, event: "/events/" + eventSlug}), [slug, eventSlug]);
    const [coreEnrollment, setCoreEnrollment] = useState(defaultEnrollment);

    const updateCoreEnrollment = (field, value) => {setCoreEnrollment(enrollment=>(
        {...enrollment, [field]: value}
    ));};

    const selectionToEnrollments = (selection_) => {
        return selection_.map(selected=>({
            ...coreEnrollment,
            profile: "/profiles/" + selected
        }));
    };

    const enrollSelection = () => {
        addEnrollments(selectionToEnrollments(selection)).then(()=>{
            toggleModal();
            setSelection([]);
        });
    };
    const closeModal = () => {
        setCoreEnrollment(defaultEnrollment);
        toggleModal();
    };

    const enrolledMemberSlugs = useMemo(()=>enrollments?.map(enrollment=>enrollment.profile) || [], [enrollments]);
    const rowSelectionEnabledFilter = (member) => !enrolledMemberSlugs.includes(member.url);

    return (
        <Container sx={{ mt: 2 }}>
            <Block>
                <Typography variant={"h5"}>Association Members</Typography>
                <hr className={"box-title-separator"}/>
                <MemberTable
                    initial_headers={["given_name", "surname"]}
                    selection={{selection: selection, setSelection: setSelection}}
                    showEditing={false}
                    showGrouping={false}
                    showExporter={false}
                    rowSelectionEnabledFilter={rowSelectionEnabledFilter}
                    rows={members || []}
                />
                <Wrapper>
                    <div/>
                    <Button variant={"contained"} color={"primary"} onClick={toggleModal}>
                        Enroll
                    </Button>
                </Wrapper>
                <EnrollModal
                    updateEnrollment={updateCoreEnrollment}
                    onCancel={closeModal}
                    enrollmentOptions={enrollmentOptions || []}
                    onSubmit={enrollSelection}
                    enrollment={coreEnrollment}
                    open={openModal}
                />
            </Block>
        </Container>
    );
};

EventEnrollmentsManagementAssociationMemberList.propTypes = {
};

export default EventEnrollmentsManagementAssociationMemberList;