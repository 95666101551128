import PropTypes from "prop-types";
import React from "react";

const withPropMap = (WrappedComponent, propMap) => props => {
    return <WrappedComponent {...propMap(props)}/>;
};

withPropMap.propTypes = {
    WrappedComponent: PropTypes.elementType.isRequired,
    propMap: PropTypes.func.isRequired
};

export default withPropMap;