import {configureStore} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import rtkQueryErrorLogger from "./Middleware";
import { emptySplitApi } from "./services/MySU";
import xkcdApi from "./services/XKCD/xkcd";
import authReducer from "./slices/authentication";
import terminalReducer from "./slices/terminal";

const setUpStore = () => {
    const store = configureStore({
        reducer: {
            auth: authReducer,
            terminal: terminalReducer,
            [emptySplitApi.reducerPath]: emptySplitApi.reducer,
            [xkcdApi.reducerPath]: xkcdApi.reducer,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat(emptySplitApi.middleware)
                .concat(xkcdApi.middleware)
                .concat(rtkQueryErrorLogger)
    });

    setupListeners(store.dispatch);

    return store;
};

const store = setUpStore();
export default store;
