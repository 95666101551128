import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    association: {slug: "", name: ""},
    user: {},
    patternMatched: false,
    cart: []
};

const terminalSlice = createSlice({
    name: "terminal",
    initialState,
    reducers: {
        clearAssociation(state) {
            state.association = {};
        },
        setAssociation(state, action) {
            state.association = action.payload;
        },
        clearUser(state) {
            state.user = {};
        },
        setUser(state, action) {
            state.user = action.payload;
        },
        emptyCart(state) {
            state.cart = [];
        },
        addProductToCart(state, action) {
            const product = action.payload;

            if (state.cart.find(productInCart=>productInCart.title === product.title)) {
                state.cart = state.cart.map(productInCart=>productInCart.title === product.title
                    ? {...productInCart, number: productInCart.number+1}
                    : productInCart
                );
            } else {
                state.cart.push({...product, number: 1});
            }

        },
    },
});

export const {
    clearUser,
    setUser,
    emptyCart,
    addProductToCart
} = terminalSlice.actions;
export default terminalSlice.reducer;