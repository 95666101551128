import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EmailIcon from "@mui/icons-material/Email";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React from "react";

import Block from "../../Components/PageLayout/Content/Block";
import RoutingButton from "../../Components/PageLayout/Content/RoutingButton";

const Help = () => {
    return (
        <Container maxWidth={"xl"}>
            <RoutingButton/>
            <Grid container spacing={5}>
                <Grid item xs={8}>
                    <Block>
                        <Typography variant={"h5"}>Frequently Asked Questions</Typography>
                        <br/>
                        <Typography variant={"h6"}>What is My SU?</Typography>
                        <Typography>
                            MySU is a Member Administration system for associations that do not have the time,
                            money or man power needed to build their own.
                        </Typography>
                        <br/>
                        <Typography variant={"h6"}>How do I add my association?</Typography>
                        <Typography>
                            If you want to add your association, please sent an email to join@my.su.utwente.nl with subject
                            `Association Join Request: [association name]`. Replace the part between square brackets with the
                            name of your association.
                        </Typography>
                        <br/>
                        <Typography variant={"h6"}>My student number has changed. Can I still login?</Typography>
                        <Typography>
                            Please email to help@su.utwente.nl.
                        </Typography>
                        <br/>
                        <Typography variant={"h6"}>Do you do any tracking?</Typography>
                        <Typography>
                            No.
                        </Typography>
                        <br/>
                        <Typography variant={"h6"}>Who can see my data?</Typography>
                        <Typography>
                            Only the board members of the Associations you are a member of.
                        </Typography>
                        <br/>
                        <Typography variant={"h6"}>How can I help improve the platform?</Typography>
                        <Typography>
                            We can use help in either of three areas:
                            <ol>
                                <li>Coding</li>
                                <li>Design</li>
                                <li>Testing</li>
                            </ol>

                            Please contact us in the discord or send an email to join@su.utwente.nl to ask what you can do to
                            help.
                            <br/><br/>
                            The code for the project is located at https://gitlab.utwente.nl/my-su. The repository `backend` has the
                            code for the backend and the repository `frontend` has the code for the frontend. Visit the wikis
                            for the respective repositories to learn more.
                        </Typography>
                    </Block>
                </Grid>
                <Grid item xs={4}>
                    <Block>
                        <Typography variant={"h5"}>Contact</Typography>
                        <br/>
                        <Typography>
                            If you run into a problem, you can reach us in the following ways:
                        </Typography>
                        <List>
                            <ListItemButton component={"a"} href={"https://discord.gg/VnEneGP"}>
                                <ListItemIcon>
                                    <FontAwesomeIcon className={"fa-lg"}  icon={["fab", "discord"]} />
                                </ListItemIcon>
                                <ListItemText primary={"Discord"} />
                            </ListItemButton>
                            <ListItemButton component={"a"} href={"mailto:support@su.utwente.nl"}>
                                <ListItemIcon>
                                    <EmailIcon/>
                                </ListItemIcon>
                                <ListItemText primary={"support@su.utwente.nl"} />
                            </ListItemButton>
                        </List>
                        <Typography>
                            If your association wants to join MySU, please sent an email to join@my.su.utwente.nl with subject
                            `Association Join Request: [association name]` to:
                        </Typography>
                        <List>
                            <ListItemButton component={"a"} href={"mailto:support@su.utwente.nl"}>
                                <ListItemIcon>
                                    <EmailIcon/>
                                </ListItemIcon>
                                <ListItemText primary={"support@su.utwente.nl"} />
                            </ListItemButton>
                        </List>
                    </Block>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Help;