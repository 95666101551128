import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import useSize from "@react-hook/size";
import PropTypes from "prop-types";
import React from "react";
import { upperCase } from "upper-case";

import Image from "../../../../img/default_photo.jpg";
import Card from "../../Card/Card";
import RoutingButton from "./RoutingButton";

const useStyles = makeStyles(() => ({
    container : {
    },
    photoHeader: {
        position: "relative",
        height: "320px",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: ({ image }) => image || `url(${Image})`,
    },
    header: {
        position: "absolute",
        left: "30px",
        bottom: "-15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
    },
    title: {
        width: "100%",
    },
    sub_photo_container: {
    },
}));

// eslint-disable-next-line react/prop-types
const FigmaContent = ({ image, name, children }) => {
    const classes = useStyles({ image: image });
    const target = React.useRef(null);
    const size = useSize();

    return (
    // <Container maxWidth={"xl"}>
    //     <RoutingButton />
    //     <Box
    //
    //         sx={{ width: "100%", backgroundColor: "background.default", borderRadius: 4, pl: 10, pr: 10, pt: 2, pb: 2 }}
    //     >
    //
    //         <Typography variant={"h5"}>Boards</Typography>

        <div className={classes.container} ref={target}>
            <div className={classes.photoHeader} style={{width: size.width}}>
                <Typography className={classes.header} variant={"h2"}>
                    { upperCase(name) || <Skeleton /> }
                </Typography>
            </div>
        </div>



    //     {/*<div className={classes.sub_photo_container}>*/}
    //     {/*    { children }*/}
    //     {/*</div>*/}
    // {/*    </Box>*/}
    // {/*    <Box sx={{ mt: 2 }}>*/}
    // {/*        /!*<Grid container spacing={4} justifyContent={"space-between"}>*!/*/}
    //
    // {/*        { children }*/}
    // {/*        /!*</Grid>*!/*/}
    // {/*    </Box>*/}
    // {/*</Container>*/}
    );
};

FigmaContent.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
};

FigmaContent.defaultProps = {
};

export default FigmaContent;
