import * as moment from "moment/moment";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {Route, Switch, useRouteMatch} from "react-router-dom";

import RequireBoard from "../../Components/Routes/RequireBoard";
import useIsBoardMemberPage from "../../Store/hooks/useIsBoardMemberPage";
import EventDetail from "./EventDetail";
import EventEnrollmentsManagement from "./EventEnrollmentsManagement";
import EventList from "./EventList";
import EventTypes from "./EventTypes";
import Scheduler from "./Scheduler";


export const EventsRouter = ({ association }) => {
    const { path, url } = useRouteMatch();
    const isBoardPage = useIsBoardMemberPage();

    const [currentCalendarDate, setCurrentCalendarDate] = useState(moment().format("YYYY-MM-DD"));

    console.log(path, url);
    return (
        <Switch>
            <Route exact path={path+"/types"} render={()=>(
                <RequireBoard isBoard={isBoardPage} redirectTo={url}>
                    <EventTypes/>
                </RequireBoard>
            )}
            />
            <Route path={path+"/:eventSlug/enrollments"} render={()=>(
                <RequireBoard isBoard={isBoardPage} redirectTo={url + "/:eventSlug"}>
                    <EventEnrollmentsManagement/>
                </RequireBoard>
            )}
            />
            <Route path={path+"/calendar"}>
                <Scheduler
                    isBoardPage={isBoardPage}
                    currentDate={currentCalendarDate}
                    setCurrentDate={setCurrentCalendarDate}
                />
            </Route>
            <Route path={path+"/:eventSlug"}>
                <EventDetail/>
            </Route>
            <Route path={path}>
                <EventList/>
            </Route>
        </Switch>
    );
};

EventsRouter.propTypes = {
    association: PropTypes.object.isRequired,
};
