import PropTypes from "prop-types";
import React from "react";
import {Route, useRouteMatch} from "react-router-dom";

import MyMatches from "../../../Pages/Buddy/MyMatches";
import {EventsRouter} from "../../../Pages/Calendar/EventRouter";
import {GroupsRouter} from "../../../Pages/Groups/Member/GroupRouter";
import MembersDetailFromData from "../../../Pages/Members/Detail/MembersDetailFromData";
import About from "../../../Pages/MyAssociation/About";
import AssociationInfoScreen from "../../../Pages/MyAssociation/AssociationInfo";
import Member from "../../../Pages/MyAssociation/Home/member";
import NotCurrentMember from "../../../Pages/MyAssociation/Home/not_current_member";
import MembershipEnd from "../../../Pages/Profile/MembershipEnd";


const MemberRoutes = ({ association, association_membership }) => {
    const { path } = useRouteMatch();

    if (!association_membership.current) {
        return (
            <Route exact path={path}>
                <NotCurrentMember
                    membership={association_membership}
                    association={association}
                />
            </Route>
        );
    }
    return (
        <>
            <Route path={path+"/membership/end"} exact>
                <MembershipEnd
                    association={association}
                    membership={association_membership}
                />
            </Route>
            <Route path={path+"/info"}>
                <AssociationInfoScreen/>
            </Route>
            { /*Shared user profile*/ }
            <Route path={path+"/my-data"}>
                <MembersDetailFromData
                    association_membership={association_membership}
                />
            </Route>
            <Route path={path+"/matchings"}>
                <MyMatches
                    profile={association_membership.profile}
                />
            </Route>
            <Route path={path+"/committees"}>
                <GroupsRouter boards={false}/>
            </Route>
            <Route path={path+"/board"}>
                <GroupsRouter boards={true}/>
            </Route>
            <Route path={path+"/about"}>
                <About association={association}/>
            </Route>
            <Route path={path + "/events"}>
                <EventsRouter
                    association={association}
                    profile={association_membership.profile}
                    board={false}
                />
            </Route>
            <Route exact path={path}>
                <Member
                    membership={association_membership}
                    association={association}
                />
            </Route>
        </>
    );
};

MemberRoutes.propTypes = {
    association: PropTypes.object.isRequired,
    association_membership: PropTypes.object.isRequired,
};

export default MemberRoutes;
