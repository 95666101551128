import * as moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import withExtraProps from "../../../Components/HOC/withExtraProps";
import withPropMap from "../../../Components/HOC/withPropMap";
import {
    useAddGroupMutation,
    useDeleteGroupMutation,
    usePatchGroupFormDataMutation,
    usePatchGroupMutation
} from "../../../Store/services/MySU/groups";
import InfoForm from "../InfoForm";
import GroupForm from "./GroupForm";
import GroupInfo from "./GroupInfo";


const formObjectToGroupFormPropsMap = ({ formObject, handleFormObjectChange, ...rest_props}) =>
    ({ group: formObject, handleGroupChange: handleFormObjectChange, ...rest_props});
const formObjectToGroupInfoPropsMap = ({ info, ...rest_props}) =>
    ({ group: info, ...rest_props});
const groupWithDatesToApi = (group) => ({
    ...group,
    founding_date: group.founding_date && moment(group.founding_date).format("YYYY-MM-DD"),
    dissolution_date: group.dissolution_date && moment(group.dissolution_date).format("YYYY-MM-DD"),
});

const fileFields = ["photo", "logo"];
const toFormData = (group, changedGroup) => {
    const groupData = new FormData();
    Object.keys(changedGroup).forEach(key => {
        if(fileFields.includes(key)) {
            if ( changedGroup[key] instanceof File) {
                groupData.append(key, changedGroup[key]);
            }
        } else {
            groupData.append(key, changedGroup[key]);
        }
    });
    groupData.append("slug", group.slug);
    return groupData;
};

const GroupInfoForm = ({ group, showInfoFormStateButton, initialInfoOrFormState, MoreActionButtonGroup, enableDelete, postSubmit, postDelete, postCancel }) => {
    const [ addGroup ] = useAddGroupMutation();
    const [ patchGroup ] = usePatchGroupFormDataMutation();
    const [ deleteGroup ] = useDeleteGroupMutation();

    const fieldAndValueToStateChanges = (field, value) => ({ [field]: value });
    const onSubmit = (group) => group.slug
        ? patchGroup(toFormData(group, groupWithDatesToApi(group))).then(()=>postSubmit(group))
        : addGroup(toFormData(group, groupWithDatesToApi(group))).then(()=>postSubmit(group));
    const onDelete = (group) => deleteGroup(group).then(()=>postDelete(group));

    const GroupInfoComponent = withPropMap(GroupInfo, formObjectToGroupInfoPropsMap);
    const GroupFormComponent = withExtraProps(withPropMap(GroupForm, formObjectToGroupFormPropsMap), {
        parentable_groups: [],
    });

    return (
        <InfoForm
            onSubmit={onSubmit}
            onDelete={onDelete}
            onCancel={postCancel}
            title={"Group"}
            enableDelete={enableDelete}
            infoFormObject={group}
            InfoComponent={GroupInfoComponent}
            FormComponent={GroupFormComponent}
            fieldAndValueToStateChanges={fieldAndValueToStateChanges}
            showInfoFormStateButton={showInfoFormStateButton}
            initialInfoOrFormState={initialInfoOrFormState}
            MoreActionButtonGroup={MoreActionButtonGroup}
        />
    );
};

const dummyFunction = () => {};

GroupInfoForm.propTypes = {
    group: PropTypes.object.isRequired,
    showInfoFormStateButton: PropTypes.bool.isRequired,
    initialInfoOrFormState: PropTypes.string.isRequired,
    MoreActionButtonGroup: PropTypes.elementType,
    enableDelete: PropTypes.bool,
    postSubmit: PropTypes.func,
    postDelete: PropTypes.func,
    postCancel: PropTypes.func
};

GroupInfoForm.defaultProps = {
    enableDelete: false,
    MoreActionButtonGroup: dummyFunction(),
    postSubmit: dummyFunction,
    postDelete: dummyFunction,
    postCancel: dummyFunction,
};

export default GroupInfoForm;