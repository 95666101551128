import PropTypes from "prop-types";
import {useState} from "react";

const useModalState = ( initial_state ) => {
    const [modalOpen, setModalOpen] = useState(initial_state);

    const toggleModal = () => setModalOpen(prevState => !prevState);

    return [modalOpen, toggleModal];
};

useModalState.propTypes = {
    initial_state: PropTypes.string.isRequired
};

export default useModalState;