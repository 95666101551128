import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, {useMemo} from "react";

import Wrapper from "../../../../Components/FormComponents/Wrapper";
import Block from "../../../../Components/PageLayout/Content/Block";
import Divider from "../../../../Components/PageLayout/Content/Divider";
import ExtremeTable from "../../../../Components/Tables/ExtremeTable";
import {useGetInvoicesByAssociationAndProfileQuery} from "../../../../Store/services/MySU/invoices";

const invoices_headers = [
    {name: "date_issued", title: "Date issued"},
    {name: "price", title: "Price"},
    {name: "reason", title: "Reason"},
    {name: "debt_collection_date", title: "Debt collection"}
];
const currencyColumns = ["price"];
const dateTimeColumns = ["date_issued", "debt_collection_date"];

const InvoicesBlock = ({ profileSlug, associationSlug }) => {
    const { data: invoices } = useGetInvoicesByAssociationAndProfileQuery({ profileSlug: profileSlug, associationSlug: associationSlug });

    return (
        <Block>
            <ExtremeTable
                rows={invoices || []}
                headers={invoices_headers}
                showSelect={false}
                showGrouping={false}
                showColumnChooser={false}
                currencyColumns={currencyColumns}
                dateTimeColumns={dateTimeColumns}
                width={250}
                title={"Individual payments"}
            />
        </Block>
    );
};

InvoicesBlock.propTypes = {
    profileSlug: PropTypes.string.isRequired,
    associationSlug: PropTypes.string.isRequired
};

export default InvoicesBlock;