import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {useLocation, useParams} from "react-router-dom";

import {BackButton} from "../../../Components/BackButton";
import Button from "../../../Components/Buttons/Button";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import useInfoFormState from "../../../Components/Hooks/useInfoFormState";
import {Profile} from "../../../Components/InfoForms/Profile";
import Block from "../../../Components/PageLayout/Content/Block";
import RoutingButton from "../../../Components/PageLayout/Content/RoutingButton";
import Tabs from "../../../Components/PageLayout/Content/Tabs";
import useIsBoardMemberPage from "../../../Store/hooks/useIsBoardMemberPage";
import DebtCollectionsBlock from "./Blocks/DebtCollectionsBlock";
import GroupsBlock from "./Blocks/GroupsBlock";
import InvoicesBlock from "./Blocks/InvoicesBlock";
import MembershipBlock from "./Blocks/MembershipBlock";
import SepaMandatesBlock from "./Blocks/SepaMandatesBlock";

const useStyles = makeStyles(() => ({
    growingGridItem: {
        flexGrow: 1
    },
    wideTab: {
        width: "280px",
    },
}));


const MembersDetail = ({ current_member, onProfileUpdate }) => {
    const classes = useStyles();

    const { slug } = useParams();
    const { pathname } = useLocation();
    const isBoardPage = useIsBoardMemberPage();

    const [profileInfoFormState, toggleProfileInfoFormState] = useInfoFormState("info");

    const isClaimed = useMemo(()=>current_member.status === "Claimed", [current_member.status]);

    const profileSlug = current_member.profile.slug;
    const isYou = pathname.includes("my-data");

    const tabElements = [
        { Icon: <PersonIcon fontSize={"small"}/>, label: "Sepa mandates", value: "Sepa mandates", Panel: <SepaMandatesBlock associationSlug={slug} profileSlug={profileSlug}/>, tabClass: classes.wideTab},
        { Icon: <PersonAddIcon fontSize={"small"}/>, label: "Debt collections", value: "Debt collections", Panel: <DebtCollectionsBlock associationSlug={slug} profileSlug={profileSlug}/>, tabClass: classes.wideTab},
        { Icon: <PersonAddIcon fontSize={"small"}/>, label: "Committees/boards", value: "Groups", Panel: <GroupsBlock associationSlug={slug} profileSlug={profileSlug}/>, tabClass: classes.wideTab},
        // { Icon: <PersonAddIcon fontSize={"small"}/>, label: "Events", value: "Events", Panel: <EventsBlock associationSlug={slug} profileSlug={profileSlug}/>},
        // { Icon: <PersonAddIcon fontSize={"small"}/>, label: "Purchases", value: "Purchases", Panel: <PurchasesBlock associationSlug={slug} profileSlug={profileSlug}/>},
        { Icon: <PersonAddIcon fontSize={"small"}/>, label: "Individual pay.", value: "Invoices", Panel: <InvoicesBlock associationSlug={slug} profileSlug={profileSlug}/>},
    ];

    return (
        <Container maxWidth={"xl"}>
            <Wrapper>
                <RoutingButton
                    routeStringPieces={isBoardPage
                        ? ["Members", "Data of " + current_member.profile.given_name]
                        : ["My data"]
                    }
                />
                { isBoardPage && <BackButton container={false}/> }
            </Wrapper>
            <Grid container spacing={2}>
                <Grid item sx={{ width: "100%" }}>
                    <Block>
                        <Grid container spacing={3} sx={{ pb: 3 }}>
                            <Grid item>
                                <Avatar src={current_member?.profile.photo} sx={{ width: 64, height: 64 }}/>
                            </Grid>
                            <Grid item xs={11}>
                                <Wrapper>
                                    <Typography variant={"h5"}>
                                        Profile
                                    </Typography>
                                    { isYou ||
                                        <Button color={"primary"} variant={"contained"} disabled={!isClaimed} onClick={toggleProfileInfoFormState}>
                                            Edit
                                        </Button>
                                    }
                                </Wrapper>
                                <Divider sx={{ borderBottomWidth: "medium"}}/>
                            </Grid>
                        </Grid>
                        <Profile
                            profile={current_member.profile}
                            infoOrForm={profileInfoFormState}
                            container={false}
                            update={true}
                            onSuccess={onProfileUpdate}
                        />
                    </Block>
                </Grid>
                <Grid item sx={{ width: "100%" }}>
                    <MembershipBlock membership={current_member} isYou={isYou}/>
                </Grid>
                <Grid item sx={{ width: "100%" }}>
                    <Tabs tabElements={tabElements}/>
                </Grid>
            </Grid>
        </Container>
    );
};

MembersDetail.propTypes = {
    current_member: PropTypes.object.isRequired,
    onProfileUpdate: PropTypes.func
};

MembersDetail.defaultProps = {
    onProfileUpdate: () => {}
};

export default MembersDetail;