import * as moment from "moment/moment";
import PropTypes from "prop-types";
import React from "react";

import Info from "../Info";


const GroupInfo = ({ group }) => {
    // const data =  {
    //     "Full Name": group.full_name,
    //     "Short Name": group.short_name,
    //     "Description": group.description,
    //     "Number": group.number,
    //     "Edition": group.edition,
    //     "Year": group.start_year === group.end_year ? group.start_year : group.start_year + " -- " + group.end_year,
    //     "Theme": group.theme,
    //     "Creed": group.creed,
    //     "Founding Date": group.founding_date ? moment(group.founding_date).format("L") : "",
    //     "Dissolution Date": group.dissolution_date ? moment(group.dissolution_date).format("L") : "",
    //     "Email": group.email,
    // };

    let data = [];

    data["Contact"] = {
        "Name": group.full_name,
        "Short name": group.short_name,
        "Email": group.email,
    };

    data["Personal"] = {
        "Founding Date": group.founding_date ? moment(group.founding_date).format("L") : "",
        "Dissolution Date": group.dissolution_date ? moment(group.dissolution_date).format("L") : "",
        "Year": group.start_year === group.end_year ? group.start_year : group.start_year + " -- " + group.end_year,
        "Edition": group.edition,
        "Theme": group.theme,
        "Number": group.number,
    };


    return (
        <Info
            headerless={false}
            data={data}
        />
    );
};

GroupInfo.propTypes = {
    group: PropTypes.object.isRequired
};

export default GroupInfo;