import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";
import React from "react";
import {Link as RouterLink} from "react-router-dom";


/**
 * This is an element used to represent a link in a list. Link cannot be used
 * directly `ListItem`. React requires a reference to be forwarded. This does
 * not happen when directly used inside a component. The React.forwardRef()
 * function used here makes sure that the passing of references works.
 *
 * @param icon
 * @param primary
 * @param to
 * @param rest_props
 * @returns {JSX.Element}
 * @constructor
 */
const ListItemLink = ({ icon, primary, to, ...rest_props }) => {
    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    );

    return (
        <li>
            <ListItem button component={renderLink} {...rest_props}>
                { icon ? <ListItemIcon style={{ color: "#fff" }}>{ icon }</ListItemIcon> : null }
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
};

ListItemLink.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};

export default ListItemLink;
