import PropTypes from "prop-types";
import React, {useState} from "react";
import { useParams } from "react-router-dom";

import ExtremeTable from "../../../Components/Tables/ExtremeTable";
import DebtEntryPopupForm from "../../../Forms/PopupForms/DebtEntry/DebtEntryPopupForm";
import {
    useGetDebtEntriesByDebtCollectionQuery,
    usePatchDebtEntryMutation
} from "../../../Store/services/MySU/debtEntries";


const headers = [
    {name: "name", title: "Who"},
    {name: "total_price", title: "Amount"},
    {name: "mandate_id", title: "Mandate ID"},
    {name: "bank_account_name", title: "Bank account name"},
    {name: "signature_date", title: "Signature date"},
    {name: "iban", title: "IBAN"},
    {name: "bic", title: "BIC"},
    {name: "email", title: "Email"},
    {name: "cancelled", title: "Cancelled"},
    {name: "paid_by", title: "Payment by"},
];
const booleanColumns = ["cancelled"];
const currencyColumns = ["total_price"];
const dateColumns = ["debt_collection_mandate_signature_date"];

const defaultHiddenColumnNames = ["mandate_id", "bank_account_name", "signature_date", "bic"];


const DebtEntries = ({ debtCollectionSlug }) => {
    const { slug } = useParams();
    const [selection, setSelection] = useState([]);

    const { data: rows } = useGetDebtEntriesByDebtCollectionQuery(debtCollectionSlug);
    const [ patchDebtEntry ] = usePatchDebtEntryMutation();

    const onEdit = (differences, original_row, edited_row, edited_row_index) => {
        patchDebtEntry({
            slug: original_row.slug,
            debtCollectionSlug: debtCollectionSlug,
            associationSlug: slug,
            cancelled: edited_row.cancelled
        });
    };
    return (
        <ExtremeTable
            headers={headers}
            rows={rows || []}
            booleanColumns={booleanColumns}
            currencyColumns={currencyColumns}
            dateColumns={dateColumns}
            defaultHiddenColumnNames={defaultHiddenColumnNames}
            showExporter={true}
            showGrouping={false}
            showSelect={false}
            showEditing={true}
            selection={{selection: selection, setSelection: setSelection}}
            allowDelete={false}
            editThroughPopup={true}
            onEdit={onEdit}
            Popup={DebtEntryPopupForm}
            title={"Individual details"}
        />
    );
};

DebtEntries.propTypes = {
    debtCollectionSlug: PropTypes.string.isRequired
};

export default DebtEntries;