import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Divider from "@mui/material/Divider";
import makeStyles from "@mui/styles/makeStyles";
import {isEmpty, isEqual} from "lodash";
import { toast } from "material-react-toastify";
import React, {useState} from "react";
import PatternLock from "react-17-pattern-lock";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {
        zIndex: "0 !important"
    },
}));

const mockCorrectPattern = [1,2,3,4,5];

const PassCodeModal = ({ selectedProfile, clearSelectedProfile }) => {
    const classes = useStyles();
    const history = useHistory();

    const [pattern, setPattern] = useState([]);
    const onFinish = () => {
        if (isEqual(pattern, mockCorrectPattern)) {
            toast.success("Matching pattern");
            history.push("/terminal/products");
        } else {
            clearSelectedProfile();
            toast.error("Mismatched pattern");
            // toast.error("Mismatched pattern, pattern used is " + pattern.join("-"));
        }
        setPattern([]);
    };
    return (
        <Dialog
            open={!isEmpty(selectedProfile)}
            maxWidth={"sm"}
            fullWidth={true}
            className={classes.root}
            aria-labelledby={"contained-modal-title-vcenter"}
            disablePortal
            disableEnforceFocus
            disableAutoFocus
        >
            <DialogTitle variant={"h5"}>Enter your passcode</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Divider/>
                    <PatternLock
                        onChange={(newPattern => setPattern(newPattern))}
                        onFinish={onFinish}
                        path={pattern}
                    />
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default PassCodeModal;