import React from "react";

import {BackButton} from "../../Components/BackButton";
import EventEnrollmentsManagementAssociationMemberList from "./EventEnrollmentsManagementAssociationMemberList";
import EventEnrollmentsManagementEnrollments from "./EventEnrollmentsManagementEnrollments";


const EventEnrollmentsManagement = () => {
    return (
        <>
            <BackButton/>
            <EventEnrollmentsManagementEnrollments/>
            <EventEnrollmentsManagementAssociationMemberList/>
        </>
    );
};

export default EventEnrollmentsManagement;