import {emptySplitApi, pessimisticCreation} from "./index";

const productCategoriesApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["ProductCategory"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getProductCategoriesByAssociation: build.query({
                query: (association_slug) => ({ url: "/event_types", params: {"association__slug": association_slug, limit: 100} }),
                providesTags: (result, error, association_slug) => [{ type: "ProductCategory", id: association_slug }],
                transformResponse: (response) => response.results
            }),
            addProductCategory: build.mutation({
                query: (body) => ({ url: "/event_types/", method: "POST", body: body }),
                async onQueryStarted(eventType, { dispatch, queryFulfilled }) {
                    await pessimisticCreation({
                        dispatch,
                        queryFulfilled,
                        api: productCategoriesApi,
                        query: "getProductCategoriesByAssociation",
                        entityToQueryParam: () => eventType.association.split("/associations/")[1]
                    });
                }
            }),
            patchProductCategory: build.mutation({
                query: ({ slug, ...patch }) => ({ url: `/event_types/${slug}`, method: "PATCH", body: patch }),
                invalidatesTags: ({ association }) => [{ type: "ProductCategory", id: association.split("/associations/")[1] }]
            }),
            deleteProductCategory: build.mutation({
                query: ({ slug }) => ({ url: `/event_types/${slug}`, method: "DELETE" }),
                invalidatesTags: ({ association }) => [{ type: "ProductCategory", id: association.split("/associations/")[1] }]
            })
        }),
        overrideExisting: false
    });

export const {
    useGetProductCategoriesByAssociationQuery,
    useAddProductCategoryMutation,
    usePatchProductCategoryMutation,
    useDeleteProductCategoryMutation,
} = productCategoriesApi;
export default productCategoriesApi;