import Create from "@mui/icons-material/Create";
import EuroIcon from "@mui/icons-material/Euro";
import Notes from "@mui/icons-material/Notes";
import PropTypes from "prop-types";
import React from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import NumberField from "../../../Components/FormComponents/Fields/NumberField";
import TextField from "../../../Components/FormComponents/Fields/TextField";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";

const EnrollmentOptionsForm = ({ enrollmentOption, handleEnrollmentOptionChange, onSubmit, onCancel, onDelete, enableDelete }) => {
    return (
        <ValidatorForm
            onSubmit={onSubmit}
            onError={errors => console.log(errors)}
        >
            <Wrapper>
                <IconHolder Icon={Create}/>
                <TextField
                    name={"name"}
                    value={enrollmentOption.name}
                    onChange={(event) => handleEnrollmentOptionChange("name", event.target.value)}
                    validators={["required"]}
                    errorMessages={["Every option must have a name", "Other option has already this name; Options must have unique names"]}
                    required
                />
                <IconHolder Icon={EuroIcon}/>
                <NumberField
                    name={"price"}
                    value={enrollmentOption.participation_fee}
                    onChange={(event) => handleEnrollmentOptionChange("participation_fee", event.target.value)}
                    InputProps={{
                        inputProps: {
                            step: 0.1, min: 0
                        }
                    }}
                    required
                />
            </Wrapper>
            <Wrapper>
                <IconHolder Icon={Notes}/>
                <TextField
                    name={"Description"}
                    value={enrollmentOption.description}
                    onChange={(event) => handleEnrollmentOptionChange("description", event.target.value)}
                    multiline
                />
            </Wrapper>
            <Wrapper>
                <div>
                    <Button variant={"outlined"} disabled={!enableDelete} color={"error"} onClick={onDelete}>
                        Delete
                    </Button>
                </div>
                <div>
                    <Button variant={"contained"} onClick={onCancel} color={"secondary"}>Cancel</Button>
                    &nbsp;
                    <Button type={"submit"} variant={"contained"} color={"primary"}>Save</Button>
                </div>
            </Wrapper>
        </ValidatorForm>
    );
};

EnrollmentOptionsForm.propTypes = {
    enrollmentOption: PropTypes.object.isRequired,
    handleEnrollmentOptionChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    enableDelete: PropTypes.bool.isRequired
};

export default EnrollmentOptionsForm;