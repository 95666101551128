import {useSelector} from "react-redux";

const useAuth = () => {
    const backend = useSelector(state => state.auth.authentication_backend);
    const token = useSelector(state => state.auth.authentication_token);
    const authenticated = Boolean(backend);

    return { backend, token, authenticated };
};

export default useAuth;