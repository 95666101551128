import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React, {createContext, useContext, useState} from "react";

import darkTheme from "../Themes/dark";
import lightTheme from "../Themes/light";

const ThemeContext = createContext({
    dark: false,
    setDark: null,
    left_to_right: true,
    setLeftToRight: null
});

export function useThemer() {
    return useContext(ThemeContext);
}

const MySUThemeProvider = (props) => {
    // eslint-disable-next-line react/prop-types
    const { children } = props;

    const [dark, setDark] = useState(false);
    const [leftToRight, setLeftToRight] = useState(false);

    const theme = dark ? darkTheme : lightTheme;

    const contextValue = {
        dark: dark,
        setDark: setDark,
        leftToRight: leftToRight,
        setLeftToRight: setLeftToRight
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeContext.Provider value={contextValue}>
                <ThemeProvider theme={theme}>
                    { children }
                </ThemeProvider>
            </ThemeContext.Provider>
        </StyledEngineProvider>
    );
};

export default MySUThemeProvider;