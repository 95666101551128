import {Grid} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import { ValidatorForm } from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import CheckboxField from "../../../Components/FormComponents/Fields/CheckboxField";
import {countries, CountryFieldV2} from "../../../Components/FormComponents/Fields/CountryField";
import {DateFieldV3} from "../../../Components/FormComponents/Fields/DateField";
import {SelectFieldV3} from "../../../Components/FormComponents/Fields/SelectField";
import {TextFieldV3} from "../../../Components/FormComponents/Fields/TextField";
import useAddValidationRule from "../../../Components/Hooks/useAddValidationRule";
import hasNoSpaces from "../../../Components/ValidatorRules/hasNoSpaces";
import isIban from "../../../Components/ValidatorRules/isIban";
import isTruthy from "../../../Components/ValidatorRules/isTruthy";
import { useGetStudiesQuery } from "../../../Store/services/MySU/studies";


const ProfileForm = ({ profile, handleProfileChange, onSubmit, onCancel }) => {
    const { data: studies } = useGetStudiesQuery();

    const smallScreen = useMediaQuery((theme)=>theme.breakpoints.up("sm"), { noSsr: true });
    const mediumScreen = useMediaQuery((theme)=>theme.breakpoints.up("md"), { noSsr: true });
    const variant = smallScreen ? "separated" : "outlined";
    const direction = mediumScreen ? "row" : "column";

    useAddValidationRule(ValidatorForm, isTruthy.name, isTruthy.validate);
    useAddValidationRule(ValidatorForm, isIban.name, isIban.validate);
    useAddValidationRule(ValidatorForm, hasNoSpaces.name, hasNoSpaces.validate);
    
    const country = useMemo(()=>countries.find(country=>profile.country === country.label) || "",[profile.country]);

    return (
        <ValidatorForm
            onSubmit={onSubmit}
            onError={errors => console.log(errors)}
            debounceTime={1000}
        >
            <Grid container spacing={2} direction={direction}>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={"body1"} sx={{ textTransform: "uppercase" }} color={"#B4B8BB"} >General</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"given_name"}
                                label={"Given name"}
                                value={profile.given_name}
                                onChange={(event) => handleProfileChange("given_name", event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"initials"}
                                label={"Initials"}
                                value={profile.initials}
                                onChange={(event) => handleProfileChange("initials", event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"surname"}
                                label={"Last name"}
                                value={profile.surname}
                                onChange={(event) => handleProfileChange("surname", event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectFieldV3
                                variant={variant}
                                name={"gender"}
                                label={"Gender"}
                                value={profile.gender}
                                onChange={(event) => handleProfileChange("gender", event.target.value)}
                                required
                            >
                                <MenuItem value={"Other"}>Other</MenuItem>
                                <MenuItem value={"Female"}>Female</MenuItem>
                                <MenuItem value={"Male"}>Male</MenuItem>
                            </SelectFieldV3>

                        </Grid>
                        <Grid item xs={12}>
                            <DateFieldV3
                                variant={variant}
                                name={"date_of_birth"}
                                label={"Date of birth"}
                                value={profile.date_of_birth}
                                onChange={date => handleProfileChange("date_of_birth", date)}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={"body1"} sx={{ textTransform: "uppercase" }} color={"#B4B8BB"} >Contact</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"phone_number"}
                                label={"Phone number"}
                                value={profile.phone_number}
                                onChange={(event) => handleProfileChange("phone_number", event.target.value)}
                                // helperText={
                                //     <>
                                //         Please enter your phone number with country code, i.e. +31 06 12345678 instead of 0612345678.
                                //     </>
                                // }
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"email"}
                                label={"Email"}
                                value={profile.email}
                                onChange={(event) => handleProfileChange("email", event.target.value)}
                                validators={["isEmail"]}
                                errorMessages={["This is not a valid email"]}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"address"}
                                label={"Address"}
                                value={profile.address}
                                onChange={(event) => handleProfileChange("address", event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"zip_code"}
                                label={"Zip code"}
                                helperText={"Dutch: postcode"}
                                value={profile.zip_code}
                                onChange={(event) => handleProfileChange("zip_code", event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"city"}
                                label={"City"}
                                value={profile.city}
                                onChange={(event) => handleProfileChange("city", event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CountryFieldV2
                                variant={variant}
                                label={"Country"}
                                name={"country"}
                                value={country}
                                onChange={(event, value) => handleProfileChange("country", value ? value.label : "")}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={"body1"} sx={{ textTransform: "uppercase" }} color={"#B4B8BB"} >Financial</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"bank_account_name"}
                                label={"Bank account name"}
                                value={profile.bank_account_name}
                                onChange={(event) => handleProfileChange("bank_account_name", event.target.value)}
                                helperText={"Name as written on your bank card."}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"iban"}
                                label={"IBAN"}
                                value={profile.iban}
                                onChange={(event) => handleProfileChange("iban", event.target.value)}
                                validators={["isIban", "hasNoSpaces"]}
                                errorMessages={["This is not a valid iban", "Enter the iban without spaces"]}
                                helperText={
                                    <>
                                        Enter the IBAN without spaces, so NL12RABO0123456789 instead of NL12 RABO 0123 4567 89.
                                    </>
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"bic"}
                                label={"BIC"}
                                value={profile.bic}
                                onChange={(event) => handleProfileChange("bic", event.target.value)}
                                helperText={"If you have a Dutch bank, you can leave this blank."}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={"body1"} sx={{ textTransform: "uppercase" }} color={"#B4B8BB"} >Education</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectFieldV3
                                variant={variant}
                                name={"phase"}
                                label={"Phase"}
                                size={"small"}
                                value={profile.phase}
                                onChange={(event) => handleProfileChange("phase", event.target.value)}
                                required
                            >
                                <MenuItem value={"PhD"}>PhD</MenuItem>
                                <MenuItem value={"Master"}>Master</MenuItem>
                                <MenuItem value={"Premaster"}>Premaster</MenuItem>
                                <MenuItem value={"Bachelor"}>Bachelor</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                            </SelectFieldV3>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectFieldV3
                                variant={variant}
                                name={"study"}
                                label={"Study"}
                                value={profile.study}
                                onChange={(event) => handleProfileChange("study", event.target.value)}
                                required
                            >
                                { studies && studies.map((study, s) =>
                                    <MenuItem key={s} value={study.url}>{ study.studycode } -- { study.name }</MenuItem>
                                ) }
                            </SelectFieldV3>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant={"body1"} sx={{ textTransform: "uppercase" }} color={"#B4B8BB"} >Judicial</Typography>
                        </Grid>
                        <CheckboxField
                            checked={profile.read_and_agreed_to_privacy_policy}
                            value={profile.read_and_agreed_to_privacy_policy}
                            name={"read_and_agreed_to_privacy_policy"}
                            onChange={(event)=>handleProfileChange("read_and_agreed_to_privacy_policy", event.target.checked)}
                            label={<span>I have read and accept the <a href={"/privacy_policy"}>privacy policy</a>. *</span>}
                            labelPlacement={"end"}
                            validators={["isTruthy"]}
                            errorMessages={["This field is required"]}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent={"flex-end"}>
                <Grid item xs={3}>
                    <div/>
                    <div>
                        <Button 
                            version={"cancel"}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        &nbsp;
                        <Button
                            version={"save"}
                            type={"submit"}
                        >
                            Save
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
};


ProfileForm.propTypes = {
    update: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    handleProfileChange: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    disabled: PropTypes.bool,
};

ProfileForm.defaultProps = {
    onSuccess: ()=>{},
    disabled: false
};

export default ProfileForm;
