import {emptySplitApi} from "./index";

const studiesApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["Study"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getStudies: build.query({
                query: () => ({ url: "/studies", params: {limit: 200} }),
                providesTags: [{ type: "Study", id: "LIST" }],
                transformResponse: (response) => response.results
            }),
        }),
        overrideExisting: false
    });

export const { useGetStudiesQuery } = studiesApi;
export default studiesApi;