import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";

import Block from "../../Components/PageLayout/Content/Block";

const Home = () => {
    return (
        <Container>
            <Block>
                <Typography variant={"h5"}>Welcome to My SU</Typography>
                <hr className={"box-title-separator"}/>
                <Typography>
                    Hello. This is your home page for My SU.
                </Typography>
            </Block>
            <Block>
                <Typography variant={"h5"}>News</Typography>
                <hr className={"box-title-separator"}/>
                <Typography>
                    Any news regarding the platform will be placed here.
                </Typography>
            </Block>
        </Container>
    );
};

export default Home;