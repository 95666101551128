import DraftsIcon from "@mui/icons-material/Drafts";
import SubjectIcon from "@mui/icons-material/Subject";
import {toast} from "material-react-toastify";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";

import {useAPI} from "../../Contexts/API";
import Button from "../Buttons/Button";
import TextField from "../FormComponents/Fields/TextField";
import IconHolder from "../FormComponents/IconHolder";
import Wrapper from "../FormComponents/Wrapper";


const EmailTemplateForm = ({ association, emailTemplate, onTemplateChange}) => {
    const API = useAPI();

    let [template, setTemplate] = useState(emailTemplate);

    const resetAssociation = () => setTemplate(emailTemplate);

    const handleTemplateChange = (field, value) => setTemplate(prevState=>(
        {...prevState, [field]: value}
    ));

    const handleSubmit = () => {
        API.callv4({
            url: association.url,
            method: "PATCH",
            object: {
                [template.what_it_is_for+"_subject"]: template.subject,
                [template.what_it_is_for+"_message"]: template.message,
            },
            on_succes: (association_with_updated_template) => {
                onTemplateChange(association_with_updated_template);
            },
            on_failure: () => {
                toast.error("Update template failed");
            }
        });
    };

    return (
        <ValidatorForm
            onSubmit={handleSubmit}
            onError={errors => console.log(errors)}
        >
            <Wrapper>
                <IconHolder Icon={SubjectIcon}/>
                <TextField
                    name={"subject"}
                    value={template.subject}
                    onChange={(event)=>handleTemplateChange("subject",event.target.value)}
                    placeholder={"You have been matched"}
                />
            </Wrapper>
            <Wrapper>
                <IconHolder Icon={DraftsIcon}/>
                <TextField
                    name={"message"}
                    value={template.message}
                    onChange={(event)=>handleTemplateChange("message",event.target.value)}
                    placeholder={"Hi {MEMBER}\n\nYou have been matched with {MEMBER} by {ASSOCIATION}. You can contact your match by email using {EMAIL}.\n\nKind regards,\n\nThe MySU team"}
                    multiline
                />
            </Wrapper>

            <Wrapper>
                <div>
                    <Button variant={"outlined"} color={"secondary"}>
                        Reset to default
                    </Button>
                </div>
                <div>
                    <Button type={"submit"} variant={"contained"} color={"primary"}>Save</Button>
                    &nbsp;
                    <Button variant={"contained"} onClick={resetAssociation}>Cancel</Button>
                </div>
            </Wrapper>
        </ValidatorForm>
    );
};

EmailTemplateForm.propTypes = {
    association: PropTypes.object.isRequired,
    onTemplateChange: PropTypes.func.isRequired,
    emailTemplate: PropTypes.shape({
        what_it_is_for: PropTypes.string,
        subject: PropTypes.string,
        message: PropTypes.string,
    }).isRequired,
};

export default EmailTemplateForm;