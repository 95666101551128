import {emptySplitApi, pessimisticRemoval, pessimisticUpdateSingleInList} from "./index";

const debtEntriesApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["DebtEntry"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getDebtEntriesByAssociationAndProfile: build.query({
                query: ({ profileSlug, associationSlug }) => ({ url: "/debt_entries", params: { profile__slug: profileSlug, association__slug: associationSlug } }),
                providesTags: (result, error, { profileSlug, associationSlug }) => [{ type: "DebtEntry", id: `${profileSlug}__${associationSlug}` }],
                transformResponse: (response) => response.results
            }),
            getDebtEntriesByDebtCollection: build.query({
                query: (debtCollectionSlug) => ({ url: "/debt_entries", params: {"debt_collection__slug": debtCollectionSlug, limit: 1000} }),
                providesTags: (result, error, debtCollectionSlug) => [{ type: "DebtEntry", id: debtCollectionSlug }],
                transformResponse: (response) => response.results
            }),
            getCancellationsByAssociation: build.query({
                query: ( slug) => ({ url: "/debt_entries", params: { association__slug: slug, cancelled: true, paid_after_cancellation: false } }),
                providesTags: (result, error, slug) => [{ type: "DebtEntry", id: slug }],
                transformResponse: (response) => response.results
            }),
            getDebtEntriesDetail: build.query({
                query: (debtEntrySlug) => ({ url: `/debt_entries/${debtEntrySlug}/detail`}),
                providesTags: (result, error, debtEntrySlug) => [{ type: "DebtEntry", id: debtEntrySlug }],
            }),
            patchCancellation: build.mutation({
                query: ({ slug, associationSlug, ...patch }) => ({ url: `/debt_entries/${slug}`, method: "PATCH", body: patch }),
                async onQueryStarted({ slug, associationSlug }, { dispatch, queryFulfilled }) {
                    await pessimisticRemoval({
                        dispatch,
                        queryFulfilled,
                        api: debtEntriesApi,
                        query: "getCancellationsByAssociation",
                        queryParam: associationSlug,
                        entityFilter: patchedDebtEntry => patchedDebtEntry.slug === slug
                    });
                }
            }),
            patchDebtEntry: build.mutation({
                query: ({ slug, debtCollectionSlug, associationSlug, ...patch }) => ({ url: `/debt_entries/${slug}`, method: "PATCH", body: patch }),
                invalidatesTags: (result, error, arg) => [{ type: "DebtEntry", id: arg.associationSlug }],
                async onQueryStarted({ slug, debtCollectionSlug }, { dispatch, queryFulfilled }) {
                    await pessimisticUpdateSingleInList({
                        dispatch,
                        queryFulfilled,
                        api: debtEntriesApi,
                        query: "getDebtEntriesByDebtCollection",
                        entityToQueryParam: () => debtCollectionSlug,
                        entityFilter: patchedDebtEntry => patchedDebtEntry.slug === slug
                    });
                }
            }),
        }),
        overrideExisting: false
    });

export const {
    useGetDebtEntriesByAssociationAndProfileQuery,
    useGetDebtEntriesByDebtCollectionQuery,
    useGetDebtEntriesDetailQuery,
    usePatchDebtEntryMutation,
    useGetCancellationsByAssociationQuery,
    usePatchCancellationMutation
} = debtEntriesApi;
export default debtEntriesApi;