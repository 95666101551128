import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import {useHistory, useParams} from "react-router-dom";

import Button from "../../Components/Buttons/Button";
import RoutingButton from "../../Components/PageLayout/Content/RoutingButton";
import TopPageBlock from "../../Components/PageLayout/Content/TopPageBlock";
import useIsBoardMemberPage from "../../Store/hooks/useIsBoardMemberPage";
import {useGetEventsByAssociationQuery} from "../../Store/services/MySU/events";
import EventCard2 from "../MyAssociation/Home/Cards/EventCard";


const EventList = () => {
    const { slug } = useParams();
    const history = useHistory();
    const isBoardPage = useIsBoardMemberPage();

    const { data: events } = useGetEventsByAssociationQuery({associationSlug: slug, hidden: isBoardPage && ""});

    return (
        <Container maxWidth={"xl"}>
            <RoutingButton/>
            <TopPageBlock>
                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item>
                        <Typography variant={"h5"}>
                            Upcoming events
                        </Typography>
                    </Grid>
                    <Grid>
                        <Button variant={"contained"} onClick={()=>alert("Modal not implemented yet")}>
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </TopPageBlock>
            <Box sx={{ mt: 2 }}>
                <Grid container spacing={4} justifyContent={"space-between"}>
                    { events?.map(event=>(
                        <Grid item key={event.slug} sx={{ flexGrow: 1 }}>
                            <EventCard2 {...event} onClick={() => history.push("./events/" + event.slug)}/>
                        </Grid>
                    )) }
                </Grid>
            </Box>
        </Container>
    );
};

EventList.propTypes = {
};

export default EventList;