import PropTypes from "prop-types";
import React from "react";

import MembersDetail from "./MembersDetail";

const MembersDetailFromData = ({ association_membership }) => {
    return (
        <MembersDetail
            current_member={association_membership}
        />
    );
};

MembersDetailFromData.propTypes = {
    association_membership: PropTypes.object.isRequired
};

export default MembersDetailFromData;