import * as moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import withPropMap from "../../../Components/HOC/withPropMap";
import {usePatchAssociationFormDataMutation} from "../../../Store/services/MySU/associations";
import InfoForm from "../InfoForm";
import AssociationAboutForm from "./AssociationAboutForm";
import AssociationAboutInfo from "./AssociationAboutInfo";



const formObjectToAssociationFormPropsMap = ({ formObject, handleFormObjectChange, ...rest_props}) =>
    ({ association: formObject, handleAssociationChange: handleFormObjectChange, ...rest_props});
const formObjectToAssociationInfoPropsMap = ({ info, ...rest_props}) =>
    ({ association: info, ...rest_props});
const associationWithDatesToApi = (association) => ({
    ...association,
    founding_date: association.founding_date && moment(association.founding_date).format("YYYY-MM-DD"),
    dissolution_date: association.dissolution_date && moment(association.dissolution_date).format("YYYY-MM-DD"),
});

const fileFields = ["photo", "logo", "bylaws_en", "bylaws_nl", "articles_of_association_en", "articles_of_association_nl", "privacy_statement", "terms_of_service"];
const toFormData = (association, changedAssociation) => {
    const associationData = new FormData();
    Object.keys(changedAssociation).forEach(key => {
        if(fileFields.includes(key)) {
            if ( changedAssociation[key] instanceof File) {
                associationData.append(key, changedAssociation[key]);
            }
        } else {
            associationData.append(key, changedAssociation[key]);
        }
    });
    associationData.append("slug", association.slug);
    return associationData;
};

const AssociationAboutInfoForm = ({ association, showInfoFormStateButton, initialInfoOrFormState, MoreActionButtonGroup, postSubmit, postCancel }) => {
    const [ patchAssociation ] = usePatchAssociationFormDataMutation();

    const fieldAndValueToStateChanges = (field, value) => ({ [field]: value });
    const onSubmit = (association) => patchAssociation(toFormData(association, associationWithDatesToApi(association))).then(()=>postSubmit(association));

    const AssociationInfoComponent = withPropMap(AssociationAboutInfo, formObjectToAssociationInfoPropsMap);
    const AssociationFormComponent = withPropMap(AssociationAboutForm, formObjectToAssociationFormPropsMap);

    return (
        <InfoForm
            onSubmit={onSubmit}
            onCancel={postCancel}
            title={"About us"}
            infoFormObject={association}
            InfoComponent={AssociationInfoComponent}
            FormComponent={AssociationFormComponent}
            fieldAndValueToStateChanges={fieldAndValueToStateChanges}
            showInfoFormStateButton={showInfoFormStateButton}
            initialInfoOrFormState={initialInfoOrFormState}
            MoreActionButtonGroup={MoreActionButtonGroup}
        />
    );
};

const dummyFunction = () => {};

AssociationAboutInfoForm.propTypes = {
    association: PropTypes.object.isRequired,
    showInfoFormStateButton: PropTypes.bool.isRequired,
    initialInfoOrFormState: PropTypes.string.isRequired,
    MoreActionButtonGroup: PropTypes.elementType,
    postSubmit: PropTypes.func,
    postCancel: PropTypes.func
};

AssociationAboutInfoForm.defaultProps = {
    MoreActionButtonGroup: dummyFunction(),
    postSubmit: dummyFunction,
    postCancel: dummyFunction,
};

export default AssociationAboutInfoForm;