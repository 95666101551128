import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useMemo, useState} from "react";
import {loremIpsum} from "react-lorem-ipsum";
import {useHistory, useParams} from "react-router-dom";

import {BackButton} from "../../Components/BackButton";
import Button from "../../Components/Buttons/Button";
import Wrapper from "../../Components/FormComponents/Wrapper";
import useModalState from "../../Components/Hooks/useModalState";
import Block from "../../Components/PageLayout/Content/Block";
import FigmaContent from "../../Components/PageLayout/Content/FigmaContent";
import EnrollmentOptionsDropDown from "../../Forms/DropdownForms/EnrollmentOptions/EnrollmentOptionsDropDown";
import EventModalForm from "../../Forms/ModalForms/Event/EventModalForm";
import useIsBoardMemberPage from "../../Store/hooks/useIsBoardMemberPage";
import {useGetEnrollmentOptionsByEventQuery} from "../../Store/services/MySU/enrollmentOptions";
import {
    useAddEnrollmentMutation,
    useDeleteEnrollmentMutation,
    useGetEnrollmentsByEventQuery
} from "../../Store/services/MySU/enrollments";
import {useGetEventQuery} from "../../Store/services/MySU/events";
import {useGetEventTypesByAssociationQuery} from "../../Store/services/MySU/eventTypes";
import {usePreferredProfile} from "../../Store/services/MySU/user";
import EnrollModal from "./EnrollModal";
import {fromAPItoSchedulerFormat} from "./Scheduler";

const useStyles = makeStyles(theme => ({
    block: {
        maxWidth: "75%",
        margin: "auto",
        paddingTop: theme.spacing(5),
    },
    paperBlock: {
        background: theme.palette.background.paper,
        width: "75%",
        margin: "auto",
        marginTop: theme.spacing(5),
        padding: theme.spacing(3),
    }
}));

const EventDetail = () => {
    const classes = useStyles();

    const history = useHistory();
    const { slug, eventSlug } = useParams();
    const isBoardPage = useIsBoardMemberPage();
    const profile = usePreferredProfile();

    const { data: enrollmentOptions } = useGetEnrollmentOptionsByEventQuery(eventSlug);
    const { data: enrollments } = useGetEnrollmentsByEventQuery(eventSlug);
    const { data: event } = useGetEventQuery(eventSlug);
    const { data: eventTypes } = useGetEventTypesByAssociationQuery(slug);

    const [ addEnrollment ] = useAddEnrollmentMutation();
    const [ deleteEnrollment ] = useDeleteEnrollmentMutation();

    const yourEnrollment =  useMemo(()=>enrollments?.find(enrollment=>enrollment.profile === profile.url) || {},[enrollments, profile]);
    const [yourEnrollmentEnrollmentOption, setYourEnrollmentEnrollmentOption] = useState("");

    const [openEnrollModal, toggleOpenEnrollModal] = useModalState(false);
    const [openEventModal, toggleOpenEventModal] = useModalState(false);

    const [creating, setCreating] = useState(false);

    const editEnrollments = () => {
        history.push(eventSlug + "/enrollments");
    };

    const findEnrollmentOptionForEnrollment = (enrollment) => {
        let option = enrollmentOptions?.find(enrollment_option=>enrollment_option.url === enrollment.enrollment_option);
        return option ? option.name : "";
    };

    const splitEnrollmentsIntoEqualListOfEnrollments = (to_be_split_enrollments, number_of_lists) => {
        // create list with `number_of_lists` empty lists
        const initial_split_enrollments = new Array(number_of_lists).fill([]);
        // go over all `to_be_split_enrollments`
        return to_be_split_enrollments.reduce((split_enrollments, enrollment, index) => {
            // add the enrollment to the proper list of enrollments based on the current index
            split_enrollments[index % number_of_lists] = split_enrollments[index % number_of_lists].concat([enrollment]);
            // return the `split_enrollments` so it can be used/updated with the next enrollment
            return split_enrollments;
        }, initial_split_enrollments);
    };
    const splitEnrollments = splitEnrollmentsIntoEqualListOfEnrollments(enrollments || [], 4);

    const updateEnrollment = (field, value) => {
        setYourEnrollmentEnrollmentOption(value);
    };
    const onEnroll = () => {
        addEnrollment({
            ...yourEnrollment,
            profile: profile.url,
            send_email: true,
            enrollment_option: yourEnrollmentEnrollmentOption,
            association: `/associations/${slug}`,
            event: "/events/" + eventSlug
        }).then(()=>toggleOpenEnrollModal());
    };
    const onUnenroll = () => {
        deleteEnrollment(yourEnrollment);
    };

    const youAreEnrolled = useMemo(()=>yourEnrollment?.url !== undefined, [yourEnrollment]);
    const youMayEnroll = useMemo(()=> {
        const now = moment();
        if (event?.enrollable && enrollmentOptions?.length > 0) {
            return (moment(event.enrollable_from) <= now && now <= moment(event.enrollable_until));
        } else {
            return false;
        }
    }, [event, enrollmentOptions]);
    const youMayUnenroll = useMemo(()=> {
        const now = moment();
        if (event?.unenrollable) {
            return (now <= moment(event.unenrollable_until));
        } else {
            return false;
        }
    }, [event]);

    if (!event) {
        return (
            <FigmaContent
                name={""}
                image={null}
            />
        );
    }

    return (
        <FigmaContent
            name={event.name}
            image={event.image}
        >
            <EventModalForm
                visible={openEventModal}
                postCancel={toggleOpenEventModal}
                postSubmit={toggleOpenEventModal}
                postDelete={toggleOpenEventModal}
                selectedEvent={fromAPItoSchedulerFormat(event)}
                eventTypes={eventTypes || []}
            />
            <BackButton/>
            <Grid container>
                <Grid item xs={6}>
                    <div className={classes.block}>
                        <Typography>
                            { event?.description || loremIpsum() }
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.paperBlock}>
                        <Wrapper>
                            <Typography variant={"h5"}>Details</Typography>
                            &nbsp;
                            { isBoardPage &&
                            <Button color={"primary"} variant={"contained"} onClick={toggleOpenEventModal}>
                                Edit
                            </Button>
                            }
                        </Wrapper>
                        <Typography>From { moment(event.start_date).format("LLLL") }</Typography>
                        <Typography>Till { moment(event.end_date).format("LLLL") }</Typography>
                        <Typography>Location { event.location }</Typography>
                        { event.enrollable &&
                            <>
                                <br/>
                                <Typography>
                                    You can enroll as:
                                </Typography>
                                <ul>
                                    { enrollmentOptions?.map(enrollmentOption=> (
                                        <li key={enrollmentOption.slug}>{ enrollmentOption.name } (€ { enrollmentOption.participation_fee } )</li>
                                    )) }
                                </ul>
                            </>
                        }
                    </div>
                </Grid>
            </Grid>
            { event.enrollable &&
                <Container sx={{ mt: 2 }}>
                    <EnrollModal
                        open={openEnrollModal}
                        onCancel={toggleOpenEnrollModal}
                        onSubmit={onEnroll}
                        enrollmentOptions={enrollmentOptions || []}
                        enrollment={yourEnrollment}
                        updateEnrollment={updateEnrollment}
                    />
                    <Block>
                        <Wrapper>
                            <Typography variant={"h6"}>Enrollments</Typography>
                            <div>
                                <Button
                                    color={youAreEnrolled ? "secondary" : "primary"}
                                    variant={"contained"}
                                    onClick={youAreEnrolled ? onUnenroll : toggleOpenEnrollModal}
                                    disabled={youAreEnrolled ? !youMayUnenroll : !youMayEnroll}
                                >
                                    <Typography>{ youAreEnrolled ? "Unenroll" : "Enroll" }</Typography>
                                </Button>
                                &nbsp;
                                { isBoardPage &&
                                <Button color={"primary"} variant={"contained"} onClick={editEnrollments}>
                                    Edit
                                </Button>
                                }
                            </div>
                        </Wrapper>
                        <hr className={"box-title-separator"}/>
                        <Grid container>
                            { splitEnrollments.map((some_split_enrollments, index) => (
                                <Grid item xs={3} key={index}>
                                    <List>
                                        { some_split_enrollments.map((enrollment, id) => (
                                            <ListItem alignItems={"flex-start"} key={id}>
                                                <ListItemAvatar>
                                                    <Avatar src={enrollment.photo}/>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={enrollment.given_name}
                                                    secondary={findEnrollmentOptionForEnrollment(enrollment)}
                                                />
                                            </ListItem>
                                        )) }
                                    </List>
                                </Grid>
                            )) }
                        </Grid>
                    </Block>
                </Container>
            }
            { isBoardPage && event.enrollable &&
            <Container sx={{ mt: 2 }}>
                <Block>
                    <Wrapper>
                        <Typography variant={"h6"}>Enrollments Options</Typography>
                        <Button onClick={()=>setCreating(true)} variant={"contained"} color={"primary"}>
                            Add
                        </Button>
                    </Wrapper>
                    <hr className={"box-title-separator"}/>
                    <EnrollmentOptionsDropDown
                        associationUrl={`/associations/${slug}`}
                        eventUrl={`/events/${eventSlug}`}
                        enrollmentOptions={enrollmentOptions || []}
                        creating={creating}
                        postSubmit={()=>setCreating(false)}
                        postCancel={()=>setCreating(false)}
                    />
                </Block>
            </Container>
            }
        </FigmaContent>
    );
};

EventDetail.propTypes = {
};

export default EventDetail;