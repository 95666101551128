import InfoIcon from "@mui/icons-material/Info";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import {TextValidator} from "react-material-ui-form-validator";

import useFieldStyles, {useFieldStylesV2, useFieldStylesV3} from "../fieldStyles";

const TextField = ({name, value, ...remaining_props}) => {
    const classes = useFieldStyles();

    return (
        <TextValidator
            variant={"outlined"}
            className={classes.field}
            containerProps={{className: classes.field}}
            name={name || ""}
            label={name ? name[0].toUpperCase() + name.slice(1).replaceAll("_", " ") : ""}
            value={value === null ? "" : value}
            {...remaining_props}
        />
    );
};

const TextFieldV2 = (props) => {
    const classes = useFieldStyles();

    return (
        <TextValidator
            variant={"outlined"}
            size={"small"}
            className={classes.field}
            containerProps={{className: classes.field}}
            {...props}
        />
    );
};

const TextFieldV3 = ({ variant, label, labelXs, helperText, ...rest_props }) => {
    const classes = useFieldStylesV2();
    if (variant === "separated") {
        return (
            <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={labelXs}>
                    <Typography variant={"body1"}>
                        { label }
                        { helperText &&
                            <Tooltip title={helperText} arrow>
                                <InfoIcon fontSize={"small"} color={"primary"}/>
                            </Tooltip>
                        }
                    </Typography>
                </Grid>
                <Grid item xs={12 - labelXs}>
                    <TextValidator
                        variant={"outlined"}
                        className={classes.field}
                        containerProps={{className: classes.field}}
                        size={"small"}
                        {...rest_props}
                    />
                </Grid>
            </Grid>
        );
    } else {
        return (
            <TextValidator
                variant={variant}
                className={classes.field}
                containerProps={{className: classes.field}}
                label={label}
                helperText={helperText}
                {...rest_props}
            />
        );
    }
};

TextFieldV3.propTypes = {
    variant: PropTypes.string,
    label: PropTypes.string,
    labelXs: PropTypes.number,
    helperText: PropTypes.string
};

TextFieldV3.defaultProps = {
    helperText: "",
    variant: "separated",
    label: "",
    labelXs: 4,
};

const TextFieldV4 = (props) => {
    const classes = useFieldStylesV3();

    return (
        <TextValidator
            variant={"outlined"}
            size={"small"}
            multiline
            rows={18}
            className={classes.field}
            containerProps={{className: classes.field}}
            {...props}
        />
    );
};

TextField.propTypes = {
};

export { TextFieldV2, TextFieldV3, TextFieldV4 };
export default TextField;