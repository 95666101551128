import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import Button from "../../../../Components/Buttons/Button";
import CardGrid from "../../../../Components/Card/CardGrid";


const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(1) ,
    },
    keyboardButton: {
        width: "100%",
        minHeight: "40px",
        height: "9.3vh"
    },
}));

const letters = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

const Keyboard = ({ keyboardValue, setKeyboardValue }) => {
    const classes = useStyles();

    const onChange = (_keyboardValue) => {setKeyboardValue(prevState=>prevState+_keyboardValue);};
    const clear = () => setKeyboardValue("");

    let buttons = letters.map(letter=>({text: letter, onClick: () => onChange(letter), disabled: false, color: "primary" }));
    buttons = buttons.concat({ text: "←", onClick: clear, disabled: keyboardValue === "", color: "default" });

    return (
        <div className={classes.container}>
            <CardGrid
                Component={KeyboardButton}
                componentProps={buttons}
                chunkSize={3}
                itemGrowth={1}
            />
        </div>
    );
};

const KeyboardButton = ({ text, onClick, disabled, color }) => {
    const classes = useStyles();
    return (
        <Button
            color={color}
            variant={"contained"}
            size={"large"}
            className={classes.keyboardButton}
            onClick={onClick}
            disabled={disabled}
        >
            { text }
        </Button>
    );
};

export default Keyboard;