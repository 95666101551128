import Paper from "@mui/material/Paper";
import React from "react";

import MemberTable from "../../Components/Tables/MemberTable";
import MemberIncomingPopupForm from "../../Forms/PopupForms/Member/Incoming/MemberIncomingPopupForm";
import useMembersByStatus from "../../Store/hooks/useMembersByStatus";
import {useAcceptOrDenyMembershipMutation} from "../../Store/services/MySU/members";

const initialHeaders = ["given_name", "surname", "study", "phase", "type", "email", "date_joined", "pay_by"];
const messages = {
    editCommand: "Decide",
};


const MembersIncoming = () => {
    const { members } = useMembersByStatus("Pending");
    const [ acceptOrDenyMembership ] = useAcceptOrDenyMembershipMutation();

    const onEdit = (differences, original_row, edited_row) => {
        acceptOrDenyMembership({
            slug: original_row.urls.membership.split("/memberships/")[1],
            status: edited_row.status
        });
    };

    return (
        <Paper elevation={0}>
            <MemberTable
                initial_headers={initialHeaders}
                messages={messages}
                allowEdit={true}
                onEdit={onEdit}
                showEditing={true}
                showSelect={false}
                showGrouping={false}
                editThroughPopup={true}
                Popup={MemberIncomingPopupForm}
                rows={members || []}
                title={"Incoming requests"}
            />
        </Paper>
    );
};

export default MembersIncoming;