import {Grid} from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {Item} from "@mui-treasury/components/flex";
import PropTypes from "prop-types";
import React from "react";

const Info = ({ headerless, ...props}) => {
    if (headerless === true) {
        return <InfoWithoutTableHeader {...props}/>;
    } else {
        return <InfoWithTableHeader {...props}/>;
    }
};

Info.propTypes = {
    headerless: PropTypes.bool.isRequired,
};

export default Info;

const InfoWithTableHeader = ({ data }) => {
    return (
        <table>
            <tbody>
                <Grid container spacing={2}>
                    { /*<Grid container item lg={6}>*/ }
                    { Object.entries(data).map(([table_header, table_content], id)=> {
                        return (
                            // <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <React.Fragment key={id}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant={"body1"} sx={{ textTransform: "uppercase" }} color={"#B4B8BB"}>{ table_header }</Typography>
                                        </Grid>
                                        { Object.entries(table_content).map(([row_header, row_content], id)=>{
                                            return <Row key={id} row_header={row_header} row_content={row_content} />;
                                        }) }
                                    </Grid>
                                </React.Fragment>

                            </Grid>
                            // </Grid>
                        );
                    }) }
                    { /*</Grid>*/ }
                </Grid>
            </tbody>
        </table>
    );
};
// <React.Fragment key={id}>
//     <tr>
//         <th colSpan={2} style={{whiteSpace: "nowrap", textAlign: "left"}}>
//             <Typography variant={"h6"}>{ table_header }</Typography>
//         </th>
//     </tr>
//     { Object.entries(table_content).map(([row_header, row_content], id)=>{
//         return <Row key={id} row_header={row_header} row_content={row_content} />;
//     }) }
// </React.Fragment>
InfoWithTableHeader.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired
};

const InfoWithoutTableHeader = ({ data }) => {
    return (
        <table>
            <tbody>
                { Object.entries(data).map(([row_header, row_content], id)=>{
                    return <Row key={id} row_header={row_header} row_content={row_content} />;
                }) }
            </tbody>
        </table>
    );
};

InfoWithoutTableHeader.propTypes = {
    data: PropTypes.object.isRequired
};

const Row = ({ row_header, row_content }) => {
    return (
        // <tr>
        //     <td style={{whiteSpace: "nowrap", width: 250}}>{ row_header }</td>
        //     <td>{ row_content }</td>
        // </tr>
        <Grid item xs={12}>
            <Grid container spacing={2} direction={"row"}>
                <Grid item xs={4}>
                    <Typography variant={"body1"}>{ row_header }</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant={"body1"}>{ row_content }</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

Row.propTypes = {
    row_header: PropTypes.string.isRequired,
    row_content: PropTypes.node
};

Row.defaultProps = {
    row_content: ""
};