import MenuItem from "@mui/material/MenuItem";
import React from "react";

import CheckboxField from "./CheckboxField";
import NumberField from "./NumberField";
import SelectField, {SelectFieldV3} from "./SelectField";
import TextField, {TextFieldV3} from "./TextField";


const SpecificDataField = ({ field, value, onChange, ...rest_props }) =>{
    let form = null;
    switch (field.type) {
    case "Choice":
        form = (
            <SelectFieldV3
                name={field.name}
                value={value}
                onChange={(event)=>onChange(field.url, event.target.value)}
                {...rest_props}
            >
                { field.choices.split(",").map((choice,c)=>(
                    <MenuItem key={c} value={choice}>{ choice }</MenuItem>
                )) }
            </SelectFieldV3>
        );
        break;
    case "String":
        form = (
            <TextFieldV3
                name={field.name}
                value={value}
                onChange={(event)=>onChange(field.url, event.target.value)}
                {...rest_props}
            />
        );
        break;
    case "Boolean":
        if (field.mandatory) {
            form = (
                <CheckboxField
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                    label={field.name + " *"}
                    value={value !== "False"}
                    checked={value !== "False"}
                    onChange={(event)=>onChange(field.url, event.target.checked ? "True" : "False")}
                    {...rest_props}
                />
            );
        } else {
            form = (
                <SelectFieldV3
                    name={field.name}
                    value={value}
                    onChange={(event)=>onChange(field.url, event.target.value)}
                    {...rest_props}
                >
                    <MenuItem value={"True"}>Yes</MenuItem>
                    <MenuItem value={"False"}>No</MenuItem>
                </SelectFieldV3>
            );
        }
        break;
    case "Number":
        form = (
            <NumberField
                name={field.name}
                value={parseInt(value)}
                onChange={(event)=>onChange(field.url, event.target.value.toString())}
                {...rest_props}
            />
        );
        break;
    }
    return (
        form
    );
};

export default SpecificDataField;