import Cookies from "js-cookie";
import PropTypes from "prop-types";
import React, {createContext, useContext} from "react";
import {useDispatch} from "react-redux";

import {Helper} from "../Helper";
import {clearCredentials} from "../Store/slices/authentication";

export const APIContext = createContext({
    call: ()=>{},
    callv2: ()=>{},
    callv3: ()=>{},
    callv4: ()=>{},
});

export function useAPI() {
    return useContext(APIContext);
}

const API = ({ children }) => {
    const dispatch = useDispatch();

    const handleLogout = () => dispatch(clearCredentials());

    const api_call = (url, method, object = undefined, json_or_form_data = "json", on_succes = undefined, on_failure = undefined, include = true, headers= {}) => {
        // make body
        // -----------------
        let body;
        if (json_or_form_data === "json") {
            body = JSON.stringify(object);
        } else if (json_or_form_data === "form-data") {
            body = new FormData();
            for (let property in object) {
                if (object[property] !== null) {
                    body.append(property, object[property]);
                }
            }
        } else {
            console.error("invalid content-type");
        }

        // set headers
        // -----------------
        if (json_or_form_data === "json") {
            headers["Content-Type"] = "application/json";
        }
        if (localStorage.getItem("SUN_backend") === "jwt"){
            headers["Authorization"] = localStorage.getItem("SUN_api_token");
        }

        // set fetch parameters
        // -----------------
        let fetch_parameters = {
            method: method,
            redirect: "follow"
        };
        if (localStorage.getItem("SUN_backend") === "OIDC" && include === true){
            fetch_parameters.credentials = "include";
            headers["X-CSRFToken"] = Cookies.get("csrftoken");
        }
        if (!Helper.isEmpty(headers)) {
            fetch_parameters.headers =  headers;
        }
        if (!(method.toUpperCase() === "GET" || method.toUpperCase() === "HEAD")){
            fetch_parameters.body = body;
        }

        // Do fetch call
        // -----------------
        let succes;
        let code;
        return fetch(url, fetch_parameters).then((resp)=> {
            succes = resp.ok;
            code = resp.status;
            return resp.json();
        }).catch(error => {
            console.error("error", error);
        }).then(resp => {
            // console.log(resp, succes, code);
            if (succes) {
                if (on_succes !== undefined) {
                    on_succes(resp);
                }
            } else {
                if (code === 401) {
                    handleLogout();
                } else {
                    if (on_failure !== undefined) {
                        on_failure(resp);
                    }
                }
            }
        });
    };

    const api_callv2 = ({url, method, object = undefined, json_or_form_data = "json", on_succes = undefined, on_failure = undefined, external = false, headers = {}}={}) => {
        return api_call(url, method, object, json_or_form_data, on_succes, on_failure, !external, headers);
    };

    const api_callv3 = ({url, method, object = undefined, json_or_form_data = "json", on_succes = undefined, on_failure = undefined, external = false, headers = {}}={}) => {
        // Every url starting with a '/' is a path, and the base url to the api should be prepended.
        if (url[0] === "/") {
            url = process.env.REACT_APP_API_URL + url;
        }
        return api_call(url, method, object, json_or_form_data, on_succes, on_failure, !external, headers);
    };

    const api_callv4 = ({url, method, queryParams = {}, object = undefined, json_or_form_data = "json", on_succes = undefined, on_failure = undefined, external = false, headers = {}}={}) => {
        url += Object.entries(queryParams).reduce((queryParamString, queryParam)=>(
            queryParamString
                ? queryParamString + "&" + queryParam.join("=")
                : "?" + queryParam.join("=")),
        "");
        return api_callv3({url, method, object, json_or_form_data, on_succes, on_failure, external, headers});
    };

    const contextValue = {
        call: api_call,
        callv2: api_callv2,
        callv3: api_callv3,
        callv4: api_callv4
    };

    return (
        <APIContext.Provider value={contextValue}>
            { children }
        </APIContext.Provider>
    );
};

API.propTypes = {
    children: PropTypes.node
};


export default API;