import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import {useParams} from "react-router-dom";

import Block from "../../Components/PageLayout/Content/Block";
import PageContent from "../../Components/PageLayout/Content/Content";
import Info from "../../Forms/InfoForms/Info";
import {useGetMatchingsByAssociationQuery} from "../../Store/services/MySU/matchings";


const useStyles = makeStyles(theme => ({
    avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    info: {
        marginLeft: theme.spacing(3)
    }
}));

const MyMatches = ({ profile }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const { slug } = useParams();
    const { data: matchings, loading, error } = useGetMatchingsByAssociationQuery(slug);

    if (error) {return null;}

    if (loading) {
        return (
            <PageContent title={"Matchings"}>
                <Container>
                    <Block>
                        <CircularProgress />
                    </Block>
                </Container>
            </PageContent>
        );
    }

    let left_matchings = matchings
        ?.filter(matching => matching.left_slug === profile.slug)
        .map(matching =>({name: matching.right_name, email: matching.right_email})) || [];

    let right_matchings = matchings
        ?.filter(matching => matching.right_slug === profile.slug)
        .map(matching =>({name: matching.left_name, email: matching.left_email})) || [];

    const myMatches = left_matchings.concat(right_matchings);

    return (
        <PageContent title={"Matchings"}>
            <Container>
                <Block>
                    <Typography variant={"h5"}>
                        { myMatches.length > 0 ? "You are matched with:" : "You are not matched to someone at the moment." }
                    </Typography>
                    <List>
                        { myMatches.map((matching, m) => (
                            <ListItem key={m}>
                                <Avatar className={classes.avatar} key={m} src={matching.photo}/>
                                <div className={classes.info}>
                                    <Info
                                        headerless={true}
                                        data={{
                                            name: matching.name,
                                            email: matching.email
                                        }}
                                    />
                                </div>
                            </ListItem>
                        )) }
                    </List>
                </Block>
            </Container>
        </PageContent>
    );
};

MyMatches.propTypes = {
    profile: PropTypes.object.isRequired,
};

MyMatches.defaultProps = {
};

export default MyMatches;