import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import {isEqual} from "lodash";
import PropTypes from "prop-types";
import React, {useState} from "react";

import {useDrawerContext} from "./Drawer";
import Link from "./Link";


/**
 *
 * @param primary
 * @param icon
 * @param depth
 * @param items
 * @param pathname
 * @param branch
 * @returns {JSX.Element}
 * @constructor
 */
const Dropdown = ({primary, icon, depth, items, pathname, branch }) => {
    const theme = useTheme();
    const { openBranch, setOpenBranch } = useDrawerContext();

    const handleToggle = () => {
        if (isEqual(openBranch, branch)) {
            setOpenBranch([]);
        } else {
            setOpenBranch(branch);
        }
    };

    const open = isEqual(openBranch, branch);
    return (
        <List sx={{ p: 0}}>
            <ListItem button={true} onClick={handleToggle} style={{paddingLeft: theme.spacing((2+depth))}}>
                <ListItemIcon style={{ color: "#fff" }}>{ icon }</ListItemIcon>
                <ListItemText primary={primary} />
                { open ? <ExpandLess /> : <ExpandMore /> }
            </ListItem>
            <Collapse in={open} timeout={"auto"}>
                { items.map((item, id)=>
                    (item.items && item.items.length > 0)
                        ? <Dropdown key={id} depth={depth+1} pathname={pathname} branch={branch.concat([id])} {...item} />
                        : <Link key={id} depth={depth+1} pathname={pathname} {...item} />
                ) }
            </Collapse>
        </List>
    );
};

Dropdown.propTypes = {
    depth: PropTypes.number.isRequired,
    icon: PropTypes.object.isRequired,
    primary: PropTypes.string.isRequired,
    items:PropTypes.array.isRequired,
    branch:PropTypes.array.isRequired,
    pathname:PropTypes.string.isRequired
};

export default Dropdown;