import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(theme=>({
    icon: {
        margin: theme.spacing(2, 0),
        marginRight: theme.spacing(2),
    },
}));

const FontAwesomeIconHolder = ({ icon, ...rest_props }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <FontAwesomeIcon
            className={["MuiSvgIcon-root MuiSvgIcon-colorAction", classes.icon].join(" ")}
            icon={icon}
            {...rest_props}
        />
    );
};

FontAwesomeIconHolder.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
};

export default FontAwesomeIconHolder;
