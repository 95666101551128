import Box from "@mui/material/Box";
import React from "react";

const TopPageBlock = ({ children }) => {
    return (
        <Box sx={{ width: "100%", backgroundColor: "background.default", borderRadius: 4, pl: 5, pr: 5, pt: 2, pb: 2 }}>
            { children }
        </Box>
    );
};

export default TopPageBlock;