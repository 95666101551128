import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, {useState} from "react";

import Wrapper from "../../../Components/FormComponents/Wrapper";
import Block from "../../../Components/PageLayout/Content/Block";
import ExtremeTable from "../../../Components/Tables/ExtremeTable";
import CancellationPopupForm from "../../../Forms/PopupForms/DebtEntry/CancellationPopupForm";
import {
    useGetCancellationsByAssociationQuery,
    usePatchCancellationMutation
} from "../../../Store/services/MySU/debtEntries";

const headers = [
    {name: "name", title: "Who"},
    {name: "total_price", title: "Amount"},
    {name: "date", title: "Debt collection date"},
    {name: "debt_collection_mandate_id", title: "Mandate ID"},
    {name: "bank_account_name", title: "Bank account name"},
    {name: "debt_collection_mandate_signature_date", title: "Signature date"},
    {name: "iban", title: "IBAN"},
    {name: "bic", title: "BIC"},
    {name: "email", title: "Email"},
];
const booleanColumns = ["cancelled"];
const currencyColumns = ["total_price"];
const dateColumns = ["debt_collection_mandate_signature_date"];
const dateTimeColumns = ["date"];

const defaultHiddenColumnNames = ["debt_collection_mandate_id", "bank_account_name", "debt_collection_mandate_signature_date", "bic"];

const Cancellations = ({ association }) => {
    const [selection, setSelection] = useState([]);

    const { data: rows } = useGetCancellationsByAssociationQuery(association.slug);
    const [ patchCancellation ] = usePatchCancellationMutation();

    const onEdit = (differences, original_row, edited_row, edited_row_index) => {
        patchCancellation({
            slug: original_row.slug,
            associationSlug: association.slug,
            paid_after_cancellation: edited_row.paid_after_cancellation
        });
    };


    return (
        <Paper elevation={0}>
            <ExtremeTable
                headers={headers}
                defaultHiddenColumnNames={defaultHiddenColumnNames}
                rows={rows || []}
                booleanColumns={booleanColumns}
                currencyColumns={currencyColumns}
                dateColumns={dateColumns}
                dateTimeColumns={dateTimeColumns}
                showExporter={true}
                showGrouping={false}
                showSelect={false}
                showEditing={true}
                selection={{selection: selection, setSelection: setSelection}}
                allowDelete={false}
                editThroughPopup={true}
                messages={{
                    editCommand: "Resolve"
                }}
                onEdit={onEdit}
                Popup={CancellationPopupForm}
                title={"Cancellations"}
            />
        </Paper>
    );
};

Cancellations.propTypes = {
    association: PropTypes.object.isRequired
};

export default Cancellations;