import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, {useCallback, useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import Button from "../../../Components/Buttons/Button";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import Block from "../../../Components/PageLayout/Content/Block";
import MemberTable from "../../../Components/Tables/MemberTable";
import useCurrentMembers from "../../../Store/hooks/useCurrentMembers";
import {
    useAddGroupMembershipsMutation,
    useGetGroupMembershipByGroupQuery
} from "../../../Store/services/MySU/groupMemberships";

const initial_headers = ["given_name", "surname", "date_joined", "phase"];
const defaultFilters = [{columnName: "given_name", operation: "startsWith", value: ""}];
const defaultSorting = [{ columnName: "given_name", direction: "asc" }];

const GroupMemberManagementAssociationMemberList = () => {
    const { slug, groupSlug} = useParams();

    const { members } = useCurrentMembers(slug);
    const { data: groupMemberships } = useGetGroupMembershipByGroupQuery(groupSlug);
    const [ addGroupMemberships ] = useAddGroupMembershipsMutation();

    const [selection, setSelection] = useState([]);

    const profileUrls = useMemo(()=>groupMemberships?.map(m=> m.profile), [groupMemberships]);
    const rowSelectionEnabledFilter = useCallback((row)=>!profileUrls?.includes(row.url), [profileUrls]);

    const addSelectedMembers = () => {
        const toBePostedMemberships = members
            .filter((member)=> selection.includes(member.slug))
            .map(member=>({
                association: `/associations/${slug}`,
                group: `/groups/${groupSlug}`,
                profile: member.urls.profile
            }));
        addGroupMemberships(toBePostedMemberships).then(()=>setSelection([]));
    };

    return (
        <Block>
            <Typography variant={"h5"}>All members</Typography>
            <hr className={"box-title-separator"}/>
            <MemberTable
                rows={members || []}
                rowSelectionEnabledFilter={rowSelectionEnabledFilter}
                initial_headers={initial_headers}
                defaultFilters={defaultFilters}
                defaultSorting={defaultSorting}
                selection={{selection: selection, setSelection: setSelection}}
                showEditing={false}
            />
            <Wrapper>
                <div/>
                <Button color={"primary"} variant={"contained"} disabled={selection.length === 0} onClick={addSelectedMembers}>
                    Add selection to group
                </Button>
            </Wrapper>
        </Block>
    );
};

GroupMemberManagementAssociationMemberList.propTypes = {
    association: PropTypes.object.isRequired
};

export default GroupMemberManagementAssociationMemberList;