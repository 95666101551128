import Autocomplete from "@mui/material/Autocomplete";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, {useMemo} from "react";

import {useGetCurrentMembersByAssociationQuery} from "../../../Store/services/MySU/members";
import {TextFieldV3} from "./TextField";

const useStyles = makeStyles({
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

const useMemberFieldOptions = (associationSlug) => {
    const { data: memberships } = useGetCurrentMembersByAssociationQuery(associationSlug);

    return useMemo(() => [{url: "", label: " - "}].concat(memberships
        ? memberships.map(membership => ({
            url: membership.profile.url,
            label: membership.profile.given_name + " " + membership.profile.surname + " (" + membership.profile.student_number + ")"
        }))
        : [])
    , [memberships]);
};

const MemberField = ({ associationSlug, ...props }) => {
    const classes = useStyles();

    const memberFieldOptions = useMemberFieldOptions(associationSlug);
    return (
        <Autocomplete
            id={"country-select-demo"}
            style={{ width: "100%" }}
            options={memberFieldOptions}
            classes={{
                // option: classes.option,
            }}
            autoHighlight
            isOptionEqualToValue={(option, value)=>option.url === value.url}
            getOptionLabel={(option) => option.label || ""}
            renderInput={(params) => (
                <TextFieldV3
                    {...params}
                    {...props}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
            {...props}
        />
    );
};

MemberField.propTypes = {
    associationSlug: PropTypes.string.isRequired,
};

export {
    useMemberFieldOptions
};
export default MemberField;