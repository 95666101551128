import CodeIcon from "@mui/icons-material/Code";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {MenuItem} from "@mui/material";
import PropTypes from "prop-types";
import React, {useState} from "react";

import FileField from "../../Components/FormComponents/Fields/FileField";
import SelectField from "../../Components/FormComponents/Fields/SelectField";
import IconHolder from "../../Components/FormComponents/IconHolder";
import Wrapper from "../../Components/FormComponents/Wrapper";
import FormModal from "../../Components/Modals/FormModal";

const ImportModal = ({title, cancelButtonText, submitButtonText, open, onCancel, onSubmit}) => {
    const [csv, setCsv] = useState();
    const [delimiter, setDelimiter] = useState("");

    return (
        <FormModal
            title={title}
            size={"xs"}
            open={open}
            onCancel={onCancel}
            onSubmit={onSubmit}
            cancelButtonText={cancelButtonText}
            submitButtonText={submitButtonText}
        >
            <Wrapper>
                <IconHolder Icon={InsertDriveFileIcon}/>
                <FileField
                    onChange={(event)=>setCsv(event.target.files[0])}
                    name={"file"}
                    label={"File"}
                />
            </Wrapper>
            <Wrapper>
                <IconHolder Icon={CodeIcon}/>
                <SelectField
                    name={"delimiter"}
                    value={delimiter}
                    onChange={(event)=>setDelimiter(event.target.value)}
                >
                    <MenuItem value={"\t"}>tab</MenuItem>
                    <MenuItem value={"|"}>|</MenuItem>
                    <MenuItem value={";"}>;</MenuItem>
                    <MenuItem value={","}>,</MenuItem>
                </SelectField>
            </Wrapper>
        </FormModal>
    );
};

ImportModal.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    cancelButtonText: PropTypes.string,
    submitButtonText: PropTypes.string
};

ImportModal.defaultProps = {
    cancelButtonText: "Cancel",
    submitButtonText: "Import"
};

export default ImportModal;