import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {toast} from "material-react-toastify";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import Button from "../../Components/Buttons/Button";
import TextField from "../../Components/FormComponents/Fields/TextField";
import FontAwesomeIconHolder from "../../Components/FormComponents/FontAwesomeIconHolder";
import Wrapper from "../../Components/FormComponents/Wrapper";
import Block from "../../Components/PageLayout/Content/Block";
import {useAPI} from "../../Contexts/API";

const Settings = ({ association: propAssociation }) => {
    const API = useAPI();

    const [emailSettings, setEmailSettings] = useState({});

    const getEmailSettings = () => {
        API.callv4({
            url: propAssociation.url,
            queryParams: {fields: "domain"},
            method: "GET",
            on_succes: (response) => {
                setEmailSettings(response);
            },
            on_failure: () => {
                toast.error("Something went wrong");
            }
        });
    };

    useEffect(getEmailSettings, [propAssociation]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = () => {
        API.callv4({
            url: propAssociation.url,
            queryParams: {fields: "domain"},
            method: "PATCH",
            object: emailSettings,
            on_succes: (response) => {
                setEmailSettings({...response});
            },
            on_failure: () => {
                toast.error("Something went wrong");
            }
        });
    };

    const handleChange = (field, value) => {
        setEmailSettings(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    return (
        <Container>
            <Block>
                <Typography variant={"h5"}>Email Settings</Typography>
                <hr className={"box-title-separator"}/>
                <ValidatorForm
                    onSubmit={handleSubmit}
                    onError={(errors)=>console.error(errors)}
                >
                    <Wrapper>
                        <FontAwesomeIconHolder icon={"at"}/>
                        <TextField
                            name={"Domain"}
                            value={emailSettings.domain}
                            onChange={(event) => handleChange("domain", event.target.value)}
                            helperText={
                                "If you have a domain set, then all groups with an email of that domain will get an alias made for them. " +
                                "You cannot set this yourself. Sent an email to help@su.utwente.nl for more details."
                            }
                            disabled
                        />
                    </Wrapper>
                    { /*<Wrapper>*/ }
                    { /*    <IconHolder Icon={ShareIcon}/>*/ }
                    { /*    <SelectField*/ }
                    { /*        name={"email_on_create_matching"}*/ }
                    { /*        value={emailSettings.email_on_create_matching}*/ }
                    { /*        onChange={(event) => handleChange("email_on_create_matching", event.target.value)}*/ }
                    { /*        helperText={*/ }
                    { /*            "When you set this to 'yes', then whenever you create a matching they will be informed directly. " +*/ }
                    { /*            "Otherwise you will have to email manually."*/ }
                    { /*        }*/ }
                    { /*    >*/ }
                    { /*        <MenuItem value={"True"}>Yes</MenuItem>*/ }
                    { /*        <MenuItem value={"False"}>No</MenuItem>*/ }
                    { /*    </SelectField>*/ }
                    { /*</Wrapper>*/ }
                    <Wrapper>
                        <div/>
                        <Button variant={"contained"} color={"primary"} type={"submit"}>
                            Save
                        </Button>
                    </Wrapper>
                </ValidatorForm>
            </Block>
        </Container>
    );
};

Settings.propTypes = {
    association: PropTypes.object.isRequired
};

export default Settings;