import PropTypes from "prop-types";
import React from "react";


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role={"tabpanel"}
            // hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {  children }
            { /*{ value === index && children }*/ }
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default TabPanel;