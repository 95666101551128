import {CircularProgress, Skeleton} from "@mui/material";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {isEmpty} from "lodash";
import React from "react";

import Wrapper from "../../../Components/FormComponents/Wrapper";


const AssociationAboutInfo = ({ association }) => {
    if (isEmpty(association)) {
        return <Wrapper><CircularProgress /></Wrapper>;
    }
    return (
        <Typography style={{whiteSpace: "pre-line"}}>
            { association.description }
        </Typography>
    );
};

export default AssociationAboutInfo;