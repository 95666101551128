import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {orderBy} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import Wrapper from "../../../Components/FormComponents/Wrapper";
import withExtraProps from "../../../Components/HOC/withExtraProps";
import Block from "../../../Components/PageLayout/Content/Block";
import ExtremeTable from "../../../Components/Tables/ExtremeTable";
import PurchasePopupForm from "../../../Forms/PopupForms/Purchase/PurchasePopupForm";
import {
    useAddInvoiceMutation, useDeleteInvoiceMutation,
    useGetInvoicesByAssociationQuery,
    usePatchInvoiceMutation
} from "../../../Store/services/MySU/invoices";
import {
    useGetCurrentMembersByAssociationQuery,
} from "../../../Store/services/MySU/members";

const columns = [
    { name: "member", title: "Member" },
    { name: "product", title: "Product" },
    { name: "price", title: "Price"},
    { name: "number", title: "Number"},
    { name: "subtotal", title: "Subtotal"},
    { name: "purchase_date", title: "Date purchased"},
    { name: "debt_collection_date", title: "Debt Collection"},
];
const numberColumns = ["number"];
const currencyColumns = ["price", "subtotal"];
const dateTimeColumns = ["purchase_date", "debt_collection_date"];

const Purchases = () => {
    const { slug } = useParams();

    const { data: members } = useGetCurrentMembersByAssociationQuery(slug);
    const { data: invoices } = useGetInvoicesByAssociationQuery(slug);

    const [ addInvoice ] = useAddInvoiceMutation();
    const [ patchInvoice ] = usePatchInvoiceMutation();
    const [ removeInvoice ] = useDeleteInvoiceMutation();

    const Popup = withExtraProps(PurchasePopupForm, {associationSlug: slug});

    const [selection, setSelection] = useState([]);

    const onAdd = (newInvoice) => addInvoice({
        ...newInvoice,
        association: `/associations/${slug}`,
        date_issued: moment(newInvoice.date_issued).format("YYYY-MM-DD")
    });
    const onDelete = (removedInvoiceSlug) => removeInvoice({ slug: removedInvoiceSlug, association: `/associations/${slug}` });
    const onEdit = (differences, originalInvoice, newInvoice) => patchInvoice(newInvoice);

    const rows = useMemo(()=> {
        const invoiceWithEntries = invoices
            ? invoices.map(invoice => {
                let member = invoice.member;
                if (!member && members) {
                    const profile = members.find(member=>member.profile.url === invoice.profile).profile;
                    member = profile.given_name + " " + profile.surname;
                }
                return {
                    ...invoice,
                    member: member,
                };
            })
            : [];
        return orderBy(invoiceWithEntries, ["date_issued"], ["desc"]);
    }, [invoices, members]);

    return (
        <Block>
            <Wrapper>
                <Typography variant={"h5"}>Purchases</Typography>
            </Wrapper>
            <hr className={"box-title-separator"}/>
            <Paper>
                <ExtremeTable
                    rows={rows}
                    headers={columns}
                    currencyColumns={currencyColumns}
                    dateTimeColumns={dateTimeColumns}
                    numberColumns={numberColumns}
                    selection={{selection: selection, setSelection:setSelection}}
                    onAdd={onAdd}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    showEditing={true}
                    showExporter={true}
                    editThroughPopup={true}
                    Popup={Popup}
                    allowAdd={true}
                    allowDelete={true}
                    allowEdit={true}
                    showGrouping={false}
                />
            </Paper>
        </Block>
    );
};

Purchases.propTypes = {
    association: PropTypes.object.isRequired
};

export default Purchases;