import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {Container, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useState} from "react";
import { loremIpsum } from "react-lorem-ipsum";
import {useHistory, useParams} from "react-router-dom";

import Button from "../../../Components/Buttons/Button";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import useModalState from "../../../Components/Hooks/useModalState";
import ConfirmationModal from "../../../Components/Modals/ConfirmationModal";
import Block from "../../../Components/PageLayout/Content/Block";
import FigmaContent from "../../../Components/PageLayout/Content/FigmaContent";
import RoutingButton from "../../../Components/PageLayout/Content/RoutingButton";
import TopPageBlock from "../../../Components/PageLayout/Content/TopPageBlock";
import {useGetEventsByAssociationQuery} from "../../../Store/services/MySU/events";
import {useGetModulesByAssociationQuery} from "../../../Store/services/MySU/modules";
import {useGetNewsByAssociationQuery} from "../../../Store/services/MySU/news";
import {useDeleteMembershipMutation} from "../../../Store/services/MySU/user";
import EventCard from "./Cards/EventCard";
import NewsCard from "./Cards/NewsCard";
import EventModal from "./Modals/EventModal";
import NewsModal from "./Modals/NewsModal";

const useStyles = makeStyles(theme => ({
    eventsBlock: {
        background: theme.palette.background.paper,
        width: "60%",
        margin: "auto",
        marginTop: "32px"
    },
    eventsList: {
        padding: theme.spacing(2)
    },
    moreEventsButton: {
        height: "40px",
        width: "100%",
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.dark
    },
    introText: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(6),
    },
    container: {

    }
}));


const Member = ({ association }) => {
    const { slug } = useParams();

    const { data: modules } = useGetModulesByAssociationQuery(slug);
    const { data: news } = useGetNewsByAssociationQuery(slug);
    const { data: events } = useGetEventsByAssociationQuery(slug);

    const [selectedNewsItem, setSelectedNewsItem] = useState("");
    const [selectedEventItem, setSelectedEventItem] = useState("");

    const onNewsClick = (newsSlug) => () => setSelectedNewsItem(newsSlug);
    const onEventsClick = (eventSlug) => () => setSelectedEventItem(eventSlug);

    const onResetNews = () => setSelectedNewsItem("");
    const onResetEvent = () => setSelectedEventItem("");


    return (
        <Container maxWidth={"xl"}>
            <RoutingButton/>
            <NewsModal
                slug={selectedNewsItem}
                onClose={onResetNews}
            />
            <EventModal
                slug={selectedEventItem}
                onClose={onResetEvent}
            />
            <TopPageBlock>
                <Typography variant={"h5"}>
                    Welcome to &lsquo;{ association.name }&rsquo;
                </Typography>
            </TopPageBlock>
            <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={9.5}>
                    { modules && modules["events"] && (
                        <>
                            <Typography variant={"h6"} sx={{ mb: 1 }}>
                                Upcoming events
                            </Typography>
                            <Grid container direction={"row"} spacing={3}>
                                { events?.map(event=>(
                                    <Grid item key={event.slug} >
                                        <EventCard key={event.slug} onClick={onEventsClick(event.slug)} {...event}/>
                                    </Grid>
                                )) }
                            </Grid>
                        </>
                    ) }
                </Grid>
                <Grid item xs={2.5}>
                    { modules && modules["news"] && (
                        <>

                            <Typography variant={"h6"} sx={{ mb: 1 }}>
                                News
                            </Typography>
                            <Grid container direction={"column"} spacing={2}>
                                { news?.map(newsItem=>(
                                    <Grid item key={newsItem.slug} >
                                        <NewsCard key={newsItem.slug} onClick={onNewsClick(newsItem.slug)} {...newsItem}/>
                                    </Grid>
                                )) }
                            </Grid>
                        </>
                    ) }
                </Grid>
            </Grid>
        </Container>
    );
};

Member.propTypes = {
    association: PropTypes.object.isRequired,
    membership: PropTypes.object.isRequired
};

export default Member;