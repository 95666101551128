import {emptySplitApi} from "./index";

const userDataApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["User"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getUserData: build.query({
                query: () => ({ url: "/users/me", params: { expand: "profiles,association_memberships.association"} }),
                providesTags: [{ type: "User", id: "Me" }],
                transformResponse: (sunmember) => ({
                    ...sunmember,
                    preferred_profile: sunmember.profiles.find(profile=>profile.url === sunmember.preferred_profile),
                    association_memberships: sunmember.association_memberships.map(association_membership=>({
                        ...association_membership,
                        profile: sunmember.profiles.find(profile=>profile.url === association_membership.profile)
                    }))
                })
            }),
            mergeProfiles: build.mutation({
                query: (body) => ({ url: "/profiles/merge", method: "POST", body: body }),
                invalidatesTags: [{ type: "User", id: "Me" }],
            }),
            resolveMemberships: build.mutation({
                query: (body) => ({ url: "/memberships/resolve", method: "POST", body: body }),
                invalidatesTags: [{ type: "User", id: "Me" }],
            }),
            addProfile: build.mutation({
                query: (body) => ({ url: "/profiles", method: "POST", body: body }),
                invalidatesTags: [{ type: "User", id: "Me" }],
            }),
            patchProfile: build.mutation({
                query: ({ slug, ...patch }) => ({ url: `/profiles/${slug}`, method: "PATCH", body: patch }),
            }),
            patchMyProfile: build.mutation({
                query: ({ slug, ...patch }) => ({ url: `/profiles/${slug}`, method: "PATCH", body: patch }),
                invalidatesTags: [{ type: "User", id: "Me" }],
            }),
            patchMyAvatar: build.mutation({
                query: (formData ) => ({ url: `/profiles/${formData.get("slug")}`, method: "PATCH", body: formData }),
                invalidatesTags: [{ type: "User", id: "Me" }],
            }),
            patchMySpecificData: build.mutation({
                query: ({ slug, ...patch }) => ({ url: `/association_data/${slug}`, method: "PATCH", body: patch }),
                invalidatesTags: [{ type: "User", id: "Me" }],
            }),
            patchMyMembership: build.mutation({
                query: ({ slug, ...patch }) => ({ url: `/memberships/${slug}`, method: "PATCH", body: patch }),
                invalidatesTags: [{ type: "User", id: "Me" }],
            }),
            requestMembership: build.mutation({
                query: (body) => ({ url: "/memberships/", method: "POST", body: body }),
                invalidatesTags: [{ type: "User", id: "Me" }],
            }),
            deleteMembership: build.mutation({
                query: (slug) => ({ url: `/memberships/${slug}`, method: "DELETE" }),
                invalidatesTags: [{ type: "User", id: "Me" }],
            }),
        }),
        overrideExisting: false
    });

const usePreferredProfile = () => {
    const { preferred_profile } = useGetUserDataQuery(undefined, {
        selectFromResult: ({ data }) => ({
            preferred_profile: data?.preferred_profile,
        }),
    });
    return preferred_profile;
};

const useMembership = (slug) => {
    const { associationMembership } = useGetUserDataQuery(undefined, {
        selectFromResult: ({ data }) => ({
            associationMembership: data?.association_memberships.find(membership=>membership.association.slug === slug),
        }),
    });
    return associationMembership;
};


const useMemberships = () => {
    const { association_memberships } = useGetUserDataQuery(undefined, {
        selectFromResult: ({ data }) => ({
            association_memberships: data?.association_memberships,
        }),
    });
    return association_memberships;
};


export { usePreferredProfile, useMembership, useMemberships };
export const {
    useGetUserDataQuery,
    useAddProfileMutation,
    usePatchProfileMutation,
    usePatchMyProfileMutation,
    usePatchMyAvatarMutation,
    usePatchMySpecificDataMutation,
    usePatchMyMembershipMutation,
    useRequestMembershipMutation,
    useDeleteMembershipMutation,
    useMergeProfilesMutation,
    useResolveMembershipsMutation
} = userDataApi;
export default userDataApi;
