import FigmaContent from "../../Components/PageLayout/Content/FigmaContent";

const Partners = () => {
    return (
        <FigmaContent
            image={null}
            name={"Partners"}
        />
    );
};

export default Partners;