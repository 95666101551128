import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Papa from "papaparse";
import React, {useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import Button from "../../Components/Buttons/Button";
import Wrapper from "../../Components/FormComponents/Wrapper";
import useModalState from "../../Components/Hooks/useModalState";
import ConfirmationModal from "../../Components/Modals/ConfirmationModal";
import Block from "../../Components/PageLayout/Content/Block";
import ExtremeTable from "../../Components/Tables/ExtremeTable";
import {Helper} from "../../Helper";
import useCurrentMembers from "../../Store/hooks/useCurrentMembers";
import {useGetEnrollmentOptionsByEventQuery} from "../../Store/services/MySU/enrollmentOptions";
import {
    useAddEnrollmentMutation,
    useDeleteEnrollmentsMutation,
    useGetEnrollmentsByEventQuery
} from "../../Store/services/MySU/enrollments";
import ImportModal from "./ImportModal";


const headers = [
    {name: "given_name", title: "Given Name"},
    {name: "enrollment_option", title: "Enrollment Option"},
];

const getRowId = row => row.slug;

const EventEnrollmentsManagementEnrollments = () => {
    const { slug, eventSlug } = useParams();

    const { data: enrollmentOptions } = useGetEnrollmentOptionsByEventQuery(eventSlug);
    const { data: enrollments } = useGetEnrollmentsByEventQuery(eventSlug);
    const { members } = useCurrentMembers();

    const [ addEnrollments ] = useAddEnrollmentMutation();
    const [ deleteEnrollments ] = useDeleteEnrollmentsMutation();

    const [openImportModal, toggleImportModal] = useModalState(false);
    const [openUnenrollModal, toggleUnenrollModal] = useModalState(false);

    const [selection, setSelection] = useState([]);

    const enabledUnenrollButton = selection.length > 0;

    const description = useMemo(()=>{
        if (selection.length > 1) {
            return "Are you sure you want to unenroll these "+ selection.length + " members?";
        } else {
            return "Are you sure you want to unenroll this member?";
        }
    },[selection]);

    const optionUrlToNameDict = Object.fromEntries(enrollmentOptions?.map(option=>[option["url"], option["name"]]) || []);
    const optionNameToUrlDict = Object.fromEntries(enrollmentOptions?.map(option=>[option["name"], option["url"]]) || []);
    const rows = enrollments?.map(enrollment=>({...enrollment, enrollment_option: optionUrlToNameDict[enrollment["enrollment_option"]]})) || [];

    const studentNumberToUrlProfileDict = useMemo(()=>(
        Object.fromEntries(members?.map(option=>[option["student_number"], option["url"]]) || [])
    ), [members]);

    const selectionToEnrollments = (selection_) => {
        return enrollments.filter(enrollment=>selection_.includes(enrollment.slug));
    };

    const handleImportSelection = (input) => {
        Papa.parse(input.target[0].files[0], {
            delimiter: input.target[2].value,
            complete: (result)=>importSelection(result.data),
            header: true,
            skipEmptyLines: true
        });
    };
    const importSelection = (data) => {
        const toBeCreatedEnrollments = data
            .map(d=>Helper.filterObjectOnProperties(d, ["student_number", "enrollment_option"]))
            .map(enrollment=>({
                profile: studentNumberToUrlProfileDict[enrollment.student_number],
                enrollment_option: optionNameToUrlDict[enrollment.enrollment_option],
                event: "/events/" + eventSlug,
                association: `/associations/${slug}`
            }));
        addEnrollments(toBeCreatedEnrollments).then(()=>toggleUnenrollModal());
    };
    const unenrollSelection = () => {
        const toBeUnenrolled = selectionToEnrollments(selection).map(enrollment=>enrollment.slug);
        deleteEnrollments({ selection: toBeUnenrolled, eventSlug: eventSlug }).then(()=>toggleUnenrollModal());
    };

    return (
        <Container>
            <Block>
                <Wrapper>
                    <Typography variant={"h5"}>Enrolled members</Typography>
                    <Button variant={"outlined"} onClick={toggleImportModal}>Import</Button>
                </Wrapper>
                <hr className={"box-title-separator"}/>
                <ExtremeTable
                    rows={rows}
                    headers={headers}
                    showGrouping={false}
                    selection={{selection: selection, setSelection: setSelection}}
                    showExporter={true}
                    getRowId={getRowId}
                />
                <Wrapper>
                    <div/>
                    <Button color={"secondary"} variant={"contained"} disabled={!enabledUnenrollButton} onClick={toggleUnenrollModal}>
                        Unenroll
                    </Button>
                </Wrapper>
                <ImportModal
                    title={"Enrollments"}
                    onSubmit={handleImportSelection}
                    onCancel={toggleImportModal}
                    open={openImportModal}
                />
                <ConfirmationModal
                    title={"Unenroll"}
                    description={description}
                    onConfirm={unenrollSelection}
                    onCancel={toggleUnenrollModal}
                    open={openUnenrollModal}
                />
            </Block>
        </Container>
    );
};

EventEnrollmentsManagementEnrollments.propTypes = {
};

export default EventEnrollmentsManagementEnrollments;