import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import React from "react";

const SearchPanelInput = ({ value, onValueChange }) => (
    <Tooltip
        title={"Search through all columns"}
        placement={"bottom"}
        enterDelay={300}
    >
        <TextField
            color={"primary"}
            variant={"outlined"}
            value={value}
            onChange={(event) => onValueChange(event.target.value)}
            sx={{ml: 1}}
            style={{textTransform: "none"}}
            InputProps={{
                startAdornment: (
                    <InputAdornment position={"start"}>
                        <SearchRoundedIcon />
                    </InputAdornment>
                ),
            }}
            size={"small"}
            placeholder={"Search..."}
        >
            Export
        </TextField>
    </Tooltip>
);

SearchPanelInput.propTypes = {
    value: PropTypes.string.isRequired,
    onValueChange: PropTypes.func.isRequired,
};

export default SearchPanelInput;