import PropTypes from "prop-types";
import React, {useEffect, useMemo} from "react";

import ConfirmationModal from "../../../../Components/Modals/ConfirmationModal";

const title = "Process Member";
const confirmButtonText = "Accept";
const secondaryButtonText = "Reject";
const cancelButtonText = "I don't know yet";

const MemberIncomingPopupForm = ({ row, onChange, onApplyChanges, onCancelChanges, open }) => {
    const description = useMemo(()=>"What do you want to do with the membership application of " + row.given_name + " " + row.surname +"?", [row.given_name, row.surname]);
    useEffect(()=>{
        if (row.status !== "Pending" && row.changed === true) {
            onApplyChanges();
        }
    }, [row.status, onApplyChanges]);

    const acceptMember = () => {
        onChange({ target: { name: "status", value: "Accepted" } });
        onChange({ target: { name: "changed", value: true } });
    };
    const rejectMember = () => {
        onChange({ target: { name: "status", value: "Rejected" } });
        onChange({ target: { name: "changed", value: true } });
    };

    return (
        <ConfirmationModal
            title={title}
            onCancel={onCancelChanges}
            open={open}
            onConfirm={acceptMember}
            description={description}
            size={"lg"}
            cancelButtonText={cancelButtonText}
            confirmButtonText={confirmButtonText}
            secondaryButtonText={secondaryButtonText}
            secondaryAction={rejectMember}
        />
    );
};

MemberIncomingPopupForm.propTypes = {
    row: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onApplyChanges: PropTypes.func.isRequired,
    onCancelChanges: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default MemberIncomingPopupForm;