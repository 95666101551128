import React from "react";
import {Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";

import Home from "../../Pages/Home/Home";
import NoProfileYet from "../../Pages/Profile/NoProfileYet";
import ProfileIssueFixModals from "../../Pages/Profile/ProfileIssueFixModals";
import Settings from "../../Pages/Profile/Settings";
import SharedProfile from "../../Pages/Profile/SharedProfile";
import {useGetUserDataQuery} from "../../Store/services/MySU/user";
import AssociationsRouter from "./AssociationsRouter";


/**
 * Primary route collection for an authenticated user.
 *
 * This component provides the roots for all the routes a user is allowed to
 * visit when authenticated. Based on the data of the logged in sunmember,
 * redirects are done in order to force the user to set mandatory data.
 *
 * @returns {JSX.Element|null}
 * @constructor
 */
export const AuthorizedRoutes = () => {
    const { data: sunmember } = useGetUserDataQuery();
    const { url } = useRouteMatch();
    const history = useHistory();
    const location = useLocation();

    if (!sunmember) {
        return null;
    }

    const numberOfProfiles = sunmember.profiles.length;
    const needToCreateProfile = numberOfProfiles === 0;

    if (needToCreateProfile && !location.pathname.includes("/profile/create")) {
        history.push(url + "/profile/create");
    }
    if (!needToCreateProfile && location.pathname.includes("/profile/create")) {
        history.replace(url + "/associations/overview");
    }

    return (
        <>
            <ProfileIssueFixModals/>
            <Switch>
                <Route path={url + "/profile"}>
                    <Route exact path={url + "/profile/create"}
                        render={() => <NoProfileYet/>}
                    />
                    <Route exact path={url + "/profile/settings"}
                        render={() => <Settings/>}
                    />
                    <Route exact path={url + "/profile/shared"}
                        render={() => <SharedProfile/>}
                    />
                </Route>
                <Route path={url + "/home"}>
                    <Home/>
                </Route>
                <Route path={url + "/associations"}>
                    <AssociationsRouter/>
                </Route>
            </Switch>
        </>
    );
};
