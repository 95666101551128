import CalendarToday from "@mui/icons-material/CalendarToday";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import PaymentIcon from "@mui/icons-material/Payment";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {ValidatorForm} from "react-material-ui-form-validator";
import {useParams} from "react-router-dom";

import Button from "../../../Components/Buttons/Button";
import CheckboxField from "../../../Components/FormComponents/Fields/CheckboxField";
import {DateFieldV2, DateFieldV3} from "../../../Components/FormComponents/Fields/DateField";
import SelectField from "../../../Components/FormComponents/Fields/SelectField";
import SpecificDataField from "../../../Components/FormComponents/Fields/SpecificDataField";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import useAddValidationRule from "../../../Components/Hooks/useAddValidationRule";
import isAfterDate from "../../../Components/ValidatorRules/isAfterDate";
import isTruthy from "../../../Components/ValidatorRules/isTruthy";
import useIsBoardMemberPage from "../../../Store/hooks/useIsBoardMemberPage";
import {useGetDataFieldsByAssociationQuery} from "../../../Store/services/MySU/dataFields";
import {useGetMemberTypesByAssociationQuery} from "../../../Store/services/MySU/memberTypes";

const debtCollectionText = (association) => <span> By signing this form, you authorize { association.name } to send recurrent collection orders to your bank
to debit your account for ??? and your bank to debit a recurrent amount from your account in accordance with
the order of { association.name }. If you do not agree with this debit, you can arrange for it to be refunded. Please
contact your bank within eight weeks of the date of the debit. Ask your bank for the conditions.*
</span>;
const dataFieldUrlToSpecificDataValue = (specificData, url) => specificData ? specificData.find(data=> data.data_field === url)?.value : "";
const dataFieldIsDisabled = (dataField, specificData, isBoardPage) => (dataField.board_only || (dataField.mandatory && dataFieldUrlToSpecificDataValue(specificData, dataField.url) === "True")) && !isBoardPage;

const MembershipForm = ({ association, membership, handleMembershipChange, onSubmit, onCancel }) => {
    const { slug } = useParams();
    const isBoardPage = useIsBoardMemberPage();
    const { data: membertypes } = useGetMemberTypesByAssociationQuery(slug);
    const { data: dataFields } = useGetDataFieldsByAssociationQuery(slug);

    useAddValidationRule(ValidatorForm, isTruthy.name, isTruthy.validate);
    useAddValidationRule(ValidatorForm, isAfterDate.name, isAfterDate.validate(membership.date_joined));

    const dateLeftHelperText = association.is_board && "You cannot change this to a value in the past to prevent " +
        "inconstistencies. If you really want to have this at a date in the past contact the helpdesk.";
    const currentMembertypeHelperText = useMemo(()=>isBoardPage
        ? "Be careful when changing this. If you have added the membership fee for this member already in a debt " +
        "collection it will not be recomputed."
        : "Only the board can change this.",
    [isBoardPage]);
    const newMembertypeHelperText = useMemo(()=>membership.date_left
        ? "Disabled because you have ended your membership"
        : "What membertype do you want to be next year?",
    [association.date_left]);

    const chooseableMemberTypes = useMemo(()=>isBoardPage
        ? membertypes
        : membertypes.filter(membertype=>!membertype.only_chooseable_by_board),
    [isBoardPage, membertypes]);

    return (
        <ValidatorForm
            onSubmit={onSubmit}
            onError={errors => console.log(errors)}
        >
            <Wrapper>
                <IconHolder Icon={CalendarToday}/>
                <DateFieldV3
                    name={"date_joined"}
                    label={"Date joined"}
                    value={membership.date_joined}
                    onChange={date=>handleMembershipChange("date_joined", date)}
                    disabled
                />
            </Wrapper>
            <Wrapper>
                <IconHolder Icon={EventBusyIcon}/>
                <DateFieldV3
                    label={"Date left"}
                    name={"date_left"}
                    value={membership.date_left}
                    onChange={date=>handleMembershipChange("date_left", date)}
                    disabled={!isBoardPage}
                    helperText={dateLeftHelperText}
                    validators={[isAfterDate.name]}
                    errorMessages={["Must be a later date than the date the member joined"]}
                />
            </Wrapper>
            <Wrapper>
                <IconHolder Icon={PaymentIcon}/>
                <SelectField
                    label={"Payment by"}
                    name={"pay_by"}
                    value={membership.pay_by}
                    onChange={(event)=>handleMembershipChange("pay_by", event.target.value)}
                    required
                >
                    <MenuItem value={"Debt collection"}>Debt collection</MenuItem>
                    <MenuItem value={"Invoice"}>Invoice</MenuItem>
                </SelectField>
            </Wrapper>
            { membership.pay_by === "Debt collection" &&
                <Wrapper>
                    <CheckboxField
                        checked={membership.debt_collection_mandate_given}
                        value={membership.debt_collection_mandate_given}
                        name={"debt_collection_mandate_given"}
                        onChange={(event)=>handleMembershipChange("debt_collection_mandate_given", event.target.checked)}
                        label={debtCollectionText(association)}
                        labelPlacement={"end"}
                        validators={["isTruthy"]}
                        errorMessages={["This field is required"]}
                    />
                </Wrapper>
            }
            { /*<Wrapper>*/ }
            { /*    <IconHolder Icon={TransferWithinAStationIcon}/>*/ }
            { /*    <SelectField*/ }
            { /*        name={"Current Membertype"}*/ }
            { /*        value={membership.type || ""}*/ }
            { /*        onChange={event=>handleMembershipChange("type", event.target.value)}*/ }
            { /*    >*/ }
            { /*        */ }
            { /*    </SelectField>*/ }
            { /*</Wrapper>*/ }
            <Wrapper>
                <IconHolder Icon={DirectionsWalkIcon}/>
                <SelectField
                    name={"Current Membertype"}
                    value={membership.type || ""}
                    onChange={event=>handleMembershipChange("type", event.target.value)}
                    disabled={!isBoardPage}
                    helperText={currentMembertypeHelperText}
                >
                    { chooseableMemberTypes && chooseableMemberTypes.map((membertype, m)=>(
                        <MenuItem key={m} value={membertype.url}>{ membertype.type }</MenuItem>
                    )) }
                </SelectField>
            </Wrapper>
            <Wrapper>
                <IconHolder Icon={TransferWithinAStationIcon}/>
                <SelectField
                    name={"New Membertype"}
                    value={membership.new_type || ""}
                    onChange={event=>handleMembershipChange("new_type", event.target.value)}
                    disabled={membership.date_left !== null}
                    helperText={newMembertypeHelperText}
                >
                    <MenuItem value={""}> - </MenuItem>
                    { chooseableMemberTypes && chooseableMemberTypes.map((membertype, m)=>(
                        <MenuItem key={m} value={membertype.url}>{ membertype.type }</MenuItem>
                    )) }
                </SelectField>
            </Wrapper>
            { /*<Wrapper>*/ }
            { /*    <IconHolder Icon={VisibilityIcon}/>*/ }
            { /*    <SelectField*/ }
            { /*        name={"Data after end of membership"}*/ }
            { /*        value={membership.visible_after_date_left}*/ }
            { /*        onChange={event=>handleMembershipChange("visible_after_date_left", event.target.value)}*/ }
            { /*        helperText={"What should happen to your data after your membership has ended?"}*/ }
            { /*    >*/ }
            { /*        <MenuItem value={true}>visible</MenuItem>*/ }
            { /*        <MenuItem value={false}>gone</MenuItem>*/ }
            { /*    </SelectField>*/ }
            { /*</Wrapper>*/ }
            { dataFields && dataFields.map((dataField, d)=>(
                <Wrapper key={d}>
                    <SpecificDataField
                        field={dataField}
                        helperText={dataField.helper_text}
                        value={dataFieldUrlToSpecificDataValue(membership.specific_data, dataField.url)}
                        onChange={handleMembershipChange}
                        disabled={dataFieldIsDisabled(dataField, membership.specific_data, isBoardPage)}
                    />
                </Wrapper>
            )) }
            <Wrapper>
                <div/>
                <div>
                    <Button variant={"contained"} onClick={onCancel} color={"secondary"}>Cancel</Button>
                    &nbsp;
                    <Button type={"submit"} variant={"contained"} color={"primary"}>Save</Button>
                </div>
            </Wrapper>
        </ValidatorForm>
    );
};

MembershipForm.propTypes = {
    membership: PropTypes.object.isRequired,
    association: PropTypes.object.isRequired,
    handleMembershipChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default MembershipForm;