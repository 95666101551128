import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";
import React, {useCallback, useEffect, useState} from "react";
import {useTimer} from "react-timer-hook";

import ColouredBlock, {generateRandomColour} from "./ColouredBlock";
import XkcdBlock, {generateRandomXkcdId} from "./XkcdBlock";

const useStyles = makeStyles((theme) => ({
    block: {
        height: "50vh",
        [theme.breakpoints.only("sm")]: {
            height: "100vh",
        },
        width: "100%",
    },
}));

const blockOptions = [
    { name: "coloured", Component: ColouredBlock, probability: 1.80, },
    { name: "xkcd", Component: XkcdBlock, probability: .20, }
];

const generateRandomInteger = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
const getRandomBlock = () => {
    const randomFloat = Math.random();
    let chosenBlockOption = null;
    let cumalativePercentage = 0;
    for (let blockOption of blockOptions) {
        const newCumalativePercentage = cumalativePercentage + blockOption["probability"];
        if (cumalativePercentage < randomFloat && randomFloat < newCumalativePercentage) {
            chosenBlockOption = blockOption;
            break;
        } else {
            cumalativePercentage += blockOption["probability"];
        }
    }
    if (chosenBlockOption["name"] === "coloured") {
        return { Component: chosenBlockOption["Component"], props: { colour: generateRandomColour() } };
    }
    if (chosenBlockOption["name"] === "xkcd") {
        return { Component: chosenBlockOption["Component"], props: { id: generateRandomXkcdId() } };
    }
    return { Component: ColouredBlock, props: { colour: generateRandomColour()} };
};

const initialBlocksLg = [
    getRandomBlock(),
    getRandomBlock(),
    getRandomBlock(),
    getRandomBlock(),
];

const TerminalBlocksLg = ({ time, timeDelta }) => {
    const classes = useStyles();
    const [blocks, setBlocks] = useState(initialBlocksLg);
    const onExpire = useCallback(()=>{
        const index = generateRandomInteger(0,3);
        setBlocks(prevState => prevState.map(
            (block, id)=> id === index ? getRandomBlock() : block)
        );
    }, [setBlocks]);

    const { isRunning, restart } = useTimer({ expiryTimestamp: time, onExpire: onExpire });

    useEffect(()=>{
        if (!isRunning) {
            const newTime = new Date();
            newTime.setSeconds(newTime.getSeconds() + timeDelta);
            restart(newTime);
        }
    }, [isRunning]);

    const Block1 = blocks[0]["Component"];
    const Block2 = blocks[1]["Component"];
    const Block3 = blocks[2]["Component"];
    const Block4 = blocks[3]["Component"];

    return (
        <Grid container xs={12}>
            <Grid container item xs={6}>
                <Grid item className={classes.block}>
                    <Block1 {...blocks[0]["props"]}/>
                </Grid>
                <Grid item className={classes.block}>
                    <Block2 {...blocks[1]["props"]}/>
                </Grid>
            </Grid>
            <Grid container item xs={6}>
                <Grid item className={classes.block}>
                    <Block3 {...blocks[2]["props"]}/>
                </Grid>
                <Grid item className={classes.block}>
                    <Block4 {...blocks[3]["props"]}/>
                </Grid>
            </Grid>
        </Grid>
    );

};

const TerminalBlocks = () => {
    const classes = useStyles();
    const theme = useTheme();
    const screenIsSm = useMediaQuery(theme.breakpoints.up("sm"));
    const screenIsMd = useMediaQuery(theme.breakpoints.up("md"));
    const screenIsLg = useMediaQuery(theme.breakpoints.up("lg"));

    const time = new Date();
    const timeDelta = 6;
    time.setSeconds(time.getSeconds() + timeDelta);

    if (screenIsLg) {
        return (
            <TerminalBlocksLg time={time} timeDelta={timeDelta}/>
        );
    }

    if (screenIsMd) {
        return (
            <Grid container xs={12}>
                <Grid item className={classes.block}>
                    <ColouredBlock/>
                </Grid>
                <Grid item className={classes.block}>
                    <ColouredBlock/>
                </Grid>
            </Grid>
        );
    }

    if (screenIsSm) {
        return (
            <Grid container xs={12}>
                <Grid item className={classes.block}>
                    <ColouredBlock/>
                </Grid>
            </Grid>
        );
    }

    return (
        <div>xs</div>
    );
};

export default TerminalBlocks;