import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {useParams} from "react-router-dom";

import {useGetCurrentMembersByAssociationQuery} from "../../../Store/services/MySU/members";
import MembersDetail from "./MembersDetail";

const MembersDetailFromSlug = () => {
    const { slug, memberSlug } = useParams();

    const { data: members } = useGetCurrentMembersByAssociationQuery(slug);

    const current_member = useMemo(()=>
        members?.find(member=>member.profile.slug === memberSlug)
    , [members, memberSlug]);

    if (current_member === undefined) {
        return null;
    }
    return (
        <MembersDetail current_member={current_member}/>
    );
};

MembersDetailFromSlug.propTypes = {
};

export default MembersDetailFromSlug;