import PropTypes from "prop-types";
import React from "react";

import withPropMap from "../../../Components/HOC/withPropMap";
import {
    useAddDataFieldMutation,
    useDeleteDataFieldMutation,
    usePatchDataFieldMutation
} from "../../../Store/services/MySU/dataFields";
import MultiFormContainer from "../MultiForm";
import DataFieldsForm from "./DataFieldsForm";
import DataFieldsInfo from "./DataFieldsInfo";

const multiObjectToDataFieldsFormPropsMap = ({ multiObject, handleMultiObjectChange, ...rest_props}) =>
    ({ dataField: multiObject, handleDataFieldChange: handleMultiObjectChange, update: Boolean(multiObject.slug), ...rest_props});

const multiObjectToDataFieldsInfoPropsMap = ({ multiObject, ...rest_props }) =>
    ({ dataField: multiObject, ...rest_props});

const dataFieldToTitle = (dataField) => dataField.name;
const fieldAndValueToStateChanges = (field, value) => {
    if ( field === "mandatory" && value === true) {
        return { mandatory: true, default: "False" };
    }
    return { [field]: value };
};

const DataFieldsMulti = ({ dataFields, associationUrl, postSubmit, postDelete, postCancel }) => {
    const initialDataField = {
        name: "",
        association: associationUrl,
        type: "String",
        board_only: false,
        mandatory: false,
        choices: "",
        default: "",
        helper_text: "",
    };
    const DataFieldFormComponent = withPropMap(DataFieldsForm, multiObjectToDataFieldsFormPropsMap);
    const DataFieldInfoComponent = withPropMap(DataFieldsInfo, multiObjectToDataFieldsInfoPropsMap);

    const [ addDataField ] = useAddDataFieldMutation();
    const [ patchDataField ] = usePatchDataFieldMutation();
    const [ deleteDataField ] = useDeleteDataFieldMutation();

    const onSubmit = (dataField) => dataField.slug
        ? patchDataField(dataField).then(()=>postSubmit(dataField))
        : addDataField(dataField).then(()=>postSubmit(dataField));
    const onDelete = (dataField) => deleteDataField(dataField).then(()=>postDelete(dataField));

    return (
        <MultiFormContainer
            multiObjects={dataFields}
            multiObjectToTitle={dataFieldToTitle}
            allowCreation={true}
            initialMultiObject={initialDataField}
            FormComponent={DataFieldFormComponent}
            InfoComponent={DataFieldInfoComponent}
            fieldAndValueToStateChanges={fieldAndValueToStateChanges}
            enableDelete={true}
            onSubmit={onSubmit}
            onCancel={postCancel}
            onDelete={onDelete}
            title={"Member characteristics"}
        />
    );
};

DataFieldsMulti.propTypes = {
    dataFields: PropTypes.array.isRequired,
    associationUrl: PropTypes.string.isRequired,
    postSubmit: PropTypes.func,
    postDelete: PropTypes.func,
    postCancel: PropTypes.func
};

const dummyFunction = () => {};
DataFieldsMulti.defaultProps = {
    postSubmit: dummyFunction,
    postDelete: dummyFunction,
    postCancel: dummyFunction
};

export default DataFieldsMulti;