import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { shuffle } from "lodash";
import {toast} from "material-react-toastify";
import PropTypes from "prop-types";
import React, { useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import Button from "../../Components/Buttons/Button";
import Wrapper from "../../Components/FormComponents/Wrapper";
import useCollectionAPI from "../../Components/Hooks/useCollectionAPI";
import ConfirmationModal from "../../Components/Modals/ConfirmationModal";
import Block from "../../Components/PageLayout/Content/Block";
import ExtremeTable from "../../Components/Tables/ExtremeTable";
import MemberTable from "../../Components/Tables/MemberTable";
import ToolbarButton from "../../Components/Tables/Plugins/ToolbarButton";
import {useAPI} from "../../Contexts/API";
import useCurrentMembers from "../../Store/hooks/useCurrentMembers";
import {useGetDataFieldsByAssociationQuery} from "../../Store/services/MySU/dataFields";
import {useGetMemberTypesByAssociationQuery} from "../../Store/services/MySU/memberTypes";
import {useGetStudiesQuery} from "../../Store/services/MySU/studies";
import {deriveMemberTableHeadersFromDataFields} from "../../Transformers/Members";

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(1, 0),
    },
    icon: {
        margin: theme.spacing(2, 0),
        marginRight: theme.spacing(2),
    },
    textField: {
        width: "100%",
    },
    column: {
        width: "46%"
    },
    matchButtonDiv: {
        display: "flex",
        justifyContent: "center",
    },
    matchButton: {
        width: "48%",
        fontSize: "24px",
    }
}));

// function to help add the "left_" and "right_" to keys
const prependKeys = (prefactor, obj) =>
    Object.keys(obj).reduce(
        (acc, key) => ({
            ...acc,
            ...{ [prefactor+key]: obj[key] }
        }),
        {}
    );

const interleave = ([ x, ...xs ], ys = []) =>
    x === undefined
        ? ys                             // base: no x
        : [ x, ...interleave (ys, xs) ];  // inductive: some x

const Matchings = () => {
    const classes = useStyles();
    const { slug } = useParams();

    const API = useAPI();
    const MatchesAPI = useCollectionAPI("/matchings", {limit: 10000, current: true, association__slug: slug});
    const { data: studies } = useGetStudiesQuery();

    const matches = MatchesAPI.collection;
    const setMatches = MatchesAPI.set;
    const { data: member_types } = useGetMemberTypesByAssociationQuery(slug);
    const { data: data_fields } = useGetDataFieldsByAssociationQuery(slug);
    const { members } = useCurrentMembers(slug);


    const [confirmationModal, setConfirmationModal] = useState(false);
    const [show, setShowMatches] = useState(true);
    const [selectedLeft, setLeft] = useState([]);
    const [selectedRight, setRight] = useState([]);
    const [selection, setSelection] = useState([]);

    const toggleShowMatches = () => setShowMatches(prevState => !prevState);

    const initial_headers = ["given_name", "type"].concat(data_fields ? data_fields.map(data_field=>data_field.name) : []);

    const createMatch = () => {
        // either selectedLeft or selectedRight contains just a single element.
        const left_profile_slugs = selectedLeft;
        const right_profile_slugs = selectedRight;

        let matchObjects;
        if (left_profile_slugs.length > right_profile_slugs.length) {
            const right_profile_slug = right_profile_slugs[0];
            matchObjects = left_profile_slugs.map(left_profile_slug=>({
                association: `/associations/${slug}`,
                left_profile: "/profiles/" + left_profile_slug,
                right_profile: "/profiles/" + right_profile_slug,
            }));
        } else {
            const left_profile_slug = left_profile_slugs[0];
            matchObjects = right_profile_slugs.map(right_profile_slug=>({
                association: `/associations/${slug}`,
                left_profile: "/profiles/" + left_profile_slug,
                right_profile: "/profiles/" + right_profile_slug,
            }));
        }

        let matchObject;
        if (matchObjects.length > 1) {
            matchObject = matchObjects;
        } else {
            matchObject = matchObjects[0];
        }

        API.callv4({
            url: "/matchings",
            method: "POST",
            object: matchObject,
            on_succes: (created_matches) => {
                if (Array.isArray(created_matches)) {
                    setMatches(prevMatches=>prevMatches.concat(created_matches));
                } else {
                    // if it is not an array, then its a single object.
                    setMatches(prevMatches=>{prevMatches.push(created_matches); return prevMatches;});
                }
                setLeft([]);
                setRight([]);
            },
            on_failure: () => {
                toast.error("Creation of matching failed.");
            }}
        );
    };

    const autoMatch = () => {
        // compute which side has less members and thus must be matched to multiple of the other side.
        let singles = [];
        let to_be_matched_to_singles = [];
        if (selectedLeft.length < selectedRight.length) {
            singles = selectedLeft;
            to_be_matched_to_singles = selectedRight;
        } else {
            singles = selectedRight;
            to_be_matched_to_singles = selectedLeft;
        }

        // shuffle users to get rid of any accidental matching on name
        singles = shuffle(singles);
        to_be_matched_to_singles = shuffle(to_be_matched_to_singles);

        // create list of the to be made matches for everyone
        let to_be_created_matches = [];
        let index = 0;
        const number_of_singles = singles.length;

        // give every single a match, then every single a second match and so on
        while (to_be_matched_to_singles.length > 0) {
            let to_be_matched_to_single = to_be_matched_to_singles.pop();
            to_be_created_matches.push({
                association: `/associations/${slug}`,
                left_profile: "/profiles/" + singles[index],
                right_profile: "/profiles/" + to_be_matched_to_single
            });
            index++;
            // reset index to start again with the first single
            if (index === number_of_singles) {
                index = 0;
            }
        }

        // Do the API call
        API.callv4({
            url: "/matchings",
            method: "POST",
            object: to_be_created_matches,
            on_succes: (created_matches) => {
                setMatches(created_matches);
                setLeft([]);
                setRight([]);
            },
            on_failure: () => {
                toast.error("Creation of matching failed.");
            }}
        );
    };

    const allowAutoMatch = selectedRight.length > 0 && selectedLeft.length > 0;
    const allowMatch = ( selectedLeft.length === 1 && selectedRight.length > 0 ) || ( selectedLeft.length > 0 && selectedRight.length === 1 );

    let matchesAssociationWithSelectedLeft = [];
    let matchesAssociationWithSelectedRight = [];
    if (matches) {
        const matchesAssociationWithSelectedLeftLTR = matches.filter(match=>selectedLeft.includes(match.left_slug)).map(match=>match.right_slug);
        const matchesAssociationWithSelectedLeftRTL = matches.filter(match=>selectedLeft.includes(match.right_slug)).map(match=>match.left_slug);
        matchesAssociationWithSelectedLeft = matchesAssociationWithSelectedLeftLTR.concat(matchesAssociationWithSelectedLeftRTL);

        const matchesAssociationWithSelectedRightLTR = matches.filter(match=>selectedRight.includes(match.left_slug)).map(match=>match.right_slug);
        const matchesAssociationWithSelectedRightRTL = matches.filter(match=>selectedRight.includes(match.right_slug)).map(match=>match.left_slug);
        matchesAssociationWithSelectedRight = matchesAssociationWithSelectedRightLTR.concat(matchesAssociationWithSelectedRightRTL);
    }
    const matched_profiles = matches.map(match=>match.left_slug).concat(matches.map(match=>match.right_slug));

    const sentEmails = () => {
        API.callv4({
            url: "/matchings/email",
            method: "POST",
            object: {sent_to_all: true, association: `/associations/${slug}`},
            on_succes: (response)=>{
                toast.success("Succesfully emailed " + response["emails send"] + " matches.");
                setMatches(prevState => prevState.map(match=>({
                    ...match,
                    email_send: true
                })));
                closeModal();
            },
            on_failure: ()=> {
                toast.error("Emailing of match(es) failed.");
                closeModal();
            }
        });
    };
    const openModal = () => setConfirmationModal(true);
    const closeModal = () => setConfirmationModal(false);
    const numberOfMatchesStillNeededToBeEmailed = matches.filter(match=>!match.email_send).length;
    const modalDescription = "Of the " + matches.length + " matches " + numberOfMatchesStillNeededToBeEmailed + " match(es) still need to " +
                             "have an email informing them. Do you want to sent them an email now?";
    const allowEmailModal = numberOfMatchesStillNeededToBeEmailed > 0;

    // TODO:
    // 1. map left_profile, right_profile to membership
    // 2. compute proper headers (left_... and right_...).
    // 3. Put that into table
    // 4. add delete button
    // first flatten the member, since otherwise they don't fit nicely inside a single table
    const rows = useMemo(()=> {
        if (!members) {
            return [];
        }
        return matches.map(match=>({
            ...prependKeys("left_", members.find(member=>member.urls.profile === match.left_profile)),
            ...prependKeys("right_", members.find(member=>member.urls.profile === match.right_profile)),
            slug: match.slug,
            email_send: match.email_send
        }));
    }, [matches.length, members, studies]);
    const headers = useMemo(()=>{
        const base_headers = deriveMemberTableHeadersFromDataFields(data_fields);
        const left_headers = base_headers.map(({name: name, title: title}) => ({name: "left_"+name, title: "Left "+title}));
        const right_headers = base_headers.map(({name: name, title: title}) => ({name: "right_"+name, title: "Right "+title}));
        return interleave(left_headers, right_headers).concat({name: "email_send", title: "Got email"});
    }, [data_fields]);
    const defaultHiddenColumnNames = useMemo(()=>{
        const base_headers = ["given_name", "surname", "email", "type"]
            .concat(data_fields ? data_fields.map(field=>field.name) : []);
        const left_headers = base_headers.map(header=>"left_"+header);
        const right_headers = base_headers.map(header=>"right_"+header);
        const visible_headers = left_headers.concat(right_headers).concat("email_send");
        return headers
            .map(header=>header.name)
            .filter(header_name => !visible_headers.includes(header_name));
    }, [headers]);
    const booleanColumns = useMemo(()=>{
        const base_columns = data_fields ? data_fields.filter(field=>field.type === "Boolean").map(field=>field.name) : [];
        const left_columns = base_columns.map(header=>"left_"+header);
        const right_columns = base_columns.map(header=>"right_"+header);
        return left_columns.concat(right_columns).concat("email_send");
    }, [data_fields]);
    const numberColumns = useMemo(()=>{
        const base_columns = data_fields ? data_fields.filter(field=>field.type === "Number").map(field=>field.name) : [];
        const left_columns = base_columns.map(header=>"left_"+header);
        const right_columns = base_columns.map(header=>"right_"+header);
        return left_columns.concat(right_columns);
    }, [data_fields]);
    const choiceColumns = useMemo(()=>{
        const base_columns = data_fields ? data_fields.filter(field=>field.type === "Choice").map(field=>field.name).concat(["phase", "type", "new_type"]) : [];
        const left_columns = base_columns.map(header=>"left_"+header);
        const right_columns = base_columns.map(header=>"right_"+header);
        return left_columns.concat(right_columns);
    }, [data_fields]);
    const choiceSelectionOptions = useMemo(()=>({
        "left_phase": ["PhD", "Master", "Premaster", "Bachelor", "Other"],
        "left_type": member_types ? member_types.map(member_type=>member_type.type) : [],
        "left_new_type": member_types ? member_types.map(member_type=>member_type.type) : [],
        "right_phase": ["PhD", "Master", "Premaster", "Bachelor", "Other"],
        "right_type": member_types ? member_types.map(member_type=>member_type.type) : [],
        "right_new_type": member_types ? member_types.map(member_type=>member_type.type) : [],
        ...Object.fromEntries((data_fields || [])
            .filter(field=>field.type === "Choice")
            .map(field=>["left_"+field.name, field.choices.split(",")]
            )),
        ...Object.fromEntries((data_fields || [])
            .filter(field=>field.type === "Choice")
            .map(field=>["right_"+field.name, field.choices.split(",")]
            ))
    }), [member_types, data_fields]);
    const currencyColumns = useMemo(()=>{
        const base_columns = ["membership_fee"];
        const left_columns = base_columns.map(header=>"left_"+header);
        const right_columns = base_columns.map(header=>"right_"+header);
        return left_columns.concat(right_columns);
    }, [headers]);
    const dateColumns = useMemo(()=>{
        const base_columns = ["date_of_birth", "date_joined", "date_left"];
        const left_columns = base_columns.map(header=>"left_"+header);
        const right_columns = base_columns.map(header=>"right_"+header);
        return left_columns.concat(right_columns);
    }, [headers]);

    const onRefresh = () => MatchesAPI.refresh();
    const onRemove = () => {
        API.callv4({
            url: "/matchings",
            queryParams: {slug__in: selection, association__slug: slug},
            method: "DELETE",
            headers: {"X-BULK-OPERATION": true},
            on_succes: ()=>{
                setMatches(matches.filter(match=>!selection.includes(match.slug)));
            },
            on_failure: ()=> {
                toast.error("Deletion of match(es) failed.");
            }
        });
    };

    const custom_miscelaneous_plugins = [
        <ToolbarButton key={1} label={"Reload"} tooltip={"Reload contents of table"} Icon={RefreshIcon} onClick={onRefresh}/>,
        <ToolbarButton key={2} label={"Remove"}tooltip={"Remove selected rows"} Icon={DeleteIcon} onClick={onRemove}/>,
    ];
    const columnBands = [
        {
            title: "Given Name",
            children: [
                { columnName: "left_given_name"},
                { columnName: "right_given_name"},
            ]
        }
    ];

    return (
        <React.Fragment>
            <ConfirmationModal
                title={"Sent Emails"}
                description={modalDescription}
                onCancel={closeModal}
                onConfirm={sentEmails}
                open={confirmationModal}
            />
            <Block>
                <Wrapper>
                    <Typography variant={"h4"}>Matchings</Typography>
                    <div>
                        <Button color={"secondary"} variant={"outlined"} onClick={openModal} disabled={!allowEmailModal}>
                            Email
                        </Button>
                        &nbsp;
                        <Button variant={"outlined"} onClick={toggleShowMatches}>
                            { show ? "Hide Matches" : "Show Matches" }
                        </Button>
                    </div>
                </Wrapper>
                <hr className={"box-title-separator"}/>
                <ExtremeTable
                    headers={headers}
                    rows={rows}
                    showGrouping={false}
                    showExporter={true}
                    showColumnBands={false}
                    selection={{selection: selection, setSelection:setSelection}}
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                    columnBands={columnBands}
                    booleanColumns={booleanColumns}
                    choiceColumns={choiceColumns}
                    choiceSelectionOptions={choiceSelectionOptions}
                    currencyColumns={currencyColumns}
                    numberColumns={numberColumns}
                    dateColumns={dateColumns}
                    custom_miscelaneous_plugins={custom_miscelaneous_plugins}
                />
            </Block>
            <Grid container spacing={1}>
                <Grid item xs={6} className={classes.column}>
                    <MemberList
                        selected={selectedLeft}
                        selectedFilter={selectedRight.concat(matchesAssociationWithSelectedRight)}
                        setSelected={setLeft}
                        initial_headers={initial_headers}
                        matched_profiles={matched_profiles}
                    />
                </Grid>
                <Grid item xs={6} className={classes.column}>
                    <MemberList
                        selected={selectedRight}
                        selectedFilter={selectedLeft.concat(matchesAssociationWithSelectedLeft)}
                        setSelected={setRight}
                        initial_headers={initial_headers}
                        matched_profiles={matched_profiles}
                    />
                </Grid>
            </Grid>
            <Container>
                <Block>
                    <div className={classes.matchButtonDiv}>
                        <Button variant={"contained"} color={"secondary"} className={classes.matchButton} onClick={autoMatch} disabled={!allowAutoMatch}>
                            Auto match
                        </Button>
                        &nbsp;
                        <Button variant={"contained"} color={"primary"} className={classes.matchButton} onClick={createMatch} disabled={!allowMatch}>
                            Match
                        </Button>
                    </div>
                </Block>
            </Container>
        </React.Fragment>
    );
};

Matchings.propTypes = {
};

export default Matchings;

const MemberList = ({ selected, selectedFilter, setSelected, initial_headers, matched_profiles }) => {
    const { slug } = useParams();
    const { members } = useCurrentMembers(slug);
    const [showMatched, setShowMatched] = useState(false);
    const toggleShowMatched = () => setShowMatched(prevState => !prevState);

    const rowSelectionEnabledFilter = (row) => {
        return !selectedFilter.includes(row.slug);
    };

    const row_filter_show = () => true;
    const row_filter_hide = (member) => !matched_profiles.includes(member.slug);
    const row_filter = useMemo(()=>(showMatched ? row_filter_show : row_filter_hide), [showMatched, matched_profiles]);

    return (
        <Block>
            <Wrapper>
                <Typography variant={"h5"}>Members</Typography>
                <Button variant={"outlined"} onClick={toggleShowMatched}>{ showMatched ? "Hide Matched" : "Show Matched" }</Button>
            </Wrapper>
            <hr className={"box-title-separator"}/>
            <MemberTable
                initial_headers={initial_headers}
                selection={{selection: selected, setSelection: setSelected}}
                rowSelectionEnabledFilter={rowSelectionEnabledFilter}
                showEditing={false}
                showColumnBands={false}
                row_filter={row_filter}
                showGrouping={false}
                rows={members || []}
            />
        </Block>
    );
};

MemberList.propTypes = {
    initial_headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelected: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired,
    selectedFilter: PropTypes.array.isRequired,
    matched_profiles: PropTypes.array.isRequired
};