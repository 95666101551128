import {useEffect} from "react";

const useAddValidationRuleConditionally = (ValidatorForm, validationRuleName, validateFunction, condition) => {
    useEffect(() => {
        if (condition) {
            if (!ValidatorForm.hasValidationRule(validationRuleName)) {
                ValidatorForm.addValidationRule(validationRuleName, validateFunction);
            }
        } else {
            if (!ValidatorForm.hasValidationRule(validationRuleName)) {
                ValidatorForm.addValidationRule(validationRuleName, ()=>true);
            }
        }

        return function cleanValidationRule() {
            if (ValidatorForm.hasValidationRule(validationRuleName)) {
                ValidatorForm.removeValidationRule(validationRuleName);
            }
        };
    });
};

export default useAddValidationRuleConditionally;