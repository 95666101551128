import CalendarToday from "@mui/icons-material/CalendarToday";
import DescriptionIcon from "@mui/icons-material/Description";
import TitleIcon from "@mui/icons-material/Title";
import {Grid} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import React from "react";
import { ValidatorForm } from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import TextField, {TextFieldV3} from "../../../Components/FormComponents/Fields/TextField";
import YearlessDateField from "../../../Components/FormComponents/Fields/YearlessDateField";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";


const MembershipSettingsForm = ({ association, handleAssociationChange, onSubmit, onCancel }) => {
    const smallScreen = useMediaQuery((theme)=>theme.breakpoints.up("sm"), { noSsr: true });
    const mediumScreen = useMediaQuery((theme)=>theme.breakpoints.up("md"), { noSsr: true });
    const variant = smallScreen ? "separated" : "outlined";
    const direction = mediumScreen ? "row" : "column";

    return (
        <ValidatorForm
            onSubmit={onSubmit}
            onError={errors => console.log(errors)}
        >
            <Grid container spacing={2} direction={direction}>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <YearlessDateField
                                name={"full_year_membership_start_date"}
                                label={"Memberships run to"}
                                onChange={
                                    (value)=>handleAssociationChange("full_year_membership_start_date", value)
                                }
                                value={association.full_year_membership_start_date}
                                helperText={"If your memberships run from the 1st of September to 31st of August, fill in September 1."}
                            />
                            <TextFieldV3
                                variant={variant}
                                name={"new_member_title"}
                                label={"Title for membership form"}
                                helperText={"If set, this text will be used instead of 'Additional Information Needed' above the new membership form."}
                                value={association.new_member_title}
                                onChange={(event)=>handleAssociationChange("new_member_title",event.target.value)}
                                multiline
                            />
                            <TextFieldV3
                                variant={variant}
                                name={"new_member_text"}
                                label={"Text for membership form"}
                                helperText={"If set, this text will be used instead of '{association} requires additional information...' above the new membership form."}
                                value={association.new_member_text}
                                onChange={(event)=>handleAssociationChange("new_member_text",event.target.value)}
                                multiline
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}/>
            </Grid>

            <Grid container spacing={1} justifyContent={"flex-end"}>
                <Grid item xs={3}>
                    <div/>
                    <div>
                        <Button onClick={onCancel} style={{textTransform: "none"}}>Cancel</Button>
                        &nbsp;
                        <Button type={"submit"} variant={"contained"} color={"primary"} style={{textTransform: "none"}}>Save</Button>
                    </div>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
};

MembershipSettingsForm.propTypes = {
    association: PropTypes.object.isRequired,
    handleAssociationChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default MembershipSettingsForm;