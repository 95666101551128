import CachedIcon from "@mui/icons-material/Cached";
import Create from "@mui/icons-material/Create";
import DescriptionIcon from "@mui/icons-material/Description";
import EuroIcon from "@mui/icons-material/Euro";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import GradeIcon from "@mui/icons-material/Grade";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import React from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import BooleanField from "../../../Components/FormComponents/Fields/BooleanField";
import NumberField from "../../../Components/FormComponents/Fields/NumberField";
import TextField, {TextFieldV3} from "../../../Components/FormComponents/Fields/TextField";
import YearlessDateField from "../../../Components/FormComponents/Fields/YearlessDateField";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";

const MemberTypesForm = ({ memberType, handleMemberTypeChange, onSubmit }) => {
    const smallScreen = useMediaQuery((theme)=>theme.breakpoints.up("sm"), { noSsr: true });
    const mediumScreen = useMediaQuery((theme)=>theme.breakpoints.up("md"), { noSsr: true });
    const variant = smallScreen ? "separated" : "outlined";
    const direction = mediumScreen ? "row" : "column";

    return (
        <ValidatorForm
            onSubmit={onSubmit}
            onError={errors => console.log(errors)}
        >
            <Grid container spacing={2} direction={direction}>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"type"}
                                label={"Type"}
                                value={memberType.type}
                                onChange={(event) => {
                                    handleMemberTypeChange("type", event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <NumberField
                                variant={variant}
                                name={"membership_fee"}
                                label={"Membership fee"}
                                value={memberType.membership_fee}
                                onChange={(event) => {
                                    handleMemberTypeChange("membership_fee", event.target.value);
                                }}
                                InputProps={{
                                    inputProps: {
                                        step: 0.1, min: 0
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BooleanField
                                variant={variant}
                                name={"only_chooseable_by_board"}
                                label={"Only chooseable by board"}
                                validators={["required"]}
                                errorMessages={["A type has to be set"]}
                                value={memberType.only_chooseable_by_board}
                                onChange={(event) => {
                                    handleMemberTypeChange("only_chooseable_by_board", event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BooleanField
                                variant={variant}
                                name={"full_year_membership"}
                                label={"Full year Membership"}
                                value={memberType.full_year_membership}
                                onChange={(event) => {
                                    handleMemberTypeChange("full_year_membership", event.target.value);
                                }}
                                required
                            />
                        </Grid>

                        { memberType.full_year_membership ||
                            <Grid item xs={12}>
                                <YearlessDateField
                                    variant={variant}
                                    name={"start_date"}
                                    label={"Membertype start date"}
                                    onChange={
                                        (value)=>handleMemberTypeChange("start_date", value)
                                    }
                                    value={memberType.start_date}
                                />
                                <YearlessDateField
                                    variant={variant}
                                    name={"end_date"}
                                    label={"Membertype end date"}
                                    onChange={
                                        (value)=>handleMemberTypeChange("end_date", value)
                                    }
                                    value={memberType.end_date}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <NumberField
                                variant={variant}
                                name={"order"}
                                label={"Ordering"}
                                helperText={"Memberships will be ordered according to this field"}
                                value={memberType.order}
                                onChange={(event) => {
                                    handleMemberTypeChange("order", event.target.value);
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldV3
                                variant={variant}
                                name={"helper_text"}
                                label={"Description"}
                                helperText={"The text here will be shown be shown just like this text is."}
                                value={memberType.helper_text}
                                onChange={(event) => {
                                    handleMemberTypeChange("helper_text", event.target.value);
                                }}
                                multiline
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}/>
            </Grid>
        </ValidatorForm>
    );
};

MemberTypesForm.propTypes = {
    memberType: PropTypes.object.isRequired,
    handleMemberTypeChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    enableDelete: PropTypes.bool.isRequired
};

export default MemberTypesForm;