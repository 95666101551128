import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { padStart } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import SelectField, {SelectFieldV3} from "./SelectField";


const YearlessDateField = ({ value, onChange, label }) => {
    const monthToNumberOfDays = {
        "01": 31,
        "02": 28,
        "03": 31,
        "04": 30,
        "05": 31,
        "06": 30,
        "07": 31,
        "08": 31,
        "09": 30,
        "10": 31,
        "11": 30,
        "12": 31,
        January: 31,
        February: 28,
        March: 31,
        April: 30,
        May: 31,
        June: 30,
        July: 31,
        August: 31,
        September: 30,
        October: 31,
        November: 30,
        December: 31,
    };

    return (
        <Grid container spacing={0} direction={"row"}>
            <Grid item xs={4}>
                <Tooltip title={""} arrow>
                    <Typography variant={"body1"}>{ label }</Typography>
                </Tooltip>
            </Grid>
            <Grid item xs={4}>
                <SelectFieldV3
                    variant={"outlined"}
                    label={"month"}
                    size={"small"}
                    validators={["required"]}
                    errorMessages={["A type has to be set"]}
                    value={value.split("-")[0]}
                    onChange={(event) => {
                        onChange(event.target.value + "-" + value.split("-")[1]);
                    }}
                >
                    <MenuItem value={"01"}>January</MenuItem>
                    <MenuItem value={"02"}>February</MenuItem>
                    <MenuItem value={"03"}>March</MenuItem>
                    <MenuItem value={"04"}>April</MenuItem>
                    <MenuItem value={"05"}>May</MenuItem>
                    <MenuItem value={"06"}>June</MenuItem>
                    <MenuItem value={"07"}>July</MenuItem>
                    <MenuItem value={"08"}>August</MenuItem>
                    <MenuItem value={"09"}>September</MenuItem>
                    <MenuItem value={"10"}>October</MenuItem>
                    <MenuItem value={"11"}>November</MenuItem>
                    <MenuItem value={"12"}>December</MenuItem>
                </SelectFieldV3>
            </Grid>
            <Grid item xs={4}>
                <SelectFieldV3
                    label={"day"}
                    variant={"outlined"}
                    size={"small"}
                    validators={["required"]}
                    errorMessages={["A type has to be set"]}
                    value={value.split("-")[1]}
                    onChange={(event) => {
                        onChange(value.split("-")[0] + "-" + event.target.value);
                    }}
                >
                    { [...Array(monthToNumberOfDays[value.split("-")[0]]).keys()].map((day, id) =>
                        <MenuItem key={id} value={padStart(String(day + 1),2,"0")}>{ day + 1 }</MenuItem>
                    ) }
                </SelectFieldV3>
            </Grid>
        </Grid>
    );
};

YearlessDateField.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default YearlessDateField;