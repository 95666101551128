import "./index.css";

// import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App/App";


ReactDOM.render(<App/>,document.getElementById("root"));

