import {emptySplitApi} from "./index";

const membersApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["Member"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getCurrentMembersByAssociation: build.query({
                query: (associationSlug) => ({ url: "/memberships", params: { association__slug: associationSlug, current: true, limit: 10000, expand: "association_specific_data,profile" } }),
                providesTags: (result, error, associationSlug) => [{ type: "Member", id: associationSlug }],
                transformResponse: (response) => response.results
            }),
            getMembersByAssociationAndStatus: build.query({
                query: ({ associationSlug, status }) => ({ url: "/memberships", params: { association__slug: associationSlug, status: status, limit: 10000, expand: "association_specific_data,profile" } }),
                providesTags: (result, error, { associationSlug, status }) => [{ type: "Member", id: `${associationSlug}__${status}` }],
                transformResponse: (response) => response.results
            }),
            patchSpecificData: build.mutation({
                query: ({ slug, ...patch }) => ({ url: `/association_data/${slug}`, method: "PATCH", body: patch }),
                async onQueryStarted({ slug, associationSlug }, { dispatch, queryFulfilled }) {
                    try {
                        const { data: patchedSpecificData } = await queryFulfilled;
                        dispatch(
                            membersApi.util.updateQueryData("getCurrentMembersByAssociation", associationSlug, (draftMemberships) => {
                                const index = draftMemberships.findIndex(draftMembership => draftMembership.association_specific_data.map(data=>data.slug).includes(slug));
                                const draftSpecificData = draftMemberships[index].association_specific_data;
                                draftMemberships.splice(index, 1,
                                    {...draftMemberships[index],
                                        association_specific_data: draftSpecificData.map(data=> data.slug === slug ? {...data, ...patchedSpecificData} : data)
                                    });
                            })
                        );
                        // eslint-disable-next-line no-empty
                    } catch {
                    }
                }
            }),
            patchMembership: build.mutation({
                query: ({ slug, ...patch }) => ({ url: `/memberships/${slug}`, method: "PATCH", body: patch, params: {expand: "association_specific_data,profile" }}),
                invalidatesTags: (result) => [{ type: "Member", id: result.association.split("/associations/"[1]) }, { type: "SepaMandate"}],
                async onQueryStarted({ slug }, { dispatch, queryFulfilled }) {
                    try {
                        const { data: patchedMembership } = await queryFulfilled;
                        dispatch(
                            membersApi.util.updateQueryData("getCurrentMembersByAssociation", patchedMembership.association.split("/associations/")[1], (draftMemberships) => {
                                const index = draftMemberships.findIndex(draftGroupMembership => draftGroupMembership.slug === slug);
                                draftMemberships.splice(index, 1, {...draftMemberships[index], ...patchedMembership});
                            })
                        );
                        // eslint-disable-next-line no-empty
                    } catch {}
                }
            }),
            setFinancialObligationSatisfied: build.mutation({
                query: ({ slug, financial_obligations_satisfied }) => ({ url: `/memberships/${slug}`, method: "PATCH", body: {financial_obligations_satisfied: financial_obligations_satisfied} }),
                invalidatesTags: (result) => [{ type: "Member", id: result.association.split("/associations/")[1] }],
            }),
            acceptOrDenyMembership: build.mutation({
                query: ({ slug, status }) => ({ url: `/memberships/${slug}`, method: "PATCH", body: {status: status} }),
                invalidatesTags: (result) => [{ type: "Member", id: result.association.split("/associations/")[1] }],
                async onQueryStarted({ slug }, { dispatch, queryFulfilled }) {
                    try {
                        const { data: patchedMembership } = await queryFulfilled;
                        dispatch(
                            membersApi.util.updateQueryData("getMembersByAssociationAndStatus", {associationSlug: patchedMembership.association.split("/associations/")[1], status: "Pending"}, (draftMemberships) => {
                                const index = draftMemberships.findIndex(draftGroupMembership => draftGroupMembership.slug === slug);
                                draftMemberships.splice(index, 1);
                            })
                        );
                        // eslint-disable-next-line no-empty
                    } catch {}
                }
            }),
        }),
        overrideExisting: false
    });

export const {
    useGetCurrentMembersByAssociationQuery,
    useGetMembersByAssociationAndStatusQuery,
    useAcceptOrDenyMembershipMutation,
    usePatchSpecificDataMutation,
    usePatchMembershipMutation,
    useSetFinancialObligationSatisfiedMutation,
} = membersApi;
export default membersApi;