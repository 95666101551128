import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import {Switch} from "react-router-dom";

import Button from "../../../Components/Buttons/Button";
import DateField, {DateFieldV2, DateFieldV3} from "../../../Components/FormComponents/Fields/DateField";
import FileField, {FileFieldV2} from "../../../Components/FormComponents/Fields/FileField";
import NumberField from "../../../Components/FormComponents/Fields/NumberField";
import SelectField from "../../../Components/FormComponents/Fields/SelectField";
import TextField, {TextFieldV3} from "../../../Components/FormComponents/Fields/TextField";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";


const GroupAboutForm = ({ onSubmit, onDelete, onCancel, enableDelete, group, parentable_groups, handleGroupChange }) => {
    let bodyText = "body1";

    return (
        <ValidatorForm
            onSubmit={onSubmit}
            onError={errors => console.log(errors)}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Typography>Image</Typography>
                        </Grid>
                        <Grid item>
                            <FileFieldV2
                                label={"Image"}
                                onChange={(group)=>handleGroupChange("photo",group.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextFieldV3
                        multiline
                        variant={"separated"}
                        label={"Description"}
                        value={group.description}
                        onChange={(group)=>handleGroupChange("description",group.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction={"row"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                    >
                        <Grid item>
                            { /*<div>*/ }
                            <Button onClick={onCancel} style={{textTransform: "none"}}>Cancel</Button>
                            &nbsp;
                            <Button type={"submit"} variant={"contained"} size={"small"} color={"primary"} style={{textTransform: "none"}}>Save</Button>
                            { /*</div>*/ }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            { /*<Wrapper>*/ }
            { /*    <div>*/ }
            { /*        <Button variant={"outlined"} color={"error"} disabled={!enableDelete} onClick={onDelete}>*/ }
            { /*            Delete*/ }
            { /*        </Button>*/ }
            { /*    </div>*/ }
            { /*    <div>*/ }
            { /*        <Button variant={"contained"} onClick={onCancel} color={"secondary"}>Cancel</Button>*/ }
            { /*        &nbsp;*/ }
            { /*        <Button type={"submit"} variant={"contained"} color={"primary"}>Save</Button>*/ }
            { /*    </div>*/ }
            { /*</Wrapper>*/ }
        </ValidatorForm>
    );
};

GroupAboutForm.propTypes = {
    group: PropTypes.object.isRequired,
    handleGroupChange: PropTypes.func.isRequired,
    // parentable_groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    enableDelete: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

GroupAboutForm.defaultProps = {
};

export default GroupAboutForm;