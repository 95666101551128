import { grey } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, {createContext, useContext, useState} from "react";
import {useLocation} from "react-router-dom";

import useContextHook from "../../Hooks/useContextHook";
import DropDown from "./DropDown";
import Link from "./Link";


const drawerContext = createContext({
});

function useDrawerContext() {
    return useContextHook(drawerContext);
}


const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        maxWidth: 360,
        color: "#fff",
        maxHeight: "853px",
    },
    divider: {
        borderColor: grey[500]
    }
}));

/**
 * The Drawer is the main element used in the side bar. It is a menu that consists
 * of dropdowns and links.
 *
 * @param items
 * @returns {JSX.Element}
 * @constructor
 */
const Drawer = ({ items }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const { pathname } = useLocation();
    const [openBranch, setOpenBranch] = useState([]);

    const drawerContextValue = {
        openBranch: openBranch,
        setOpenBranch: setOpenBranch,
    };
    let depth = 0;

    return (
        <drawerContext.Provider value={drawerContextValue}>
            <List
                component={"nav"}
                aria-labelledby={"nested-list-subheader"}
                subheader={
                    <ListSubheader component={"div"} id={"nested-list-subheader"}>
                    </ListSubheader>
                }
                className={classes.root}
            >
                { items && items.map((item, id)=>
                    <React.Fragment key={id}>
                        { (item.items && item.items.length > 0)
                        ? <DropDown key={id} depth={depth} pathname={pathname} branch={[id]} {...item}/>
                        : <Link key={id} depth={depth} pathname={pathname} {...item} />
                        }
                        <Divider className={classes.divider} variant={"fullWidth"}/>
                    </React.Fragment>
                ) }
            </List>
        </drawerContext.Provider>
    );
};

Drawer.propTypes = {
    items:PropTypes.array.isRequired
};

export { useDrawerContext };
export default Drawer;