import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {toast} from "material-react-toastify";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import { ValidatorForm } from "react-material-ui-form-validator";

import {useAPI} from "../../Contexts/API";
import { useGetStudiesQuery } from "../../Store/services/MySU/studies";
import Button from "../Buttons/Button";
import CountryField, { countries } from "../FormComponents/Fields/CountryField";
import DateField from "../FormComponents/Fields/DateField";
import SelectField from "../FormComponents/Fields/SelectField";
import TextField from "../FormComponents/Fields/TextField";
import Wrapper from "../FormComponents/Wrapper";
import useAddValidationRule from "../Hooks/useAddValidationRule";
import Block from "../PageLayout/Content/Block";
import hasNoSpaces from "../ValidatorRules/hasNoSpaces";
import isIban from "../ValidatorRules/isIban";
import { Osiris } from "./Osiris";


const ProfileForm = ({container, ...rest}) => {
    if (container) {
        return <ProfileFormWithContainer {...rest} />;
    } else {
        return <ProfileFormWithoutContainer {...rest} />;
    }
};

ProfileForm.propTypes = {
    container: PropTypes.bool
};

ProfileForm.defaultProps = {
    container: true
};

export default ProfileForm;

const ProfileFormWithContainer = (props) => {
    return (
        <Container>
            <Block>
                <Typography variant={"h4"}>Profile</Typography>
                <hr className={"box-title-separator"}/>
                { process.env.REACT_APP_OSIRIS === "true" &&
                    <div>
                        <Typography>
                            Instead of filling in the form, you can also simple
                            sync your profile with the Osiris. This means that
                            whenever you move, your address will automatically
                            be changed. This is the recommended option.
                        </Typography>
                        <Osiris/>
                    </div>
                }
                <ProfileFormWithoutContainer {...props} />
            </Block>
        </Container>
    );
};

const ProfileFormWithoutContainer = ({ update, profile, onSuccess, disabled, handleProfileChange }) => {
    const API = useAPI();

    const { data: studies } = useGetStudiesQuery();

    useAddValidationRule(ValidatorForm, isIban.name, isIban.validate);
    useAddValidationRule(ValidatorForm, hasNoSpaces.name, hasNoSpaces.validate);

    const handleSubmit = () => {
        if (update) {
            patchProfile(profile);
        } else {
            postProfile(profile);
        }
    };

    const postProfile = (profile) => {
        return API.callv3({
            url: "/profiles",
            method: "POST",
            object: profile,
            on_succes: (data) => {
                onSuccess(data);
            },
            on_failure: () => {
                toast.error("Save failed");
            }
        });
    };

    const patchProfile = (profile) => {
        return API.callv3({
            url: profile.url,
            method: "PATCH",
            object: profile,
            on_succes: (data) => {
                onSuccess(data);
            },
            on_failure: () => {
                toast.error("Save failed");
            },
        });
    };

    const country = useMemo(()=>countries.find(country=>profile.country === country.label),[profile.country]);

    return (
        <ValidatorForm
            onSubmit={handleSubmit}
            onError={errors => console.log(errors)}
            debounceTime={1000}
        >
            <Typography variant={"h5"}>General</Typography>
            <Wrapper>
                <TextField
                    name={"Given name"}
                    helperText={"This is the name you are commonly addressed with; in Dutch: roepnaam. Example: People adres Henk Jan Boudewijn with Henk, so his given name is Henk."}
                    value={profile.given_name}
                    onChange={(event) => handleProfileChange("given_name", event.target.value)}
                    disabled={disabled}
                    required
                />
            </Wrapper>
            <Wrapper>
                <TextField
                    name={"Initials"}
                    value={profile.initials}
                    onChange={(event) => handleProfileChange("initials", event.target.value)}
                    disabled={disabled}
                    required
                />
            </Wrapper>
            <Wrapper>
                <TextField
                    name={"Last name"}
                    value={profile.surname}
                    onChange={(event) => handleProfileChange("surname", event.target.value)}
                    disabled={disabled}
                    required
                />
            </Wrapper>
            <Wrapper>
                <SelectField
                    name={"Gender"}
                    value={profile.gender}
                    onChange={(event) => handleProfileChange("gender", event.target.value)}
                    disabled={disabled}
                    required
                >
                    <MenuItem value={"Other"}>Other</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                    <MenuItem value={"Male"}>Male</MenuItem>
                </SelectField>
            </Wrapper>
            <Wrapper>
                <DateField
                    name={"Date of birth"}
                    value={profile.date_of_birth}
                    onChange={date => handleProfileChange("date_of_birth", date)}
                    disabled={disabled}
                    required
                />
            </Wrapper>
            <Typography variant={"h5"}>Contact</Typography>
            <Wrapper>
                <TextField
                    name={"Phone number"}
                    value={profile.phone_number}
                    onChange={(event) => handleProfileChange("phone_number", event.target.value)}
                    disabled={disabled}
                    helperText={
                        <>
                            Please enter your phone number with country code, i.e. +31 06 12345678 instead of 0612345678.
                        </>
                    }
                    required
                />
                { /*<PhoneNumberField*/ }
                { /*    label={"Phone number"}*/ }
                { /*    value={profile.phone_number}*/ }
                { /*    onChange={phone => handleProfileChange("phone_number", phone)}*/ }
                { /*    disabled={disabled}*/ }
                { /*    helperText={*/ }
                { /*        <>*/ }
                { /*            Please enter your phone number with country code, i.e. +31 06 12345678 instead of 0612345678.<br/>*/ }
                { /*            NB: Dutch mobile phone numbers start with +31 06, not +31 6.*/ }
                { /*        </>*/ }
                { /*    }*/ }
                { /*    required*/ }
                { /*/>*/ }
            </Wrapper>
            <Wrapper>
                <TextField
                    name={"Email"}
                    value={profile.email}
                    onChange={(event) => handleProfileChange("email", event.target.value)}
                    validators={["isEmail"]}
                    errorMessages={["This is not a valid email"]}
                    disabled={disabled}
                    required
                />
            </Wrapper>
            <Wrapper>
                <TextField
                    name={"Address"}
                    value={profile.address}
                    onChange={(event) => handleProfileChange("address", event.target.value)}
                    disabled={disabled}
                    required
                />
            </Wrapper>
            <Wrapper>
                <TextField
                    name={"Zip code"}
                    helperText={"Dutch: postcode"}
                    value={profile.zip_code}
                    onChange={(event) => handleProfileChange("zip_code", event.target.value)}
                    disabled={disabled}
                    required
                />
            </Wrapper>
            <Wrapper>
                <TextField
                    name={"City"}
                    value={profile.city}
                    onChange={(event) => handleProfileChange("city", event.target.value)}
                    disabled={disabled}
                    required
                />
            </Wrapper>
            <Wrapper>
                <CountryField
                    label={"Country"}
                    value={country}
                    onChange={(event, value) => handleProfileChange("country", value ? value.label : "")}
                    disabled={disabled}
                    required
                />
            </Wrapper>
            <Typography variant={"h5"}>Education</Typography>
            <Wrapper>
                <SelectField
                    name={"Phase"}
                    value={profile.phase}
                    onChange={(event) => handleProfileChange("phase", event.target.value)}
                    disabled={disabled}
                    required
                >
                    <MenuItem value={"PhD"}>PhD</MenuItem>
                    <MenuItem value={"Master"}>Master</MenuItem>
                    <MenuItem value={"Premaster"}>Premaster</MenuItem>
                    <MenuItem value={"Bachelor"}>Bachelor</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                </SelectField>
            </Wrapper>
            <Wrapper>
                <SelectField
                    name={"Study"}
                    value={profile.study}
                    onChange={(event) => handleProfileChange("study", event.target.value)}
                    disabled={disabled}
                    required
                >
                    { studies && studies.map((study, s) =>
                        <MenuItem key={s} value={study.url}>{ study.studycode } -- { study.name }</MenuItem>
                    ) }
                </SelectField>
            </Wrapper>
            <Typography variant={"h5"}>Financial</Typography>
            <Wrapper>
                <TextField
                    name={"Bank account name"}
                    value={profile.bank_account_name}
                    onChange={(event) => handleProfileChange("bank_account_name", event.target.value)}
                    helperText={"Name as written on your bank card."}
                    disabled={disabled}
                />
            </Wrapper>
            <Wrapper>
                <TextField
                    name={"IBAN"}
                    value={profile.iban}
                    onChange={(event) => handleProfileChange("iban", event.target.value)}
                    validators={["isIban", "hasNoSpaces"]}
                    errorMessages={["This is not a valid iban", "Enter the iban without spaces"]}
                    helperText={
                        <>
                           Enter the IBAN without spaces, so NL12RABO0123456789 instead of NL12 RABO 0123 4567 89.
                        </>
                    }
                    disabled={disabled}
                />
            </Wrapper>
            <Wrapper>
                <TextField
                    name={"BIC"}
                    value={profile.bic}
                    onChange={(event) => handleProfileChange("bic", event.target.value)}
                    disabled={disabled}
                    helperText={"If you have a Dutch bank, you can leave this blank."}
                />
            </Wrapper>
            <Wrapper>
                <div/>
                { !disabled && <Button color={"primary"} variant={"contained"} type={"submit"}>Save profile</Button> }
            </Wrapper>

        </ValidatorForm>
    );
};

ProfileFormWithoutContainer.propTypes = {
    update: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    handleProfileChange: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    disabled: PropTypes.bool,
};

ProfileFormWithoutContainer.defaultProps = {
    onSuccess: ()=>{},
    disabled: false
};
