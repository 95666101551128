import {
    IntegratedSummary,
    SummaryState,
} from "@devexpress/dx-react-grid";
import {
    Grid,
    Table,
    TableHeaderRow,
    TableSummaryRow
} from "@devexpress/dx-react-grid-material-ui";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";

import {useGetDebtEntriesDetailQuery} from "../../../../Store/services/MySU/debtEntries";

const headers = [
    {name: "event", title: "Event"},
    {name: "enrollment_option", title: "Enrollment Option"},
    {name: "fee", title: "Fee"},
];
const totalItems = [
    { columnName: "fee", type: "max" },
    { columnName: "fee", type: "sum" },
];
const getRowId = row => row.slug;

const EventDetail = ({ slug }) => {
    const { events } = useGetDebtEntriesDetailQuery(slug, {
        selectFromResult: ({ data }) => ({
            events: data?.events,
        }),
    });
    const rows = events?.map(event=>({...event, fee: parseFloat(event.fee), amount: parseFloat(event.fee) * event.number }));
    if (!rows?.length > 0) {
        return null;
    }
    return (
        <>
            <Typography variant={"h5"}>Events</Typography>
            <Grid
                rows={rows}
                columns={headers}
                getRowId={getRowId}
            >
                <SummaryState
                    totalItems={totalItems}
                />
                <IntegratedSummary />
                <Table />
                <TableHeaderRow />
                <TableSummaryRow />
            </Grid>
        </>
    );
};

EventDetail.propTypes = {
    slug: PropTypes.string.isRequired,
};

export default EventDetail;