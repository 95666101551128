// import "react-table/react-table.css";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as moment from "moment";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import {DateFieldV2} from "../../../Components/FormComponents/Fields/DateField";
import NumberField from "../../../Components/FormComponents/Fields/NumberField";
import TextField from "../../../Components/FormComponents/Fields/TextField";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import ExtremeTable from "../../../Components/Tables/ExtremeTable";
import {
    useDeleteGroupMembershipMutation,
    useGetGroupMembershipByGroupQuery, usePatchGroupMembershipMutation
} from "../../../Store/services/MySU/groupMemberships";

const getRowId = (row) => row.slug;

/* eslint-disable no-shadow */
const PopupBoard = ({ row, onChange, onApplyChanges, onCancelChanges, open }) => {
    return (
        <Dialog open={open} onClose={onCancelChanges} aria-labelledby={"form-dialog-title"} fullWidth={true}>
            <ValidatorForm
                onSubmit={onApplyChanges}
                onError={errors => console.log(errors)}
                debounceTime={1000}
            >
                <DialogTitle id={"form-dialog-title"}>Board membership details</DialogTitle>
                <DialogContent>
                    <Wrapper>
                        <TextField
                            name={"duty"}
                            label={"Duty"}
                            value={row.duty || ""}
                            onChange={onChange}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <DateFieldV2
                            name={"date_joined"}
                            label={"Date joined"}
                            value={row.date_joined}
                            onChange={(date)=>onChange({ target: {name: "date_joined", value: date}})}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <DateFieldV2
                            name={"date_left"}
                            label={"Date left"}
                            value={row.date_left}
                            onChange={(date)=>onChange({ target: {name: "date_left", value: date}})}
                        />
                    </Wrapper>
                    <Wrapper>
                        <TextField
                            name={"email"}
                            label={"Email"}
                            value={row.email || ""}
                            onChange={onChange}
                            validators={["isEmail"]}
                            errorMessages={["This is not a valid email"]}
                        />
                    </Wrapper>
                    <Wrapper>
                        <NumberField
                            name={"order"}
                            value={row.order || ""}
                            onChange={onChange}
                            InputProps={{
                                inputProps: {
                                    step: 1, min: 1
                                }
                            }}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <TextField
                            name={"description"}
                            label={"Board text"}
                            value={row.description || ""}
                            onChange={onChange}
                            multiline
                        />
                    </Wrapper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelChanges} variant={"contained"}>
                        Cancel
                    </Button>
                    <Button type={"submit"} color={"primary"} variant={"contained"}>
                        Save
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
};

/* eslint-disable no-shadow */
const PopupCommittee = ({ row, onChange, onApplyChanges, onCancelChanges, open }) => {
    return (
        <Dialog open={open} onClose={onCancelChanges} aria-labelledby={"form-dialog-title"} fullWidth={true}>
            <ValidatorForm
                onSubmit={onApplyChanges}
                onError={errors => console.log(errors)}
            >
                <DialogTitle id={"form-dialog-title"}>Committee membership details</DialogTitle>
                <DialogContent>
                    <Wrapper>
                        <TextField
                            name={"duty"}
                            label={"Duty"}
                            value={row.duty || ""}
                            onChange={onChange}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <DateFieldV2
                            name={"date_joined"}
                            label={"Date joined"}
                            value={row.date_joined}
                            onChange={(date)=>onChange({ target: {name: "date_joined", value: date}})}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <DateFieldV2
                            name={"date_left"}
                            label={"Date left"}
                            value={row.date_left}
                            onChange={(date)=>onChange({ target: {name: "date_left", value: date}})}
                        />
                    </Wrapper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelChanges} variant={"contained"}>
                        Cancel
                    </Button>
                    <Button type={"submit"} color={"primary"} variant={"contained"}>
                        Save
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
};

const editingStateColumnExtensions = [
    { columnName: "full_name", editingEnabled: false },
];
const defaultFilters = [
    {columnName: "date_joined", operation: "startsWith", value: ""}
];
const defaultSorting = [
    {columnName: "order", direction: "asc" },
    {columnName: "date_joined", direction: "asc" }
];

const dateColumns = ["date_joined", "date_left"];
const numberColumns = ["order"];

const GroupMemberManagementGroupMemberList = ({ board_group, groupSlug }) => {
    const { data: memberships } = useGetGroupMembershipByGroupQuery(groupSlug);
    const [ deleteGroupMembership ] = useDeleteGroupMembershipMutation();
    const [ patchGroupMembership ] = usePatchGroupMembershipMutation();

    let columns = useMemo(()=>{
        let cols = [
            { name: "full_name", title: "Name" },
            { name: "duty", title: "Function"},
            { name: "date_joined", title: "Since"},
            { name: "date_left", title: "Until"}
        ];
        if (board_group === true) {
            cols.push({ name: "email", title: "Email"});
            cols.push({ name: "order", title: "Order"});
            cols.push({ name: "description", title: "Board text"});
        }
        return cols;
    },[board_group]);

    const onChange = (differences, original_row, edited_row, edited_row_index) => {
        let membership = {
            slug: original_row.slug,
            url: edited_row.url,
            date_joined: edited_row.date_joined && moment(edited_row.date_joined).format("YYYY-MM-DD"),
            date_left: edited_row.date_left && moment(edited_row.date_left).format("YYYY-MM-DD"),
            duty: edited_row.duty
        };
        if (board_group === true) {
            membership.email = edited_row.email;
            membership.order = edited_row.order;
            membership.description = edited_row.description;
        }
        patchGroupMembership(membership);
    };

    const onDelete = (deletedRow) => {
        deleteGroupMembership({slug: deletedRow, groupSlug: groupSlug});
    };

    const Popup = useMemo(()=>board_group ? PopupBoard : PopupCommittee, [board_group]);

    return (
        <ExtremeTable
            headers={columns}
            rows={memberships || []}
            getRowId={getRowId}
            showSelect={false}
            showGrouping={false}
            showEditing={true}
            allowDelete={true}
            numberColumns={numberColumns}
            dateColumns={dateColumns}
            defaultFilters={defaultFilters}
            defaultSorting={defaultSorting}
            editingStateColumnExtensions={editingStateColumnExtensions}
            onEdit={onChange}
            onDelete={onDelete}
            editThroughPopup={true}
            Popup={Popup}
        />
    );
};

GroupMemberManagementGroupMemberList.propTypes = {
    groupSlug: PropTypes.string.isRequired,
    board_group: PropTypes.bool.isRequired
};

export default GroupMemberManagementGroupMemberList;
