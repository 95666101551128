import { createSlice } from "@reduxjs/toolkit";

import authApi from "../services/MySU/auth";


const authSlice = createSlice({
    name: "auth",
    initialState: {
        authentication_backend: localStorage.getItem("SUN_backend"),
        authentication_token: localStorage.getItem("SUN_api_token"),
        authenticated: Boolean(localStorage.getItem("SUN_backend"))
    },
    reducers: {
        loginOIDC: (state) => {
            localStorage.setItem("SUN_backend", "OIDC");
            return {
                ...state,
                authentication_backend: "OIDC",
                authenticated: false
            };
        },
        clearCredentials: (state) => {
            localStorage.removeItem("SUN_backend");
            localStorage.removeItem("SUN_api_token");
            return {
                ...state,
                authentication_backend: null,
                authentication_token: null,
                authenticated: false
            };
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.loginJWT.matchFulfilled,
            (state, { payload: { token } }) => {
                state.authentication_backend = "jwt";
                state.authentication_token = "JWT " + token;
                state.authenticated = true;
                localStorage.setItem("SUN_backend", "jwt");
                localStorage.setItem("SUN_api_token", "JWT " + token);
            }
        );
    },
});

export const {
    loginOIDC,
    clearCredentials
} = authSlice.actions;
export default authSlice.reducer;