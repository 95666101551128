import React from "react";

import {TextFieldV3} from "./TextField";

const NumberField = (props) => {
    return (
        <TextFieldV3
            InputLabelProps={{
                shrink: true,
            }}
            type={"number"}
            {...props}
        />
    );
};

NumberField.propTypes = {
};

export default NumberField;