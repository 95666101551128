import {emptySplitApi} from "./index";

const enrollmentOptionsApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["Enrollment"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getEnrollmentOptionsByEvent: build.query({
                query: (eventSlug) => ({ url: "/enrollment_options", params: {"event__slug": eventSlug, limit: 1000} }),
                providesTags: (result, error, eventSlug) => [{ type: "Enrollment", id: eventSlug }],
                transformResponse: (response) => response.results
            }),
            addEnrollmentOption: build.mutation({
                query: (body) => ({ url: "/enrollment_options/", method: "POST", body: body }),
                invalidatesTags: ({ event }) => [{ type: "Enrollment", id: event.split("/events/")[1] }]
            }),
            patchEnrollmentOption: build.mutation({
                query: ({ slug, ...patch }) => ({ url: `/enrollment_options/${slug}`, method: "PATCH", body: patch }),
                invalidatesTags: (result, error, { event }) => [{ type: "Enrollment", id: event.split("/events/")[1] }]
            }),
            deleteEnrollmentOption: build.mutation({
                query: ({ slug }) => ({ url: `/enrollment_options/${slug}`, method: "DELETE" }),
                invalidatesTags: (result, error, { event }) => [{ type: "Enrollment", id: event.split("/events/")[1] }]
            })
        }),
        overrideExisting: false
    });

export const {
    useGetEnrollmentOptionsByEventQuery,
    useAddEnrollmentOptionMutation,
    usePatchEnrollmentOptionMutation,
    useDeleteEnrollmentOptionMutation,
} = enrollmentOptionsApi;
export default enrollmentOptionsApi;