import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import PropTypes from "prop-types";
import React from "react";

const ToggleGroup = ({ toggle, setToggle, toggleButtons }) => {

    const handleToggle = (event, newToggleValue) => {
        // using this makes sure that always some value is selected
        if (newToggleValue !== null) {
            setToggle(newToggleValue);
        }
    };

    return (
        <ToggleButtonGroup
            value={toggle}
            exclusive
            onChange={handleToggle}
        >
            { toggleButtons.map((toggleButton, id)=>
                <ToggleButton
                    key={id}
                    value={toggleButton.value}
                    aria-label={toggleButton.ariaLabel}
                >
                    <toggleButton.Icon/>
                </ToggleButton>
            ) }
        </ToggleButtonGroup>
    );
};

ToggleGroup.propTypes = {
    toggle: PropTypes.any.isRequired,
    setToggle: PropTypes.func.isRequired,
    toggleButtons: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.any,
        ariaLabel: PropTypes.string,
        Icon: PropTypes.elementType
    })).isRequired
};

export default ToggleGroup;