import {pick} from "@mui-treasury/styling/utils";
import Papa from "papaparse";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import {
    useAddInvoicesMutation
} from "../../../Store/services/MySU/invoices";
import {
    useGetCurrentMembersByAssociationQuery,
} from "../../../Store/services/MySU/members";
import ImportModal from "../../Calendar/ImportModal";


const InvoiceImporter = ({ associationSlug, openImportModal, toggleImportModal }) => {
    const { data: members } = useGetCurrentMembersByAssociationQuery(associationSlug);
    const [ addInvoices ] = useAddInvoicesMutation();

    const studentNumberToUrlProfileDict = useMemo(()=>(
        members ? Object.fromEntries(members.map(option=>[option.profile.student_number, option.profile.url])) : {}
    ), [members]);

    const handleImportSelection = (input) => {
        Papa.parse(input.target[0].files[0], {
            delimiter: input.target[2].value,
            header: true,
            skipEmptyLines: true,
            complete: (result)=>importSelection(result.data),
        });
    };
    const importSelection = (data) => {
        const toBeCreatedInvoices = data
            .map(invoice=>pick(invoice, ["student_number", "price", "reason", "date_issued"]))
            .map(invoice=>({
                profile: studentNumberToUrlProfileDict[invoice.student_number],
                price: invoice.price,
                reason: invoice.reason,
                date_issued: invoice.date_issued,
                association: `/association_data/${associationSlug}`
            }));
        addInvoices(toBeCreatedInvoices).unwrap()
            .then(()=>toggleImportModal())
            .catch(()=>toggleImportModal());
    };

    return (
        <ImportModal
            title={"Individual payments"}
            onSubmit={handleImportSelection}
            onCancel={toggleImportModal}
            open={openImportModal}
        />
    );
};

InvoiceImporter.propTypes = {
    associationSlug: PropTypes.string.isRequired,
    openImportModal: PropTypes.bool.isRequired,
    toggleImportModal: PropTypes.func.isRequired
};

export default InvoiceImporter;