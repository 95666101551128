import EuroIcon from "@mui/icons-material/Euro";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import {MenuItem} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import { DateFieldV3 } from "../../../Components/FormComponents/Fields/DateField";
import MemberField, {useMemberFieldOptions} from "../../../Components/FormComponents/Fields/MemberField";
import NumberField from "../../../Components/FormComponents/Fields/NumberField";
import SelectField from "../../../Components/FormComponents/Fields/SelectField";
import FontAwesomeIconHolder from "../../../Components/FormComponents/FontAwesomeIconHolder";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import {useGetProductsByAssociationQuery} from "../../../Store/services/MySU/product";

const PurchasePopupForm = ({ associationSlug, row, onChange, onApplyChanges, onCancelChanges, open }) => {
    const memberFieldOptions = useMemberFieldOptions(associationSlug);
    const profile = useMemo(()=>memberFieldOptions.find(option=> option.url === row.profile), [memberFieldOptions, row.profile]);

    const { data: products } = useGetProductsByAssociationQuery(associationSlug);

    return (
        <Dialog open={open} onClose={onCancelChanges} aria-labelledby={"form-dialog-title"} fullWidth={true}>
            <ValidatorForm
                onSubmit={onApplyChanges}
                onError={errors => console.log(errors)}
            >
                <DialogTitle id={"form-dialog-title"}>Invoice Details</DialogTitle>
                <DialogContent>
                    <Wrapper>
                        <IconHolder Icon={PersonIcon}/>
                        <MemberField
                            associationSlug={associationSlug}
                            name={"profile"}
                            label={"Member"}
                            value={profile || ""}
                            onChange={(event, value)=>onChange({target: {name: "profile", value: value ? value.url : ""}})}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <FontAwesomeIconHolder icon={["fas", "socks"]}/>
                        <SelectField
                            name={"product"}
                            label={"Product"}
                            value={row.product || ""}
                            onChange={onChange}
                        >
                            { products?.map((product)=>(
                                <MenuItem key={product.slug} value={product.url}>
                                    { product.name }
                                </MenuItem>
                            )) }
                        </SelectField>
                    </Wrapper>
                    <Wrapper>
                        <IconHolder Icon={EventIcon}/>
                        <DateFieldV3
                            name={"date_issued"}
                            label={"Date issued"}
                            value={row.date_issued || ""}
                            onChange={(date)=>onChange({target: {name: "date_issued", value: date}})}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <IconHolder Icon={EuroIcon}/>
                        <NumberField
                            name={"number"}
                            value={row.number || ""}
                            onChange={onChange}
                            InputProps={{
                                inputProps: {
                                    step: 1, min: 0
                                }
                            }}
                            required
                        />
                    </Wrapper>
                    <Wrapper>
                        <div/>
                        <div>
                            <Button onClick={onCancelChanges} variant={"contained"}>
                                Cancel
                            </Button>
                            &nbsp;
                            <Button type={"submit"} color={"primary"} variant={"contained"}>
                                Save
                            </Button>
                        </div>
                    </Wrapper>
                </DialogContent>
            </ValidatorForm>
        </Dialog>
    );
};

PurchasePopupForm.propTypes = {
    associationSlug: PropTypes.string.isRequired,
    row: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onApplyChanges: PropTypes.func.isRequired,
    onCancelChanges: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default PurchasePopupForm;