import React from "react";
import {Route, Switch, useHistory, useParams, useRouteMatch} from "react-router-dom";

import AssociationDetail from "../../Pages/AssociationsOverview/AssociationDetail";
import Associations from "../../Pages/AssociationsOverview/Associations";
import MembershipRequest from "../../Pages/AssociationsOverview/MembershipRequest";
import {useGetAssociationsQuery} from "../../Store/services/MySU/associations";
import {useMembership} from "../../Store/services/MySU/user";
import BoardMemberRoutes from "./MyAssociations/BoardMember";
import MemberRoutes from "./MyAssociations/Member";


const MembershipRequestRoute = () => {
    const history = useHistory();
    const { slug } = useParams();

    const { association } = useGetAssociationsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            association: data?.find(association=>association.slug === slug),
        }),
    });

    if (association === undefined) {
        return null;
    }

    return (
        <MembershipRequest
            onCancel={history.goBack}
            association={association}
        />
    );
};


const MemberRoute = () => {
    const { slug } = useParams();
    const associationMembership = useMembership(slug);

    if (!associationMembership) {
        return null;
    }
    return (
        <MemberRoutes
            association={associationMembership.association}
            association_membership={associationMembership}
        />
    );
};

const BoardMemberRoute = () => {
    const { slug } = useParams();
    const associationMembership = useMembership(slug);

    if (!associationMembership || !associationMembership.association.is_board) {
        return null;
    }
    return (
        <BoardMemberRoutes
            association={associationMembership.association}
            association_membership={associationMembership}
        />
    );
};

const AssociationsRouter = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={path + "/overview"}>
                <Associations/>
            </Route>
            <Route path={path + "/:slug/info"}>
                <AssociationDetail/>
            </Route>
            <Route path={path + "/:slug/request"}>
                <MembershipRequestRoute/>
            </Route>
            <Route path={path + "/:slug/member"}>
                <MemberRoute/>
            </Route>
            <Route path={path + "/:slug/boardmember"}>
                <BoardMemberRoute/>
            </Route>
        </Switch>
    );
};

AssociationsRouter.propTypes = {
};

export default AssociationsRouter;