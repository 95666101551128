import IconButton from "@mui/material/IconButton";
import MuiMenu from "@mui/material/Menu";
import PropTypes from "prop-types";
import React from "react";

import MenuItemButton from "./MenuItemButton";
import MenuItemLink from "./MenuItemLink";


const Menu = ({ children, items }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {setAnchorEl(event.currentTarget);};
    const handleClose = () => {setAnchorEl(null);};

    return (
        <div>
            <IconButton
                aria-label={"account of current user"}
                aria-controls={"menu-appbar"}
                aria-haspopup={"true"}
                onClick={handleClick}
                color={"inherit"}
                size={"large"}
            >
                { children }
            </IconButton>
            <MuiMenu
                id={"menu-appbar"}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
            >
                { items.map((item, id)=>{
                    if (item.to) {
                        return <MenuItemLink {...item} key={id}/>;
                    } else {
                        return <MenuItemButton {...item} key={id}/>;
                    }
                }) }
            </MuiMenu>
        </div>
    );
};

Menu.propTypes = {
    items: PropTypes.array.isRequired,
    children: PropTypes.element
};

export default Menu;