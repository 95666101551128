import {Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import Button from "../Buttons/Button";
import Wrapper from "../FormComponents/Wrapper";

const ConfirmationModal = ({ children, onConfirm, onCancel, title, description, open, cancelButtonText, confirmButtonText, secondaryAction, secondaryButtonText, size }) => {
    return (
        <Dialog
            open={open}
            maxWidth={size}
            aria-labelledby={"contained-modal-title-vcenter"}
        >
            <DialogTitle variant={"h5"}>{ title }</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { description }
                </DialogContentText>
                { children }
                <Wrapper>
                    <Button variant={"contained"} onClick={onCancel}>{ cancelButtonText }</Button>
                    &nbsp;
                    { secondaryAction
                    ? (
                        <div>
                            <Button variant={"contained"} color={"secondary"} onClick={secondaryAction}>{ secondaryButtonText }</Button>
                            &nbsp;
                            <Button variant={"contained"} color={"primary"} onClick={onConfirm}>{ confirmButtonText }</Button>
                        </div>
                    )
                    : (
                        <Button variant={"contained"} color={"primary"} onClick={onConfirm}>{ confirmButtonText }</Button>
                    ) }
                </Wrapper>
            </DialogContent>
        </Dialog>
    );
};

ConfirmationModal.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
    size: PropTypes.string,
    secondaryAction: PropTypes.func,
    secondaryButtonText: PropTypes.string,
};

ConfirmationModal.defaultProps = {
    secondaryAction: null,
    cancelButtonText: "Cancel",
    confirmButtonText: "Confirm",
    secondaryButtonText: "",
    size: "md"
};

export default ConfirmationModal;