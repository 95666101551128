import Container from "@mui/material/Container";
import PropTypes from "prop-types";
import React from "react";
import {useHistory, useParams} from "react-router-dom";

import {BackButton} from "../../../Components/BackButton";
import GroupInfoForm from "../../../Forms/InfoForms/Group/GroupInfoForm";


const GroupAdd = ({ boards }) => {
    const history = useHistory();
    const { slug } = useParams();

    const postSubmit = () => history.replace(boards ? "../boards" : "../committees");
    const postCancel = () => history.goBack();

    const initialGroup = {
        association: `/associations/${slug}`,
        founding_date: "",
        dissolution_date: "",
        board_group: boards,
        // permissions: []
    };

    return (
        <Container>
            <BackButton/>
            <GroupInfoForm
                group={initialGroup}
                showInfoFormStateButton={false}
                initialInfoOrFormState={"form"}
                postSubmit={postSubmit}
                postCancel={postCancel}
            />
        </Container>
    );
};

GroupAdd.propTypes = {
    boards: PropTypes.bool.isRequired
};

export default GroupAdd;
