import PropTypes from "prop-types";
import React from "react";

import withPropMap from "../../../Components/HOC/withPropMap";
import {
    useAddEventTypeMutation,
    useDeleteEventTypeMutation,
    usePatchEventTypeMutation
} from "../../../Store/services/MySU/eventTypes";
import DropDownFormContainer from "../dropDownForm";
import EventTypesForm from "./EventTypesForm";

const dropDownObjectToEventTypesFormPropsMap = ({ dropDownObject, handleDropDownObjectChange, ...rest_props}) =>
    ({ eventType: dropDownObject, handleEventTypeChange: handleDropDownObjectChange, ...rest_props});

const eventTypeToTitle = (eventType) => eventType.type;
const fieldAndValueToStateChanges = (field, value) => ({ [field]: value} );

const EventTypesDropDown = ({ eventTypes, associationUrl, postSubmit, postCancel, postDelete, creating }) => {
    const initialEventType = { association: associationUrl };
    const EventTypeFormComponent = withPropMap(EventTypesForm, dropDownObjectToEventTypesFormPropsMap);

    const [ addEventType ] = useAddEventTypeMutation();
    const [ patchEventType ] = usePatchEventTypeMutation();
    const [ removeEventType ] = useDeleteEventTypeMutation();

    const onSubmit = (eventType) => eventType.slug
        ? patchEventType(eventType).then(()=>postSubmit(eventType, eventTypes))
        : addEventType(eventType).then(()=>postSubmit(eventType, eventTypes));
    const onDelete = (eventType) => removeEventType(eventType).then(()=>postDelete(eventType, eventTypes));

    return (
        <DropDownFormContainer
            creating={creating}
            dropDownObjects={eventTypes}
            dropDownObjectToTitle={eventTypeToTitle}
            allowCreation={true}
            initialDropDownObject={initialEventType}
            FormComponent={EventTypeFormComponent}
            fieldAndValueToStateChanges={fieldAndValueToStateChanges}
            enableDelete={true}
            onSubmit={onSubmit}
            onCancel={postCancel}
            onDelete={onDelete}
        />
    );
};

EventTypesDropDown.propTypes = {
    eventTypes: PropTypes.array.isRequired,
    associationUrl: PropTypes.string.isRequired,
    postSubmit: PropTypes.func,
    postCancel: PropTypes.func,
    postDelete: PropTypes.func
};

const dummyFunction = () => {};
EventTypesDropDown.defaultProps = {
    postSubmit: dummyFunction,
    postCancel: dummyFunction, 
    postDelete: dummyFunction
};

export default EventTypesDropDown;