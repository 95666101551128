import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DraftsIcon from "@mui/icons-material/Drafts";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import {Icon, SpeedDial, SpeedDialAction, SpeedDialIcon, SvgIcon} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { green } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import {SizeMe, withSize} from "react-sizeme";

import logo from "../../../img/logo oud-all-white.png";
import useAuth from "../../Store/hooks/useAuth";
import {useGetUserDataQuery} from "../../Store/services/MySU/user";
import useModalState from "../Hooks/useModalState";
import Burger from "./Burger";
import AssociationHeader from "./Header/Header";
import AvatarMenu from "./Menu/AvatarMenu";
import NotificationMenu from "./Menu/NotificationMenu";
import AssociationBoardSideBar from "./SideBar/AssociationBoardSideBar";
import AssociationMemberSideBar from "./SideBar/AssociationMemberSideBar";
import EmptySideBar from "./SideBar/EmptySideBar";
import PublicSideBar from "./SideBar/PublicSideBar";
import StudentUnionChannels from "./StudentUnionChannels";

const drawerHeaderHeight = 90;
const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: "flex",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: theme.zIndex.drawer + 1
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.primary.dark,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
        height: drawerHeaderHeight
    },
    drawerHeaderTopOfScreen: {
        height: 126
    },
    content: {
        width: `calc(100% - ${drawerWidth}px)`,
        flexGrow: 1,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        [theme.breakpoints.up("sm")]: {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }
    },
    green: {
        color: "#fff",
        backgroundColor: green[500],
    },
    headerBar: {
        display: "flex",
        paddingLeft: 20,
        paddingRight: 20,
        justifyContent: "space-between", /* align horizontal */
        alignItems: "center", /* align vertical */
        height: "90px",
        width: "100%"
    },
    navigation: {
        display: "flex",
        alignItems: "center"
    },
    speedDial: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(1)
    },
    logoHolder : {
        display: "flex",
        justifyContent: "space-between",
        width: drawerWidth,
    },
    logo: {
        height: 59,
        maxWidth: 140
    }
});

const useStyles = makeStyles(styles);

const actions = [
    { icon: <FontAwesomeIcon icon={["fab", "discord"]} />, name: "Discord", href: "https://discord.gg/VnEneGP" },
    { icon: <DraftsIcon/>, name: "Email", href: "mailto:support@su.utwente.nl" },
    { icon: <Icon sx={{ width: "100%" }}><Box sx={{ fontSize: "1rem" }}>FAQ</Box></Icon>,
        name: "Frequently Asked Questions",
        href: "/help"
    },
    // { icon: <DraftsIcon/>, name: "Email", href: "mailto:gitlab+my-su-service-desk-issue-tracker-2716-issue-@utwente.nl" },
];

const SizedStudentUnionChannels = withSize({monitorHeight: true})(StudentUnionChannels);

const Banner = () => {
    return (
        <Box sx={{ width: "100%", backgroundColor: "#F5821F", p: 1}}>
            <Typography align={"center"}>
                This is the development server. Expect random down time.
            </Typography>
        </Box>
    );
};

const PageLayout = ({ children }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const { authenticated } = useAuth();
    const { data: user } = useGetUserDataQuery();

    const { pathname } = useLocation();

    const [topOfScreen, setTopOfScreen] = useState(126);

    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const [openSpeedDial, toggleOpenSpeedDial] = useModalState(false);
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleDrawerClose = () => setOpenDrawer(false);
    const toggleDrawer = () => setOpenDrawer(!openDrawer);

    let Header;
    if (authenticated && user && user.preferred_profile) {
        Header = AssociationHeader;
    } else {
        Header = ()=>null;
    }

    const includesAssociation = useMemo(()=>pathname.includes("protected/associations/"), [pathname]);
    const includesBoardmember = useMemo(()=>pathname.includes("/boardmember"), [pathname]);
    const includesMember = useMemo(()=>pathname.includes("/member"), [pathname]);
    const includesHome = useMemo(()=>pathname.includes("/home"), [pathname]);

    let Sidebar;
    if (includesAssociation && includesBoardmember) {
        Sidebar = AssociationBoardSideBar;
    } else if (includesAssociation && includesMember) {
        Sidebar = AssociationMemberSideBar;
    } else if (includesHome) {
        Sidebar = PublicSideBar;
    } else {
        Sidebar = EmptySideBar;
    }

    // Open the sidebar when logging in, close it when logging out.
    // Only on Desktop; on mobile this behavior is disruptive.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(()=> {
        if (!mobile){
            setOpenDrawer(authenticated);
        }
    }, [authenticated]);

    const allowOpenDrawer = includesMember || includesBoardmember || includesHome;
    const openBanner = process.env.REACT_APP_PRODUCTION_ENV === "false";

    return (
        <>
            <AppBar
                position={"sticky"}
                className={classes.appBar}
            >
                <Banner/>
                <SizedStudentUnionChannels onSize={
                    (size)=>setTopOfScreen(
                    openBanner
                        ? size.height + drawerHeaderHeight + 40
                        : size.height + drawerHeaderHeight
                    )}
                />
                <Toolbar>
                    <div className={classes.logoHolder}>
                        <img className={classes.logo} src={logo} alt={""}/>
                        { authenticated && <Burger open={openDrawer} onClick={toggleDrawer}/> }
                    </div>
                    <SizeMe>{ ({ size }) =>
                        <div style={{width: "100%"}}>
                            <div className={classes.headerBar}>
                                <div className={classes.navigation}>
                                    <Header size={size}/>
                                </div>
                                <div className={classes.navigation}>
                                    { process.env.REACT_APP_PRODUCTION_ENV === "false" &&
                                        <NotificationMenu/>
                                    }
                                    <AvatarMenu/>
                                </div>
                            </div>
                        </div> }
                    </SizeMe>
                </Toolbar>
            </AppBar>
            <div className={classes.root}>
                <nav className={classes.drawer}>
                    <Hidden mdUp implementation={"js"}>
                        <Drawer
                            variant={"temporary"}
                            anchor={"left"}
                            open={allowOpenDrawer && openDrawer}
                            onClose={handleDrawerClose}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            <div className={classes.drawerHeader} style={{ height: topOfScreen }}>
                                <IconButton
                                    color={"inherit"}
                                    onClick={handleDrawerClose}
                                    edge={"start"}
                                    className={clsx(classes.menuButton, openDrawer || classes.hide)}
                                    size={"large"}
                                >
                                    <ChevronLeftIcon/>
                                </IconButton>
                            </div>
                            <Divider/>
                            <Sidebar/>
                        </Drawer>
                    </Hidden>
                    <Hidden mdDown implementation={"js"}>
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant={"persistent"}
                            open={allowOpenDrawer && openDrawer}
                        >
                            <div className={classes.drawerHeader} style={{ height: topOfScreen }}/>
                            <Divider/>
                            <Sidebar/>
                        </Drawer>
                    </Hidden>
                </nav>
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: allowOpenDrawer && openDrawer,
                    })}
                >
                    { children }
                </main>
            </div>
            <SpeedDial
                ariaLabel={"Support SpeedDial"}
                className={classes.speedDial}
                icon={<SpeedDialIcon icon={<LiveHelpIcon/>}/>}
                onClose={toggleOpenSpeedDial}
                onOpen={toggleOpenSpeedDial}
                open={openSpeedDial}
            >
                { actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        FabProps={{
                            size: "large",
                            href: action.href,
                            rel: "noopener noreferrer",
                            target: "_blank"
                        }}
                    />
                )) }
            </SpeedDial>
        </>
    );
};

PageLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default PageLayout;