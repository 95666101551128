import "react-image-crop/dist/ReactCrop.css";
import "cropperjs/dist/cropper.css";

import {DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import Cropper from "react-cropper";

import Button from "../../../Components/Buttons/Button";
import {usePatchMyAvatarMutation} from "../../../Store/services/MySU/user";


const AvatarCropperModal = ({ onSubmit, disabled }) => {
    const [cropper, setCropper] = useState();
    const [imgSrc, setImgSrc] = useState();

    const resetState = () => {
        setImgSrc(undefined);
        setCropper(undefined);
    };

    const onSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                setImgSrc(reader.result?.toString() || ""),
            );
            reader.readAsDataURL(event.target.files[0]);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        cropper.getCroppedCanvas({
            width: 100,
            height: 100
        }).toBlob(
            (blob)=> {
                resetState();
                onSubmit(blob);
            },
            "image/jpeg",
        );
    };

    return (
        <>
            <input hidden accept={"image/*"} type={"file"} onChange={onSelectFile} disabled={disabled}/>
            <Dialog
                open={!disabled && !!imgSrc}
                maxWidth={"xs"}
                onClose={resetState}
            >
                <DialogTitle variant={"h5"}>Upload profile picture</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ width: "400px", height: "400px" }}>
                        <Cropper
                            style={{ height: 400, width: "100%" }}
                            zoomTo={0.5}
                            aspectRatio={1}
                            src={imgSrc}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => setCropper(instance)}
                            guides={true}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};


const ProfileTitleComponent = ({ title, formType, toggleFormType, infoFormObject, showInfoFormStateButton, MoreActionButtonGroup }) => {
    const [ patchMyAvatar ] = usePatchMyAvatarMutation();

    const onSubmit = (blob) => {
        const profileData = new FormData();
        profileData.append("slug", infoFormObject.slug);
        profileData.append("photo", blob, infoFormObject.slug + ".jpg");
        patchMyAvatar(profileData);
    };

    return (
        <Grid container spacing={3} sx={{ pb: 3 }} direction={"row"}>

            <Grid item>
                <IconButton component={"label"}>
                    <AvatarCropperModal
                        onSubmit={onSubmit}
                        disabled={formType === "info"}
                    />
                    { formType === "info"
                        ? (
                            <Avatar
                                src={infoFormObject?.photo}
                                sx={{ width: 64, height: 64 }}
                            />
                        )
                        : (
                            <Tooltip title={"Click to change profile picture"} arrow>
                                <Avatar
                                    src={infoFormObject?.photo}
                                    sx={{ width: 64, height: 64 }}
                                />
                            </Tooltip>
                        )
                    }
                </IconButton>
            </Grid>
            <Grid item xs>
                <Grid container justifyContent={"space-between"} sx={{ pb: 2, pt: 1 }}>
                    <Grid item xs={2}>
                        <Typography variant={"h5"}>{ title }</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <MoreActionButtonGroup formType={formType} infoFormObject={infoFormObject}/>
                        { showInfoFormStateButton && formType === "info" &&
                            <Button
                                onClick={toggleFormType}
                                version={"edit"}
                            >
                                { formType === "info" ? "Edit" : "View" }
                            </Button>
                        }
                    </Grid>
                </Grid>
                <Divider sx={{ borderBottomWidth: "medium"}}/>
            </Grid>
        </Grid>
    );
};

export default ProfileTitleComponent;