import makeStyles from "@mui/styles/makeStyles";

const useFieldStyles = makeStyles(() => ({
    field: {
        width: "90%",
        // paddingBottom    : "2px",
        // padding: "10px"
        // top: "-4px",
    },
}));

const useFieldStylesV2 = makeStyles(() => ({
    field: {
        width: "100%",
        // paddingBottom    : "2px",
        // padding: "10px"
        // top: "-4px",
    },
}));

const useFieldStylesV3 = makeStyles(() => ({
    field: {
        width: "100%",
        // paddingBottom    : "2px",
        // padding: "10px"
        // top: "-4px",
        height: "460px"
    },
}));

export { useFieldStylesV2, useFieldStylesV3 };
export default useFieldStyles;