import { Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import useSize from "@react-hook/size";
import PropTypes from "prop-types";
import React from "react";

import Image from "../../../../img/default_photo.jpg";

const useStyles = makeStyles(theme => ({
    container : {
        margin: theme.spacing(1),
    },
    photo_header: {
        height: "300px",
        borderRadius: 2*theme.shape.borderRadius,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${Image})`,
    },
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
    },
    titlebar: {
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: "100%",
        backgroundColor: theme.palette.primary.main,
    },
    sub_photo_container: {
    }
}));

const PageContent = (props) => {
    const classes = useStyles();
    const target = React.useRef(null);
    const size = useSize();

    // eslint-disable-next-line react/prop-types
    const { children } = props;

    const { title } = props;

    return (
        <div className={classes.container} ref={target}>
            <div className={classes.photo_header} style={{width: size.width}}/>

            <div className={classes.titlebar}>
                <Typography className={classes.header} variant={"h3"}>{ title || <Skeleton /> }</Typography>
            </div>

            <div className={classes.sub_photo_container}>
                { children }
            </div>
        </div>
    );
};

PageContent.propTypes = {
    title: PropTypes.string.isRequired,

};

PageContent.defaultProps = {
};

export default PageContent;
