import {Typography} from "@mui/material";
import Container from "@mui/material/Container";
import React from "react";
import {useParams} from "react-router-dom";

import Wrapper from "../../../Components/FormComponents/Wrapper";
import Block from "../../../Components/PageLayout/Content/Block";
import ProductCategoriesDropDown from "../../../Forms/DropdownForms/ProductCategories/ProductCategoriesDropDown";
import {useGetProductCategoriesByAssociationQuery} from "../../../Store/services/MySU/productCategories";


const ProductCategories = () => {
    const { slug } = useParams();
    const { data: productCategories } = useGetProductCategoriesByAssociationQuery(slug);
    return (
        <Container>
            <Block>
                <Wrapper>
                    <Typography variant={"h5"}>Product Categories</Typography>
                </Wrapper>
                <Typography>
                    Product Categories are the categories products can belong too. For example a banana could be
                    associated with the type `fruit` and a kitkat with `chocolate`.
                </Typography>
                <hr className={"box-title-separator"}/>
                <ProductCategoriesDropDown
                    productCategories={productCategories || []}
                    associationUrl={`/associations/${slug}`}
                />
            </Block>
        </Container>
    );

};

ProductCategories.propTypes = {
};

ProductCategories.defaultProps = {
};

export default ProductCategories;
