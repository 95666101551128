import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import {ValidatorForm} from "react-material-ui-form-validator";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";

import Button from "../../Components/Buttons/Button";
import CheckboxField from "../../Components/FormComponents/Fields/CheckboxField";
import SelectField from "../../Components/FormComponents/Fields/SelectField";
import Wrapper from "../../Components/FormComponents/Wrapper";
import ProfileInfo from "../../Forms/InfoForms/Profile/ProfileInfo";
import {
    useGetUserDataQuery,
    useMergeProfilesMutation,
    useResolveMembershipsMutation
} from "../../Store/services/MySU/user";


const ClaimedMembershipsModal = ({ claimedMemberships, open }) => {
    const [ resolveMemberships ] = useResolveMembershipsMutation();

    // This creates a list ['slug1', 'slug2',...] with each slug corresponding
    // to the slug of a "status=Claimed" membership
    const claimed_membership_slugs = claimedMemberships.map(membership => membership.slug);
    // We need to save the slugs in a state to be able to use it in a form.
    // The number of slugs is not always the same. We could create a state for each.
    // However having a dynamic number of state variables leads to all sorts of problems.
    // Hence we do some manipulation to save all in a single state variable.
    // To save the slugs in a single state variable we need to map the slugs to a single object
    // This changes ['slug1', 'slug2',...] to {'slug1': false, 'slug2': false,...}
    const init_for_checkboxes = claimed_membership_slugs.reduce(
        (options, option) => ({
            ...options,
            [option]: false
        }),
        {}
    );

    const [checkboxes, setCheckboxes] = useState(init_for_checkboxes);

    const handleCheckboxChange = (field, value) => {
        setCheckboxes(prevState=>({
            ...prevState,
            [field]: value
        }));
    };

    const handleSubmit = () => {
        // The API endpoint expects an object like {'membership_dict_list':{slug: action, slug: action, ...}}
        // with action being either "Accepted" or "Disputed"
        // Fields when checked represent "Accepted", and when unchecked "Disputed"
        // This transforms {'slug1': bool, 'slug2': bool,...} to [['slug1', bool], ['slug2', bool],...] to {'slug1': action, 'slug2': action,...}
        const resolveDict = Object.entries(checkboxes).reduce(
            (Y,[slug,checked])=>({
                ...Y,
                [slug]: checked ? "Accepted" : "Disputed"}
            ),
            {}
        );
        resolveMemberships(resolveDict);
    };

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={"md"}
            aria-labelledby={"contained-modal-title-vcenter"}
        >
            <ValidatorForm
                onSubmit={handleSubmit}
                onError={errors => console.log(errors)}
            >
                <DialogTitle>Confirm memberships</DialogTitle>
                <DialogContent>
                    <Typography variant={"body1"}>New associations have joined MySU. According to some of them you are a member of their association. Please check the boxes of the association you are a member of, and submit to confirm that you are indeed a member.</Typography>
                    <ValidatorForm
                        onSubmit={handleSubmit}
                        onError={errors => console.error(errors)}
                    >
                        { claimedMemberships.map((membership, m)=>(
                            <CheckboxField
                                key={m}
                                label={membership.association.name}
                                value={checkboxes[membership.slug]}
                                checked={checkboxes[membership.slug]}
                                onChange={event=>handleCheckboxChange(membership.slug, event.target.checked)}
                                labelPlacement={"end"}
                            />
                        )) }
                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                    <Wrapper>
                        <div/>
                        &nbsp;
                        <Button variant={"contained"} color={"primary"} type={"submit"}>Confirm</Button>
                    </Wrapper>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
};

const ProfileInfoModal = ({ open, profile, onClose }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth={"md"}
            aria-labelledby={"contained-modal-title-vcenter"}
        >
            <DialogTitle>{ profile.profilename || profile.slug }</DialogTitle>
            <DialogContent>
                <ProfileInfo
                    profile={profile}
                />
            </DialogContent>
            <DialogActions>
                <Wrapper>
                    <div/>
                    &nbsp;
                    <Button variant={"contained"} onClick={onClose}>Close</Button>
                </Wrapper>
            </DialogActions>
        </Dialog>
    );
};


const NeedToMergeProfilesModal = ({ open, profiles }) => {
    const [profileSelector, setProfileSelector] = useState("");
    const [profileModalProfile, setProfileModalProfile] = useState({});

    const [ mergeProfiles ] = useMergeProfilesMutation();

    const handleSubmit = () => {
        mergeProfiles({slug: profileSelector});
    };

    return (
        <>
            <ProfileInfoModal
                open={Boolean(profileModalProfile.slug)}
                profile={profileModalProfile}
                onClose={()=>setProfileModalProfile({})}
            />
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={"md"}
                aria-labelledby={"contained-modal-title-vcenter"}
            >
                <ValidatorForm
                    onSubmit={handleSubmit}
                    onError={errors => console.log(errors)}
                >
                    <DialogTitle>Merge profiles</DialogTitle>
                    <DialogContent>
                        <Typography variant={"body1"}>
                            Multiple associations have a different profile of you.

                            To make full use of the benefits of MySU, you need to reduce that to one.

                            You can inspect the known profiles below by clicking on them.
                        </Typography>
                        { profiles.map((profile, id)=>(
                            <React.Fragment key={id}>
                                <Button onClick={()=>setProfileModalProfile(profile)}>
                                    { profile.profilename || profile.slug }
                                </Button>
                                <br/>
                            </React.Fragment>
                        )) }
                        <Typography variant={"body1"}>
                            Which one do you want to use?
                        </Typography>
                        <SelectField
                            name={"Profile"}
                            value={profileSelector}
                            onChange={event => setProfileSelector(event.target.value)}
                        >
                            { profiles.map((profile,c)=>(
                                <MenuItem key={c} value={profile.slug}>{ profile.profilename }</MenuItem>
                            )) }
                        </SelectField>
                    </DialogContent>
                    <DialogActions>
                        <Wrapper>
                            <div/>
                            &nbsp;
                            <Button variant={"contained"} color={"primary"} type={"submit"}>Confirm</Button>
                        </Wrapper>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </>
    );
};


const ProfileIssueFixModals = () => {
    const { data: sunmember } = useGetUserDataQuery();

    if (!sunmember) {
        return null;
    }

    const numberOfProfiles = sunmember.profiles.length;
    const preferredProfileSet = sunmember.preferred_profile !== undefined;
    const claimedMemberships = sunmember.association_memberships.filter(membership => membership.status === "Claimed");
    const gotClaimedMemberships = claimedMemberships.length > 0;
    const needToMergeProfiles = !preferredProfileSet && numberOfProfiles > 0;

    console.log(preferredProfileSet, needToMergeProfiles, numberOfProfiles, sunmember.preferred_profile);
    return (
        <>
            <NeedToMergeProfilesModal
                open={needToMergeProfiles}
                profiles={sunmember.profiles}

            />
            <ClaimedMembershipsModal
                claimedMemberships={claimedMemberships}
                open={gotClaimedMemberships}
            />
        </>
    );
};

export default ProfileIssueFixModals;