import MuiDivider from "@mui/material/Divider";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles(theme => ({
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3)
    }
}));


const Divider = (props) => {
    const classes = useStyles();
    return <MuiDivider className={classes.divider} variant={"fullWidth"} {...props}/>;
};

export default Divider;
