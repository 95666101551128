import PropTypes from "prop-types";
import React from "react";

import withPropMap from "../../../Components/HOC/withPropMap";
import {
    useAddEnrollmentOptionMutation, useDeleteEnrollmentOptionMutation,
    usePatchEnrollmentOptionMutation
} from "../../../Store/services/MySU/enrollmentOptions";
import DropDownFormContainer from "../dropDownForm";
import EnrollmentOptionsForm from "./EnrollmentOptionsForm";

const dropDownObjectToEnrollmentOptionsFormPropsMap = ({ dropDownObject, handleDropDownObjectChange, ...rest_props}) =>
    ({ enrollmentOption: dropDownObject, handleEnrollmentOptionChange: handleDropDownObjectChange, ...rest_props});

const EnrollmentOptionToTitle = (enrollmentOption) => enrollmentOption.name;
const fieldAndValueToStateChanges = (field, value) => ({ [field]: value} );

const EnrollmentOptionsDropDown = ({ enrollmentOptions, associationUrl, eventUrl, postSubmit, postDelete, postCancel, creating }) => {
    const initialEnrollmentOption = {
        association: associationUrl,
        event: eventUrl,
        name: "",
        participation_fee: "",
        description: "",
    };
    const EnrollmentOptionFormComponent = withPropMap(EnrollmentOptionsForm, dropDownObjectToEnrollmentOptionsFormPropsMap);

    const [ addEnrollmentOption ] = useAddEnrollmentOptionMutation();
    const [ patchEnrollmentOption ] = usePatchEnrollmentOptionMutation();
    const [ deleteEnrollmentOption ] = useDeleteEnrollmentOptionMutation();

    const onSubmit = (enrollmentOption) => enrollmentOption.slug
        ? patchEnrollmentOption(enrollmentOption).then(()=>postSubmit(enrollmentOption, enrollmentOptions))
        : addEnrollmentOption(enrollmentOption).then(()=>postSubmit(enrollmentOption, enrollmentOptions));
    const onDelete = (enrollmentOption) => deleteEnrollmentOption(enrollmentOption).then(()=>postDelete(enrollmentOption, enrollmentOptions));

    return (
        <DropDownFormContainer
            creating={creating}
            dropDownObjects={enrollmentOptions}
            dropDownObjectToTitle={EnrollmentOptionToTitle}
            allowCreation={true}
            initialDropDownObject={initialEnrollmentOption}
            FormComponent={EnrollmentOptionFormComponent}
            fieldAndValueToStateChanges={fieldAndValueToStateChanges}
            enableDelete={true}
            onSubmit={onSubmit}
            onCancel={postCancel}
            onDelete={onDelete}
        />
    );
};

EnrollmentOptionsDropDown.propTypes = {
    enrollmentOptions: PropTypes.array.isRequired,
    associationUrl: PropTypes.string.isRequired,
    eventUrl: PropTypes.string.isRequired,
    postSubmit: PropTypes.func,
    postCancel: PropTypes.func,
    postDelete: PropTypes.func
};

const dummyFunction = () => {};
EnrollmentOptionsDropDown.defaultProps = {
    postSubmit: dummyFunction,
    postCancel: dummyFunction,
    postDelete: dummyFunction
};

export default EnrollmentOptionsDropDown;