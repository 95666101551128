import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Switch from "@mui/material/Switch";
import React from "react";

import {useThemer} from "../../Contexts/Theme";

const ThemeSwitch = (props) => {
    const themer = useThemer();
    const handleCheck = (event) => themer.setDark(event.target.checked);

    return (
        <Switch
            checked={themer.dark}
            onChange={handleCheck}
            icon={<Brightness7Icon/>}
            checkedIcon={<Brightness4Icon/>}
        />
    );
};

export default ThemeSwitch;