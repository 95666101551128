import AddIcon from "@mui/icons-material/Add";
import ViewListIcon from "@mui/icons-material/ViewList";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import cx from "clsx";
import PropTypes from "prop-types";
import React, {useCallback, useState} from "react";

import Button from "../../Components/Buttons/Button";
import Wrapper from "../../Components/FormComponents/Wrapper";
import useInfoFormState from "../../Components/Hooks/useInfoFormState";
import useUpdateEffect from "../../Components/Hooks/useUpdateEffect";
import Block from "../../Components/PageLayout/Content/Block";
import Divider from "../../Components/PageLayout/Content/Divider";

const useStyles = makeStyles(theme => ({
    tableStriped: {
        backgroundColor: alpha(theme.palette.primary.main, 0.35),
    },
    avatarSelected: {
        backgroundColor: theme.palette.primary.main
    },
    avatarOpaque: {
        opacity: "25%"
    },
    avatarSpan: {
        lineHeight: 3.5,
        marginLeft: theme.spacing(2)
    },
    avatarDiv: {
        marginRight: theme.spacing(7),
        marginBottom: theme.spacing(3),
        display: "flex"
    }
}));

const MultiFormContainer = ({ title, multiObjects, multiObjectToTitle, allowCreation, initialMultiObject, ...multiFormProps }) => {
    const classes = useStyles();

    const [formType, toggleFormType, setFormType] = useInfoFormState("info");
    const [creating, setCreating] = useState(false);
    const [openedMultiIndex, setOpenedMultiIndex] = useState(-1);

    useUpdateEffect(()=>{
        if (openedMultiIndex > -1) {
            setCreating(false);
        }
    }, [setCreating, openedMultiIndex]);
    useUpdateEffect(()=>{
        if (creating) {
            setOpenedMultiIndex(-1);
        }
    }, [creating, setOpenedMultiIndex]);

    return (
        <Block>
            <Wrapper>
                <Typography variant={"h5"}>{ title }</Typography>
                <Button
                    onClick={()=>setCreating(true)}
                    version={"add"}
                    disabled={formType !== "info"}
                >
                    Add
                </Button>
            </Wrapper>
            <Divider/>
            <Grid container direction={"column"}>
                <Grid container direction={"row"} item>
                    { multiObjects.map((multiObject, index)=>(
                        <Grid item className={classes.avatarDiv} key={index}>
                            <Avatar
                                sx={{ width: 56, height: 56 }}
                                className={cx(
                                    (openedMultiIndex > -1 && openedMultiIndex !== index) && classes.avatarOpaque,
                                    openedMultiIndex === index && classes.avatarSelected
                                )}
                                onClick={()=>formType === "info" && setOpenedMultiIndex(openedMultiIndex === index ? -1 : index)}
                            >
                                <ViewListIcon size={"large"}/>
                            </Avatar>
                            <span className={classes.avatarSpan}>
                                { multiObjectToTitle(multiObject) }
                            </span>
                        </Grid>
                    )) }
                </Grid>
                <Grid item direction={"row"} >
                    { (openedMultiIndex > -1 ) && (
                    ( formType === "info" )
                        ? <MultiInfo
                            multiObject={multiObjects[openedMultiIndex] || {}}
                            toggleFormType={toggleFormType}
                            setOpenedMultiIndex={setOpenedMultiIndex}
                            {...multiFormProps}
                        />
                        : <MultiForm
                            multiObject={multiObjects[openedMultiIndex]}
                            setCreating={setCreating}
                            setFormType={setFormType}
                            toggleFormType={toggleFormType}
                            {...multiFormProps}
                        />
                    ) }
                    { allowCreation && creating && (
                        <MultiForm
                            multiObject={initialMultiObject}
                            setCreating={setCreating}
                            setFormType={setFormType}
                            toggleFormType={toggleFormType}
                            {...multiFormProps}
                        />
                    ) }
                </Grid>
            </Grid>
        </Block>
    );
};

MultiFormContainer.propTypes = {
    title: PropTypes.string.isRequired,
    multiObjects: PropTypes.array.isRequired,
    multiObjectToTitle: PropTypes.func.isRequired,
    allowCreation: PropTypes.bool.isRequired,
    initialMultiObject: PropTypes.object,
    creating: PropTypes.bool
};

MultiFormContainer.defaultProps = {
    initialMultiObject: {},
    creating: false
};

const MultiInfo = ({ InfoComponent, multiObject, onDelete: propOnDelete, toggleFormType, setOpenedMultiIndex }) => {
    const onDelete = ()=>{
        propOnDelete(multiObject);
        setOpenedMultiIndex(-1);
    };

    return (
        <>
            <InfoComponent multiObject={multiObject}/>
            <Wrapper>
                <div/>
                <div>
                    <Button
                        version={"remove"}
                        onClick={onDelete}
                    >
                        Remove
                    </Button>
                    &nbsp;
                    <Button
                        version={"edit"}
                        onClick={toggleFormType}
                    >
                        Edit
                    </Button>
                </div>
            </Wrapper>
        </>
    );
};

const MultiForm = ({ FormComponent, multiObject: propMultiObject, fieldAndValueToStateChanges, enableDelete, onSubmit: propOnSubmit, onCancel: propOnCancel, setFormType, setCreating }) => {
    const [multiObjectChanges, setMultiObjectChanges] = useState({});
    const multiObject = {...propMultiObject, ...multiObjectChanges};

    const handleMultiObjectChange = (field, value) => {
        setMultiObjectChanges(prevState => ({...prevState, ...fieldAndValueToStateChanges(field, value, prevState)}));
    };

    const baseCallback = useCallback(()=>{
        setFormType("info");
        setCreating(false);
        setMultiObjectChanges({});
    }, [setFormType, setCreating, setMultiObjectChanges]);
    const onSubmit = () => {
        propOnSubmit(multiObject);
        baseCallback();
    };
    const onCancel = () => {
        propOnCancel(multiObject);
        baseCallback();
    };
    return (
        <>
            <FormComponent
                multiObject={multiObject}
                onSubmit={onSubmit}
                onCancel={onCancel}
                enableDelete={enableDelete}
                handleMultiObjectChange={handleMultiObjectChange}
            />
            <Wrapper>
                <div/>
                <div>
                    <Button
                        version={"save"}
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    &nbsp;
                    <Button
                        version={"save"}
                        type={"submit"}
                        onClick={onSubmit}
                    >
                        Save
                    </Button>
                </div>
            </Wrapper>
        </>
    );
};

MultiForm.propTypes = {
    FormComponent: PropTypes.elementType.isRequired,
    multiObject: PropTypes.object.isRequired,
    fieldAndValueToStateChanges: PropTypes.func.isRequired,
    enableDelete: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    setFormType: PropTypes.func.isRequired,
    setCreating: PropTypes.func.isRequired,
};

export default MultiFormContainer;