import HomeIcon from "@mui/icons-material/Home";
import React from "react";

import Drawer from "../../Lists/Drawer/Drawer";


export default function PublicSideBar(props){
    return PublicDrawer(props);
}

const data = [
    {
        icon: <HomeIcon />,
        primary: "Home",
        to: "/protected/home"
    },
    // {
    //     icon: <EventIcon />,
    //     primary: "Calendar",
    //     to: "/protected/calendar"
    // },
    // {
    //     icon: <PaymentIcon />,
    //     primary: "Campus Card",
    //     to: "/protected/campus-card"
    // }
];
const PublicDrawer = () => {
    return (
        <Drawer items={data}/>
    );
};
