import PropTypes from "prop-types";
import React, {useState} from "react";

import ProfileInfo from "../../Forms/InfoForms/Profile/ProfileInfo";
import ProfileForm from "../Forms/ProfileForm";

export const Profile = ({profile: propProfile, infoOrForm, ...props}) => {
    const [profile, setProfile] = useState(propProfile);

    const handleProfileChange = (field, value) => {
        setProfile(prevState =>({...prevState, [field]: value}));
    };

    if (infoOrForm === "info") {
        return (
            <ProfileInfo
                profile={profile}
            />
        );
    } else {
        return (
            <ProfileForm
                profile={profile}
                handleProfileChange={handleProfileChange}
                {...props}
            />
        );
    }
};

Profile.propTypes = {
    infoOrForm: PropTypes.string.isRequired,
    profile: PropTypes.object
};

Profile.defaultProps = {
    profile: {
        given_name: "",
        initials: "",
        surname: "",
        gender: "",
        date_of_birth: null,
        phone_number: "",
        email: "",
        address: "",
        zip_code: "",
        city: "",
        country: "",
        iban: "",
        bic_code: "",
        is_master: "",
        study: ""
    }
};