import {DataTypeProvider} from "@devexpress/dx-react-grid";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";

const BooleanOperations = [
    "equal",
    "notEqual",
];

const BooleanFormatter = ({ value }) => (value === "True" || value === true) ? "Yes" : "No";

const BooleanEditor = ({ value, onValueChange, disabled }) => {
    return (
        <Select
            input={<Input />}
            value={value || ""}
            onChange={event => onValueChange(event.target.value)}
            style={{ width: "100%" }}
            disabled={disabled}
        >
            <MenuItem value={""}>
                -
            </MenuItem>
            <MenuItem value={"True"}>
                Yes
            </MenuItem>
            <MenuItem value={"False"}>
                No
            </MenuItem>
        </Select>
    );
};

const BooleanTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={BooleanFormatter}
        editorComponent={BooleanEditor}
        availableFilterOperations={BooleanOperations}
        {...props}
    />
);

export default BooleanTypeProvider;