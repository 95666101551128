import PropTypes from "prop-types";
import React, {useMemo} from "react";

import Block from "../../../../Components/PageLayout/Content/Block";
import ExtremeTable from "../../../../Components/Tables/ExtremeTable";
import {useGetGroupMembershipByAssociationAndProfileQuery} from "../../../../Store/services/MySU/groupMemberships";
import {useGetGroupsByAssociationQuery} from "../../../../Store/services/MySU/groups";

const group_memberships_headers = [
    {name: "name", title: "Name"},
    {name: "duty", title: "Duty"},
    {name: "date_joined", title: "Date joined"},
    {name: "date_left", title: "Date left/leaving"}
];

const GroupsBlock = ({ profileSlug, associationSlug }) => {
    const { data: groups } = useGetGroupsByAssociationQuery({ associationSlug: associationSlug });
    const { data: groupMemberships } = useGetGroupMembershipByAssociationAndProfileQuery({ profileSlug: profileSlug, associationSlug: associationSlug });

    const group_memberships_rows = useMemo(()=>groupMemberships?.map((group_membership)=> {
        const group = groups?.find(group => group.url === group_membership.group);
        return ({
            slug: group_membership.slug,
            name: group ? (group.full_name || group.short_name) : "",
            duty: group_membership.duty,
            date_joined: group_membership.date_joined,
            date_left: group_membership.date_left || ""
        });
    }),[groupMemberships, groups]);

    return (
        <Block>
            <ExtremeTable
                rows={group_memberships_rows || []}
                headers={group_memberships_headers}
                showSelect={false}
                showGrouping={false}
                showColumnChooser={false}
                width={250}
                title={"Committees/boards"}
            />
        </Block>
    );
};

GroupsBlock.propTypes = {
    profileSlug: PropTypes.string.isRequired,
    associationSlug: PropTypes.string.isRequired
};

export default GroupsBlock;