import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import {useParams} from "react-router-dom";

import Button from "../../Components/Buttons/Button";
import Wrapper from "../../Components/FormComponents/Wrapper";
import Block from "../../Components/PageLayout/Content/Block";
import MemberTypesDropDown from "../../Forms/DropdownForms/MemberTypes/MemberTypesDropDown";
import {useGetMemberTypesByAssociationQuery} from "../../Store/services/MySU/memberTypes";


const AssociationMemberTypes = () => {
    const { slug } = useParams();
    const { data: memberTypes } = useGetMemberTypesByAssociationQuery(slug);

    const [creating, setCreating] = useState(false);

    return (
        <Container>
            <Block>
                <Wrapper>
                    <Typography variant={"h5"}>Membertypes</Typography>
                    <Button onClick={()=>setCreating(true)} variant={"contained"} color={"primary"}>
                        Add
                    </Button>
                </Wrapper>
                <hr className={"box-title-separator"}/>
                <MemberTypesDropDown
                    associationUrl={`/associations/${slug}`}
                    memberTypes={memberTypes || []}
                    creating={creating}
                    postSubmit={()=>setCreating(false)}
                    postCancel={()=>setCreating(false)}
                />
            </Block>
        </Container>
    );
};

AssociationMemberTypes.propTypes = {
};

export default AssociationMemberTypes;