import PropTypes from "prop-types";
import React from "react";

import withPropMap from "../../../Components/HOC/withPropMap";
import {
    useAddProfileMutation,
    usePatchMyProfileMutation,
    usePatchProfileMutation
} from "../../../Store/services/MySU/user";
import InfoForm from "../InfoForm";
import ProfileForm from "./ProfileForm";
import ProfileInfo from "./ProfileInfo";
import ProfileTitleComponent from "./ProfileTitleComponent";


const formObjectToProfileFormPropsMap = ({ formObject, handleFormObjectChange, ...rest_props}) =>
    ({ profile: formObject, handleProfileChange: handleFormObjectChange, ...rest_props});
const formObjectToProfileInfoPropsMap = ({ info, ...rest_props}) =>
    ({ profile: info, ...rest_props});

const ProfileInfoForm = ({ profile, showInfoFormStateButton, initialInfoOrFormState, MoreActionButtonGroup, postSubmit, postCancel, is_you }) => {
    const [ addProfile ] = useAddProfileMutation();
    const [ patchMyProfile ] = usePatchMyProfileMutation();
    const [ patchProfile ] = usePatchProfileMutation();

    const fieldAndValueToStateChanges = (field, value) => ({ [field]: value });

    const onSubmit = (submittedProfile) => !submittedProfile.slug
        ? addProfile(submittedProfile).then(()=>postSubmit(submittedProfile))
        : (
            is_you
                ? patchMyProfile(submittedProfile).then(()=>postSubmit(submittedProfile))
                : patchProfile(submittedProfile).then(()=>postSubmit(submittedProfile))
        );

    const ProfileInfoComponent = withPropMap(ProfileInfo, formObjectToProfileInfoPropsMap);
    const ProfileFormComponent = withPropMap(ProfileForm, formObjectToProfileFormPropsMap);

    return (
        <InfoForm
            onSubmit={onSubmit}
            onCancel={postCancel}
            title={"Profile"}
            infoFormObject={profile}
            InfoComponent={ProfileInfoComponent}
            FormComponent={ProfileFormComponent}
            TitleComponent={ProfileTitleComponent}
            fieldAndValueToStateChanges={fieldAndValueToStateChanges}
            showInfoFormStateButton={showInfoFormStateButton}
            initialInfoOrFormState={initialInfoOrFormState}
            MoreActionButtonGroup={MoreActionButtonGroup}
        />
    );
};

const dummyFunction = () => {};

ProfileInfoForm.propTypes = {
    profile: PropTypes.object.isRequired,
    showInfoFormStateButton: PropTypes.bool.isRequired,
    initialInfoOrFormState: PropTypes.string.isRequired,
    MoreActionButtonGroup: PropTypes.elementType,
    postSubmit: PropTypes.func,
    postCancel: PropTypes.func,
    is_you: PropTypes.bool.isRequired,
};

ProfileInfoForm.defaultProps = {
    MoreActionButtonGroup: dummyFunction(),
    postSubmit: dummyFunction,
    postDelete: dummyFunction,
    postCancel: dummyFunction,
};

export default ProfileInfoForm;