import { Skeleton } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";

import TextBlock from "../../../Components/PageLayout/Content/TextBlock";
import {useCurrentAssociation} from "../../../Store/services/MySU/associations";

const Home = () => {
    const { data: association, isLoading } = useCurrentAssociation();

    if (isLoading || !association) {
        return (
            <Container>
                <Skeleton variant={"rectangular"} width={"100%"} height={250}/>
            </Container>
        );
    }
    
    return (
        <Container>
            <Typography align={"center"} variant={"h2"} sx={{ marginTop: 19}}>
                Welcome to &lsquo;{ association.name }&rsquo;
            </Typography>
            <Typography align={"center"} variant={"body1"} sx={{ margin: "auto", marginTop: 8, maxWidth: "400px"}} style={{whiteSpace: "pre-line"}}>
                Here you can manage the members, the committees and the information of the assocation.
                <br /><br />
                To go to the members side of the application, you can click on the association logo in the top bar.
            </Typography>
        </Container>
    );
};

Home.propTypes = {
};

export default Home;