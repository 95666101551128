import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles(theme => ({
    block: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4),
        // borderRadius: theme.shape.borderRadius
        borderRadius: 4*theme.shape.borderRadius,
    }
}));

const Block = (props) => {
    // eslint-disable-next-line react/prop-types
    const { children } = props;

    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <div className={classes.block}>
            { children }
        </div>
    );
};

export default Block;