import React from "react";
import {useDispatch} from "react-redux";
import {Redirect} from "react-router-dom";

import {loginOIDC} from "../../Store/slices/authentication";


const OIDC = () => {
    const dispatch = useDispatch();
    dispatch(loginOIDC());

    return <Redirect to={{ pathname: "/protected/associations/overview" }}/>;
};

export default OIDC;