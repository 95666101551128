import {faUserGear} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonOff from "@mui/icons-material/PersonOff";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import Container from "@mui/material/Container";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import Wrapper from "../../Components/FormComponents/Wrapper";
import RoutingButton from "../../Components/PageLayout/Content/RoutingButton";
import Tabs from "../../Components/PageLayout/Content/Tabs";
import MembersCurrent from "./MembersCurrent";
import MembersDisputed from "./MembersDisputed";
import MembershipSettings from "./MembershipSettings";
import MembersIncoming from "./MembersIncoming";
import MembersLeaving from "./MembersLeaving";

const useStyles = makeStyles(() => ({
    rightTab: {
        width: "300px!important",
        marginLeft: "auto"
    },
    rightTabPanel: {
        backgroundColor: "transparent",
        padding: "0px",
    },
}));

const Members = () => {
    const classes = useStyles();

    const tabElements = [
        { Icon: <PersonIcon/>, label: "Current", value: "Current", Panel: <MembersCurrent/>},
        { Icon: <PersonAddIcon/>, label: "Incoming", value: "Incoming", Panel: <MembersIncoming/>},
        { Icon: <PersonRemoveIcon/>, label: "Leaving", value: "Leaving", Panel: <MembersLeaving/>},
        { Icon: <PersonOff/>, label: "Disputed", value: "Disputed", Panel: <MembersDisputed/>},
        { Icon: <FontAwesomeIcon icon={faUserGear} size={"lg"}/>, label: "Membership settings", value: "settings", Panel: <MembershipSettings/>, tabClass: classes.rightTab, tabPanelClass: classes.rightTabPanel},
    ];

    return (
        <Container maxWidth={"xl"} >
            <Wrapper>
                <RoutingButton
                    routeStringPieces={[
                        "Members"
                    ]}
                />
            </Wrapper>
            <Tabs tabElements={tabElements}/>
        </Container>
    );
};

export default Members;