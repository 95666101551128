import List from "@mui/material/List";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";

import ListItemLink from "../ListItemLink";


const useStyles = makeStyles(() => ({
    pathSelected: {
        backgroundColor: "rgba(137, 194, 230, 0.21)",
    }
}));

/**
 *
 *
 * @param icon
 * @param primary
 * @param to
 * @param depth
 * @param pathname
 * @returns {JSX.Element}
 * @constructor
 */
const Link = ({ icon, primary, to, depth, pathname}) => {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <List component={"div"} disablePadding className={(to === pathname) && classes.pathSelected}>
            <ListItemLink
                icon={icon}
                primary={primary}
                to={to}
                style={{paddingLeft: theme.spacing((2+2*depth))}}
            />
        </List>
    );
};

Link.propTypes = {
    depth: PropTypes.number.isRequired,
    icon: PropTypes.object,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
};

export default Link;