import Cookies from "js-cookie";


export class Helper {
    // DEPRECATED
    static api_call(url, method, object = undefined, json_or_form_data = "json", on_succes = undefined, on_failure = undefined, include = true) {

        // console.log("API object:", object);
        // make body
        // -----------------
        let body;
        if (json_or_form_data === "json") {
            body = JSON.stringify(object);
        } else if (json_or_form_data === "form-data") {
            body = new FormData();
            for (let property in object) {
                if (object[property] !== null) {
                    body.append(property, object[property]);
                }
            }
        } else {
            console.error("invalid content-type");
        }

        // set headers
        // -----------------
        let headers = {};
        if (json_or_form_data === "json") {
            headers["Content-Type"] = "application/json";
        }
        if (localStorage.getItem("SUN_backend") === "jwt"){
            headers["Authorization"] = localStorage.getItem("SUN_api_token");
        }

        // set fetch parameters
        // -----------------
        let fetch_parameters = {
            method: method,
            redirect: "follow"
        };
        if (localStorage.getItem("SUN_backend") === "OIDC" && include === true){
            fetch_parameters.credentials = "include";
            headers["X-CSRFToken"] = Cookies.get("csrftoken");
        }
        if (!this.isEmpty(headers)) {
            fetch_parameters.headers =  headers;
        }
        if (!(method.toUpperCase() === "GET" || method.toUpperCase() === "HEAD")){
            fetch_parameters.body = body;
        }

        // Do fetch call
        // -----------------
        let succes;
        let code;
        return fetch(url, fetch_parameters).then((resp)=> {
            succes = resp.ok;
            code = resp.status;
            return resp.json();
        }).catch(error => {
            console.error("error", error);
        }).then(resp => {
            console.log(resp, succes, code);
            if (succes) {
                if (on_succes !== undefined) {
                    on_succes(resp);
                }
            } else {
                if (on_failure !== undefined) {
                    on_failure(resp);
                }
            }
        });
    }

    // DEPRECATED
    static api_callv2({url, method, object = undefined, json_or_form_data = "json", on_succes = undefined, on_failure = undefined, external = false}={}) {
        this.api_call(url, method, object, json_or_form_data, on_succes, on_failure, !external);
    }

    // DEPRECATED
    static api_callv3({url, method, object = undefined, json_or_form_data = "json", on_succes = undefined, on_failure = undefined, external = false}={}) {
        if (!external) {
            url = process.env.REACT_APP_API_URL + url;
        }
        this.api_call(url, method, object, json_or_form_data, on_succes, on_failure, !external);
    }

    static isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    // https://stackoverflow.com/questions/33232823/how-to-compare-two-objects-and-get-key-value-pairs-of-their-differences
    static isObject(x) {
        return Object (x) === x;
    }
    static empty = {}; // Not sure why this is needed, but the code doesn't work without

    static objectLeftDifference(left = {}, right = {}, rel = "left") {
        return Object.entries(left)
            .map(
                ([ k, v ]) =>
                this.isObject (v) && this.isObject (right[k])
                    ? [ k, this.objectLeftDifference (v, right[k], rel) ]
                    : right[k] !== v
                    ? [ k, { [rel]: v } ]
                    : [ k, this.empty ]
            )
            .reduce(
                (acc, [ k, v ]) =>
                    v === this.empty
                        ? acc
                        : { ...acc, [k]: v },
                this.empty
            );
    }

    static objectMerge(left = {}, right = {}) {
        return Object.entries(right)
            .reduce(
                (acc, [ k, v ]) =>
                    this.isObject (v) && this.isObject(left[k])
                        ? { ...acc, [k]: this.objectMerge(left[k], v) }
                        : { ...acc, [k]: v }
                , left
            );
    }

    static objectDifference(original = {}, modified = {}) {
        return this.objectMerge(
            this.objectLeftDifference(original, modified, "original"),
            this.objectLeftDifference(modified, original, "modified")
        );
    }
    static intersection(array_1, array_2) {
        return array_1.filter(value => array_2.includes(value));
    }

    static difference(array_1, array_2) {
        return array_1.filter(x => !array_2.includes(x));
    }

    // https://stackoverflow.com/questions/17781472/how-to-get-a-subset-of-a-javascript-objects-properties
    static filterObjectOnProperties(object, properties) {
        return properties.reduce((a, e) => (a[e] = object[e], a), {});
    }
}