import {pick} from "@mui-treasury/styling/utils";
import PropTypes from "prop-types";
import React from "react";

import withPropMap from "../../../Components/HOC/withPropMap";
import {usePatchAssociationMutation} from "../../../Store/services/MySU/associations";
import InfoForm from "../InfoForm";
import MembershipSettingsForm from "./MembershipSettingsForm";
import MembershipSettingsInfo from "./MembershipSettingsInfo";


const formObjectToAssociationFormPropsMap = ({ formObject, handleFormObjectChange, ...rest_props}) =>
    ({ association: formObject, handleAssociationChange: handleFormObjectChange, ...rest_props});
const formObjectToAssociationInfoPropsMap = ({ info, ...rest_props}) =>
    ({ association: info, ...rest_props});

const fields = ["slug", "new_member_text", "full_year_membership_start_date", "new_member_title"];

const MembershipSettingsInfoForm = ({ association, showInfoFormStateButton, initialInfoOrFormState, MoreActionButtonGroup, postSubmit, postCancel }) => {
    const [ patchAssociation ] = usePatchAssociationMutation();

    const fieldAndValueToStateChanges = (field, value) => ({ [field]: value });
    const onSubmit = (association) => patchAssociation(pick(association, fields)).then(()=>postSubmit(association));

    const AssociationInfoComponent = withPropMap(MembershipSettingsInfo, formObjectToAssociationInfoPropsMap);
    const AssociationFormComponent = withPropMap(MembershipSettingsForm, formObjectToAssociationFormPropsMap);

    return (
        <InfoForm
            onSubmit={onSubmit}
            onCancel={postCancel}
            title={"Membership settings"}
            infoFormObject={association}
            InfoComponent={AssociationInfoComponent}
            FormComponent={AssociationFormComponent}
            fieldAndValueToStateChanges={fieldAndValueToStateChanges}
            showInfoFormStateButton={showInfoFormStateButton}
            initialInfoOrFormState={initialInfoOrFormState}
            MoreActionButtonGroup={MoreActionButtonGroup}
        />
    );
};

const dummyFunction = () => {};

MembershipSettingsInfoForm.propTypes = {
    association: PropTypes.object.isRequired,
    showInfoFormStateButton: PropTypes.bool.isRequired,
    initialInfoOrFormState: PropTypes.string.isRequired,
    MoreActionButtonGroup: PropTypes.elementType,
    postSubmit: PropTypes.func,
    postCancel: PropTypes.func
};

MembershipSettingsInfoForm.defaultProps = {
    MoreActionButtonGroup: dummyFunction(),
    postSubmit: dummyFunction,
    postCancel: dummyFunction,
};

export default MembershipSettingsInfoForm;