import {CircularProgress} from "@mui/material";
import {isEmpty, pickBy} from "lodash";
import React from "react";

import Wrapper from "../../../Components/FormComponents/Wrapper";
import AssociationType from "../../../Components/Types/Association";
import Info from "../Info";

const removeEmpty = (data) => (
    Object.entries(data)
        .reduce(
            (dataArray, [header, body])=> {
                const newBody = pickBy(body);
                if (!isEmpty(newBody)) {
                    dataArray[header] = newBody;
                }
                return dataArray;
            },
            []
        )
);

const AssociationInfo = ({ association }) => {
    if (isEmpty(association)) {
        return <Wrapper><CircularProgress /></Wrapper>;
    }
    let data = [];
    // data["General"] = {
    //     "Name": association.name,
    //     "Short name": association.short_name,
    //     "Description": association.description,
    // };
    data["Contact"] = {
        "Name": association.name,
        "Short name": association.short_name,
        "Email": association.email,
        "Phone number": association.phone_number,

    };
    data["Visiting address"] = {
        "Street": association.address,
        "Zip Code": association.zip_code, //+ (" ") + association.city,
        "City" : association.city,
        "Room": association.room,
    };
    data["Postal address"] = {
        "City": association.postal_city,
        "Zip Code": association.postal_zipcode,// + (" ") + association.postal_city,
        "Street": association.postal_address,
    };
    data["Financial"] = {
        "Bank account name": association.bank_account_name,
        "Iban": association.iban,
        "BIC": association.bic,
        "Incassant Id": association.incassant_id,
    };

    data["Constitutional"] = {
        "Articles of Association": <>
            <a href={association.articles_of_association_en} target={"_blank"} rel={"noopener noreferrer"}>Articles of Association</a><br/>
            <a href={association.articles_of_association_nl} target={"_blank"} rel={"noopener noreferrer"}>Statuten</a>
        </>,
        "Bylaws": <>
            <a href={association.bylaws_en} target={"_blank"} rel={"noopener noreferrer"}>Bylaws</a><br/>
            <a href={association.bylaws_nl} target={"_blank"} rel={"noopener noreferrer"}>Huishoudelijk Regelement</a>
        </>,
        "Founding date": association.founding_date,
    };

    data["Legal"] = {
        "Privacy Statement": <a href={association.privacy_statement} target={"_blank"} rel={"noopener noreferrer"}>Privacy Statement</a>,
        "Terms of Service": <a href={association.terms_of_service} target={"_blank"} rel={"noopener noreferrer"}>Terms of Service</a>
    };
    return (
        <Info
            headerless={false}
            data={removeEmpty(data)}
        />
    );
};

AssociationInfo.propTypes = {
    association: AssociationType
};

export default AssociationInfo;