import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {toast} from "material-react-toastify";
import * as moment from "moment";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {Switch, useHistory, useParams} from "react-router-dom";

import { BackButton } from "../../../Components/BackButton";
import Button from "../../../Components/Buttons/Button";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import Block from "../../../Components/PageLayout/Content/Block";
import RoutingButton from "../../../Components/PageLayout/Content/RoutingButton";
import TopPageBlock from "../../../Components/PageLayout/Content/TopPageBlock";
import ExtremeTable from "../../../Components/Tables/ExtremeTable";
import GroupAboutInfoForm from "../../../Forms/InfoForms/Group/GroupAboutInfoForm";
import GroupInfoForm from "../../../Forms/InfoForms/Group/GroupInfoForm";
import PopupBoard from "../../../Forms/PopupForms/GroupMembership/GroupMembershipPopupBoard";
import PopupCommittee from "../../../Forms/PopupForms/GroupMembership/GroupMembershipPopupCommittee";
import {
    useAddGroupMembershipMutation,
    useDeleteGroupMembershipMutation,
    useGetGroupMembershipByGroupQuery, usePatchGroupMembershipMutation
} from "../../../Store/services/MySU/groupMemberships";
import {useGetGroupQuery, usePatchGroupMutation} from "../../../Store/services/MySU/groups";


const getRowId = (row) => row.slug;
const editingStateColumnExtensions = [
    { columnName: "full_name", editingEnabled: false },
];
const defaultFilters = [
    {columnName: "date_joined", operation: "startsWith", value: ""}
];
const defaultSorting = [
    {columnName: "order", direction: "asc" },
    {columnName: "date_joined", direction: "asc" }
];

const dateColumns = ["date_joined", "date_left"];
const numberColumns = ["order"];

const GroupDetail = ({ boards }) => {
    const { slug, groupSlug } = useParams();
    const history = useHistory();

    const { data: group } = useGetGroupQuery(groupSlug);
    const [ patchGroup ] = usePatchGroupMutation();

    const { data: groupMemberships } = useGetGroupMembershipByGroupQuery(groupSlug);
    const [ addGroupMembership ] = useAddGroupMembershipMutation();
    const [ deleteGroupMembership ] = useDeleteGroupMembershipMutation();
    const [ patchGroupMembership ] = usePatchGroupMembershipMutation();

    let columns = useMemo(()=>{
        let cols = [
            { name: "full_name", title: "Name" },
            { name: "duty", title: "Function"},
            { name: "date_joined", title: "Since"},
            { name: "date_left", title: "Until"}
        ];
        if (group?.board_group === true) {
            cols.push({ name: "email", title: "Email"});
            cols.push({ name: "order", title: "Order"});
            cols.push({ name: "description", title: "Board text"});
        }
        return cols;
    },[group?.board_group]);

    const onChange = (differences, original_row, edited_row, edited_row_index) => {
        let membership = {
            slug: original_row.slug,
            url: edited_row.url,
            date_joined: edited_row.date_joined && moment(edited_row.date_joined).format("YYYY-MM-DD"),
            date_left: edited_row.date_left && moment(edited_row.date_left).format("YYYY-MM-DD"),
            duty: edited_row.duty
        };
        if (group?.board_group === true) {
            membership.email = edited_row.email;
            membership.order = edited_row.order;
            membership.description = edited_row.description;
        }
        patchGroupMembership(membership);
    };

    const onAdd = (props) => {
        addGroupMembership({
            association: `/associations/${slug}`,
            group: `/groups/${groupSlug}`,
            ...props
        });
    };
    const onDelete = (deletedRow) => {
        deleteGroupMembership({slug: deletedRow, groupSlug: groupSlug});
    };

    const Popup = useMemo(()=>group?.board_group ? PopupBoard : PopupCommittee, [group?.board_group]);
    const postDelete = (group) => {
        history.replace(group.group?.board_group ? "../boards" : "../committees");
    };

    const changeBoardPermissions = (newPermissions, onSuccess, onFailure) => {
        patchGroup({ ...group, permissions: newPermissions});
    };
    const grantBoardPermissions = () => {
        changeBoardPermissions(
            ["board"],
            ()=>toast.success("Granted group board permissions"),
            ()=>toast.error("Granting group board permissions failed")
        );
    };
    const revokeBoardPermissions = () => {
        changeBoardPermissions(
            [],
            ()=>toast.success("Revoked group board permissions"),
            ()=>toast.error( "Revoking group board permissions failed")
        );
    };

    if (group === undefined) {
        return null;
    }

    const MoreActionButtonGroup = () => (
        boards
            ? (
                <Container>
                    <Button
                        variant={"contained"}
                        color={"warning"}
                        size={"small"}
                        sx={{textTransform: "none"}}
                        onClick={group.current_board ? revokeBoardPermissions : grantBoardPermissions}
                    >
                        { group.current_board ? "Revoke board permissions" : "Grant board permissions" }
                    </Button>
                </Container>
            )
            : null
    );

    const has_members = groupMemberships?.length > 0;

    return (
        <Container maxWidth={"xl"}>
            <Wrapper>
                <RoutingButton routeStringPieces={[
                    boards ? "Boards" : "Committees", group.short_name
                ]}
                />
            </Wrapper>
            <TopPageBlock>
                <Typography variant={"h5"}>
                    { group.full_name }
                </Typography>
            </TopPageBlock>

            <Grid container rowSpacing={4} spacing={6} sx={{ mt: 0 }}>
                <Grid item xs={8}>
                    <GroupAboutInfoForm
                        initialInfoOrFormState={"info"}
                        group={group || {}}
                        showInfoFormStateButton={true}
                    />
                </Grid>
                <Grid item xs={4}>
                    <GroupInfoForm
                        group={group || {}}
                        showInfoFormStateButton={true}
                        initialInfoOrFormState={"info"}
                        enableDelete={!has_members}
                        MoreActionButtonGroup={MoreActionButtonGroup}
                        postDelete={postDelete}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Block>
                        <ExtremeTable
                            headers={columns}
                            rows={groupMemberships || []}
                            getRowId={getRowId}
                            showSelect={false}
                            showGrouping={false}
                            showEditing={true}
                            allowAdd={true}
                            allowDelete={true}
                            numberColumns={numberColumns}
                            dateColumns={dateColumns}
                            defaultFilters={defaultFilters}
                            defaultSorting={defaultSorting}
                            editingStateColumnExtensions={editingStateColumnExtensions}
                            onAdd={onAdd}
                            onEdit={onChange}
                            onDelete={onDelete}
                            editThroughPopup={true}
                            Popup={Popup}
                            title={"Group members"}
                        />
                    </Block>
                </Grid>
            </Grid>
        </Container>
    );
};

GroupDetail.propTypes = {
    boards: PropTypes.bool.isRequired,
};

GroupDetail.defaultProps = {
};

export default GroupDetail;