import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import React from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import Button from "../../../Components/Buttons/Button";
import {TextFieldV2, TextFieldV3, TextFieldV4} from "../../../Components/FormComponents/Fields/TextField";
import useAddValidationRule from "../../../Components/Hooks/useAddValidationRule";
import isIban from "../../../Components/ValidatorRules/isIban";

const AssociationAboutForm = ({ association, handleAssociationChange, onSubmit, onCancel }) => {
    useAddValidationRule(ValidatorForm, isIban.name, isIban.validate);

    return (
        <ValidatorForm
            onSubmit={onSubmit}
            onError={errors => console.log(errors)}
        >
            <TextFieldV4
                value={association.description}
                onChange={(event)=>handleAssociationChange("description",event.target.value)}
            />
            <Grid item xs={12}>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                >
                    <Grid item xs={3}>
                        <div>
                            <Button onClick={onCancel} style={{textTransform: "none"}}>Cancel</Button>
                            &nbsp;
                            <Button type={"submit"} variant={"contained"} color={"primary"} style={{textTransform: "none"}}>Save</Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

        </ValidatorForm>
    );
};

AssociationAboutForm.propTypes = {
    association: PropTypes.object.isRequired,
    handleAssociationChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default AssociationAboutForm;