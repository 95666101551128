import emptySplitApi, {
    pessimisticCreation,
    pessimisticCreationMultiple,
    pessimisticRemoval,
    pessimisticUpdateSingleInList
} from "./index";

const groupMembershipApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["groupMembership"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getGroupMembershipByAssociationAndProfile: build.query({
                query: ({ profileSlug, associationSlug }) => ({ url: "/group_memberships", params: { profile__slug: profileSlug, association__slug: associationSlug } }),
                providesTags: (result, error, { profileSlug, associationSlug }) => [{ type: "groupMembership", id: `${profileSlug}__${associationSlug}` }],
                transformResponse: (response) => response.results
            }),
            getGroupMembershipByGroup: build.query({
                query: (group_slug) => (group_slug && { url: "/group_memberships", params: { "django_group__group__slug": group_slug } }),
                providesTags: (result, error, group_slug) => [{ type: "groupMembership", id: group_slug }],
                transformResponse: (response) => response.results
            }),
            addGroupMembership: build.mutation({
                query: (body) => ({ url: "/group_memberships/", method: "POST", body: body }),
                async onQueryStarted(groupMembership, { dispatch, queryFulfilled }) {
                    await pessimisticCreation({
                        dispatch,
                        queryFulfilled,
                        api: groupMembershipApi,
                        query: "getGroupMembershipByGroup",
                        entityToQueryParam: (groupMembership) => groupMembership.group.split("/groups/")[1]
                    });
                }
            }),
            addGroupMemberships: build.mutation({
                query: (body) => ({ url: "/group_memberships/", method: "POST", body: body }),
                async onQueryStarted(groupMembership, { dispatch, queryFulfilled }) {
                    await pessimisticCreationMultiple({
                        dispatch,
                        queryFulfilled,
                        api: groupMembershipApi,
                        query: "getGroupMembershipByGroup",
                        entityToQueryParam: () => groupMembership[0].group.split("/groups/")[1]
                    });
                }
            }),
            patchGroupMembership: build.mutation({
                query: ({ slug, ...patch}) => ({ url: `/group_memberships/${slug}`, method: "PATCH", body: patch }),
                async onQueryStarted({ slug }, { dispatch, queryFulfilled }) {
                    await pessimisticUpdateSingleInList({
                        dispatch,
                        queryFulfilled,
                        api: groupMembershipApi,
                        query: "getGroupMembershipByGroup",
                        entityToQueryParam: (patchedGroupMembership) => patchedGroupMembership.group.split("/groups/")[1],
                        entityFilter: draftGroupMembership => draftGroupMembership.slug === slug
                    });
                }
            }),
            deleteGroupMembership: build.mutation({
                query: ({ slug }) => ({ url: `/group_memberships/${slug}`, method: "DELETE" }),
                async onQueryStarted({ slug, groupSlug }, { dispatch, queryFulfilled }) {
                    await pessimisticRemoval({
                        dispatch,
                        queryFulfilled,
                        api: groupMembershipApi,
                        query: "getGroupMembershipByGroup",
                        queryParam: groupSlug,
                        entityFilter: draftGroupMembership => draftGroupMembership.slug === slug
                    });
                }
            })
        }),
        overrideExisting: false
    });

export const {
    useGetGroupMembershipByAssociationAndProfileQuery,
    useGetGroupMembershipByGroupQuery,
    useAddGroupMembershipsMutation,
    useAddGroupMembershipMutation,
    usePatchGroupMembershipMutation,
    useDeleteGroupMembershipMutation
} = groupMembershipApi;
export default groupMembershipApi;