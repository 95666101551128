import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import {ValidatorForm} from "react-material-ui-form-validator";

import Button from "../../Components/Buttons/Button";
import Wrapper from "../FormComponents/Wrapper";


const useStyles = makeStyles(() => ({
    content: {
        minWidth: 120,
    },
}));


const FormModal = ({ onSubmit, cancellable, onCancel, title, open, size, cancelButtonText, submitButtonText, children}) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={size}
            aria-labelledby={"contained-modal-title-vcenter"}
        >
            <ValidatorForm
                onSubmit={onSubmit}
                onError={errors => console.log(errors)}
            >
                <DialogTitle>{ title }</DialogTitle>
                <DialogContent className={classes.content}>
                    { children }
                </DialogContent>
                <DialogActions>
                    <Wrapper>
                        { cancellable
                            ? <Button variant={"contained"} onClick={onCancel}>{ cancelButtonText }</Button>
                            : <div/>
                        }
                        &nbsp;
                        <Button variant={"contained"} color={"primary"} type={"submit"}>{ submitButtonText }</Button>
                    </Wrapper>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
};

FormModal.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    size: PropTypes.string,
    cancelButtonText: PropTypes.string,
    submitButtonText: PropTypes.string,
    cancellable: PropTypes.bool,
    children: PropTypes.node
};

FormModal.defaultProps = {
    size: "m",
    cancelButtonText: "Cancel",
    submitButtonText: "Submit",
    cancellable: true
};

export default FormModal;