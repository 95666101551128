import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import cx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(theme=>({
    wrapper: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(1, 0),
    },
}));

const Wrapper = ({ children, className }) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <div className={className ? cx(classes.wrapper, className) : classes.wrapper}>
            { children }
        </div>
    );
};

Wrapper.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
};

export default Wrapper;