import Paper from "@mui/material/Paper";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";

import MemberTable from "../../Components/Tables/MemberTable";
import useCurrentMembers from "../../Store/hooks/useCurrentMembers";

const MembersCurrent = () => {
    const { members } = useCurrentMembers();
    const history = useHistory();

    const [selection, setSelection] = useState([]);
    const onDetail = (clicked) => history.push(clicked);

    return (
        <Paper elevation={0}>
            <MemberTable
                selection={{selection: selection, setSelection:setSelection}}
                allowEdit={false}
                allowDelete={true}
                onDelete={onDetail}
                rows={members || []}
                title={"Current members"}
            />
        </Paper>
    );
};

export default MembersCurrent;