import moment from "moment";
import PropTypes from "prop-types";
import React, {useMemo} from "react";

import {useGetStudiesQuery} from "../../../Store/services/MySU/studies";
import Info from "../Info";

const ProfileInfo = ({ profile }) => {
    const {data: studies} = useGetStudiesQuery();
    console.log(profile);
    const data = useMemo(()=>{
        const study = (studies?.find(existing_study => existing_study.url === profile.study)?.name) || "Unknown";

        let data = [];
        data["General"] = {
            "Given name:": profile.given_name,
            "Initials:": profile.initials,
            "Last name:": profile.surname,
            "Student number:": profile.student_number,
            "Gender:": profile.gender,
            "Date of birth:": profile.date_of_birth ? moment(profile.date_of_birth).format("L") : "",
        };
        data["Contact"] = {
            "Address:": profile.address,
            "Zip code:": profile.zip_code,
            "City": profile.city,
            "Country:": profile.country,
            "Phone number:": profile.phone_number,
            "Email address": profile.email
        };
        data["Financial"] = {
            "Bank account name:": profile.bank_account_name,
            "IBAN:": profile.iban,
            "BIC:": profile.bic,
        };
        data["Education"] = {
            "Phase:": profile.phase,
            "Study": study
        };
        return data;
    }, [profile, studies]);

    return (
        <Info
            headerless={false}
            data={data}
        />
    );
};

ProfileInfo.propTypes = {
    profile: PropTypes.object.isRequired
};

export default ProfileInfo;