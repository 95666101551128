import moment from "moment/moment";
import PropTypes from "prop-types";
import React from "react";

import {useGetDebtCollectionDetailQuery} from "../../../Store/services/MySU/debtCollections";
import Detail from "./Detail";

const eventHeaders = [
    {name: "event", title: "Event"},
    {name: "enrollment_option", title: "Enrollment Option"},
    {name: "fee", title: "Fee"},
    {name: "number", title: "Number"},
    {name: "amount", title: "Amount"},
];
const eventTotalItems = [
    { columnName: "amount", type: "sum" },
];
const productHeaders = [
    {name: "product", title: "Product"},
    {name: "cost", title: "Unit Cost"},
    {name: "number", title: "Number"},
    {name: "amount", title: "Amount"},
];
const productTotalItems = [
    { columnName: "amount", type: "max" },
    { columnName: "amount", type: "sum" },
    { columnName: "number", type: "sum" },
];
const invoiceHeaders = [
    {name: "reason", title: "Reason"},
    {name: "date_issued", title: "Date Issued"},
    {name: "price", title: "Price"},
];
const invoiceTotalItems = [
    { columnName: "price", type: "sum" },
];
const membershipFeesHeaders = [
    {name: "type", title: "Type"},
    {name: "fee", title: "Fee"},
    {name: "number", title: "Number"},
    {name: "amount", title: "Amount"},
];
const membershipFeesTotalItems = [
    { columnName: "amount", type: "max" },
    { columnName: "amount", type: "sum" },
    { columnName: "number", type: "sum" },
];

const DebtCollectionSummary = ({ debtCollection }) => {
    const { events, products, membershipFees, invoices } = useGetDebtCollectionDetailQuery(debtCollection.slug, {
        selectFromResult: ({ data }) => ({
            events: data?.events.map(event=>({...event, fee: parseFloat(event.fee), amount: parseFloat(event.fee) * event.number })) || [],
            products: data?.products.map(product=>({...product, amount: product.cost * product.number })) || [],
            membershipFees: data?.membership_fees,
            invoices: data?.invoices ? [{slug: debtCollection.slug, reason: "Combined", date_issued: moment(debtCollection.debt_collection_date).format("LLLL"), price: data.invoices }] : [],
        }),
    });

    return (
        <>
            <Detail headers={eventHeaders} rows={events} totalItems={eventTotalItems}/>
            <Detail headers={productHeaders} rows={products} totalItems={productTotalItems}/>
            <Detail headers={invoiceHeaders} rows={invoices} totalItems={invoiceTotalItems}/>
            <Detail headers={membershipFeesHeaders} rows={membershipFees} totalItems={membershipFeesTotalItems}/>
        </>
    );
};

DebtCollectionSummary.propTypes = {
    debtCollection: PropTypes.object.isRequired
};

export default DebtCollectionSummary;