import {emptySplitApi} from "./index";

const enrollmentsApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["Enrollment"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getEnrollmentsByAssociationAndProfile: build.query({
                query: ({ profileSlug, associationSlug }) => ({ url: "/enrollments", params: { profile__slug: profileSlug, association__slug: associationSlug, expand: "enrollment_option" } }),
                providesTags: (result, error, { profileSlug, associationSlug }) => [{ type: "Enrollment", id: `${profileSlug}__${associationSlug}` }],
                transformResponse: (response) => response.results
            }),
            getEnrollmentsByEvent: build.query({
                query: (eventSlug) => ({ url: "/enrollments", params: {"event__slug": eventSlug, limit: 1000} }),
                providesTags: (result, error, eventSlug) => [{ type: "Enrollment", id: eventSlug }],
                transformResponse: (response) => response.results
            }),
            addEnrollment: build.mutation({
                query: (body) => ({ url: "/enrollments/", method: "POST", body: body }),
                invalidatesTags: (result, error, { event }) => [{ type: "Enrollment", id: event.split("/events/")[1] }]
            }),
            addEnrollments: build.mutation({
                query: (body) => ({ url: "/enrollments/", method: "POST", body: body }),
                invalidatesTags: (result, error, enrollments) => [{ type: "Enrollment", id: enrollments[0].event.split("/events/")[1] }]
            }),
            patchEnrollment: build.mutation({
                query: ({ slug, ...patch }) => ({ url: `/enrollments/${slug}`, method: "PATCH", body: patch }),
                invalidatesTags: (result, error, { event }) => [{ type: "Enrollment", id: event.split("/events/")[1] }]
            }),
            deleteEnrollments: build.mutation({
                query: ({ selection }) => ({ url: "/enrollments", method: "DELETE", params: { slug__in: selection }, headers: {"X-BULK-OPERATION": true }}),
                invalidatesTags: (result, error, { eventSlug }) => [{ type: "Enrollment", id: eventSlug }]
            }),
            deleteEnrollment: build.mutation({
                query: ({ slug }) => ({ url: `/enrollments/${slug}`, method: "DELETE" }),
                invalidatesTags: (result, error, { event }) => [{ type: "Enrollment", id: event.split("/events/")[1] }]
            })
        }),
        overrideExisting: false
    });

export const {
    useGetEnrollmentsByAssociationAndProfileQuery,
    useGetEnrollmentsByEventQuery,
    useAddEnrollmentMutation,
    useAddEnrollmentsMutation,
    usePatchEnrollmentMutation,
    useDeleteEnrollmentMutation,
    useDeleteEnrollmentsMutation,
} = enrollmentsApi;
export default enrollmentsApi;