import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, {useCallback, useEffect, useState} from "react";

const useStyles = makeStyles(theme => ({
    tableStriped: {
        backgroundColor: alpha(theme.palette.primary.main, 0.35),
    },
}));

const DropDownFormContainer = ({ dropDownObjects, dropDownObjectToTitle, allowCreation, initialDropDownObject, creating, ...dropDownFormProps }) => {
    const classes = useStyles();

    const [openedDropDownIndex, setOpenedDropDownIndex] = useState(-1);
    const numberOfDropDownObjects = dropDownObjects.length;

    useEffect(()=>{
        if (creating) {
            setOpenedDropDownIndex(numberOfDropDownObjects);
        }
    }, [creating, numberOfDropDownObjects]);

    return (
        <List>
            { dropDownObjects.map((dropDownObject, index)=>(
                <React.Fragment key={index}>
                    <ListItem button className={classes.tableStriped}
                        onClick={()=>setOpenedDropDownIndex(openedDropDownIndex === index ? -1 : index)}
                    >
                        <ListItemText primary={dropDownObjectToTitle(dropDownObject)} />
                        { openedDropDownIndex === index ? <ExpandLess /> : <ExpandMore /> }
                    </ListItem>
                    <Collapse in={openedDropDownIndex === index}>
                        <DropDownForm
                            dropDownObject={dropDownObject}
                            setOpenedDropDownIndex={setOpenedDropDownIndex}
                            {...dropDownFormProps}
                        />
                    </Collapse>
                    <br/>
                </React.Fragment>
            )) }
            { allowCreation && creating && (
                <React.Fragment>
                    <Divider/>
                    <br/>
                    <ListItem button className={classes.tableStriped}
                        onClick={()=>setOpenedDropDownIndex(openedDropDownIndex === numberOfDropDownObjects ? -1 : numberOfDropDownObjects)}
                    >
                        <ListItemText primary={"New"} />
                        { openedDropDownIndex === numberOfDropDownObjects ? <ExpandLess /> : <ExpandMore /> }
                    </ListItem>
                    <Collapse in={openedDropDownIndex === numberOfDropDownObjects}>
                        <DropDownForm
                            dropDownObject={initialDropDownObject}
                            setOpenedDropDownIndex={setOpenedDropDownIndex}
                            {...dropDownFormProps}
                        />
                    </Collapse>
                </React.Fragment>
            ) }
        </List>
    );
};

DropDownFormContainer.propTypes = {
    dropDownObjects: PropTypes.array.isRequired,
    dropDownObjectToTitle: PropTypes.func.isRequired,
    allowCreation: PropTypes.bool.isRequired,
    initialDropDownObject: PropTypes.object,
    creating: PropTypes.bool
};

DropDownFormContainer.defaultProps = {
    initialDropDownObject: {},
    creating: false
};

const DropDownForm = ({ FormComponent, dropDownObject: propDropDownObject, fieldAndValueToStateChanges, enableDelete, onSubmit: propOnSubmit, onCancel: propOnCancel, onDelete: propOnDelete, setOpenedDropDownIndex }) => {
    const [dropDownObjectChanges, setdropDownObjectChanges] = useState({});
    const dropDownObject = {...propDropDownObject, ...dropDownObjectChanges};

    const handleDropDownObjectChange = (field, value) => {
        setdropDownObjectChanges(prevState => ({...prevState, ...fieldAndValueToStateChanges(field, value, prevState)}));
    };

    const baseCallback = useCallback(()=>{
        setOpenedDropDownIndex(-1);
        setdropDownObjectChanges({});
    }, [setOpenedDropDownIndex, setdropDownObjectChanges]);
    const onSubmit = ()=>{
        propOnSubmit(dropDownObject);
        baseCallback();
    };
    const onDelete = ()=>{
        propOnDelete(dropDownObject);
        baseCallback();
    };
    const onCancel = ()=>{
        propOnCancel(dropDownObject);
        baseCallback();
    };
    return (
        <FormComponent
            dropDownObject={dropDownObject}
            onSubmit={onSubmit}
            onDelete={onDelete}
            onCancel={onCancel}
            enableDelete={enableDelete}
            handleDropDownObjectChange={handleDropDownObjectChange}
        />
    );
};

DropDownForm.propTypes = {
    FormComponent: PropTypes.elementType.isRequired,
    dropDownObject: PropTypes.object.isRequired,
    fieldAndValueToStateChanges: PropTypes.func.isRequired,
    enableDelete: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    setOpenedDropDownIndex: PropTypes.func.isRequired
};

DropDownForm.defaultProps = {
};

export { DropDownForm };
export default DropDownFormContainer;