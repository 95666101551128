import Container from "@mui/material/Container";
import React from "react";

import ProfileInfoForm from "../../Forms/InfoForms/Profile/ProfileInfoForm";
import {usePreferredProfile} from "../../Store/services/MySU/user";


const SharedProfile = () => {
    const profile = usePreferredProfile();
    return (
        <Container>
            <ProfileInfoForm
                initialInfoOrFormState={"info"}
                profile={profile}
                showInfoFormStateButton={true}
                is_you={true}
            />
        </Container>
    );
};

SharedProfile.propTypes = {
};

export default SharedProfile;