import {connectProps} from "@devexpress/dx-react-core";
import {EditingState,ViewState} from "@devexpress/dx-react-scheduler";
import {
    AllDayPanel,
    AppointmentForm,
    Appointments,
    AppointmentTooltip,
    DateNavigator,
    DayView,
    MonthView,
    Scheduler,
    TodayButton,
    Toolbar,
    ViewSwitcher,
    WeekView} from "@devexpress/dx-react-scheduler-material-ui";
import Room from "@mui/icons-material/Room";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";
import classNames from "clsx";
import * as moment from "moment/moment";
import PropTypes from "prop-types";
import React, {useMemo, useState} from "react";
import {useHistory, useParams} from "react-router-dom";

import Button from "../../Components/Buttons/Button";
import Wrapper from "../../Components/FormComponents/Wrapper";
import useModalState from "../../Components/Hooks/useModalState";
import EventModalForm from "../../Forms/ModalForms/Event/EventModalForm";
import {
    useGetEventsByAssociationQuery,
} from "../../Store/services/MySU/events";
import {useGetEventTypesByAssociationQuery} from "../../Store/services/MySU/eventTypes";


const fromAPItoSchedulerFormat = (calendarEvent) => {
    // Scheduler wants strings, but API gives UTC ISOstrings.
    let schedulerCalendarEvent = {
        ...calendarEvent,
        startDate: moment.utc(calendarEvent.start_date).local(),
        endDate: moment.utc(calendarEvent.end_date).local(),
        title: calendarEvent.name,
        allDay: false
    };
    if (calendarEvent.enrollable === true) {
        schedulerCalendarEvent["enrollable_until"] = moment.utc(schedulerCalendarEvent["enrollable_until"]).local();
        schedulerCalendarEvent["enrollable_from"] = moment.utc(schedulerCalendarEvent["enrollable_from"]).local();
    }
    if (calendarEvent.unenrollable === true) {
        schedulerCalendarEvent["unenrollable_from"] = moment.utc(schedulerCalendarEvent["unenrollable_from"]).local();
    }
    return schedulerCalendarEvent;
};

const fromSchedulertoAPIFormat = (calendarEvent) => {
    // API wants UTC ISOstrings, but scheduler gives local strings.
    let apiCalendarEvent = {
        ...calendarEvent,
        start_date:  new Date(calendarEvent.startDate).toISOString(),
        end_date:  new Date(calendarEvent.endDate).toISOString(),
        name: calendarEvent.title
    };
    if (calendarEvent.enrollable === true) {
        apiCalendarEvent["enrollable_until"] = new Date(apiCalendarEvent["enrollable_until"]).toISOString();
        apiCalendarEvent["enrollable_from"] = new Date(apiCalendarEvent["enrollable_from"]).toISOString();
    }
    if (apiCalendarEvent.unenrollable === true) {
        apiCalendarEvent["unenrollable_until"] = new Date(apiCalendarEvent["unenrollable_until"]).toISOString();
    }
    return apiCalendarEvent;
};

const style = ({ palette }) => ({
    icon: {
        color: palette.action.active,
    },
    textCenter: {
        textAlign: "center",
    },
    firstRoom: {
        background: "url(https://js.devexpress.com/Demos/DXHotels/Content/Pictures/Lobby-4.jpg)",
    },
    secondRoom: {
        background: "url(https://js.devexpress.com/Demos/DXHotels/Content/Pictures/MeetingRoom-4.jpg)",
    },
    thirdRoom: {
        background: "url(https://js.devexpress.com/Demos/DXHotels/Content/Pictures/MeetingRoom-0.jpg)",
    },
    header: {
        height: "260px",
        backgroundSize: "cover",
    },
});

const Appointment = ({children, style, ...restProps}) => (
    <Appointments.Appointment
        {...restProps}
        style={{
            ...style,
            backgroundColor: "#e6b800",
            borderRadius: "8px",
        }}
    >
        { children }
    </Appointments.Appointment>
);

const getClassByLocation = (classes, location) => {
    if (location === "Room 1") return classes.firstRoom;
    if (location === "Room 2") return classes.secondRoom;
    return classes.thirdRoom;
};


const Header = withStyles(style, { name: "Header" })(({
    children, appointmentData, classes, ...restProps
}) => (
    <AppointmentTooltip.Header
        {...restProps}
        className={classNames(getClassByLocation(classes, appointmentData.location), classes.header)}
        appointmentData={appointmentData}
    >
    </AppointmentTooltip.Header>
));

const Content = withStyles(style, { name: "Content" })(({
    children, appointmentData, classes, ...restProps
}) => {
    const history = useHistory();
    const readMore = () => history.push("./" + appointmentData.slug);
    return (
        <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
            { appointmentData.location &&
            <Grid container alignItems={"center"}>
                <Grid item xs={2} className={classes.textCenter}>
                    <Room className={classes.icon} />
                </Grid>
                <Grid item xs={10}>
                    <span>{ appointmentData.location }</span>
                </Grid>
            </Grid>
            }
            <Wrapper>
                <div/>
                <Button onClick={readMore}>Read more</Button>
            </Wrapper>
        </AppointmentTooltip.Content>
    );
});

const dummyFunction = () => {};

const MySUScheduler = ({ isBoardPage, currentDate, setCurrentDate }) => {
    const { slug } = useParams();

    const { data: events } = useGetEventsByAssociationQuery({associationSlug: slug, hidden: isBoardPage && ""});
    const { data: eventTypes } = useGetEventTypesByAssociationQuery(slug);

    const [selectedCalendarEvent, setSelectedCalendarEvent] = useState({});
    const [dialogVisibility, toggleDialogVisibility] = useModalState(false);

    const onEditingAppointmentChange = (calendarEvent) => setSelectedCalendarEvent(fromSchedulertoAPIFormat(calendarEvent));
    const onAddedAppointmentChange = (calendarEvent) => setSelectedCalendarEvent(fromSchedulertoAPIFormat(calendarEvent));

    const OverlayComponent = useMemo(()=>connectProps(EventModalForm, ()=>{
        return ({
            visible: dialogVisibility,
            postCancel: toggleDialogVisibility,
            postSubmit: toggleDialogVisibility,
            postDelete: toggleDialogVisibility,
            selectedEvent: {...selectedCalendarEvent, association: `/associations/${slug}`},
            eventTypes: eventTypes
        });
    }), [dialogVisibility, toggleDialogVisibility, selectedCalendarEvent, eventTypes, slug]);


    const calendarEvents = useMemo(() => events?.map(event=>fromAPItoSchedulerFormat(event) || []), [events]);

    return (
        <Paper>
            { /*<ThemeSwitch/>*/ }
            <Scheduler
                data={calendarEvents}
                height={829}
                locale={"nl-NL"}
            >
                <ViewState
                    currentDate={currentDate}
                    onCurrentDateChange={setCurrentDate}
                    defaultCurrentViewName={"Week"}
                />
                <EditingState
                    onCommitChanges={dummyFunction}
                    onEditingAppointmentChange={onEditingAppointmentChange}
                    onAddedAppointmentChange={onAddedAppointmentChange}
                />
                <DayView
                    startDayHour={0}
                    endDayHour={24}
                />
                <WeekView
                    startDayHour={0}
                    endDayHour={24}
                />
                <MonthView />
                <Toolbar />
                <DateNavigator />
                <TodayButton />
                <ViewSwitcher />
                <Appointments
                    appointmentComponent={Appointment}
                />
                <AppointmentTooltip
                    headerComponent={Header}
                    contentComponent={Content}
                    showOpenButton={isBoardPage}
                />
                <AppointmentForm
                    overlayComponent={OverlayComponent}
                    visibility={dialogVisibility}
                    onVisibilityChange={toggleDialogVisibility}
                />
                <AllDayPanel/>
            </Scheduler>
        </Paper>
    );
};

MySUScheduler.propTypes = {
    isBoardPage: PropTypes.bool.isRequired,
    currentDate: PropTypes.object.isRequired,
    setCurrentDate: PropTypes.func.isRequired
};

export {
    fromAPItoSchedulerFormat
};
export default MySUScheduler;