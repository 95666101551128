import React from "react";

const useContextHook = (context, error_message) => {
    const used_context = React.useContext(context);
    if (used_context === undefined) {
        throw new Error(error_message);
    }
    return used_context;
};

export default useContextHook;