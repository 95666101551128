import {Skeleton} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import Wrapper from "../../Components/FormComponents/Wrapper";
import Block from "../../Components/PageLayout/Content/Block";
import RoutingButton from "../../Components/PageLayout/Content/RoutingButton";
import TopPageBlock from "../../Components/PageLayout/Content/TopPageBlock";
import AssociationInfo from "../../Forms/InfoForms/Association/AssociationInfo";
import {useCurrentAssociation} from "../../Store/services/MySU/associations";

const About = () => {
    const { data: association } = useCurrentAssociation();

    return (
        <Container maxWidth={"xl"}>
            <Wrapper>
                <RoutingButton
                    routeStringPieces={[
                        "Association", "About"
                    ]}
                />
            </Wrapper>
            <TopPageBlock>
                <Typography variant={"h5"}>
                    { association ? association.name : <Skeleton /> }
                </Typography>
            </TopPageBlock>
            <Grid container rowSpacing={4} spacing={6} sx={{ mt: 0 }}>
                <Grid item xs={8}>
                    <Block>
                        <Typography variant={"h6"}>
                            About
                        </Typography>
                        <Divider/>
                        <Typography>
                            { association ? association.description : <Skeleton /> }
                        </Typography>
                    </Block>
                </Grid>
                <Grid item xs={4}>
                    <Block>
                        <Grid container alignItems={"center"}>
                            <Grid item xs={2}>
                                <Typography variant={"h5"}>Details</Typography>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <AssociationInfo association={association}/>
                    </Block>
                </Grid>
            </Grid>
        </Container>
    );
};

About.propTypes = {
};

About.defaultProps = {
};

export default About;
