import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import React from "react";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";

import useAuth from "../../../Store/hooks/useAuth";
import {usePreferredProfile} from "../../../Store/services/MySU/user";
import {clearCredentials} from "../../../Store/slices/authentication";


const MenuItemButton = React.forwardRef((props, ref) => {
    const { onClick, primary, icon } = props;
    return (
        <MenuItem
            ref={ref}
            onClick={onClick}
            primary={primary}
        >
            <ListItemIcon fontSize={"small"}>{ icon }</ListItemIcon>
            <ListItemText primary={primary} />
        </MenuItem>
    );
});

MenuItemButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    primary: PropTypes.string.isRequired,
    icon: PropTypes.element,
};


const MenuItemLink = React.forwardRef((props, ref) => {
    const { to, primary, icon } = props;
    return (
        <MenuItem
            ref={ref}
            component={Link}
            to={to}
            primary={primary}
        >
            <ListItemIcon fontSize={"small"}>{ icon }</ListItemIcon>
            <ListItemText primary={primary} />
        </MenuItem>
    );
});

MenuItemLink.propTypes = {
    to: PropTypes.string.isRequired,
    primary: PropTypes.string.isRequired,
    icon: PropTypes.element,
};

const AvatarMenu = () => {
    const dispatch = useDispatch();
    const { authenticated } = useAuth();

    const profile = usePreferredProfile();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const logout = () => dispatch(clearCredentials());

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let avatarMenuItems;
    if (authenticated) {
        avatarMenuItems = [
            {to: "/protected/profile/shared", icon: <PersonIcon/>, primary: "Profile"},
            {to: "/help", icon: <ContactSupportIcon/>, primary: "Help"},
            {onClick: logout, icon: <LogoutIcon/>, primary: "Logout"},
        ];
    } else {
        avatarMenuItems = [
            {to: "/help", icon: <ContactSupportIcon/>, primary: "Help"},
            {to: "/account/login", icon: <LoginIcon/>, primary: "Login"},
        ];
    }

    return (
        <React.Fragment>
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <IconButton
                    onClick={handleClick}
                    size={"large"}
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup={"true"}
                    aria-expanded={open ? "true" : undefined}
                >
                    <Avatar src={profile?.photo}></Avatar>
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id={"account-menu"}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: "\"\"",
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                { avatarMenuItems.map((item, id)=>{
                    if (item.to) {
                        return <MenuItemLink {...item} key={id}/>;
                    } else {
                        return <MenuItemButton {...item} key={id}/>;
                    }
                }) }
            </Menu>
        </React.Fragment>
    );
};

AvatarMenu.propTypes = {
    children: PropTypes.element
};

export default AvatarMenu;