import PropTypes from "prop-types";
import React from "react";
import { Redirect } from "react-router-dom";

import useAuth from "../../Store/hooks/useAuth";

const RequireAuth = ({ children, redirectTo }) => {
    const { authenticated } = useAuth();
    return authenticated ? children : <Redirect to={redirectTo}/>;
};

RequireAuth.propTypes = {
    children: PropTypes.node.isRequired,
    redirectTo: PropTypes.string.isRequired,
};

export default RequireAuth;