import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";

const DataFieldsInfo = ({ dataField }) => {
    return (
        <div>
            <Grid container spacing={2}>
                <Grid container item lg={4}>
                    <Grid container item spacing={2}>
                        <Grid item xs={6}>
                            <Typography>Name</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{ dataField.name }</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item xs={6}>
                            <Typography>Type</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{ dataField.type }</Typography>
                        </Grid>
                    </Grid>
                    { dataField.type === "Choice" &&
                        <Grid container item spacing={2}>
                            <Grid item xs={6}>
                                <Typography>Choices</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>{ dataField.choices }</Typography>
                            </Grid>
                        </Grid>
                    }
                    <Grid container item spacing={2}>
                        <Grid item xs={6}>
                            <Typography>Default</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{ dataField.default || " - " }</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item xs={6}>
                            <Typography>Help message</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{ dataField.help_message || " - " }</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item xs={6}>
                            <Typography>Board only</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>{ dataField.board_only === true ? "Yes" : "No" }</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

DataFieldsInfo.propTypes = {
    dataField: PropTypes.object.isRequired,
};

export default DataFieldsInfo;