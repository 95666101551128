import {useEffect} from "react";

const useAddValidationRule = (ValidatorForm, validationRuleName, validateFunction) => {
    useEffect(() => {
        if (!ValidatorForm.hasValidationRule(validationRuleName)) {
            ValidatorForm.addValidationRule(validationRuleName, validateFunction);
        }

        return function cleanValidationRule() {
            if (ValidatorForm.hasValidationRule(validationRuleName)) {
                ValidatorForm.removeValidationRule(validationRuleName);
            }
        };
    });
};

export default useAddValidationRule;