import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DraftsIcon from "@mui/icons-material/Drafts";
import SubjectIcon from "@mui/icons-material/Subject";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";

import Button from "../../Components/Buttons/Button";
import Wrapper from "../../Components/FormComponents/Wrapper";
import Block from "../../Components/PageLayout/Content/Block";


const useStyles = makeStyles(theme => ({
    icon: {
        margin: theme.spacing(2, 0),
        marginRight: theme.spacing(2),
    },
    textField: {
        width: "100%",
    },
}));

const EmailSender = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    // const { data: emails, loading: loadingEmails } = useGet(process.env.REACT_APP_API_URL + "/emails");
    const emails = [];

    const textEditorProps = field => ({
        variant: "outlined",
        label: field[0].toUpperCase() + field.slice(1),
        className: classes.textField,
        containerProps: {className: classes.textField}
    });

    const handleValidSubmit = () => {};

    return (
        <Container>
            <Block>
                <Typography variant={"h5"}>Send Email</Typography>
                <Typography>This option has been disabled until further notice.</Typography>
                <hr className={"box-title-separator"}/>
                <ValidatorForm
                    onSubmit={handleValidSubmit}
                    onError={errors => console.log(errors)}
                >
                    <Wrapper>
                        <ContactMailIcon className={classes.icon} color={"action"}/>
                        <TextValidator
                            {...textEditorProps("Sender")}
                            // onChange={(event) => {
                            //     this.handleDataChange('name', event.target.value)
                            // }}
                        />
                    </Wrapper>
                    <Wrapper>
                        <AlternateEmailIcon className={classes.icon} color={"action"}/>
                        <TextValidator
                            {...textEditorProps("Receiver")}
                            // onChange={(event) => {
                            //     this.handleDataChange('name', event.target.value)
                            // }}
                        />
                    </Wrapper>
                    <Wrapper>
                        <SubjectIcon className={classes.icon} color={"action"}/>
                        <TextValidator
                            {...textEditorProps("Subject")}
                            // onChange={(event) => {
                            //     this.handleDataChange('name', event.target.value)
                            // }}
                        />
                    </Wrapper>
                    <Wrapper>
                        <DraftsIcon className={classes.icon} color={"action"}/>
                        <TextValidator
                            {...textEditorProps("Content")}
                            multiline
                            // onChange={(event) => {
                            //     this.handleDataChange('name', event.target.value)
                            // }}
                        />
                    </Wrapper>
                    <Wrapper>
                        <div/>
                        <div>
                            <Button disabled type={"submit"} variant={"contained"} color={"primary"}>Send</Button>
                        </div>
                    </Wrapper>
                </ValidatorForm>
            </Block>
        </Container>
    );
};

EmailSender.propTypes = {
    association: PropTypes.object.isRequired,
};

export default EmailSender;
