import PropTypes from "prop-types";
import React from "react";
import {Route, Switch, useRouteMatch} from "react-router-dom";

import Matchings from "../../../Pages/Buddy/Matchings";
import {EventsRouter} from "../../../Pages/Calendar/EventRouter";
import FileLibrary from "../../../Pages/Documents/FileLibrary";
import ReactMediaLibraryDemo from "../../../Pages/Documents/ReactMediaLibraryDemo";
import EmailLists from "../../../Pages/Email/EmailLists";
import EmailSender from "../../../Pages/Email/EmailSender";
import EmailTemplates from "../../../Pages/Email/EmailTemplates";
import EmailUsers from "../../../Pages/Email/EmailUsers";
import EmailSettings from "../../../Pages/Email/Settings";
import FinancialRouter from "../../../Pages/Financial/FinancialRouter";
import {GroupsRouter} from "../../../Pages/Groups/Board/GroupRouter";
import ProductCategories from "../../../Pages/Inventory/ProductCategories/ProductCategories";
import Products from "../../../Pages/Inventory/Products/Products";
import Purchases from "../../../Pages/Inventory/Purchases/Purchases";
import Lab from "../../../Pages/Lab/Lab";
import MembersDetailFromSlug from "../../../Pages/Members/Detail/MembersDetailFromSlug";
import Members from "../../../Pages/Members/Members";
import MembersCurrent from "../../../Pages/Members/MembersCurrent";
import MembersDisputed from "../../../Pages/Members/MembersDisputed";
import MembersIncoming from "../../../Pages/Members/MembersIncoming";
import MembersLeaving from "../../../Pages/Members/MembersLeaving";
import AssociationInfoScreen from "../../../Pages/MyAssociation/AssociationInfo";
import AssociationMemberSettings from "../../../Pages/MyAssociation/AssociationMemberSettings";
import Home from "../../../Pages/MyAssociation/Home/board";
import News from "../../../Pages/News/News";
import Partners from "../../../Pages/Partners/Partners";
import MembershipEnd from "../../../Pages/Profile/MembershipEnd";
import AssociationDataFields from "../../../Pages/Settings/AssociationDataFields";
import AssociationMembertypes from "../../../Pages/Settings/AssociationMembertypes";
import Modules from "../../../Pages/Settings/Modules";
import MemberStatistics from "../../../Pages/Statistics/MemberStatistics";


const BoardMemberRoutes = ({association_membership, association}) => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path+"/membership/end"}>
                <MembershipEnd/>
            </Route>

            <Route path={path+"/settings"}>
                <Route path={path+"/settings/modules"}>
                    <Modules/>
                </Route>
                <Route path={path+"/settings/data_fields"}>
                    <AssociationDataFields/>
                </Route>
                <Route path={path+"/settings/membertypes"}>
                    <AssociationMemberSettings/>
                    <AssociationMembertypes/>
                </Route>
            </Route>

            <Route path={path+"/association"}>
                <Route exact path={path+"/association/info"}>
                    <AssociationInfoScreen/>
                </Route>
                <Route path={path+"/association/news"}>
                    <News/>
                </Route>
                <Route path={path+"/association/partners"}>
                    <Partners/>
                </Route>
            </Route>

            { /*Groups*/ }
            <Route path={path+"/committees"}>
                <GroupsRouter boards={false}/>
            </Route>
            <Route path={path+"/boards"}>
                <GroupsRouter boards={true}/>
            </Route>
            <Route path={path+"/matchings"}>
                <Matchings/>
            </Route>

            <Route path={path+"/financial"}>
                <FinancialRouter/>
            </Route>

            { /*Emails*/ }
            <Route path={path + "/emails"}>
                <Route path={path+"/emails/settings"}>
                    <EmailSettings
                        association={association}
                    />
                </Route>
                <Route path={path+"/emails/templates"}>
                    <EmailTemplates
                        association={association}
                    />
                </Route>
                <Route path={path+"/emails/send"}>
                    <EmailSender
                        association={association}
                    />
                </Route>
                <Route path={path+"/emails/lists"}>
                    <EmailLists
                        association={association}
                    />
                </Route>
                <Route path={path+"/emails/users"}>
                    <EmailUsers
                        association={association}
                    />
                </Route>
            </Route>

            <Route path={path + "/events"}>
                <EventsRouter
                    association={association}
                    profile={association_membership.profile}
                />
            </Route>

            <Route path={path+"/members"}>
                <Route path={path+"/members/all"}>
                    <Members/>
                </Route>
                <Route path={path+"/members/current"}>
                    <MembersCurrent/>
                </Route>
                <Route path={path+"/members/incoming"}>
                    <MembersIncoming/>
                </Route>
                <Route path={path+"/members/disputed"}>
                    <MembersDisputed/>
                </Route>
                <Route path={path+"/members/leaving"}>
                    <MembersLeaving/>
                </Route>
                <Route path={path+"/members/:memberSlug"}>
                    <MembersDetailFromSlug/>
                </Route>
            </Route>

            <Route path={path+"/inventory"}>
                <Route path={path+"/inventory/products"}>
                    <Products
                        association={association}
                    />
                </Route>
                <Route path={path+"/inventory/categories"}>
                    <ProductCategories
                        association={association}
                    />
                </Route>
                <Route path={path+"/inventory/purchases"}>
                    <Purchases
                        association={association}
                    />
                </Route>
            </Route>

            <Route path={path+"/lab"}>
                <Lab/>
            </Route>

            <Route path={path+"/documents"}>
                <FileLibrary/>
                { /*<ReactMediaLibraryDemo/>*/ }
            </Route>

            <Route path={path+"/statistics/members"}>
                <MemberStatistics/>
            </Route>

            { /*Default route*/ }
            <Route exact path={path}>
                <Home/>
            </Route>
        </Switch>
    );
};

BoardMemberRoutes.propTypes = {
    association_membership: PropTypes.object.isRequired,
    association: PropTypes.object.isRequired
};

export default BoardMemberRoutes;
