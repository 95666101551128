import BallotIcon from "@mui/icons-material/Ballot";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";

import SelectField from "../../../Components/FormComponents/Fields/SelectField";
import IconHolder from "../../../Components/FormComponents/IconHolder";
import Wrapper from "../../../Components/FormComponents/Wrapper";
import FormModal from "../../../Components/Modals/FormModal";

const MemberTypeRemoveModal = ({ toBeRemovedMemberType, alternativeMemberTypes, modalOpen, toggleModalOpen, onDelete }) => {
    return (
        <FormModal
            title={"Select alternative member type"}
            size={"md"}
            open={modalOpen}
            onCancel={toggleModalOpen}
            onSubmit={onDelete}
            cancelButtonText={"Cancel"}
            submitButtonText={"Confirm"}
        >
            <Typography>
                Each member needs to have a member type.
                Some members have the member type, { toBeRemovedMemberType.type }, that you are trying to delete.
                Select a new member type for these members.
            </Typography>
            <Wrapper>
                <IconHolder Icon={BallotIcon}/>
                <SelectField
                    name={"alternativeMemberType"}
                    label={"Alternative member type"}
                    required
                >
                    { alternativeMemberTypes && alternativeMemberTypes.map(memberType => (
                        <MenuItem key={memberType.slug} value={memberType.slug}>
                            { memberType.type }
                        </MenuItem>
                    )) }
                </SelectField>
            </Wrapper>
        </FormModal>
    );
};

MemberTypeRemoveModal.propTypes = {
    toBeRemovedMemberType: PropTypes.object.isRequired,
    alternativeMemberTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    modalOpen: PropTypes.bool.isRequired,
    toggleModalOpen: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default MemberTypeRemoveModal;