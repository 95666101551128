import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    routing: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    }
}));

const RoutingJson = {
    "1": [{name: "Association > Members", link: "/association"}]
    // "1": [{name: "Association", link: "/association"}, {name: "Members", link: "/association/members"}]
};

const RoutingButton = ({ routeStringPieces }) => {
    const classes = useStyles();

    const { pathname } = useLocation();

    if (routeStringPieces?.length > 0) {
        const routeString = routeStringPieces.join(" > ");
        return (
            <Typography variant={"subtitle2"}>
                { routeString }
            </Typography>
        );
    } else {
        return <br/>;
    }

    // eslint-disable-next-line no-unreachable
    const routingBlocks = RoutingJson["1"];
    const numberOfBlock = routingBlocks.length;

    return (
        <div className={classes.routing}>
            { routingBlocks.map((block, id)=> (
                <React.Fragment key={id}>
                    <Link href={block.link} underline={"hover"}>{ block.name }</Link>
                    { id + 1 < numberOfBlock && " > " }
                </React.Fragment>
            )) }
        </div>
    );
};

RoutingButton.propTypes = {
    routeStringPieces: PropTypes.arrayOf(PropTypes.string)
};

RoutingButton.defaultProps = {
    routeStringPieces: []
};

export default RoutingButton;