import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DescriptionIcon from "@mui/icons-material/Description";
import EventIcon from "@mui/icons-material/Event";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import PersonIcon from "@mui/icons-material/Person";
import ShareIcon from "@mui/icons-material/Share";
import React, {useMemo} from "react";
import {useLocation} from "react-router-dom";

import {useGetModulesByAssociationQuery} from "../../../Store/services/MySU/modules";
import {useMemberships} from "../../../Store/services/MySU/user";
import Drawer from "../../Lists/Drawer/Drawer";

const AssociationMemberSideBar = (props) => {
    return AssociationMemberDrawer(props);
};

export default AssociationMemberSideBar;

const AssociationMemberDrawer = () => {
    const location = useLocation();
    let url = location.pathname;
    const slug = url.split("/")[3];
    url = url.substring(0, url.indexOf("member") + 6);

    const { data: modules } = useGetModulesByAssociationQuery(slug);

    const data = useMemo(()=> {
        let data = [
            {
                icon: <HomeIcon/>,
                primary: "Home",
                to: url
            },
            {
                icon: <GroupsIcon/>,
                primary: "Association",
                items: [
                    {
                        // icon: <ShareIcon/>,
                        primary: "About",
                        to: url + "/about"
                    },
                    // {
                    //     // icon: <InfoIcon/>,
                    //     primary: "Info",
                    //     to: url + "/info"
                    // },
                    {
                        // icon: <GradeIcon/>,
                        primary: "Board",
                        to: url + "/board"
                    },
                    // {
                    //     icon: <BusinessIcon/>,
                    //     primary: "Partners",
                    //     to: url + "/association/partners"
                    // },
                ]
            },
            {
                icon: <PersonIcon/>,
                primary: "Personal",
                to: url + "/my-data"
            }
        ];
        if (modules) {
            if (modules["committees"]) {
                data[1].items.push(
                    {
                        // icon: <SupervisedUserCircleIcon/>,
                        primary: "Committees",
                        to: url + "/committees"
                    },
                );
            }
            if (modules["matchings"]) {
                data.push(
                    {
                        icon: <ShareIcon />,
                        primary: "Matchings",
                        to: url + "/matchings"
                    },
                );
            }
            if (modules["events"]) {
                data.push(
                    {
                        icon: <EventIcon/>,
                        primary: "Calendar",
                        to: url + "/events"
                    },
                );
            }
            if (modules["documents"]) {
                data.push(
                    {
                        icon: <DescriptionIcon/>,
                        primary: "Documents",
                        to: url + "/documents"
                    },
                );
            }
        }
        // {
        //     icon: <BookIcon />,
        //     primary: "Education",
        //     items: [
        //         {
        //             icon: <SupervisedUserCircleIcon />,
        //             primary: "Exams",
        //             to: url + '/committees'
        //         },
        //     ]
        // },
        return data;
    }, [url, modules]);

    const memberships = useMemberships();
    const currentMembership = memberships?.find(membership=>membership.association.slug===slug)?.current;
    if (!currentMembership) {
        return null;
    }

    return (
        <Drawer items={data}/>
    );
};
