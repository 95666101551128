import PropTypes from "prop-types";
import React from "react";

const withExtraProps = (WrappedComponent, extraProps) => props => {
    return <WrappedComponent {...props} {...extraProps}/>;
};

withExtraProps.propTypes = {
    WrappedComponent: PropTypes.elementType.isRequired,
    extraProps: PropTypes.func.isRequired
};

export default withExtraProps;