import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import LinesEllipsis from "react-lines-ellipsis";

import Sushi from "../../../../../img/Sushi-11-me.jpg";


const EventCard = ({ name, description, start_date, end_date, image, onClick }) => {
    let dateString;
    if (moment(start_date).format("D MMM") === moment(end_date).format("D MMM")) {
        dateString = moment(start_date).format("D MMM");
    } else if (moment(start_date).format("MMM") === moment(end_date).format("MMM")) {
        dateString = moment(start_date).format("D") + " - " + moment(end_date).format("D MMM");
    } else {
        dateString = moment(start_date).format("D MMM") + " - " + moment(end_date).format("D MMM");
    }

    return (
        <Card sx={{ width: 560, borderRadius: 4 }}>
            <CardActionArea onClick={onClick} sx={{ height: 248, pl: 3, pr: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <CardMedia
                            image={image || Sushi}
                            sx={{
                                width: 244,
                                height: 200,
                                borderRadius: 4
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={8}>
                                <Typography variant={"h5"}>{ name }</Typography>
                                <Typography variant={"h6"}>
                                    { moment(start_date).format("h:mm")  } - { moment(end_date).format("h:mm")  }
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={{
                                    borderColor: "primary.main",
                                    borderWidth: 0.5,
                                    borderStyle: "solid",
                                    borderRadius: 4,
                                    padding: 1.5
                                }}
                                >
                                    <Typography color={"primary.main"}>
                                        { dateString }
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <LinesEllipsis
                            className={"MuiTypography-root MuiTypography-body1"}
                            component={"p"}
                            maxLine={5}
                            text={description || ""}
                        />
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    );
};

EventCard.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    start_date: PropTypes.any,
    end_date: PropTypes.any,
    image: PropTypes.any,
};

EventCard.defaultProps = {
};

export default EventCard;
