import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import React from "react";

import SelectField from "../../Components/FormComponents/Fields/SelectField";
import IconHolder from "../../Components/FormComponents/IconHolder";
import Wrapper from "../../Components/FormComponents/Wrapper";
import FormModal from "../../Components/Modals/FormModal";

const EnrollModal = ({open, onCancel, onSubmit, enrollment, updateEnrollment, enrollmentOptions}) => {
    return (
        <FormModal
            title={"Enrollment"}
            size={"xs"}
            open={open}
            onCancel={onCancel}
            onSubmit={onSubmit}
            cancelButtonText={"Cancel"}
            submitButtonText={"Enroll"}
        >
            <Wrapper>
                <IconHolder Icon={FormatListBulletedIcon}/>
                <SelectField
                    name={"Enrollment Option"}
                    value={enrollment.enrollment_option}
                    onChange={(event)=>updateEnrollment("enrollment_option", event.target.value)}
                >
                    { enrollmentOptions.map((enrollmentOption, index) => (
                        <MenuItem key={index} value={enrollmentOption.url}>
                            { enrollmentOption.name }
                        </MenuItem>
                    )) }
                </SelectField>
            </Wrapper>
        </FormModal>
    );
};

EnrollModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    enrollment: PropTypes.object.isRequired,
    updateEnrollment: PropTypes.func.isRequired,
    enrollmentOptions: PropTypes.array.isRequired
};

export default EnrollModal;