import {emptySplitApi, providesListTags} from "./index";

const newsApi = emptySplitApi
    .enhanceEndpoints({addTagTypes: ["News"]})
    .injectEndpoints({
        endpoints: (build) => ({
            getNewsByAssociation: build.query({
                query: (associationSlug) => ({ url: "/news", params: {"association__slug": associationSlug} }),
                providesTags: (result, error, associationSlug) =>
                    providesListTags(result, "News", associationSlug),
                transformResponse: (response) => response.results
            }),
            getNews: build.query({
                query: (news_slug) => (news_slug && { url: `/news/${news_slug}`  }),
                providesTags: (result, error, news_slug) => [{ type: "News", id: news_slug }],
            }),
            addNews: build.mutation({
                query: (body) => ({ url: "/news/", method: "POST", body: body }),
                invalidatesTags: (result) => [{ type: "News", id: result.association.split("/associations/")[1] }],
            }),
            patchNews: build.mutation({
                query: ({ slug, ...patch}) => ({ url: `/news/${slug}`, method: "PATCH", body: patch }),
                invalidatesTags: (result, error, arg) => [{ type: "News", id: arg.association.split("/associations/")[1] }],
            }),
            deleteNews: build.mutation({
                query: (slug) => ({ url: `/news/${slug}`, method: "DELETE" }),
                invalidatesTags: (result, error, arg) => [{ type: "News", id: arg.association.split("/associations/")[1] }],
            })
        }),
        overrideExisting: false
    });

export const {
    useGetNewsByAssociationQuery,
    useGetNewsQuery,
    useAddNewsMutation,
    usePatchNewsMutation,
    useDeleteNewsMutation
} = newsApi;
export default newsApi;