import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EuroIcon from "@mui/icons-material/Euro";
import GroupsIcon from "@mui/icons-material/Groups";
import InfoIcon from "@mui/icons-material/Info";
import PlaceIcon from "@mui/icons-material/Place";
import TodayIcon from "@mui/icons-material/Today";
import {DialogActions, DialogContentText, ListItem} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useState} from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import {useParams} from "react-router-dom";

import Image from "../../../../../img/default_photo.jpg";
import Button from "../../../../Components/Buttons/Button";
import CheckboxField from "../../../../Components/FormComponents/Fields/CheckboxField";
import SelectField from "../../../../Components/FormComponents/Fields/SelectField";
import useAddValidationRule from "../../../../Components/Hooks/useAddValidationRule";
import isTruthy from "../../../../Components/ValidatorRules/isTruthy";
import {useGetEnrollmentOptionsByEventQuery} from "../../../../Store/services/MySU/enrollmentOptions";
import {
    useAddEnrollmentMutation, useDeleteEnrollmentMutation,
    useGetEnrollmentsByEventQuery
} from "../../../../Store/services/MySU/enrollments";
import {useGetEventQuery} from "../../../../Store/services/MySU/events";
import {usePreferredProfile} from "../../../../Store/services/MySU/user";

const useStyles = makeStyles(()=>({
    image: {
        borderRadius: 16,
        maxHeight: 200,
        width: "100%",
        objectFit: "cover"
    }
}));

const EventModal = ({ slug, onClose }) => {
    const classes = useStyles();

    const { slug: associationSlug } = useParams();
    const profile = usePreferredProfile();

    const { data: event, isLoading } = useGetEventQuery(slug, { skip: slug === "" });
    const { data: enrollmentOptions } = useGetEnrollmentOptionsByEventQuery(slug, { skip: slug === "" });
    const { data: enrollments } = useGetEnrollmentsByEventQuery(slug, { skip: slug === "" });
    const [ addEnrollment ] = useAddEnrollmentMutation();
    const [ deleteEnrollment ] = useDeleteEnrollmentMutation();

    useAddValidationRule(ValidatorForm, isTruthy.name, isTruthy.validate);


    const participationFees = enrollmentOptions?.map(enrollmentOption=> parseFloat(enrollmentOption.participation_fee)) || [];
    const minimumFee = Math.min(...participationFees);
    const maximumFee = Math.max(...participationFees);
    let feeString = "";
    if (minimumFee !== Infinity) {
        if (minimumFee === maximumFee) {
            feeString = minimumFee.toLocaleString("en-GB", { style: "currency", currency: "EUR" });
        } else {
            feeString = minimumFee.toLocaleString("en-GB", { style: "currency", currency: "EUR" })
                + " - " + maximumFee.toLocaleString("en-GB", { style: "currency", currency: "EUR" });
        }
    }
    const yourEnrollment = enrollments?.find(enrollment => enrollment.profile === profile.url) || {};
    const [enrollmentFormState, setEnrollmentFormState] = useState({
        enrollment_option: "",
        unenroll_notice_checked: false,
        debt_collection_okay_given: false,
    });
    const updateEnrollmentFormState = (field, value) => {
        setEnrollmentFormState(prevState =>({...prevState, [field]: value}));
    };
    const onSubmit = () => {
        addEnrollment({
            enrollment_option: enrollmentFormState.enrollment_option,
            association: "/associations/" + associationSlug,
            profile: profile.url,
            event: "/events/" + slug,
        });
    };
    const disenroll = () => deleteEnrollment({ slug: yourEnrollment.slug });

    if (isLoading) {
        return null;
    }

    return (
        <Dialog
            open={slug !== "" && !isLoading && event !== undefined && event.slug === slug && enrollmentOptions !== undefined}
            onClose={onClose}
            fullWidth={true}
            maxWidth={"sm"}
            scroll={"body"}
            aria-labelledby={"contained-modal-title-vcenter"}
        >
            <DialogTitle>
                <Typography variant={"h5"}>{ event?.name }</Typography>
            </DialogTitle>
            <DialogContent >
                <img src={event?.photo || Image} alt={"bad description"} className={classes.image}/>
                <Grid container justifyContent={"space-between"}>
                    <Grid item xs={7}>
                        <DialogContentText>
                            { event?.description }
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={4}>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <TodayIcon/>
                                </ListItemIcon>
                                <ListItemText primary={moment(event?.start_date).format("L")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <AccessTimeIcon/>
                                </ListItemIcon>
                                <ListItemText primary={moment(event?.start_date).format("L")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <PlaceIcon/>
                                </ListItemIcon>
                                <ListItemText primary={event?.location}/>
                            </ListItem>
                            { event?.enrollable &&
                                <ListItem>
                                    <ListItemIcon>
                                        <EuroIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={feeString}/>
                                </ListItem>
                            }
                            { event?.enrollable &&
                                <ListItem>
                                    <ListItemIcon>
                                        <GroupsIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={event?.max_number_of_enrollments > 0
                                        ? enrollments?.length + " / " + event?.max_number_of_enrollments
                                        : <>&infin;</>
                                    }
                                    />
                                </ListItem>
                            }
                        </List>
                    </Grid>
                </Grid>
                { event?.enrollable && (
                    <>
                        <Divider/>
                        <Typography variant={"h6"}>Enrollment</Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <InfoIcon/>
                                </ListItemIcon>
                                <ListItemText primary={"You can enroll from " + moment(event?.enrollable_from).format("LLL")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <InfoIcon/>
                                </ListItemIcon>
                                <ListItemText primary={"You can enroll until " + moment(event?.enrollable_until).format("LLL")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <InfoIcon/>
                                </ListItemIcon>
                                { event?.unenrollable
                                ? <ListItemText
                                    primary={"You can disenroll until " + moment(event?.unenrollable_until).format("LLL")}
                                />
                                : <ListItemText
                                    primary={"You cannot disenroll"}
                                />
                                }
                            </ListItem>
                            :
                        </List>
                        <Divider/>
                        <Typography variant={"h6"}>Who&apos;s joining?</Typography>
                        { enrollments
                            ? (
                                <List>
                                    { enrollments?.map( enrollment => (
                                        <ListItem key={enrollment.slug}>
                                            <ListItemText primary={enrollment.given_name + " " + enrollment.given_name}/>
                                        </ListItem>
                                    )) }
                                </List>
                            )
                            : <CircularProgress />
                        }
                    </>) }
            </DialogContent>
            { event?.enrollable && (yourEnrollment.slug
                ? (
                    <>
                        <DialogContent>
                            <Divider/>
                            <Typography variant={"h6"}>Enrolled as:</Typography>
                            <Typography>
                                { enrollmentOptions?.find(enrollmentOption => enrollmentOption.url === yourEnrollment.enrollment_option)?.name }
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose}>Cancel</Button>
                            { event?.unenrollable &&
                                <Button onClick={disenroll}>Disenroll</Button>
                            }
                        </DialogActions> 
                    </>
                ) : (
                <ValidatorForm
                    onSubmit={onSubmit}
                    onError={errors => console.log(errors)}
                >
                    <DialogContent>
                        <Divider/>
                        <Typography variant={"h6"}>Enroll as:</Typography>
                        <br/>
                        <SelectField
                            name={"enrollment_option"}
                            label={"Enrollment option"}
                            value={enrollmentFormState.enrollment_option}
                            onChange={(event)=>updateEnrollmentFormState("enrollment_option", event.target.value)}
                        >
                            { enrollmentOptions?.map(enrollmentOption => (
                                <MenuItem key={enrollmentOption.slug} value={enrollmentOption.url}>
                                    { enrollmentOption.name }
                                </MenuItem>
                            )) }
                        </SelectField>
                        <CheckboxField
                            checked={enrollmentFormState.debt_collection_okay_given}
                            value={enrollmentFormState.debt_collection_okay_given}
                            name={"debt_collection_okay_given"}
                            onChange={(event)=>updateEnrollmentFormState("debt_collection_okay_given", event.target.checked)}
                            label={"I agree to the debt collection of the enrollment fee."}
                            labelPlacement={"end"}
                            validators={["isTruthy"]}
                            errorMessages={["This field is required"]}
                        />
                        <CheckboxField
                            checked={enrollmentFormState.unenroll_notice_checked}
                            value={enrollmentFormState.unenroll_notice_checked}
                            name={"unenroll_notice_checked"}
                            onChange={(event)=>updateEnrollmentFormState("unenroll_notice_checked", event.target.checked)}
                            label={"I am aware I cannot disenroll."}
                            labelPlacement={"end"}
                            validators={["isTruthy"]}
                            errorMessages={["This field is required"]}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type={"submit"} variant={"contained"}>Enroll</Button>
                    </DialogActions>
                </ValidatorForm>
                )) }
        </Dialog>
    );
};

EventModal.propTypes = {
    slug: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default EventModal;